import React from "react";
import styled from "styled-components";
import { FaTimes } from "react-icons/fa";
import Store from "../../Common/Store";
import AlertBox from '../Common/AlertBox';
import SearchableSelect from 'react-select';

const EmailContainer = styled.div`
	width: 60%;
	height:auto;
	// top:0%;
	position: absolute;
	background-color: #f6f9fd;
    border-radius: 2px;
    box-shadow: 0 0 20px #a7a6a6;
	z-index: 1005;
	padding: 0px;
`;
const CancelBtn = styled.button`
    padding: 10px 30px;
	background-color: #ffffff;
	border: 1px solid #EBEBEB;
	border-radius: 2px;
	color: #282828;
	cursor: pointer;	
`;
const MRModalLabel = styled.div`
    color: #212121;
    font-weight: 600;
    margin-top: 10px;
    margin-bottom: 10px;
`;
const MRModalHeader = styled.div`
    background-color: white;
    display: block;
    padding-top: 5px;
    font-size: 14px;
    font-weight: 700;
    height: 100px;    
    width: calc(100% - 10px);
    border-width: 1px 1px 1px 10px;    
    border-left: 9px solid #04ADA8; 
    border-bottom: 1px solid #DFDFDF;
`;
const MRModalHeaderText = styled.div`
    font-weight: 700;
    font-size: 22px;
    float: left;
    margin-top: 24px;
    margin-left: 35px;  
    color: #143151; 
`;
const MRModalHeaderCloseBtn = styled.div`
    position: absolute;
    top: 8px;
    right: 10px;
    box-sizing: border-box;
    margin-top: 24px;
    margin-right: 20px;
    font-size: 25px;
    cursor: pointer;
    font-weight: 200;
`;
const MRModalBody = styled.div`
	position: relative;
	display: block;
	box-sizing: border-box;
	background-color: #F6F9FD;
	padding: 35px 35px 35px 35px;
	border-radius: 4px;
`;
const SaveBtn = styled.button`
	padding: 10px 30px;
	background-color: #37ADA7;
	border: 1px solid #37ADA7;
	border-radius: 2px;
	color: #ffffff;
	cursor: pointer;
	margin-left: 15px;
`;
const MRModalSubHeaderText = styled.div`
    font-weight: 700;
    font-size: 13px;
    float: left;
    margin-left: 35px;
    margin-bottom: 20px;
    margin-top: 10px;
    color: #636363;
`;
const DocFooter = styled.div`
    position: relative;
    background-color: white;
    border-style: solid;
    border-width: 1px;
    border-color: #e9ecef;
    height: 79px;
    width: 100%;
    margin-left: 0px;
    border-bottom-radius: 2px;
`;
const FooterButtonsContainer = styled.div`
	text-align: right;
	padding-top: 25px;
	width: 95%;
`;

const ScoreBodyContainer = styled.div`
  padding: 10px;
  text-align: left;
//   overflow-y: scroll;
  height: 350px;
`;

class AddEditUserLevelApprovalModal extends React.Component {
    state = {
        users_list: [],
        specific_user_list: [],
        selected_user: {},
        approval_required_list: [
            { value: 'ar', label: 'Approver Role' },
            { value: 'lm', label: 'Line Manager' },
            { value: 'nr', label: 'Not required (Auto-approve)' },
            { value: 'su', label: 'Specific User' }
        ],
        selected_approval: {},
        selected_su_user: {},
        alert_param: null,
        isActive: 1,
        isDelete: 0,
        ready: false
    };

    componentDidMount() {
        console.log("curid", this.props.curid)
        let user_level_approval = this.props.user_level_approval;
        if(this.props.curid !== 0) {
            let contacts = this.props.allActiveUsers;
            let val = this.props.user_level_approval[this.props.curid.toString()]
            let users_list = [];
            let specific_user_list = [];
            for(let k in contacts){
                if(!(k in user_level_approval)){
                    let user = { value: k, label: contacts[k.toString()] };
                    users_list.push(user);
                }
                if(k.toString() === this.props.curid.toString()){
                    let user = { value: k, label: contacts[k.toString()] };
                    users_list.push(user);
                }
                if(val.id.toString() !== k.toString()){
                    let spuser = { value: k, label: contacts[k.toString()] };
                    specific_user_list.push(spuser);
                }
            }

            let selected_user = { value: val.id, label: val.name }
            let selected_approval = { value: val.app_req_id, label: val.app_req_name }
            let selected_su_user = 'su_user' in val ? val.su_user : {}

            users_list = users_list.sort(function(a,b) {return (a.label.toUpperCase() > b.label.toUpperCase()) ? 1 : ((b.label.toUpperCase() > a.label.toUpperCase()) ? -1 : 0);});
            specific_user_list = specific_user_list.sort(function(a,b) {return (a.label.toUpperCase() > b.label.toUpperCase()) ? 1 : ((b.label.toUpperCase() > a.label.toUpperCase()) ? -1 : 0);});

            this.setState({users_list, selected_user, selected_approval, specific_user_list, selected_su_user, ready: true})
        }else{
            let contacts = this.props.allActiveUsers;
            let users_list = [];
            let specific_user_list = [];
            for(let k in contacts){
                if(!(k in user_level_approval)){
                    let user = { value: k, label: contacts[k.toString()] };
                    users_list.push(user);
                }
                let spuser = { value: k, label: contacts[k.toString()] };
                specific_user_list.push(spuser);
            }

            users_list = users_list.sort(function(a,b) {return (a.label.toUpperCase() > b.label.toUpperCase()) ? 1 : ((b.label.toUpperCase() > a.label.toUpperCase()) ? -1 : 0);});
            specific_user_list = specific_user_list.sort(function(a,b) {return (a.label.toUpperCase() > b.label.toUpperCase()) ? 1 : ((b.label.toUpperCase() > a.label.toUpperCase()) ? -1 : 0);});

            this.setState({users_list, specific_user_list, ready: true})
        }
    }

    closeModal = (event) => {
		event.preventDefault();
		this.props.closeModal();
    }

    handleSelectUser = (data) => {
        //console.log("data==>", data)
        let sp_update_user_list = [];
        let sp_user_list = this.state.specific_user_list;
        for(let d of sp_user_list){
            if(d.value.toString() !== data.value.toString()){
                sp_update_user_list.push(d)
            }
        }

        let ssu = this.state.selected_su_user
        if(this.props.curid !== 0 && Object.keys(this.state.selected_su_user).length !== 0) {
            if(data.value.toString() === this.state.selected_su_user.value.toString()){
                ssu = {}
            }
        }
        console.log("sp_update_user_list==>", sp_update_user_list)
        sp_update_user_list = sp_update_user_list.sort(function(a,b) {return (a.label.toUpperCase() > b.label.toUpperCase()) ? 1 : ((b.label.toUpperCase() > a.label.toUpperCase()) ? -1 : 0);});

        this.setState({selected_user: data, specific_user_list: sp_update_user_list, selected_su_user: ssu})
    }
    handleSelectSUUser = (data) => {
        this.setState({selected_su_user: data})
    }
    handleSelectApproval = (data) => {
        //console.log("data==>", data)
        this.setState({selected_approval: data, selected_su_user: {}})
    }

    submitUserLevelApproval = (event) => {
        if(Object.keys(this.state.selected_user).length === 0){
            let alert_param = {title: 'ERROR', message: 'Please select a user name.', ok_text: 'Ok', confirm: false,
                           alertHandler: this.checkvalidation, stack: {}}
            this.setState({alert_param: alert_param})
            return
        }
        if(Object.keys(this.state.selected_approval).length === 0){
            let alert_param = {title: 'ERROR', message: 'Please select a approval required.', ok_text: 'Ok', confirm: false,
                           alertHandler: this.checkvalidation, stack: {}}
            this.setState({alert_param: alert_param})
            return
        }
        if(Object.keys(this.state.selected_approval).length !== 0 && this.state.selected_approval.value === 'su' && Object.keys(this.state.selected_su_user).length === 0){
            let alert_param = {title: 'ERROR', message: 'Please select a specific user name.', ok_text: 'Ok', confirm: false,
                           alertHandler: this.checkvalidation, stack: {}}
            this.setState({alert_param: alert_param})
            return
        }

        let resultObject = {}
        resultObject.id = this.state.selected_user.value.toString();
        resultObject.name = this.state.selected_user.label;
        resultObject.app_req_id = this.state.selected_approval.value;
        resultObject.app_req_name = this.state.selected_approval.label;
        resultObject.su_user = this.state.selected_approval.value === 'su' ? this.state.selected_su_user : {}
        resultObject.isActive = parseInt(this.state.isActive);
        resultObject.isDelete = parseInt(this.state.isDelete);
        console.log("resultObject==>", resultObject)
        if(this.props.curid !== 0) {
            this.props.updateUserLevelApproval(resultObject, this.props.curid);
        }else{
            this.props.saveUserLevelApproval(resultObject);
        }
    }

    checkvalidation = (result, stack) => {
        this.setState({alert_param: null})
    }

    render() {
        if(!this.state.ready){
            return(<div>Loading...</div>)
        }
        let headerText = 'headerText' in this.props ? this.props.headerText : 'Add';
        return (<div>
			<EmailContainer style={{top: '22%'}}>
                <MRModalHeader>
                    <MRModalHeaderText>{headerText} User to Approval Whitelist</MRModalHeaderText>
                    <MRModalHeaderCloseBtn onClick={this.closeModal}><FaTimes /></MRModalHeaderCloseBtn>
                    <div style={{clear: "both"}}></div>
                    <MRModalSubHeaderText>Add a user which requires custom approval.</MRModalSubHeaderText>
                    <div style={{clear: "both"}}></div>
                </MRModalHeader>
                <ScoreBodyContainer>
                <MRModalBody>
                    <MRModalLabel>Name <span style={{marginLeft: "5px",color: "red"}}>*</span></MRModalLabel>
                    <SearchableSelect options={this.state.users_list} placeholder="Select user" value={this.state.selected_user} onChange={this.handleSelectUser}/>
                    <MRModalLabel style={{marginTop: "20px"}}>Approval Required <span style={{marginLeft: "5px",color: "red"}}>*</span></MRModalLabel>
                    <SearchableSelect options={this.state.approval_required_list} placeholder="Select a approval" value={this.state.selected_approval} onChange={this.handleSelectApproval} isSearchable={false}/>
                    {
                        (() => {
                            if(Object.keys(this.state.selected_approval).length !== 0 && this.state.selected_approval.value === 'su'){
                                return(<div>
                                    <MRModalLabel style={{marginTop: "20px", marginBottom: "0px"}}>Name of Specific Approver <span style={{marginLeft: "5px",color: "red"}}>*</span></MRModalLabel>
                                    <MRModalLabel style={{marginTop: "5px", fontSize: "11px", color: "#212121b3"}}>(Note: Only this named individual will be able to approve gifts entered for the user added to the approval whitelist)</MRModalLabel>
                                    <SearchableSelect options={this.state.specific_user_list} value={this.state.selected_su_user} onChange={this.handleSelectSUUser} />
                                </div>)
                            }
                        })()
                    }

                </MRModalBody>
                </ScoreBodyContainer>
                <div style={{clear: "both"}}></div>
                <DocFooter style={{float: "right"}}>
                    <FooterButtonsContainer>
                        <CancelBtn onClick={this.closeModal}>Cancel</CancelBtn>
                        <SaveBtn onClick={this.submitUserLevelApproval}>Submit</SaveBtn>
                    </FooterButtonsContainer>
                </DocFooter>
			</EmailContainer>
            <AlertBox alertParam = {this.state.alert_param}/>
            </div>);
    }
}
export default AddEditUserLevelApprovalModal;
