import Store from './Store';

class XcelLoader {
	gifts_export_tasks(tasks){		
		let j = 0;
		let results = [];
		let module_config = Store.getStoreData('module_config');  
		let contacts = Store.getStoreData('contacts');  
		for(let d of tasks) {
			j++;		  
		    let elem = {		            
		            'Status': d.status,
		            'ID': d.giftid,
		            'Received/Given' : d.receivedorgiven,
		            'Type' : d.gifttype,
					'Gifted Date' : d.gifted_date,
		            'Recipient/Given By' : d.recipientname,
					'Donor/Given To' : d.receivedorgiven === "Received" ? d.donor : d.giftGivenTo,
					'Company' : d.company_name,
					'Value' : d.value,
					'Result' : d.resultvalue,
					'Added Date' : d.discloserdate,						
					'Reviewer' : d.reviewerName,
					'Reviewed' : d.reviewedDate,
					'Description Text' : d.description,
					'No of follow-up' : d.no_of_followup,
					'Added By': d.addedBy in contacts ? contacts[d.addedBy]	: ''							
		           };	
				let ARText = d.approval_rejection_text;
				if(d.approval_rejection_description !== ""){
					ARText = d.approval_rejection_text + " - " + d.approval_rejection_description;
				}
				elem['Approval / Rejection Text'] = ARText;
				if(Object.keys(module_config).length > 0 && module_config.custom_fields.enabled) {					
					if(module_config.custom_fields.additional_fields.length !== 0 && d.additional_fields.length !== 0){
						d.additional_fields.forEach((item) => {
							if(item.type === 'Dropdown' && item.value === 'other'){
								elem[item.nickname] = item.other_value;
							}else{
								elem[item.nickname] = item.value;
							}							
						})
					}
				}	    
		    results.push(elem)
		}
		return results;
	}
}
export default new XcelLoader();
