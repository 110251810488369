import React from 'react';
import styled from 'styled-components';
import Store from '../../Common/Store';
import currency_codes from './currency_codes.js'
import { IoIosCheckboxOutline, IoIosSquareOutline} from "react-icons/io";

const SelectedRadio = styled.div`
	background-color: #37ADA7;
	border: 1px solid #1f615e;
	padding: 5px 10px;
	color: #ffffff;
	display: inline-block;
	cursor: pointer;
`;
const UnselectedRadio = styled.div`
	background-color: #F4F4F4;
	border: 1px solid #8c8c8c;
	padding: 5px 10px;
	color: #262626;
	display: inline-block;
	cursor: pointer;
`;
const GeneralContainer = styled.div`
	width: 100%;
`;
const GeneralInnerContainer = styled.div`
	background-color: #F4F5F7;
	padding: 30px;
`;
const GeneralLabel = styled.div`
	font-weight: 600;
	color: #282828;
	margin-top: 20px;
`;
const RadioBlock = styled.div`
	margin-top: 10px;
`;
const MRModalSelect = styled.select`
    box-sizing: border-box;
    height: 40px;    
    background-color: #fff;
    border: 1px solid #ffffff;
    border-radius: 3px;
    box-shadow: 0 0 4px #c4c4c4;
    font-family: Montserrat,sans-serif;
    color: #444444;    
    padding: 10px;
    font-weight: 600;
`;
class General extends React.Component
{
	state = {general: null,currency: "",minvalue: 0}

	componentDidMount()
	{
		this.setState({general: this.props.general,currency: this.props.general.companyCurrencyDetails.currency,minvalue:this.props.general.companyCurrencyDetails.minvalue});		
	}

	componentDidUpdate(prevProps)
	{
		if (prevProps !== this.props) {			
			this.setState({general: this.props.general,currency: this.props.general.companyCurrencyDetails.currency,minvalue:this.props.general.companyCurrencyDetails.minvalue});			
		}
	}	

	handleChange = (evt) => {        
		//this.setState({[evt.target.name]: evt.target.value});
		//console.log("evt.target.value",evt.target.value)
		this.props.updateCurrencyValue(evt.target.value)
	}
	
	switchRadio = (key) => (event) => {		
		event.preventDefault();
		let {general} = this.state;
		general[key] = general[key] ? false : true;
		this.props.updateGeneral(general);
	}

	modifyClientCurrency = (e)=>{	
		let {general} = this.state;
		general.companyCurrencyDetails.currency = e.target.value;	
		this.setState({general, isLoadedIcon: true})	 
		this.props.updateGeneral(general);
	}

	isShowMsg = (value) => {
        let {general} = this.state;
		general['is_show_decl_msg'] = value;        
        this.props.updateGeneral(general);
    }

	render()
	{
		if (this.state.general === null) {
			return (<div>Loading...</div>);
		}

		return (
			<div>			
			<GeneralContainer>
				<GeneralInnerContainer>
					{
						(() => {
							if(Store.getStoreData('has_gc')){
								return(<div>
									<GeneralLabel>Show Parent Company Gifts</GeneralLabel>
									{
										
										(() => {
											if (this.state.general.show_parent_company_gifts) {
												return (
													<RadioBlock>
														<SelectedRadio>Yes</SelectedRadio>
														<UnselectedRadio onClick={this.switchRadio('show_parent_company_gifts')}>No</UnselectedRadio>																												
													</RadioBlock>
												);
											} else {
												return (
													<RadioBlock>
														<UnselectedRadio onClick={this.switchRadio('show_parent_company_gifts')}>Yes</UnselectedRadio>
														<SelectedRadio>No</SelectedRadio>																												
													</RadioBlock>
												);
											}
										})()
									}
								</div>)
							}else{
								return(<div>
									<GeneralLabel style={{color: "#bfbfbf"}}>Show Parent Company Gifts</GeneralLabel>
									{
										
										(() => {
											if (this.state.general.show_parent_company_gifts) {
												return (
													<RadioBlock>
														<SelectedRadio style={{backgroundColor: "#72b9b5",border: "1px solid #bfbfbf",cursor: "default"}}>Yes</SelectedRadio>
														<UnselectedRadio style={{color: "#9c9c9c",border: "1px solid #bfbfbf",cursor: "default"}}>No</UnselectedRadio>																												
													</RadioBlock>
												);
											} else {
												return (
													<RadioBlock>
														<UnselectedRadio style={{color: "#9c9c9c",border: "1px solid #bfbfbf",cursor: "default"}}>Yes</UnselectedRadio>	
														<SelectedRadio style={{backgroundColor: "#72b9b5",border: "1px solid #bfbfbf",cursor: "default"}}>No</SelectedRadio>																											
													</RadioBlock>
												);
											}
										})()
									}
								</div>)
							}
						})()
					}
					<GeneralLabel style={{marginBottom: "10px"}}>Client Currency</GeneralLabel>
					<MRModalSelect name="client_currency" onChange={(e)=> this.modifyClientCurrency(e)} value={this.state.general.companyCurrencyDetails.currency}>	
						{/* <option value='EUR'>EUR</option>					
						<option value='GBP'>GBP</option>						
						<option value='USD'>USD</option> */}
						{currency_codes.map((cc) => (
							<option key={cc.code} value={cc.code}>
							{cc.code}
							</option>
						))}
					</MRModalSelect>
					<GeneralLabel style={{marginBottom: "10px"}}>Enable 'Auto-Approval' for gifts below the declarable limit</GeneralLabel>
					{
										
						(() => {
							if (this.state.general.enable_auto_approval) {
								return (
									<RadioBlock>										
										<SelectedRadio>Yes</SelectedRadio>		
										<UnselectedRadio onClick={this.switchRadio('enable_auto_approval')}>No</UnselectedRadio>								
									</RadioBlock>
								);
							} else {
								return (
									<RadioBlock>
										<UnselectedRadio onClick={this.switchRadio('enable_auto_approval')}>Yes</UnselectedRadio>
										<SelectedRadio>No</SelectedRadio>																				
									</RadioBlock>
								);
							}
						})()
					}
					<div style={{display: "inline-block", width: "100%",marginTop: "25px", marginBottom: "10px"}}>         
                        {
                            (() => {
                                if(this.state.general.is_show_decl_msg === 1){
                                    return(<div style={{cursor: "pointer", fontSize: "21px", float: "left", display: "inline-block", width: "4%"}} onClick={() => this.isShowMsg(0)}><IoIosCheckboxOutline /></div>)
                                }else{
                                    return(<div style={{cursor: "pointer", fontSize: "21px", float: "left", display: "inline-block", width: "4%"}} onClick={() => this.isShowMsg(1)}><IoIosSquareOutline /></div>)
                                }
                            })()
                        }                          
                        <div style={{display: "inline-block", width: "90%", marginTop: "2px"}}>
                            <div style={{fontFamily: "Montserrat,sans-serif", fontWeight: "600" ,color: "#222222"}}>Display warning that value of gift/hospitality is under the reportable limit</div>                            
                        </div>
                    </div>  					
				</GeneralInnerContainer>
			</GeneralContainer>			
			</div>
		);
	}
}

export default General;