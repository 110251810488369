import React from 'react';
import styled from 'styled-components';

const RoleSelect = styled.select`
    display: block;
    box-sizing: border-box;
    height: 30px;
    width: 100%;
    background-color: #fff;
    border: 1px solid #ffffff;
    border-radius: 3px;
    box-shadow: 0 0 4px #c4c4c4;
    font-family: Montserrat,sans-serif;
    color: #222222;
`;

class RoleChanger extends React.Component
{
	state = {roles: null, active_role: null, user_id: null};

	componentDidMount()
	{
		this.setState({roles: this.props.roles, active_role: this.props.active_role, user_id: this.props.user_id});
	}

	componentDidUpdate(prevProps)
	{
		if (prevProps !== this.props) {
			this.setState({roles: this.props.roles, active_role: this.props.active_role, user_id: this.props.user_id});
		}
	}

	handleChange = (event) => {
		console.log("user_id:", this.state.user_id);
		event.preventDefault();
		const obj = {user_id: this.state.user_id, role: event.target.value};
		this.props.changeRole(obj);
	}

	render()
	{
		if (this.state.roles === null || this.state.active_role === null || this.state.user_id === null) {
			return (<div>Loading</div>);
		}
		return (
			<RoleSelect value={this.state.active_role} onChange={this.handleChange}>
				{
					this.state.roles.map((role) => {
						return (<option key={role.id} value={role.name}>{role.nickname}</option>);
					})
				}
			</RoleSelect>
		);
	}
}

export default RoleChanger;