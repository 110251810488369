		let data = {table_headers: [], data: []};
        data.module = {id: 'gifts', display: 'Gifts Register'}
		data.table_headers = [
            {
                accessor: "status", nickname: "Status", type: "alpha", width: 1.2,
                "valign": {'top':false,'vcentred':true,'bottom':false}, 
                "halign":{'left':true,'hcentred':false, 'right':false}
            },
            {
                accessor: "giftid", nickname: "ID", type: "alpha", width: 1.3,
                "valign": {'top':false,'vcentred':true,'bottom':false}, 
                "halign":{'left':true,'hcentred':false, 'right':false}
            },
            {
                accessor: "receivedorgiven", nickname: "Received /Given", type: "alpha", width: 1.4,
                "valign": {'top':false,'vcentred':true,'bottom':false}, 
                "halign":{'left':false,'hcentred':false, 'right':true}
            },
            {
                accessor: "gifttype", nickname: "Type", type: "alpha", width: 1.2,
                "valign": {'top':false,'vcentred':true,'bottom':false}, 
                "halign":{'left':true,'hcentred':false, 'right':false}
            },
            {
                accessor: "recipientname", nickname: "Recipient", type: "alpha", width: 1.4,
                "valign": {'top':false,'vcentred':true,'bottom':false}, 
                "halign":{'left':true,'hcentred':false, 'right':false}
            },
            {
                accessor: "company_name", nickname: "Company", type: "alpha", width: 1.3,
                "valign": {'top':false,'vcentred':true,'bottom':false}, 
                "halign":{'left':true,'hcentred':false, 'right':false}
            },
            {
                accessor: "no_of_followup", nickname: "Tasks", type: "numeric", width: 0.8,
                "valign": {'top':false,'vcentred':true,'bottom':false}, 
                "halign":{'left':false,'hcentred':true, 'right':false}
            },
            {
                accessor: "value", nickname: "Value", type: "numeric", width: 0.8,
                "valign": {'top':false,'vcentred':true,'bottom':false}, 
                "halign":{'left':true,'hcentred':false, 'right':false}
            },
            {
                accessor: "resultvalue", nickname: "Result", type: "alpha", width: 1.5,
                "valign": {'top':false,'vcentred':true,'bottom':false}, 
                "halign":{'left':true,'hcentred':false, 'right':false}
            },
            {
                accessor: "discloserdate", nickname: "Added Date", type: "date", width: 1.1,
                "valign": {'top':false,'vcentred':true,'bottom':false}, 
                "halign":{'left':true,'hcentred':false, 'right':false}
            },
            {
                accessor: "gifted_date", nickname: "Gifted Date", type: "date", width: 1.1,
                "valign": {'top':false,'vcentred':true,'bottom':false}, 
                "halign":{'left':true,'hcentred':false, 'right':false}
            }
        ];    

        data.sortby = {
            fields: [
                {accessor: "status", nickname: "Status", type: "alpha", width: 1.2},
                {accessor: "giftid", nickname: "ID", type: "alpha", width: 1.3},
                {accessor: "receivedorgiven", nickname: "Received /Given", type: "alpha", width: 1.5},
                {accessor: "gifttype", nickname: "Type", type: "alpha", width: 1.2},
                {accessor: "recipientname", nickname: "Recipient", type: "alpha", width: 1.4},
                {accessor: "company_name", nickname: "Company", type: "alpha", width: 1.3},
                {accessor: "no_of_followup", nickname: "Tasks", type: "numeric", width: 0.8},
                {accessor: "value", nickname: "Value", type: "numeric", width: 0.8},
                {accessor: "resultvalue", nickname: "Result", type: "alpha", width: 1.5},
                {accessor: "discloserdate", nickname: "Added Date", type: "date", width: 1.1},
                {accessor: "gifted_date", nickname: "Gifted Date", type: "date", width: 1.1}
            ],
            selected: {"accessor": "none", "order": "Descending"}
        };
        data.groupby = {
            fields: [
                {accessor: "status", nickname: "Status", type: "alpha", width: 1.2},
                {accessor: "giftid", nickname: "ID", type: "alpha", width: 1.3},
                {accessor: "receivedorgiven", nickname: "Received /Given", type: "alpha", width: 1.5},
                {accessor: "gifttype", nickname: "Type", type: "alpha", width: 1.2},
                {accessor: "recipientname", nickname: "Recipient", type: "alpha", width: 1.4},
                {accessor: "company_name", nickname: "Company", type: "alpha", width: 1.3},
                {accessor: "no_of_followup", nickname: "Tasks", type: "numeric", width: 0.8},
                {accessor: "value", nickname: "Value", type: "numeric", width: 0.8},
                {accessor: "resultvalue", nickname: "Result", type: "alpha", width: 1.5},
                {accessor: "discloserdate", nickname: "Added Date", type: "date", width: 1.1},
                {accessor: "gifted_date", nickname: "Gifted Date", type: "date", width: 1.1}
            ],
            selections: []
        };
        data.font_size_map = {
            section_titles :    {default : {selected : false, size : 16}, small : {selected : false, size : 14}, large : {selected : true, size : 18}},
            introductory_text : {default : {selected : false, size : 12}, small : {selected : false, size : 11}, large : {selected : true, size : 14}},
            header_row_text :   {default : {selected : true, size : 12}, small : {selected : false, size : 11}, large : {selected : false, size : 14}},
            table_data_text :   {default : {selected : true, size : 11}, small : {selected : false, size : 10}, large : {selected : false, size : 12}},       
            footer:             {default : {selected : true, size : 10}, small : {selected : false, size : 9}, large : {selected : false, size : 11}}
        }

module.exports = data