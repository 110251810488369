import React from 'react';
import styled from 'styled-components';
import Store from '../../Common/Store';
import APICall from '../../Common/APICall';
import CSLTable from '../Common/CSLTable';
import FollowupModal from './FollowupModal'
import { FaTimes} from 'react-icons/fa';

const RegModalContainer = styled.div`
    display: block;
    box-sizing: border-box;
    height: auto;
    width: 97%;
    position: absolute;
    margin: 0 0;
    top: 28%;
    z-index: 1001;
    background-color: #f7f7f7;
    border-radius: 5px;
    box-shadow: 0 0 20px #a7a6a6;
    margin-left: 10px;
`;
const RegModalHeaderText = styled.div`
    float: left;
    box-sizing: border-box;
    margin-top: 16px;
    margin-left: 20px;
    color: #2C2C2C;
    font-size: 17px;
    font-weight: bold;
`;
const RegModalHeaderCloseBtn = styled.div`
    float: right;
    box-sizing: border-box;
    margin-top: 16px;
    margin-right: 20px;
    color: #9C9C9C;
    font-size: 16px;
    cursor: pointer;
`;

const RegCancelSubmitButtonOuterContainer = styled.div`
    display: block;
    box-sizing: border-box;
    padding-right: 20px;
    padding-bottom: 20px;
    text-align: right;
    margin-top: 30px;
`;
const RegCancelButton = styled.button`
    display: inline-block;
    box-sizing: border-box;
    background-color: #ffffff;
    color: #909090;
    border: 2px solid #dadada;
    width: 100px;
    height: 35px;
    border-radius: 4px;
    font-size: 11px;
    cursor: pointer;
`;
const RegSubmitButton = styled.button`
    display: inline-block;
    box-sizing: border-box;
    background-color: #37ada7;
    color: #ffffff;
    width: 100px;
    height: 35px;
    border-radius: 4px;
    border: 1px solid #37ada7;
    margin-left: 5px;
    font-size: 11px;
    cursor: pointer;
`;
const RegModalContainertop = styled.div`
    display: grid;
    grid-template-columns: 10% calc(90% - 15px);
    grid-gap: 15px;
    padding: 20px;
`;
const RegModalNameLabelDiv = styled.div`
    height: auto;
    width: 100%;
    padding-top: 7px;
    font-weight: 600;
    color: #979797;
`;
const RegModalNameInputDiv = styled.div`
    height: auto;
    width: 100%;
`;
const RegDismissalReason = styled.textarea`
    height: 130px;
    width: 98%;
    border: 1px solid #ffffff;
    border-radius: 3px;
    box-shadow: 0 0 4px #c4c4c4;
    padding: 5px;
    color: #444444;
    font-weight: 100;   
`;

const ModalNameLabelDivReference = styled.div`
    height: auto;
    width: 100%;
    padding-top: 48px;
    font-weight: 600;
    color: #979797;
    padding-left: 24px;
    display:inline;
`;
const ModalNameLabelDivReferenceID = styled.div`
    height: auto;
    width: 100%;
    padding-top: 48px;
    font-weight: 600;
    color: #979797;
    padding-left: 72px;
    display:inline;
`;

const MRModalLabel = styled.div`
    color: #212121;
    font-weight: 600;
    margin-top: 10px;
    margin-bottom: 10px;
`;
const MRModalTextarea = styled.textarea`
    width: 98%;
    border: 1px solid #ffffff;
    border-radius: 3px;
    box-shadow: 0 0 4px #c4c4c4;
    padding: 5px;
    color: #979797;
`;
const MRModalBody = styled.div`
    display: block;
    box-sizing: border-box;
    padding: 0px 20px 10px 20px;
`;
const RegDismisOuterContainer = styled.div`
    display: block;
    box-sizing: border-box;
    width: 100%;
    padding: 6px 20px 20px 20px;
`;
const OverviewInfoBody = styled.div`
    padding: 15px;
    background-color: #EDF0F1;
    border: 1px solid #d7dddf;
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
    border-top-right-radius: 5px;
    border-top-left-radius: 5px;
    width: 96%;
    margin: 0 auto;
    margin-bottom: 30px; 
    display: inline-block;
    margin-top: 28px;   
`;
const MRButton = styled.button`
    box-sizing: border-box;
    background-color: #37ada7;
    color: #ffffff;
    border: 1px solid #37ada7;
    width: 84px;
    font-size: 13px;
    height: 25px;
    border-radius: 4px;
`;
const OverviewInfoHeader = styled.div`
    padding: 12px 15px;
    background-color: #04ada8;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;    
    width: 96%;
    margin: 0 auto;
    margin-top: 30px;    
`;

class CurrentFollowupListsModal extends React.Component
{
    
    state = {show_modal: null};

	componentDidMount()
	{
         
	}
	
    closeModal = (event) => {
        event.preventDefault();
        this.props.closeModal();
    }

    closefollowupModal = (event) => {
        //event.preventDefault();
        this.setState({showModal: null})
    }

    processMyfollowupTaskData = () => {
        var taskdata = Store.getStoreData('current_followup_lists');
        let ret = {data: [], columns: []};
        let i = 0;        
        ret.columns =[{Header: '#', accessor: 'index', width: 30, headerStyle: {textAlign: 'left'}}, 
                        {Header: 'Assigned To', accessor: 'assign_to', width: 150, headerStyle: {textAlign: 'left'}}, 
                        {Header: 'Notes', accessor: 'notes', minWidth: 300, headerStyle: {textAlign: 'left'}}, 
                        {Header: 'Due Date', accessor: 'due_date', width: 200, headerStyle: {textAlign: 'left'}}, 
                        {Header: 'Status', accessor: 'status', width: 150, headerStyle: {textAlign: 'left'}, Cell: cellInfo => (<div><MRButton style={{cursor: 'default',backgroundColor: `${cellInfo.value.color}`}}>{cellInfo.value.text}</MRButton></div>)},
                        {'Header' : 'Action', Cell: row => (
                            <div>
                                <MRButton style={{width: '100px',cursor: 'pointer'}}  onClick={this.showFollowUp(row.original.id)}>View</MRButton>
                            </div>
                         ), headerStyle:{textAlign: 'left'}}
                    ];
        for(let d of taskdata) {           
            i++;
            let tdata = JSON.parse(d.task_json).action_data;
            let taskdata1 = JSON.parse(d.task_json).task_data;
            let taskdataobject = JSON.parse(d.task_json).object_data;
            let status = "Pending";
            if(d.last_action === 'COMP_GIFTS_FOLLOWUP_DISMISS'){
                status = "Dismissed";
            }else if(d.last_action === 'COMP_GIFTS_FOLLOWUP_COMPLETE'){
                status = "Completed";
            }
            //console.log("d.cur_assigned_to",taskdata1.cur_assigned_to)
            let assigntoName = Store.getStoreData('contacts')[taskdata1.cur_assigned_to]
            let notes = taskdataobject.note;
            let due_date_str = d.due_date ? d.due_date : '1970-01-01T00:00:00.000Z';
            
            const c_date = new Date(due_date_str);
            let day = c_date.getDate().toString().length === 1 ? "0"+c_date.getDate().toString() : c_date.getDate().toString();
            let month = (c_date.getMonth() + 1).toString().length === 1 ? "0"+(c_date.getMonth() + 1).toString() : (c_date.getMonth() + 1).toString();
            let year = c_date.getFullYear().toString();
            const due_date = day+"/"+month+"/"+year;

            var dd   = parseInt(due_date_str.substring(8,12));
            var mm  = parseInt(due_date_str.substring(5,7));
            var yyyy   = parseInt(due_date_str.substring(0,4));
            console.log('day, month, year', dd,mm,yyyy)

            let due_date_status = new Date(yyyy, mm-1, dd);
            var due_date_status_final = new Date ( due_date_status );
            due_date_status_final.setHours ( due_date_status.getHours() + 23 );
            due_date_status_final.setMinutes ( due_date_status.getMinutes() + 59 );
            due_date_status_final.setSeconds ( due_date_status.getSeconds() + 59 );

            // var day_str = dd > 10 ? dd.toString() : "0"+dd.toString();
            // var month_str = mm > 10 ? mm.toString() : "0"+mm.toString();
            // var str_due_date = day_str + "/"+ month_str +"/"+yyyy.toString();

            let today = new Date();
            console.log('due_date_status', due_date_status_final, 'today',today)

            let text = due_date_status_final > today ? status : 'Overdue';
            let color = due_date_status_final > today ? '#2E8B57' : '#FF0000';   
            if(status === "Pending"){
                text = due_date_status_final > today ? status : 'Overdue';
                color = due_date_status_final > today ? '#2E8B57' : '#FF0000';
            }else if(status === "Completed"){
                text = status;
                color = '#2E8B57';
            }                   
            let elem = {
                'id' : d.id,
                'index' : i, 
                'assign_to' : assigntoName,
                'notes' : notes,
                'due_date' : due_date,                
                'status' : {text: text, color: color}
               };
            ret.data.push(elem);
        }
        return ret;
    }

    showFollowUp = (task_id) => (event) => {
        event.preventDefault();
        let postData = { command: "get_subtask", task_id: task_id};
        let api = new APICall();
        api.command(postData, this.openFollowupModal);
    }
    openFollowupModal = (result) => {
        console.log(result);
        Store.updateStore('followup_task', result.result.task);
        this.setState({showModal: 'view_followup_modal'});
    }

    render()
    {
        
        return(

            <RegModalContainer>
                <div>
                    <RegModalHeaderText>{this.props.curGiftsid} - follow-up Tasks</RegModalHeaderText>
                    <RegModalHeaderCloseBtn onClick={this.closeModal}><FaTimes /></RegModalHeaderCloseBtn>
                </div>
                <RegDismisOuterContainer>                      
                    <OverviewInfoBody style={{marginBottom: "10px"}}>
                    <div><span style={{color: "#383939"}}>These are the current active follow-up tasks associated with this Gifts & Hospitality.</span></div>                    
                    </OverviewInfoBody>
                </RegDismisOuterContainer>                
                    <RegDismisOuterContainer>
                        <OverviewInfoHeader>
                            <div style={{float: "left", color: "#ffffff", fontWeight: "600"}}>follow-up Tasks</div>                                                                               
                            <div style={{clear: "both"}}></div>
                        </OverviewInfoHeader>                        
                        {
                            (() => {
                                if(Store.getStoreData('current_followup_lists').length !== 0) {
                                    return(
                                        <OverviewInfoBody style={{marginBottom: "0px",marginTop: "0px",marginLeft: "10px",backgroundColor: "#ffffff"}}>
                                            <CSLTable add={false} processData={this.processMyfollowupTaskData} headerText={''} tableRows="3" refreshCallback={this.refreshCallback} />
                                        </OverviewInfoBody>
                                    )
                                }else {
                                    return(
                                    <OverviewInfoBody style={{marginBottom: "0px",marginTop: "0px"}}>
                                        <div style={{textAlign: "center",fontStyle: "italic", color: "#dddddd"}}>There are currently no follow-up Task Available</div>
                                    </OverviewInfoBody>
                                    );
                                }
                            })()
                        }
                    </RegDismisOuterContainer>                                       
                <RegCancelSubmitButtonOuterContainer>
                    <RegCancelButton onClick={this.closeModal}>Cancel</RegCancelButton>
                </RegCancelSubmitButtonOuterContainer>  
                {
                (() => {
                    if (this.state.showModal !== null) {
                        switch(this.state.showModal) {                            
                            case 'view_followup_modal' : return <FollowupModal closeModal={this.closefollowupModal} />; break;
                        }
                    }
                })()
                }  
            </RegModalContainer>
        )
    }

}
export default CurrentFollowupListsModal;
