import React from 'react';
import styled from 'styled-components';
import Store from '../../Common/Store';
import ReactTable from 'react-table';
import Pagination from "../Common/Pagination";

import CSLReportModal from './CSLReportModal';
import Utils from '../../Common/Utils';
import PdfGenerator from '../Common/PdfGenerator';

// import AdvanceFilter from './../MongodbRegister/Filter/AdvanceFilter'
import 'react-table/react-table.css'
// import "./table_styles.css";


const OverviewInfoHeader = styled.div`
    padding: 12px 15px;
    background-color: #ffffff;    
    margin-top: 30px;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;  
`;

const InactiveOverlay = styled.div`
	display: block;
	box-sizing: border-box;
	height: calc(100vh-200px);
	width: 100%;
	background-color: #ffffff;
	position: absolute;
	z-index: 1000;
	opacity: 0.8;
	top: 0px;
`;
const RegisterButton = styled.button`
    box-sizing: border-box;
    background-color: #EBEBEB;
    color: black;
    border: 1px solid #CECECE;
    width: 100px;
    font-size: 13px;
    height: 32px;
    border-radius: 4px;
    margin-right: 10px;
    box-shadow: inset 0 0 4px #CECECE;
`;
const MRButton = styled.button`
    box-sizing: border-box;
    background-color: #37ada7;
    color: #ffffff;
    border: 1px solid #37ada7;
    width: 95px;
    font-size: 13px;
    height: 25px;
    border-radius: 0px;
    margin-right:1px;
`;
const HeaderText = styled.div`
    margin-left: 5px;
    margin-top: 10px;
    margin-bottom: 10px;
    color: #282828;
    font-weight: 600;
    letter-spacing: 1px;
`;
const TableHeaderIcon = styled.div`
    float: right;
    margin-right: 20px;
    margin-top: 15px;
    color: #c0c0c0;
`;

const TextPara = styled.div`
    padding: 15px 15px 15px 15px;
    color: #c0c0c0;
`
const TableContainer = styled.div`
    background-color: #ffffff;
`

const HeaderSelectText = styled.div`
    background-color: #1E3E62;
    color: #ffffff;
    font-weight: 600;
    letter-spacing: 1px;
    padding: 15px;
    margin-top: 12px;
    border-top-right-radius: 5px;
    border-top-left-radius: 5px; 
`;
const ExportButton = styled.button`
    box-sizing: border-box;
    background-color: #213E61;
    color: #F2F2F2;
    border: 1px solid #213E61;
    width: 125px;
    font-size: 14px;
    height: 32px;
    border-radius: 5px;
    margin: 12px;
    cursor: pointer;
    float: left;
`;

const SelectedRadio = styled.div`
	background-color: #37ADA7;
	border: 1px solid #1f615e;
	padding: 5px 10px;
	color: #ffffff;
	display: inline-block;
	cursor: pointer;
`;
const UnselectedRadio = styled.div`
	background-color: #F4F4F4;
	border: 1px solid #8c8c8c;
	padding: 5px 10px;
	color: #262626;
	display: inline-block;
	cursor: pointer;
`;

class CSLRegisterTable extends React.Component
{
	state = {show_filter: true, filters: {}, show_report: false, generate: false, groupby_selections: [], sortby_selected: {accessor: 'none', order: 'Descending'}, selections_gathered: [], toggle_state: true, toggle_state_adfilter: true,
registerbucLoader: false, orientation:'portrait', tilte_pdf : null,}

	data = {}

	noOps = () => {

	}

	componentDidMount() {
        let tilte_pdf = this.props.titlePDF === undefined ? '' : this.props.titlePDF;
        let orientation = this.state.orientation === null ? this.props.orientation : this.state.orientation;
        if ("toggle_state" in this.props) this.setState({toggle_state: this.props.toggle_state, registerbucLoader: this.props.registerbucLoader,orientation, tilte_pdf});
    }

    componentDidUpdate(prev_props) {
        if (this.props !== prev_props) {
            let tilte_pdf = this.props.titlePDF === undefined ? '' : this.props.titlePDF;
            let orientation = this.state.orientation === null ? this.props.orientation : this.state.orientation;
            if ("toggle_state" in this.props) this.setState({toggle_state: this.props.toggle_state, registerbucLoader: this.props.registerbucLoader, orientation, tilte_pdf});
        }
    }

	toggleGenerate = () => {
		let generate = this.state.generate ? false : true;
		this.setState({generate});		
	}

	hideReport = () => {
		this.setState({show_report: false})
	}	

    goToReport = () => (event) => {
        event.preventDefault();
        //this.props.goToReport();
        this.setState({show_report: true})
    }

    toggleOrientation = (mode) => {
        this.setState({orientation: mode});
    }

    redirectexportToCSV = () => (event) => {
        event.preventDefault();
        this.props.exportToCSV()
    }

	render() {
		let tableData = this.props.processData();
		//console.log('tableData', tableData);
        let row_count = this.props.tableRows === null ? 10 : this.props.tableRows;
		return (

    		<div style={{height: '100%', marginLeft: 10, marginRight: 10}}>
			<div style={{clear: "both"}}></div>	
            <TableContainer>
            <div style={{marginTop: '5px', width:'100%'}}>
                <div  style={{position: 'relative'}}>

                <div style={{clear: "both", height: '3px'}}></div>
                <ReactTable
                    PaginationComponent={Pagination}
                    data={tableData.data}
                    columns={tableData.columns}
                    defaultPageSize={parseInt(row_count)}
                    filterable={true}
                    defaultFilterMethod={(filter, row, column) => {
                        const id = filter.pivotId || filter.id
                        return row[id] !== undefined ? String(row[id]).toLowerCase().includes(filter.value.toLowerCase()) : true
                    }}
                />
                </div>
                
                {
                    (() => {
                        if ('export' in this.props && this.props.export === true && tableData.data.length > 0) {                            
                            return (<ExportButton  style={{marginLeft: '0px'}}onClick={this.redirectexportToCSV()}>Export</ExportButton>);                                                        
                        }
                    })()
                }
                {
                    (() => {
                        if ('reportButton' in this.props && this.props.reportButton === true && tableData.data.length > 0) {                            
                            return (<ExportButton style={{width: 250}} onClick={this.goToReport()}>Run Report of Current View</ExportButton>);                                                        
                        }
                    })()
                }
   
                {
                    (() => {
                        if(this.state.show_report) {
                            {/*let fields = this.props.fields*/}
                            let data = this.props.data
                            //console.log("CSRegisterData",data)
                            data.data = this.props.processData().data
                            this.data = data;   
                            //console.log("this.data",this.data)                          

                            return (
                                    <div style={{position:'absolute', top:'0px', width:'100%'}}>
                                        <InactiveOverlay />
                                        <CSLReportModal
                                            data={this.data}
                                            //changeSort={this.changeSort}
                                            // changeGroup={this.changeGroup}
                                            hideReport={this.hideReport}
                                            toggleGenerate={this.toggleGenerate}
                                            toggleOrientation={this.toggleOrientation}                                            
                                        />
                                    </div>
                                );
                        }
                    })()
                }   
                {
                    (() => {
                        if (this.state.generate) {
                            return (
                                <div style={{position:'absolute', top:'10px', width:'calc(100%-50px)', height: 'calc(100vh-90px)'}}>
                                    <InactiveOverlay style={{zIndex: "1010"}} />
                                    <PdfGenerator
                                        //filters={this.state.filters} 
                                        // pills={this.state.pill_filters} 
                                        toggleGenerate={this.toggleGenerate} 
                                        contactName={Store.getStoreData('contact')} 
                                        tiltePDF={this.state.tilte_pdf}
                                        orientation={this.state.orientation} 
                                    />
                                </div>
                            );
                        }
                    })()
                }         
            </div>
            </TableContainer>
		</div>
		)
	}
}

export default CSLRegisterTable