import React, { Component } from 'react'
import styled from "styled-components";

const PageBtn = styled.button`
	display: inline-block;
	box-sizing: border-box;
	color: #1A73E8;
	border-radius: 4px;
	font-size: 10px;
	margin-left: 10px;
	float: left;
	cursor: pointer;
  	background-color: #ffffff;
  	border: 1px solid #1A73E8;
  	padding: 9px;
`;

export default class SimplePagination extends Component {
    state = {data : [] , Pagination_container : [] , currentPageindex : 0 , show_Data:5, ready: false}
    constructor(props){
        super(props)
    }

componentDidMount(){
    let data = this.props.data
    let dt_cnt = this.props.data_count
    let arr = []
    let pg = 0
    console.log('datafiltered' ,data)  
    let paginator = this.paginator(data , 1 , dt_cnt)
    let pg_cnt = paginator.total_pages
    for(let i=0 ; i<pg_cnt ; i++){
        arr.push(i)
    }
    this.setState({Pagination_container : arr, ready: true})
    this.firstpageload(dt_cnt)
}

 paginator = (items, current_page, per_page_items)=> {
	let page = current_page || 1,
	per_page = per_page_items || 10,
	offset = (page - 1) * per_page,

	paginatedItems = items.slice(offset).slice(0, per_page_items),
	total_pages = Math.ceil(items.length / per_page);

	return {
		page: page,
		per_page: per_page,
		pre_page: page - 1 ? page - 1 : null,
		next_page: (total_pages > page) ? page + 1 : null,
		total: items.length,
		total_pages: total_pages,
		data: paginatedItems
	};
}

changePage = (arrelem , arr , index)=>{
    let dt_cnt = this.props.data_count

    if(index+1 === arr.length){
        this.props.changePage(index * dt_cnt , this.props.data.length)
    }else{
        this.props.changePage(index * dt_cnt , (index+1)*dt_cnt)
    }
    this.setState({currentPageindex:index})
}

firstpageload = (p)=>{
    this.props.changePage(0 , p)
}
btnStyle = (i)=>{
    let display = this.state.Pagination_container.length > 1 ?'': 'none'
    if(i === this.state.currentPageindex){
        let backgroundColor ='#1A73E8'
        let color = '#ffffff'
        return {backgroundColor , color , display}
    }else{
        return {display}
    }
}
  render() {
    if(!this.state.ready){
        return(<div></div>)
    }
    return (
      <div style={{display:'flex',float: 'right'}}>
          {this.state.Pagination_container.map((elem , i)=>{
              return(
                <PageBtn onClick={()=>this.changePage(elem , this.state.Pagination_container , i)} style={this.btnStyle(i)}>{i+1}</PageBtn>
               )
          })}
       
     </div>
    )
  }
}
