import React, { Component } from 'react'
import styled from "styled-components";
import { FaPlusCircle } from "react-icons/fa";
import ApprovalTable from './ApprovalTable';
import ApprovalTableWithType from './ApprovalTableWithType';
import approval_options from './approval_options.js'
import user_approval from './user_approval.js'
import VerticalEllipsisMenuFolderApprovalGiftTypes from "./VerticalEllipsisMenuFolderApprovalGiftTypes";
import VerticalEllipsisMenuFolderApproval from "./VerticalEllipsisMenuFolderApproval";
import CSLTable from '../Common/CSLTable';
import CSLLoader from './../Common/CSLLoader';
import GiftsConfigModal from './GiftsConfigModal';
import AddEditApprovalModal from './AddEditApprovalModal';
import AddEditApprovalModalType from './AddEditApprovalModalType';
import AddEditUserLevelApprovalModal from './AddEditUserLevelApprovalModal';
import AlertBox from '../Common/AlertBox';
import Store from '../../Common/Store';
import { blockInvalidChar } from "../Common/blockInvalidChar";

const SelectedRadio = styled.div`
	background-color: #37ADA7;
	border: 1px solid #1f615e;
	padding: 5px 10px;
	color: #ffffff;
	display: inline-block;
	cursor: pointer;
`;
const UnselectedRadio = styled.div`
	background-color: #F4F4F4;
	border: 1px solid #8c8c8c;
	padding: 5px 10px;
	color: #262626;
	display: inline-block;
	cursor: pointer;
`;
const GeneralContainer = styled.div`
	width: 100%;
`;
const GeneralInnerContainer = styled.div`
	background-color: #F4F5F7;
	padding: 30px;
`;
const GeneralLabel = styled.div`
	font-weight: 600;
	color: #282828;
	margin-top: 20px;
`;
const RadioBlock = styled.div`
	margin-top: 10px;
`;

const MRModalLabel = styled.div`
    color: #212121;
    font-weight: 600;
    margin-top: 10px;
    margin-bottom: 10px;
    display: inline-block;
`;


const MRModalInput = styled.input`
    height: 20px;
    width: 99%;
    border: 1px solid #ffffff;
    border-radius: 3px;
    box-shadow: 0 0 4px #c4c4c4;
    padding: 5px;
    color: #000000;    
    font-family: 'Montserrat',sans-serif;
    display: inline-block;
`;



const EmailTopHeader = styled.div`
	font-weight: 600;
	color: #595959;
	margin-top: 5px;
	width:100%;
	height: 58px;
	padding: 20px;
	border-radius: 4px;
	border: 1px solid #e0e0e0;
	background-color: #ffffff;
	box-sizing: border-box;	
`;

const InactiveOverlay = styled.div`
  display: block;
  box-sizing: border-box;
  height: 210%;
  width: 100%;
  background-color: #ffffff;
  position: absolute;
  left: 0%;
  top: -46px;
  z-index: 1000;
  opacity: 0.8;
`;
const RoleSelect = styled.select`
  display: block;
  box-sizing: border-box;
  height: 28px;
  width: 100%;
  background-color: #fff;
  border: 1px solid #ffffff;

  box-shadow: 0 0 4px #c4c4c4;
  font-family: "Montserrat", sans-serif;
  color: #222222;
`;


class Approval extends React.Component
{

    state = {
		general: null,
        currency: "",
        minvalue: 50,
		show_approval_modal: false,
		headerText: '',
		curid: 0,
		show_gift_types_array: [],
		show_gift_types_object: {},
		isLoadedIcon: false,
		show_gift_type_modal: false,
		curGiftTypeid: 0,
		contentGiftType: null,
		headerTextGiftType: "",
		alert_param: null,
		curATid: 0,		
		giftTypeID: 0,
		user_level_approval: {},
		show_user_level_approval_modal: false,
		curULAid: 0
	}

	componentDidMount()
	{	
		let update_giftsType = []
		let show_gift_types_object = {}
		for(let d of this.props.gift_types){
			if(d.isActive === 1){
				update_giftsType.push(d)
				show_gift_types_object[d.giftTypeID.toString()] = d.giftTypeName
			}
		}		
		// console.log("this.props.approval_level==>", this.props.approval_level.approval[0].elem)
		Store.updateStore('approval_level', this.props.approval_level)		
        this.setState({general: this.props.general, approval_level: this.props.approval_level, currency: this.props.general.companyCurrencyDetails.currency, minvalue:this.props.general.companyCurrencyDetails.minvalue, show_gift_types_array: update_giftsType, gift_types: this.props.gift_types, show_gift_types_object,
			user_level_approval: this.props.user_level_approval
		})	
	}

	componentDidUpdate(prevProps)
	{
		if (prevProps !== this.props) {		
			let update_giftsType = []
			let show_gift_types_object = {}
			for(let d of this.props.gift_types){
				if(d.isActive === 1){
					update_giftsType.push(d)
					show_gift_types_object[d.giftTypeID.toString()] = d.giftTypeName
				}
			}
			Store.updateStore('approval_level', this.props.approval_level)
            this.setState({general: this.props.general, approval_level: this.props.approval_level, currency: this.props.general.companyCurrencyDetails.currency, minvalue:this.props.general.companyCurrencyDetails.minvalue, show_gift_types_array: update_giftsType, gift_types: this.props.gift_types, show_gift_types_object, isLoadedIcon: false,
				user_level_approval: this.props.user_level_approval
			})			
		}
	}

	switchRadio = (key) => (event) => {		
		event.preventDefault();
		let {approval_level} = this.state;
		approval_level[key] = approval_level[key] ? false : true;
		this.props.updateApprovalGeneral(approval_level);
	}

    handleChange = (evt) => {  
		this.props.updateCurrencyValue(evt.target.value)
	}

    handleChangeApproval = (evt) => {  		
		evt.preventDefault();
		let {approval_level} = this.state;
		approval_level[evt.target.name] = evt.target.value;
		// console.log("Sourav", approval_level)
		this.props.updateApprovalMinimumValue(approval_level);
	}   

	addApprovalLevels = (event) => {
		event.preventDefault();		
		this.setState({show_approval_modal: true, curid: 0, headerText: "Add"})
    }
	addApprovalLevelsWithType = (event) => {
		event.preventDefault();		
		this.setState({show_approval_modal_type: true, curATid: 0, giftTypeID: 0, headerText: "Add"})
    }
	addApprovalUserLevelsApproval = (event) => {
		event.preventDefault();		
		this.setState({show_user_level_approval_modal: true, curULAid: 0, headerText: "Add"})
    }
	editApproval = (curID) => {
		// event.preventDefault();		
		console.log("curID===>", curID)
		this.setState({show_approval_modal: true, curid: curID, headerText: "Edit"})
    }
	editApprovalType = (curID, giftTypeID) => {
		// event.preventDefault();		
		console.log("curID===>", curID, giftTypeID)
		this.setState({show_approval_modal_type: true, curATid: curID, giftTypeID: giftTypeID, headerText: "Edit"})
    }

	deleteApproval = (curID) => {
		let alert_param = {title: 'Warning', message: 'Are you sure you want to delete this approval?', ok_text: 'Yes', cancel_text: 'No', confirm: true, 
                           alertHandler: this.deleteApprovalAlert, stack: {id: curID}}
        this.setState({alert_param: alert_param})
	}
	deleteApprovalType = (curID, giftTypeID) => {
		let alert_param = {title: 'Warning', message: 'Are you sure you want to delete this approval?', ok_text: 'Yes', cancel_text: 'No', confirm: true, 
                           alertHandler: this.deleteApprovalTypeAlert, stack: {id: curID, giftTypeID: giftTypeID}}
        this.setState({alert_param: alert_param})
	}

	statusCheck = (curID) => {
		let app_wi_gfty = this.state.approval_level.approval[0].elem
		let newreason = [];
		app_wi_gfty.forEach((item) => {
			if (item.id === curID) {
				item.status = item.status === true ? false : true;				
			}
			newreason.push(item);
		})
		console.log("newreason==>", newreason)
		
		let givenObject = {};
		let receivedObject = {};
		let update_approval_with_giftTypes = [];
		let m = 0;
		let arrayinIDgiven = []
		let arrayinIDreceived = []	
		for(let d of newreason){
			if(d.given.value === 'n/a'){
				arrayinIDgiven.push({'id': d.id, 'value': 9999999999, 'given': d.given, 'givenApproval': d.givenApproval, 'status': d.status, 'isApprovalGivenReceived': d.isApprovalGivenReceived})
			}else{
				arrayinIDgiven.push({'id': d.id, 'value': d.given.value, 'given': d.given, 'givenApproval': d.givenApproval, 'status': d.status, 'isApprovalGivenReceived': d.isApprovalGivenReceived})
			}
			if(d.received.value === 'n/a'){
				arrayinIDreceived.push({'id': d.id, 'value': 9999999999, 'received': d.received, 'receivedApproval': d.receivedApproval, 'status': d.status, 'isApprovalGivenReceived': d.isApprovalGivenReceived})
			}else{
				arrayinIDreceived.push({'id': d.id, 'value': d.received.value, 'received': d.received, 'receivedApproval': d.receivedApproval, 'status': d.status, 'isApprovalGivenReceived': d.isApprovalGivenReceived})
			}	
			givenObject[d.given.value] = {'given': d.given, 'givenApproval': d.givenApproval}
			receivedObject[d.received.value] = {'received': d.received, 'receivedApproval': d.receivedApproval}
			if(d.status){
				m++
				d.level = 'Level '+ m;
			}
			update_approval_with_giftTypes.push(d)		
		}

		console.log("givenObject===>", givenObject)	
		console.log("receivedObject===>", receivedObject)

		let recipientlistGiven = arrayinIDgiven.sort(function(a, b) {return a.value - b.value } );
		let recipientlistReceived = arrayinIDreceived.sort(function(a, b) {return a.value - b.value } );

		console.log("recipientlistnew===>", recipientlistGiven)
		console.log("recipientlistnew_all===>", recipientlistReceived)

		let levelObjectGiven = {}
		let i = 0;		
		for(let d of recipientlistGiven){			
			if(d.status){
				i++
				let val = 'Level '+ i;
				levelObjectGiven[val] = {'given': d.given, 'givenApproval': d.givenApproval, 'id': d.id, 'isApprovalGivenReceived': d.isApprovalGivenReceived};
			}
		}
		let levelObjectReceive = {}
		let j = 0;
		for(let d of recipientlistReceived){			
			if(d.status){
				j++
				let val = 'Level '+ j;
				levelObjectReceive[val] = {'received': d.received, 'receivedApproval': d.receivedApproval, 'id': d.id, 'isApprovalGivenReceived': d.isApprovalGivenReceived};
			}
		}		
		console.log("levelObjectGiven===>", levelObjectGiven)	
		console.log("levelObjectReceive===>", levelObjectReceive)
		console.log("update_approval_with_giftTypes===>", update_approval_with_giftTypes)		
		let new_approval_type = []		
		for(let d of update_approval_with_giftTypes){		
			let appObject = {};
			appObject.level = d.level;
			appObject.received = d.received;
			appObject.given = d.given;
			appObject.status = d.status;
			appObject.id = d.id;
			appObject.isApprovalGivenReceived = d.isApprovalGivenReceived;
			appObject.defaultApproval = d.defaultApproval;	
			appObject.givenApproval = d.givenApproval;			
			appObject.receivedApproval = d.receivedApproval;
			if(d.status){			
				if(d.level in levelObjectGiven){
					appObject.given = levelObjectGiven[d.level].given;
					appObject.givenApproval = levelObjectGiven[d.level].givenApproval	
					appObject.isApprovalGivenReceived = levelObjectGiven[d.level].isApprovalGivenReceived;				
				}
				if(d.level in levelObjectReceive){
					appObject.received = levelObjectReceive[d.level].received
					appObject.receivedApproval = levelObjectReceive[d.level].receivedApproval
					appObject.defaultApproval = levelObjectReceive[d.level].receivedApproval	
					appObject.isApprovalGivenReceived = levelObjectReceive[d.level].isApprovalGivenReceived;				
				}	
			}	
			new_approval_type.push(appObject)
		}	
		console.log("new_approval_type===>", new_approval_type)	
		let new_approval_type_update = [];
		for(let s of new_approval_type){
			let appObject = {};
			appObject.level = s.level;
			appObject.received = s.received;
			appObject.given = s.given;
			appObject.status = s.status;
			appObject.id = s.id;
			appObject.isApprovalGivenReceived = s.isApprovalGivenReceived;
			appObject.defaultApproval = s.defaultApproval;	
			appObject.givenApproval = s.givenApproval;			
			appObject.receivedApproval = s.receivedApproval;
			if(s.given.value !== s.received.value){
				appObject.isApprovalGivenReceived = false;
			}
			new_approval_type_update.push(appObject)
		}		

		this.setState({isLoadedIcon: true})
		this.props.updateApprovalValue(new_approval_type_update)
		// newreason.sort(function(a, b) {
		// 	return a.value - b.value;
		// });

		// let update_approval_within_giftTypes = []		
		// let i = 0;
		// for(let d of newreason){
		// 	if(d.status){
		// 		i++
		// 		d.level = 'Level '+ i;				
		// 	}
		// 	update_approval_within_giftTypes.push(d)			
		// }	
		// console.log("update_approval_within_giftTypes==>", update_approval_within_giftTypes)
		// this.props.updateApprovalValue(update_approval_within_giftTypes)
	}

	statusCheckType = (curID, giftTypeID) => {
		let approval_type_all = this.state.approval_level.approval_giftTypes;
		let selectTypeApproval = [];
		for(let d of approval_type_all){
			if(parseInt(d.giftTypeID) === parseInt(giftTypeID)){
				selectTypeApproval.push(d)
			}
		}
		let app_wi_gfty = selectTypeApproval[0].elem
		
		let newreason = [];
		app_wi_gfty.forEach((item) => {
			if (item.id === curID) {
				item.status = item.status === true ? false : true;				
			}
			newreason.push(item);
		})

		let givenObject = {};
		let receivedObject = {};
		let update_approval_with_giftTypes = [];
		let m = 0;
		let arrayinIDgiven = []
    	let arrayinIDreceived = []	
		for(let d of newreason){
			if(d.given.value === 'n/a'){
				arrayinIDgiven.push({'id': d.id, 'value': 9999999999, 'given': d.given, 'givenApproval': d.givenApproval, 'status': d.status, 'isApprovalGivenReceived': d.isApprovalGivenReceived})
			}else{
				arrayinIDgiven.push({'id': d.id, 'value': d.given.value, 'given': d.given, 'givenApproval': d.givenApproval, 'status': d.status, 'isApprovalGivenReceived': d.isApprovalGivenReceived})
			}
			if(d.received.value === 'n/a'){
				arrayinIDreceived.push({'id': d.id, 'value': 9999999999, 'received': d.received, 'receivedApproval': d.receivedApproval, 'status': d.status, 'isApprovalGivenReceived': d.isApprovalGivenReceived})
			}else{
				arrayinIDreceived.push({'id': d.id, 'value': d.received.value, 'received': d.received, 'receivedApproval': d.receivedApproval, 'status': d.status, 'isApprovalGivenReceived': d.isApprovalGivenReceived})
			}
			givenObject[d.given.value] = {'given': d.given, 'givenApproval': d.givenApproval}
			receivedObject[d.received.value] = {'received': d.received, 'receivedApproval': d.receivedApproval}
			if(d.status){
				m++
				d.level = 'Level '+ m;
			}
			update_approval_with_giftTypes.push(d)
		}

		console.log("givenObject===>", givenObject)	
    	console.log("receivedObject===>", receivedObject)

		let recipientlistGiven = arrayinIDgiven.sort(function(a, b) {return a.value - b.value } );
		let recipientlistReceived = arrayinIDreceived.sort(function(a, b) {return a.value - b.value } );
			
		console.log("recipientlistnew===>", recipientlistGiven)
		console.log("recipientlistnew_all===>", recipientlistReceived)

		let levelObjectGiven = {}
		let i = 0;		
		for(let d of recipientlistGiven){			
			if(d.status){
				i++
				let val = 'Level '+ i;
				levelObjectGiven[val] = {'given': d.given, 'givenApproval': d.givenApproval, 'id': d.id, 'isApprovalGivenReceived': d.isApprovalGivenReceived};
			}
		}
		let levelObjectReceive = {}
		let j = 0;
		for(let d of recipientlistReceived){			
			if(d.status){
				j++
				let val = 'Level '+ j;
				levelObjectReceive[val] = {'received': d.received, 'receivedApproval': d.receivedApproval, 'id': d.id, 'isApprovalGivenReceived': d.isApprovalGivenReceived};
			}
		}		
		console.log("levelObjectGiven===>", levelObjectGiven)	
		console.log("levelObjectReceive===>", levelObjectReceive)
		console.log("update_approval_with_giftTypes===>", update_approval_with_giftTypes)
		
		let new_approval_type = []
		for(let d of update_approval_with_giftTypes){
			let appObject = {};
			appObject.level = d.level;
			appObject.received = d.received;
			appObject.given = d.given;
			appObject.status = d.status;
			appObject.id = d.id;
			appObject.isApprovalGivenReceived = d.isApprovalGivenReceived;
			appObject.defaultApproval = d.defaultApproval;	
			appObject.givenApproval = d.givenApproval;			
			appObject.receivedApproval = d.receivedApproval;
			if(d.status){			
				if(d.level in levelObjectGiven){
					appObject.given = levelObjectGiven[d.level].given;
					appObject.givenApproval = levelObjectGiven[d.level].givenApproval	
					appObject.isApprovalGivenReceived = levelObjectGiven[d.level].isApprovalGivenReceived;				
				}
				if(d.level in levelObjectReceive){
					appObject.received = levelObjectReceive[d.level].received
					appObject.receivedApproval = levelObjectReceive[d.level].receivedApproval
					appObject.defaultApproval = levelObjectReceive[d.level].receivedApproval	
					appObject.isApprovalGivenReceived = levelObjectReceive[d.level].isApprovalGivenReceived;				
				}	
			}	
			new_approval_type.push(appObject)
		}	
		console.log("new_approval_type===>", new_approval_type)	
		let new_approval_type_update = [];
		for(let s of new_approval_type){
			let appObject = {};
			appObject.level = s.level;
			appObject.received = s.received;
			appObject.given = s.given;
			appObject.status = s.status;
			appObject.id = s.id;
			appObject.isApprovalGivenReceived = s.isApprovalGivenReceived;
			appObject.defaultApproval = s.defaultApproval;	
			appObject.givenApproval = s.givenApproval;			
			appObject.receivedApproval = s.receivedApproval;
			if(s.given.value !== s.received.value){
				appObject.isApprovalGivenReceived = false;
			}
			new_approval_type_update.push(appObject)
		}	

		for(let d of approval_type_all){
            if(parseInt(d.giftTypeID) === parseInt(giftTypeID)){					
                d.elem = new_approval_type_update
            }
        }

		this.setState({isLoadedIcon: true})
    	this.props.updateApprovalValueType(approval_type_all)

		// let update_approval_within_giftTypes = []		
		// let i = 0;
		// for(let d of newreason){
		// 	if(d.status){
		// 		i++
		// 		d.level = 'Level '+ i;				
		// 	}
		// 	update_approval_within_giftTypes.push(d)			
		// }
		
		// for(let d of approval_type_all){
        //     if(parseInt(d.giftTypeID) === parseInt(giftTypeID)){					
        //         d.elem = update_approval_within_giftTypes					
        //     }            
        // }
		// console.log("approval_type_all===>", approval_type_all)
    	// this.props.updateApprovalValueType(approval_type_all)		
	}

	deleteApprovalAlert = (result, stack) => {
        if(!result) {
            this.setState({alert_param: null})
            return
        }
        console.log("id",stack.id)
        let app_wi_gfty = this.state.approval_level.approval[0].elem
		let newreason = [];
		app_wi_gfty.forEach((item) => {
			if (item.id !== stack.id) {
				newreason.push(item);
			}			
		})	

		let update_approval_within_giftTypes = []		
		let i = 0;
		for(let d of newreason){
			if(d.status){
				i++
				d.level = 'Level '+ i;
			}
			update_approval_within_giftTypes.push(d)
		}
		console.log('update_approval_within_giftTypes==>', update_approval_within_giftTypes)
		this.props.deleteApprovalValue(update_approval_within_giftTypes)
		this.setState({alert_param: null,isLoadedIcon: true})
    }

	deleteApprovalTypeAlert = (result, stack) => {
        if(!result) {
            this.setState({alert_param: null})
            return
        }
        console.log("id",stack.id)
		let approval_type_all = this.state.approval_level.approval_giftTypes;
		let selectTypeApproval = [];
		for(let d of approval_type_all){
			if(parseInt(d.giftTypeID) === parseInt(stack.giftTypeID)){
				selectTypeApproval.push(d)
			}
		}
        let app_wi_gfty = selectTypeApproval[0].elem
		let newreason = [];
		let i = 0;
		app_wi_gfty.forEach((item) => {
			if (item.id !== stack.id) {
				if(item.status){
					i++
					item.level = 'Level '+ i;
				}
				newreason.push(item);
			}			
		})
		console.log("newreason===>", newreason)
		let updateNewApp = []
		if(newreason.length !== 0){
			for(let d of approval_type_all){
				if(parseInt(d.giftTypeID) === parseInt(stack.giftTypeID)){					
					d.elem = newreason					
				}
				updateNewApp.push(d)
			}
		}else{
			for(let d of approval_type_all){
				if(parseInt(d.giftTypeID) !== parseInt(stack.giftTypeID)){					
					updateNewApp.push(d)
				}
			}
		}
		console.log("approval_type_all===>", updateNewApp)
		this.props.deleteApprovalValueType(updateNewApp)
		this.setState({alert_param: null, isLoadedIcon: true})
    }

	closeApprovalModal = () => {		
		this.setState({show_approval_modal: false})
    }
	closeApprovalModalType = () => {		
		this.setState({show_approval_modal_type: false})
    }
	closeUserLevelApprovalModal = () => {		
		this.setState({show_user_level_approval_modal: false})
    }

	handleLevelChange = (section_id) => (event) => {
		console.log("content:", section_id);
		let {gift_types} = this.state;
		let newgifts = [];
		gift_types.forEach((item) => {
			if (item.giftTypeID === section_id) {
				item.approver_level = event.target.value; 				
			}
			newgifts.push(item);
		})
		console.log("gifts:", newgifts);
        this.setState({isLoadedIcon: true})
		this.props.updateGiftValue(newgifts);
	}
	handleUserLevelChange = (userid) => (event) => {
		console.log("content:", userid);
		let user_level_approval = this.state.user_level_approval;
		if(userid.toString() in user_level_approval){
			let val = user_level_approval[userid.toString()]
			val.app_req_id = event.target.value
			if(event.target.value === 'ar'){
				val.app_req_name = 'Approver Role'
				val.su_user	= {}
			}else if(event.target.value === 'lm'){
				val.app_req_name = 'Line Manager'
				val.su_user	= {}
			}else if(event.target.value === 'nr'){
				val.app_req_name = 'Not required (Auto-approve)'
				val.su_user	= {}
			}else if(event.target.value === 'su'){
				val.app_req_name = 'Specific User'
				console.log("allActiveUsers", this.props.allActiveUsers)
				let allActiveUsers = this.props.allActiveUsers
				let userroles = Store.getStoreData('userroles');
				let su_user_array = []
				for(let k in userroles){
					if(userroles[k] === 'manager' && k in allActiveUsers){
						su_user_array.push({value: k.toString(), label: allActiveUsers[k.toString()]})
					}
				}
				let su_user	= {};
				if(su_user_array.length === 1){
					su_user = su_user_array[0];
				}else if(su_user_array.length > 1){
					let su_user_sort_array = su_user_array.sort(function(a,b) {return (a.label > b.label) ? 1 : ((b.label > a.label) ? -1 : 0);} );
					console.log("su_user_sort_array==>", su_user_sort_array)
					su_user = su_user_sort_array[0];
				}
				val.su_user	= su_user;
			}

			user_level_approval[userid.toString()] = val;
		}
		
		console.log("user_level_approval===>:", user_level_approval);
        this.setState({isLoadedIcon: true})
		this.props.updateUserLevelApproval(user_level_approval);
	}
	
	updateUserLevelApproval = (obj, userid) => {
		console.log("content:", userid);
		let user_level_approval = this.state.user_level_approval;
		delete user_level_approval[userid.toString()];

        user_level_approval[obj.id.toString()] = obj;
		this.setState({show_user_level_approval_modal: false})
		this.props.updateUserLevelApproval(user_level_approval);
	}

	showConfigModal = (curid, isdelete) => (obj) => {
		console.log("curid==>", curid)
		console.log("obj==>", obj)
		if(obj === 'Edit'){			
            const content = this.state.gift_types.find((item) => {
                return item.giftTypeID === curid;
            })
            this.setState({contentGiftType: content, curGiftTypeid: curid, show_gift_type_modal: true, headerTextGiftType: "Edit"});
		}
	};

	showConfigModalUserApproval = (curid) => (obj) => {
		console.log("curid==>", curid)
		console.log("obj==>", obj)
		if(obj === 'Edit'){		
            this.setState({curULAid: curid, show_user_level_approval_modal: true, headerText: "Edit"});
		}else if(obj === 'Delete'){
			let alert_param = {title: 'Warning', message: 'Are you sure you want to delete this user level approval?', ok_text: 'Yes', cancel_text: 'No', confirm: true, 
                           alertHandler: this.deleteUserLevelApprovalAlert, stack: {id: curid}}
        	this.setState({alert_param: alert_param})			
		}
	};

	deleteUserLevelApprovalAlert = (result, stack) => {
        if(!result) {
            this.setState({alert_param: null})
            return
        }
		let user_level_approval = this.state.user_level_approval;
		delete user_level_approval[stack.id.toString()];
		this.setState({alert_param: null,isLoadedIcon: true})		
		this.props.deletedUserLevelApproval(user_level_approval); 
    }

	tableDataUserApproval = () => {
		let ret = {data: [], columns: []};
		ret.columns =[{Header: 'Name', accessor: 'name', minWidth: 120, headerStyle: {textAlign: 'left',fontWeight: "600"}, style: {fontWeight: "700", color: "#545454"}},
					{Header: "Approval Required",accessor: "approver_level",headerStyle: { textAlign: "center", fontWeight: "600"},	filterable: false, sortable: false,	style: { whiteSpace: "unset" },
						Cell: (row) => {                              
							return <RoleSelect style={{width: "98%"}} value={row.original.approver_level} key={row.original.index} onChange={this.handleUserLevelChange(row.original.id)} disabled={row.original.status === "Active" ? false : true}>
								{user_approval.map((role) => (
								<option key={role.id} value={role.id}>
									{role.name}
								</option>
								))}
							</RoleSelect>
						},width: 250},                        
					{'Header' : '', Cell: row => (
						<div>     
							<VerticalEllipsisMenuFolderApproval editDelete={this.showConfigModalUserApproval(row.original.id)} options={["Edit", "Delete"]} />   
						</div>
					), minWidth: 40, filterable: false,	sortable: false,headerStyle:{textAlign: 'left'},style: {textAlign: 'center'}}];

		let user_level_approval = this.state.user_level_approval;
		let user_level_approval_list = [];
		for(let k in user_level_approval){
			user_level_approval_list.push(user_level_approval[k])
		}
		user_level_approval_list = user_level_approval_list.sort(function(a,b) {return (a.name > b.name) ? 1 : ((b.name > a.name) ? -1 : 0);} );
		user_level_approval_list.forEach((item) => {
			let statusValue = item.isActive === 1 ? "Active" : "Inactive"
			let elem = {id: item.id, name: item.name, status: statusValue, isdelete: item.isDelete, 'approver_level': item.app_req_id};
			ret.data.push(elem);
		})       
		return ret;
	}

	tableDataGiftTypes = () => {
        // console.log("this.state.show_gift_types_array",this.state.show_gift_types_array)
        let ret = {data: [], columns: []};
        ret.columns =[
                        {Header: 'Name', accessor: 'name', minWidth: 120, headerStyle: {textAlign: 'left',fontWeight: "600"}, style: {fontWeight: "700", color: "#545454"}},
                        {
                            Header: "Approval Required",
                            accessor: "approver_level",
                            headerStyle: { textAlign: "center", fontWeight: "600"},
                            filterable: false,
                            sortable: false,
                            style: { whiteSpace: "unset" },
                            Cell: (row) => {                              
                              return <RoleSelect style={{width: "98%"}} value={row.original.approver_level} key={row.original.index} onChange={this.handleLevelChange(row.original.id)} disabled={row.original.status === "Active" ? false : true}>
                                {approval_options.map((role) => (
                                  <option key={role.id} value={role.id}>
                                    {role.name}
                                  </option>
                                ))}
                              </RoleSelect>
                              },
                            width: 250,                            
                          },                        
                        {'Header' : '', Cell: row => (
                            <div>                                
                                  <VerticalEllipsisMenuFolderApprovalGiftTypes editDelete={this.showConfigModal(row.original.id, row.original.isdelete)} options={["Edit"]} />	
                            </div>
                         ), minWidth: 40, filterable: false,
                         sortable: false,
                         headerStyle:{textAlign: 'left'},style: {textAlign: 'center'}}
                        ];

        let gift_type_sort_array = this.state.show_gift_types_array.sort(function(a,b) {return (a.giftTypeName > b.giftTypeName) ? 1 : ((b.giftTypeName > a.giftTypeName) ? -1 : 0);} );
		gift_type_sort_array.forEach((item) => {
			let statusValue = item.isActive === 1 ? "Active" : "Inactive"
			let elem = {id: item.giftTypeID, name: item.giftTypeName, status: statusValue, isdelete: item.isDelete, 'approver_level': item.approver_level,};
			ret.data.push(elem);
        })       
        return ret;
    }

	closeModal = () => {		
		this.setState({show_gift_type_modal: false})
    }

	updateGiftTypeValue = (content) => {
		console.log("content:", content);
		//console.log("this.state",this.state.reason_for_removal)
		let {gift_types} = this.state;
		let newreason = [];
		gift_types.forEach((item) => {
			if (item.giftTypeID === content.giftTypeID) {
				item = content;
				console.log("matched", item);
			}
			newreason.push(item);
		})
		console.log("resons:", newreason);
		this.setState({show_gift_type_modal: false})
		this.props.updateGiftValue(newreason);		
	}

	saveApprovalValue = (obj) => {
		// this.setState({show_approval_modal: false})
		let app_wi_gfty = this.state.approval_level.approval[0].elem
		app_wi_gfty.push(obj)
		app_wi_gfty.sort(function(a, b) {
			return a.given.value - b.given.value;
		});
		app_wi_gfty.sort(function(a, b) {
			return a.received.value - b.received.value;
		});

		console.log("app_wi_gfty_sort===>", app_wi_gfty)
		let update_approval = []		
		let i = 0;
		for(let d of app_wi_gfty){
			if(d.status){
				i++
				d.level = 'Level '+ i;
			}
			update_approval.push(d)
		}
		console.log("update_approval===>", update_approval)	
		this.setState({show_approval_modal: false, isLoadedIcon: true})
		this.props.saveApprovalValue(update_approval)
    }

	saveApprovalValueType = (obj) => {		
		let app_giftTypeAll = this.state.approval_level.approval_giftTypes
		console.log("app_wi_gfty===>", app_giftTypeAll)	
		let isCreated = 0
		let app_wi_gfty = [];
		for(let d of app_giftTypeAll){
			if(parseInt(d.giftTypeID) === parseInt(obj.selectedGiftType.id)){
				isCreated = 1
				app_wi_gfty = d.elem
			}
		}
		console.log("app_wi_gfty===>", app_wi_gfty)	
		app_wi_gfty.push(obj)		
		app_wi_gfty.sort(function(a, b) {
			return a.given.value - b.given.value;
		});
		app_wi_gfty.sort(function(a, b) {
			return a.received.value - b.received.value;
		});
		console.log("app_wi_gfty_sort===>", app_wi_gfty)
		let update_approval_with_giftTypes = []		
		let i = 0;
		for(let d of app_wi_gfty){
			if(d.status){
				i++
				d.level = 'Level '+ i;
			}
			update_approval_with_giftTypes.push(d)
		}
		console.log("update_approval_with_giftTypes===>", update_approval_with_giftTypes)
		if(isCreated === 0){
			let newObj = {};
			newObj.title = obj.selectedGiftType.label
			newObj.giftTypeID = obj.selectedGiftType.id
			newObj.elem = update_approval_with_giftTypes
			app_giftTypeAll.push(newObj)
		}else{
			for(let d of app_giftTypeAll){
				if(parseInt(d.giftTypeID) === parseInt(obj.selectedGiftType.id)){					
					d.elem = update_approval_with_giftTypes
				}
			}
		}
		console.log("app_giftTypeAll===>", app_giftTypeAll)
		this.setState({show_approval_modal_type: false, isLoadedIcon: true})
		this.props.saveApprovalValueType(app_giftTypeAll)
    }

	saveApprovalValue_receive_given = (obj) => {	
		console.log("obj===>", obj)	
		let app_wi_gfty = Store.getStoreData('approval_level').approval[0].elem
		app_wi_gfty.push(obj)
		console.log("app_wi_gfty===>", app_wi_gfty)		
		let givenObject = {};
		let receivedObject = {};
		let update_approval_with_giftTypes = [];
		let m = 0;
		let arrayinIDgiven = []
		let arrayinIDreceived = []		
		for(let d of app_wi_gfty){			
			if(d.given.value === 'n/a'){
				arrayinIDgiven.push({'id': d.id, 'value': 9999999999, 'given': d.given, 'givenApproval': d.givenApproval, 'status': d.status, 'isApprovalGivenReceived': d.isApprovalGivenReceived})
			}else{
				arrayinIDgiven.push({'id': d.id, 'value': d.given.value, 'given': d.given, 'givenApproval': d.givenApproval, 'status': d.status, 'isApprovalGivenReceived': d.isApprovalGivenReceived})
			}
			if(d.received.value === 'n/a'){
				arrayinIDreceived.push({'id': d.id, 'value': 9999999999, 'received': d.received, 'receivedApproval': d.receivedApproval, 'status': d.status, 'isApprovalGivenReceived': d.isApprovalGivenReceived})
			}else{
				arrayinIDreceived.push({'id': d.id, 'value': d.received.value, 'received': d.received, 'receivedApproval': d.receivedApproval, 'status': d.status, 'isApprovalGivenReceived': d.isApprovalGivenReceived})
			}	
			givenObject[d.given.value] = {'given': d.given, 'givenApproval': d.givenApproval}
			receivedObject[d.received.value] = {'received': d.received, 'receivedApproval': d.receivedApproval}
			if(d.status){
				m++
				d.level = 'Level '+ m;
			}
			update_approval_with_giftTypes.push(d)
		}
		console.log("givenObject===>", givenObject)	
		console.log("receivedObject===>", receivedObject)

		let recipientlistGiven = arrayinIDgiven.sort(function(a, b) {return a.value - b.value } );
		let recipientlistReceived = arrayinIDreceived.sort(function(a, b) {return a.value - b.value } );

		console.log("recipientlistnew===>", recipientlistGiven)
		console.log("recipientlistnew_all===>", recipientlistReceived)
		let levelObjectGiven = {}
		let i = 0;		
		for(let d of recipientlistGiven){			
			if(d.status){
				i++
				let val = 'Level '+ i;
				levelObjectGiven[val] = {'given': d.given, 'givenApproval': d.givenApproval, 'id': d.id, 'isApprovalGivenReceived': d.isApprovalGivenReceived};
			}
		}
		let levelObjectReceive = {}
		let j = 0;
		for(let d of recipientlistReceived){			
			if(d.status){
				j++
				let val = 'Level '+ j;
				levelObjectReceive[val] = {'received': d.received, 'receivedApproval': d.receivedApproval, 'id': d.id, 'isApprovalGivenReceived': d.isApprovalGivenReceived};
			}
		}		
		console.log("levelObjectGiven===>", levelObjectGiven)	
		console.log("levelObjectReceive===>", levelObjectReceive)
		console.log("update_approval_with_giftTypes===>", update_approval_with_giftTypes)		
		let new_approval_type = []		
		for(let d of update_approval_with_giftTypes){		
			let appObject = {};
			appObject.level = d.level;
			appObject.received = d.received;
			appObject.given = d.given;
			appObject.status = d.status;
			appObject.id = d.id;
			appObject.isApprovalGivenReceived = d.isApprovalGivenReceived;
			appObject.defaultApproval = d.defaultApproval;	
			appObject.givenApproval = d.givenApproval;			
			appObject.receivedApproval = d.receivedApproval;
			if(d.status){			
				if(d.level in levelObjectGiven){
					appObject.given = levelObjectGiven[d.level].given;
					appObject.givenApproval = levelObjectGiven[d.level].givenApproval	
					appObject.isApprovalGivenReceived = levelObjectGiven[d.level].isApprovalGivenReceived;				
				}
				if(d.level in levelObjectReceive){
					appObject.received = levelObjectReceive[d.level].received
					appObject.receivedApproval = levelObjectReceive[d.level].receivedApproval
					appObject.defaultApproval = levelObjectReceive[d.level].receivedApproval	
					appObject.isApprovalGivenReceived = levelObjectReceive[d.level].isApprovalGivenReceived;				
				}	
			}	
			new_approval_type.push(appObject)
		}	
		console.log("new_approval_type===>", new_approval_type)	
		let new_approval_type_update = [];
		for(let s of new_approval_type){
			let appObject = {};
			appObject.level = s.level;
			appObject.received = s.received;
			appObject.given = s.given;
			appObject.status = s.status;
			appObject.id = s.id;
			appObject.isApprovalGivenReceived = s.isApprovalGivenReceived;
			appObject.defaultApproval = s.defaultApproval;	
			appObject.givenApproval = s.givenApproval;			
			appObject.receivedApproval = s.receivedApproval;
			if(s.given.value !== s.received.value){
				appObject.isApprovalGivenReceived = false;
			}
			new_approval_type_update.push(appObject)
		}		
		this.setState({show_approval_modal: false, isLoadedIcon: true})
		this.props.saveApprovalValue(new_approval_type_update)
    }

	saveApprovalValueType_receive_given = (obj) => {		
		let app_giftTypeAll = this.state.approval_level.approval_giftTypes
		console.log("app_wi_gfty===>", app_giftTypeAll)	
		let isCreated = 0
		let app_wi_gfty = [];
		for(let d of app_giftTypeAll){
			if(parseInt(d.giftTypeID) === parseInt(obj.selectedGiftType.id)){
				isCreated = 1
				app_wi_gfty = d.elem
			}
		}
		console.log("app_wi_gfty===>", app_wi_gfty)	
		app_wi_gfty.push(obj)
		let givenObject = {};
		let receivedObject = {};
		let update_approval_with_giftTypes = [];
		let m = 0;
		let arrayinIDgiven = []
		let arrayinIDreceived = []
		for(let d of app_wi_gfty){
			if(d.given.value === 'n/a'){
				arrayinIDgiven.push({'id': d.id, 'value': 9999999999, 'given': d.given, 'givenApproval': d.givenApproval, 'status': d.status, 'isApprovalGivenReceived': d.isApprovalGivenReceived})
			}else{
				arrayinIDgiven.push({'id': d.id, 'value': d.given.value, 'given': d.given, 'givenApproval': d.givenApproval, 'status': d.status, 'isApprovalGivenReceived': d.isApprovalGivenReceived})
			}
			if(d.received.value === 'n/a'){
				arrayinIDreceived.push({'id': d.id, 'value': 9999999999, 'received': d.received, 'receivedApproval': d.receivedApproval, 'status': d.status, 'isApprovalGivenReceived': d.isApprovalGivenReceived})
			}else{
				arrayinIDreceived.push({'id': d.id, 'value': d.received.value, 'received': d.received, 'receivedApproval': d.receivedApproval, 'status': d.status, 'isApprovalGivenReceived': d.isApprovalGivenReceived})
			}
			givenObject[d.given.value] = {'given': d.given, 'givenApproval': d.givenApproval}
			receivedObject[d.received.value] = {'received': d.received, 'receivedApproval': d.receivedApproval}
			if(d.status){
				m++
				d.level = 'Level '+ m;
			}
			update_approval_with_giftTypes.push(d)
		}
		console.log("givenObject===>", givenObject)	
		console.log("receivedObject===>", receivedObject)

		let recipientlistGiven = arrayinIDgiven.sort(function(a, b) {return a.value - b.value } );
		let recipientlistReceived = arrayinIDreceived.sort(function(a, b) {return a.value - b.value } );

		console.log("recipientlistnew===>", recipientlistGiven)
		console.log("recipientlistnew_all===>", recipientlistReceived)
		let levelObjectGiven = {}
		let i = 0;
		for(let d of recipientlistGiven){
			if(d.status){
				i++
				let val = 'Level '+ i;
				levelObjectGiven[val] = {'given': d.given, 'givenApproval': d.givenApproval, 'id': d.id, 'isApprovalGivenReceived': d.isApprovalGivenReceived};
			}
		}
		let levelObjectReceive = {}
		let j = 0;
		for(let d of recipientlistReceived){
			if(d.status){
				j++
				let val = 'Level '+ j;
				levelObjectReceive[val] = {'received': d.received, 'receivedApproval': d.receivedApproval, 'id': d.id, 'isApprovalGivenReceived': d.isApprovalGivenReceived};
			}
		}		
		console.log("levelObjectGiven===>", levelObjectGiven)	
		console.log("levelObjectReceive===>", levelObjectReceive)
		console.log("update_approval_with_giftTypes===>", update_approval_with_giftTypes)
		let new_approval_type = []
		for(let d of update_approval_with_giftTypes){
			let appObject = {};
			appObject.level = d.level;
			appObject.received = d.received;
			appObject.given = d.given;
			appObject.status = d.status;
			appObject.id = d.id;
			appObject.isApprovalGivenReceived = d.isApprovalGivenReceived;
			appObject.defaultApproval = d.defaultApproval;	
			appObject.givenApproval = d.givenApproval;			
			appObject.receivedApproval = d.receivedApproval;
			if(d.status){			
				if(d.level in levelObjectGiven){
					appObject.given = levelObjectGiven[d.level].given;
					appObject.givenApproval = levelObjectGiven[d.level].givenApproval	
					appObject.isApprovalGivenReceived = levelObjectGiven[d.level].isApprovalGivenReceived;				
				}
				if(d.level in levelObjectReceive){
					appObject.received = levelObjectReceive[d.level].received
					appObject.receivedApproval = levelObjectReceive[d.level].receivedApproval
					appObject.defaultApproval = levelObjectReceive[d.level].receivedApproval	
					appObject.isApprovalGivenReceived = levelObjectReceive[d.level].isApprovalGivenReceived;				
				}	
			}
			new_approval_type.push(appObject)
		}		
		console.log("new_approval_type===>", new_approval_type)	
		let new_approval_type_update = [];
		for(let s of new_approval_type){
			let appObject = {};
			appObject.level = s.level;
			appObject.received = s.received;
			appObject.given = s.given;
			appObject.status = s.status;
			appObject.id = s.id;
			appObject.isApprovalGivenReceived = s.isApprovalGivenReceived;
			appObject.defaultApproval = s.defaultApproval;	
			appObject.givenApproval = s.givenApproval;			
			appObject.receivedApproval = s.receivedApproval;
			if(s.given.value !== s.received.value){
				appObject.isApprovalGivenReceived = false;
			}
			new_approval_type_update.push(appObject)
		}		

		console.log("new_approval_type_update===>", new_approval_type_update)	
		if(isCreated === 0){
			console.log("sourav 0")
			let newObj = {};
			newObj.title = obj.selectedGiftType.label
			newObj.giftTypeID = obj.selectedGiftType.id
			newObj.elem = new_approval_type_update
			app_giftTypeAll.push(newObj)
		}else{
			console.log("sourav 1")
			for(let d of app_giftTypeAll){
				if(parseInt(d.giftTypeID) === parseInt(obj.selectedGiftType.id)){					
					d.elem = new_approval_type_update
				}
			}
		}
		console.log("app_giftTypeAll===>", app_giftTypeAll)
		this.setState({show_approval_modal_type: false, isLoadedIcon: true})
		this.props.saveApprovalValueType(app_giftTypeAll)

    }

	updateApprovalValue = (obj) => {
		let app_wi_gfty = this.state.approval_level.approval[0].elem
		let newreason = [];
		app_wi_gfty.forEach((item) => {
			if (item.id === obj.id) {
				item = obj;
				console.log("matched", item);
			}
			newreason.push(item);
		})

		let givenObject = {};
		let receivedObject = {};
		let update_approval_with_giftTypes = [];
		let m = 0;
		let arrayinIDgiven = []
    	let arrayinIDreceived = []	
		for(let d of newreason){
			if(d.given.value === 'n/a'){
				arrayinIDgiven.push({'id': d.id, 'value': 9999999999, 'given': d.given, 'givenApproval': d.givenApproval, 'status': d.status, 'isApprovalGivenReceived': d.isApprovalGivenReceived})
			}else{
				arrayinIDgiven.push({'id': d.id, 'value': d.given.value, 'given': d.given, 'givenApproval': d.givenApproval, 'status': d.status, 'isApprovalGivenReceived': d.isApprovalGivenReceived})
			}
			if(d.received.value === 'n/a'){
				arrayinIDreceived.push({'id': d.id, 'value': 9999999999, 'received': d.received, 'receivedApproval': d.receivedApproval, 'status': d.status, 'isApprovalGivenReceived': d.isApprovalGivenReceived})
			}else{
				arrayinIDreceived.push({'id': d.id, 'value': d.received.value, 'received': d.received, 'receivedApproval': d.receivedApproval, 'status': d.status, 'isApprovalGivenReceived': d.isApprovalGivenReceived})
			}	
			givenObject[d.given.value] = {'given': d.given, 'givenApproval': d.givenApproval}
			receivedObject[d.received.value] = {'received': d.received, 'receivedApproval': d.receivedApproval}
			if(d.status){
				m++
				d.level = 'Level '+ m;
			}
			update_approval_with_giftTypes.push(d)		
		}

		console.log("givenObject===>", givenObject)	
    	console.log("receivedObject===>", receivedObject)

		let recipientlistGiven = arrayinIDgiven.sort(function(a, b) {return a.value - b.value } );
		let recipientlistReceived = arrayinIDreceived.sort(function(a, b) {return a.value - b.value } );

		console.log("recipientlistnew===>", recipientlistGiven)
		console.log("recipientlistnew_all===>", recipientlistReceived)

		let levelObjectGiven = {}
		let i = 0;		
		for(let d of recipientlistGiven){			
			if(d.status){
				i++
				let val = 'Level '+ i;
				levelObjectGiven[val] = {'given': d.given, 'givenApproval': d.givenApproval, 'id': d.id, 'isApprovalGivenReceived': d.isApprovalGivenReceived};
			}
		}
		let levelObjectReceive = {}
		let j = 0;
		for(let d of recipientlistReceived){			
			if(d.status){
				j++
				let val = 'Level '+ j;
				levelObjectReceive[val] = {'received': d.received, 'receivedApproval': d.receivedApproval, 'id': d.id, 'isApprovalGivenReceived': d.isApprovalGivenReceived};
			}
		}		
		console.log("levelObjectGiven===>", levelObjectGiven)	
		console.log("levelObjectReceive===>", levelObjectReceive)
		console.log("update_approval_with_giftTypes===>", update_approval_with_giftTypes)		
		let new_approval_type = []		
		for(let d of update_approval_with_giftTypes){		
			let appObject = {};
			appObject.level = d.level;
			appObject.received = d.received;
			appObject.given = d.given;
			appObject.status = d.status;
			appObject.id = d.id;
			appObject.isApprovalGivenReceived = d.isApprovalGivenReceived;
			appObject.defaultApproval = d.defaultApproval;	
			appObject.givenApproval = d.givenApproval;			
			appObject.receivedApproval = d.receivedApproval;
			if(d.status){			
				if(d.level in levelObjectGiven){
					appObject.given = levelObjectGiven[d.level].given;
					appObject.givenApproval = levelObjectGiven[d.level].givenApproval	
					appObject.isApprovalGivenReceived = levelObjectGiven[d.level].isApprovalGivenReceived;				
				}
				if(d.level in levelObjectReceive){
					appObject.received = levelObjectReceive[d.level].received
					appObject.receivedApproval = levelObjectReceive[d.level].receivedApproval
					appObject.defaultApproval = levelObjectReceive[d.level].receivedApproval	
					appObject.isApprovalGivenReceived = levelObjectReceive[d.level].isApprovalGivenReceived;				
				}	
			}	
			new_approval_type.push(appObject)
		}	
		console.log("new_approval_type===>", new_approval_type)	
		let new_approval_type_update = [];
		for(let s of new_approval_type){
			let appObject = {};
			appObject.level = s.level;
			appObject.received = s.received;
			appObject.given = s.given;
			appObject.status = s.status;
			appObject.id = s.id;
			appObject.isApprovalGivenReceived = s.isApprovalGivenReceived;
			appObject.defaultApproval = s.defaultApproval;	
			appObject.givenApproval = s.givenApproval;			
			appObject.receivedApproval = s.receivedApproval;
			if(s.given.value !== s.received.value){
				appObject.isApprovalGivenReceived = false;
			}
			new_approval_type_update.push(appObject)
		}		

		this.setState({show_approval_modal: false, isLoadedIcon: true})
		this.props.updateApprovalValue(new_approval_type_update)

		// let levelObjectGiven = {}
		// let i = 0;
		// for(let k in givenObject){
		// 	i++
		// 	let val = 'Level '+ i;
		// 	levelObjectGiven[val] = givenObject[k];
		// }
		// let levelObjectReceive = {}
		// let j = 0;
		// for(let k in receivedObject){
		// 	j++
		// 	let val = 'Level '+ j;
		// 	levelObjectReceive[val] = receivedObject[k];
		// }	
		// console.log("levelObjectGiven===>", levelObjectGiven)	
		// console.log("levelObjectReceive===>", levelObjectReceive)
		// console.log("update_approval_with_giftTypes===>", update_approval_with_giftTypes)
		// let new_approval_type = []
		// for(let d of update_approval_with_giftTypes){
		// 	if(d.level in levelObjectGiven){
		// 		d.given = levelObjectGiven[d.level].given
		// 		d.givenApproval = levelObjectGiven[d.level].givenApproval
		// 	}
		// 	if(d.level in levelObjectReceive){
		// 		d.received = levelObjectReceive[d.level].received
		// 		d.receivedApproval = levelObjectReceive[d.level].receivedApproval
		// 	}
		// 	new_approval_type.push(d)
		// }	

		// console.log("new_approval_type===>", new_approval_type)
		// this.setState({show_approval_modal: false, isLoadedIcon: true})
		// this.props.updateApprovalValue(new_approval_type)
	}

	updateApprovalValueType = (obj, giftTypeID) => {
		let approval_type_all = this.state.approval_level.approval_giftTypes;
		let selectTypeApproval = [];
		for(let d of approval_type_all){
			if(parseInt(d.giftTypeID) === parseInt(giftTypeID)){
				selectTypeApproval.push(d)
			}
		}
		let app_wi_gfty = selectTypeApproval[0].elem
		
		let newreason = [];		
		app_wi_gfty.forEach((item) => {
			if (item.id === obj.id) {
				item = obj;
				console.log("matched", item);
			}
			newreason.push(item);
		})

		let givenObject = {};
		let receivedObject = {};
		let update_approval_with_giftTypes = [];
		let m = 0;
		let arrayinIDgiven = []
    	let arrayinIDreceived = []	
		for(let d of newreason){
			if(d.given.value === 'n/a'){
				arrayinIDgiven.push({'id': d.id, 'value': 9999999999, 'given': d.given, 'givenApproval': d.givenApproval, 'status': d.status, 'isApprovalGivenReceived': d.isApprovalGivenReceived})
			}else{
				arrayinIDgiven.push({'id': d.id, 'value': d.given.value, 'given': d.given, 'givenApproval': d.givenApproval, 'status': d.status, 'isApprovalGivenReceived': d.isApprovalGivenReceived})
			}
			if(d.received.value === 'n/a'){
				arrayinIDreceived.push({'id': d.id, 'value': 9999999999, 'received': d.received, 'receivedApproval': d.receivedApproval, 'status': d.status, 'isApprovalGivenReceived': d.isApprovalGivenReceived})
			}else{
				arrayinIDreceived.push({'id': d.id, 'value': d.received.value, 'received': d.received, 'receivedApproval': d.receivedApproval, 'status': d.status, 'isApprovalGivenReceived': d.isApprovalGivenReceived})
			}
			givenObject[d.given.value] = {'given': d.given, 'givenApproval': d.givenApproval}
			receivedObject[d.received.value] = {'received': d.received, 'receivedApproval': d.receivedApproval}
			if(d.status){
				m++
				d.level = 'Level '+ m;
			}
			update_approval_with_giftTypes.push(d)
		}

		console.log("givenObject===>", givenObject)	
    	console.log("receivedObject===>", receivedObject)

		let recipientlistGiven = arrayinIDgiven.sort(function(a, b) {return a.value - b.value } );
		let recipientlistReceived = arrayinIDreceived.sort(function(a, b) {return a.value - b.value } );
			
		console.log("recipientlistnew===>", recipientlistGiven)
		console.log("recipientlistnew_all===>", recipientlistReceived)

		let levelObjectGiven = {}
		let i = 0;		
		for(let d of recipientlistGiven){			
			if(d.status){
				i++
				let val = 'Level '+ i;
				levelObjectGiven[val] = {'given': d.given, 'givenApproval': d.givenApproval, 'id': d.id, 'isApprovalGivenReceived': d.isApprovalGivenReceived};
			}
		}
		let levelObjectReceive = {}
		let j = 0;
		for(let d of recipientlistReceived){			
			if(d.status){
				j++
				let val = 'Level '+ j;
				levelObjectReceive[val] = {'received': d.received, 'receivedApproval': d.receivedApproval, 'id': d.id, 'isApprovalGivenReceived': d.isApprovalGivenReceived};
			}
		}		
		console.log("levelObjectGiven===>", levelObjectGiven)	
		console.log("levelObjectReceive===>", levelObjectReceive)
		console.log("update_approval_with_giftTypes===>", update_approval_with_giftTypes)
		
		let new_approval_type = []
		for(let d of update_approval_with_giftTypes){
			let appObject = {};
			appObject.level = d.level;
			appObject.received = d.received;
			appObject.given = d.given;
			appObject.status = d.status;
			appObject.id = d.id;
			appObject.isApprovalGivenReceived = d.isApprovalGivenReceived;
			appObject.defaultApproval = d.defaultApproval;	
			appObject.givenApproval = d.givenApproval;			
			appObject.receivedApproval = d.receivedApproval;
			if(d.status){			
				if(d.level in levelObjectGiven){
					appObject.given = levelObjectGiven[d.level].given;
					appObject.givenApproval = levelObjectGiven[d.level].givenApproval	
					appObject.isApprovalGivenReceived = levelObjectGiven[d.level].isApprovalGivenReceived;				
				}
				if(d.level in levelObjectReceive){
					appObject.received = levelObjectReceive[d.level].received
					appObject.receivedApproval = levelObjectReceive[d.level].receivedApproval
					appObject.defaultApproval = levelObjectReceive[d.level].receivedApproval	
					appObject.isApprovalGivenReceived = levelObjectReceive[d.level].isApprovalGivenReceived;				
				}	
			}	
			new_approval_type.push(appObject)
		}	
		console.log("new_approval_type===>", new_approval_type)	
		let new_approval_type_update = [];
		for(let s of new_approval_type){
			let appObject = {};
			appObject.level = s.level;
			appObject.received = s.received;
			appObject.given = s.given;
			appObject.status = s.status;
			appObject.id = s.id;
			appObject.isApprovalGivenReceived = s.isApprovalGivenReceived;
			appObject.defaultApproval = s.defaultApproval;	
			appObject.givenApproval = s.givenApproval;			
			appObject.receivedApproval = s.receivedApproval;
			if(s.given.value !== s.received.value){
				appObject.isApprovalGivenReceived = false;
			}
			new_approval_type_update.push(appObject)
		}	

		for(let d of approval_type_all){
            if(parseInt(d.giftTypeID) === parseInt(giftTypeID)){					
                d.elem = new_approval_type_update
            }
        }

		this.setState({show_approval_modal_type: false, isLoadedIcon: true})
    	this.props.updateApprovalValueType(approval_type_all)	
	}

	saveUserLevelApproval = (obj) => {
		let user_level_approval = this.state.user_level_approval;		
        user_level_approval[obj.id.toString()] = obj;
		this.setState({show_user_level_approval_modal: false})
		this.props.saveUserLevelApproval(user_level_approval)
    }

    render() {
		if (this.state.general === null) {
			return (<div>Loading...</div>);
		}
        return (
			<div>
				{
                    (() => {
                        if (this.state.show_gift_type_modal) {
                            return (<div><InactiveOverlay style={{height: "105%", position: "fixed"}}/><GiftsConfigModal closeModal={this.closeModal} curid={this.state.curGiftTypeid} content={JSON.parse(JSON.stringify(this.state.contentGiftType))} updateGiftTypeValue={this.updateGiftTypeValue} isStatusShow={false} isApprovalFieldShow={true}/></div>);
                        }
                    })()
                }
				{
					(() => {
						if (this.state.show_approval_modal) {
							return (<div ><InactiveOverlay style={{height: "105%"}}/><AddEditApprovalModal headerText={this.state.headerText} closeModal={this.closeApprovalModal} curid={this.state.curid} approvalGiftTypesAll={this.state.approval_level.approval} saveApprovalValue={this.saveApprovalValue} saveApprovalValue_receive_given={this.saveApprovalValue_receive_given} updateApprovalValue={this.updateApprovalValue}/></div>);
						}
					})()
				}
				{
					(() => {
						if (this.state.show_approval_modal_type) {
							return (<div ><InactiveOverlay style={{height: "105%"}}/><AddEditApprovalModalType headerText={this.state.headerText} closeModal={this.closeApprovalModalType} curid={this.state.curATid} giftTypeID={this.state.giftTypeID} approvalGiftTypesAll={this.state.approval_level.approval_giftTypes} gift_types={this.state.show_gift_types_array} saveApprovalValue={this.saveApprovalValueType} saveApprovalValueType_receive_given={this.saveApprovalValueType_receive_given} updateApprovalValue={this.updateApprovalValueType}/></div>);
						}
					})()
				}				
				{
					(() => {
						if(this.state.isLoadedIcon){
							return <div><CSLLoader style={{position: 'absolute'}}/></div>
						}
					})()
				}
				<GeneralContainer>
					<GeneralInnerContainer>
						<GeneralLabel style={{marginBottom: "10px"}}>Enable Enhanced Approval?</GeneralLabel>
							{
												
								(() => {
									if (this.state.approval_level.enable_enhanced_approval) {
										return (
											<RadioBlock>
												<SelectedRadio>Yes</SelectedRadio>
												<UnselectedRadio onClick={this.switchRadio('enable_enhanced_approval')}>No</UnselectedRadio>																						
											</RadioBlock>
										);
									} else {
										return (
											<RadioBlock>
												<UnselectedRadio onClick={this.switchRadio('enable_enhanced_approval')}>Yes</UnselectedRadio>
												<SelectedRadio>No</SelectedRadio>																						
											</RadioBlock>
										);
									}
								})()
							}
							{
								(() => {
									if (!this.state.approval_level.enable_enhanced_approval) {
										return(<div>
											<GeneralLabel style={{marginBottom: "10px"}}>Minimum value for reportable gifts/hospitality</GeneralLabel>   
											<MRModalLabel style={{display: "inline-block",marginTop: "20px"}}>{this.state.currency}</MRModalLabel> 					
											<MRModalInput style={{width: "10%",marginLeft: "30px"}} type="number" name="minvalue" min="0" onKeyDown={blockInvalidChar} onChange={this.handleChange} value={this.state.minvalue}/>
										</div>)
									}
								})()
							}
							{
								(() => {
									if(this.state.approval_level.enable_enhanced_approval){
										return(<div>
											<GeneralLabel style={{marginBottom: "10px"}}>Enable Approval Levels</GeneralLabel>
											{														
												(() => {
													if (this.state.approval_level.enable_approval_levels) {
														return (
															<RadioBlock>
																<SelectedRadio>Yes</SelectedRadio>	
																<UnselectedRadio onClick={this.switchRadio('enable_approval_levels')}>No</UnselectedRadio>																									
															</RadioBlock>
														);
													} else {
														return (
															<RadioBlock>																
																<UnselectedRadio onClick={this.switchRadio('enable_approval_levels')}>Yes</UnselectedRadio>
																<SelectedRadio>No</SelectedRadio>										
															</RadioBlock>
														);
													}
												})()
											}
										</div>)
									}
								})()
							}
							{
								(() => {
									if(this.state.approval_level.enable_enhanced_approval && this.state.approval_level.enable_approval_levels){
										return(<div>
											<GeneralLabel style={{marginBottom: "10px"}}>Applies to all gift types</GeneralLabel>
											{														
												(() => {
													if (this.state.approval_level.applies_to_all_gift_types) {
														return (
															<RadioBlock>
																<SelectedRadio>Yes</SelectedRadio>
																<UnselectedRadio onClick={this.switchRadio('applies_to_all_gift_types')}>No</UnselectedRadio>																										
															</RadioBlock>
														);
													} else {
														return (
															<RadioBlock>																
																<UnselectedRadio onClick={this.switchRadio('applies_to_all_gift_types')}>Yes</UnselectedRadio>
																<SelectedRadio>No</SelectedRadio>										
															</RadioBlock>
														);
													}
												})()
											}
										</div>)
									}
								})()
							}
							{
								(() => {
									if(this.state.approval_level.enable_enhanced_approval && this.state.approval_level.enable_approval_levels && this.state.approval_level.applies_to_all_gift_types){
										return(<div>
											{
												(() => {
													if(this.state.approval_level.approval[0].elem.length === 0){
														return(<div>
															<GeneralLabel style={{marginBottom: "10px"}}>Minimum value for reportable gifts/hospitality</GeneralLabel>   
															<MRModalLabel style={{display: "inline-block",marginTop: "20px"}}>{this.state.currency}</MRModalLabel> 					
															<MRModalInput style={{width: "10%",marginLeft: "30px"}} type="number" name="min_val_approval" min="0" onKeyDown={blockInvalidChar} onChange={this.handleChangeApproval} value={this.state.approval_level.min_val_approval}/>
														</div>)
													}
												})()
											}											
											<EmailTopHeader style={{display: "inline-block" , fontWeight: 700, marginTop: "20px"}}>
												Approval Levels
												<div style={{float:'right'}}>
													<div onClick={this.addApprovalLevels} style={{float: "left", cursor: "pointer",fontSize: "20px",color: "#04ADA8"}}>
														<FaPlusCircle  /> 
													</div>	
												</div>
											</EmailTopHeader>
											<ApprovalTable data={this.state.approval_level.approval} editApproval={this.editApproval} deleteApproval={this.deleteApproval} statusCheck={this.statusCheck}/>										
										</div>)
									}
								})()
							}
							{
								(() => {
									if(this.state.approval_level.enable_enhanced_approval && this.state.approval_level.enable_approval_levels && !this.state.approval_level.applies_to_all_gift_types){
										return(<div>
											<GeneralLabel style={{marginBottom: "10px"}}>Minimum value for reportable gifts/hospitality types with undefined level</GeneralLabel>   
											<MRModalLabel style={{display: "inline-block",marginTop: "20px"}}>{this.state.currency}</MRModalLabel> 					
											<MRModalInput style={{width: "10%",marginLeft: "30px"}} type="number" name="min_val_approval_giftTypes" min="0" onKeyDown={blockInvalidChar} onChange={this.handleChangeApproval} value={this.state.approval_level.min_val_approval_giftTypes}/>
											<EmailTopHeader style={{display: "inline-block" , fontWeight: 700, marginTop: "20px"}}>
												Approval Levels
												<div style={{float:'right'}}>
													<div onClick={this.addApprovalLevelsWithType} style={{float: "left", cursor: "pointer",fontSize: "20px",color: "#04ADA8"}}>
														<FaPlusCircle  /> 
													</div>	
												</div>
											</EmailTopHeader>
											<ApprovalTableWithType data={this.state.approval_level.approval_giftTypes} gift_types={this.state.show_gift_types_object} editApproval={this.editApprovalType} deleteApproval={this.deleteApprovalType} statusCheck={this.statusCheckType}/>										
										</div>)
									}
								})()
							}
							{
								(() => {
									if(this.state.approval_level.enable_enhanced_approval && !this.state.approval_level.enable_approval_levels){
										return(<div>
											<GeneralLabel style={{marginBottom: "10px"}}>Minimum value for reportable gifts/hospitality</GeneralLabel>   
											<MRModalLabel style={{display: "inline-block",marginTop: "20px"}}>{this.state.currency}</MRModalLabel> 					
											<MRModalInput style={{width: "10%",marginLeft: "30px"}} type="number" name="min_val_gift_types" min="0" onKeyDown={blockInvalidChar} onChange={this.handleChangeApproval} value={this.state.approval_level.min_val_gift_types}/>
											<EmailTopHeader style={{display: "inline-block" , fontWeight: 700, marginTop: "20px"}}>
												Gift and Hospitality Types											
											</EmailTopHeader>
											<CSLTable add={false} processData={this.tableDataGiftTypes} tableRows="10" />
											{
												(() => {
													if (this.state.show_user_level_approval_modal) {
														return (<div ><InactiveOverlay style={{height: "105%", position: "fixed"}}/><AddEditUserLevelApprovalModal server_name={this.props.server_name} allActiveUsers={this.props.allActiveUsers} headerText={this.state.headerText} closeModal={this.closeUserLevelApprovalModal} user_level_approval={this.state.user_level_approval} curid={this.state.curULAid} saveUserLevelApproval={this.saveUserLevelApproval} updateUserLevelApproval={this.updateUserLevelApproval}/></div>);
													}
												})()
											}
											<EmailTopHeader style={{display: "inline-block" , fontWeight: 700, marginTop: "20px"}}>
												User-level Approval List	
												<div style={{float:'right'}}>
													<div onClick={this.addApprovalUserLevelsApproval} style={{float: "left", cursor: "pointer",fontSize: "20px",color: "#04ADA8"}}>
														<FaPlusCircle  /> 
													</div>	
												</div>										
											</EmailTopHeader>
											<CSLTable add={false} processData={this.tableDataUserApproval} tableRows="10" />
										</div>)
									}
								})()
							}
										
					</GeneralInnerContainer>
				</GeneralContainer>
				<AlertBox alertParam = {this.state.alert_param}/>    
			</div>)
    }
}


export default Approval;
