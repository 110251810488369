import React from 'react';
import styled from 'styled-components';
import {FaTimes, FaPlusCircle, FaStarOfLife} from 'react-icons/fa';
import APICall from '../../Common/APICall';
import Store from '../../Common/Store.js';
import GMFollowupTask from './GMFollowupTask';
import CSLTable from '../Common/CSLTable';
import Dropzone from '../Common/Dropzone';
import Utils from '../../Common/Utils';
import CSLLoader from '../Common/CSLLoader';
import DatePicker from 'react-date-picker';
import {FaCalendarAlt} from 'react-icons/fa';
import CSLTextInput from './../Common/CSLTextInput';
import moment from 'moment';
import AlertBox from '../Common/AlertBox'
import currency_codes from './../Moduleconfig/currency_codes.js'
import { MdModeEdit,MdDelete } from "react-icons/md";
import { blockInvalidChar } from "../Common/blockInvalidChar";

const MRButton = styled.button`
    box-sizing: border-box;
    background-color: #37ada7;
    color: #ffffff;
    border: 1px solid #37ada7;
    width: 84px;
    font-size: 13px;
    height: 25px;
    border-radius: 4px;
`;

const MRModalContainer = styled.div`
    display: block;
    box-sizing: border-box;
    height: auto;
    width: 80%;
    position: absolute;    
    z-index: 1001;
    background-color: rgb(243, 247, 251);
    box-shadow: rgb(167, 166, 166) 0px 0px 5px;
    margin-bottom: 10px;
    border-radius: 5px;
    margin-left: 100px;
`;
const MRModalHeader = styled.div`
    background-color: white;
    display: block;
    padding-top: 5px;
    font-size: 14px;
    font-weight: 700;
    height: 100px;    
    width: calc(100% - 10px);
    border-width: 1px 1px 1px 10px;    
    border-left: 9px solid #04ADA8; 
    border-bottom: 1px solid #DFDFDF;
`;
const MRModalHeaderText = styled.div`
    font-weight: 700;
    font-size: 22px;
    float: left;
    margin-top: 24px;
    margin-left: 35px;  
    color: #143151;
`;
const MRModalHeaderCloseBtn = styled.div`
    position: absolute;
    top: 8px;
    right: 10px;
    box-sizing: border-box;
    margin-top: 24px;
    margin-right: 20px;
    font-size: 25px;
    cursor: pointer;
    font-weight: 200;
`;
const MRModalBody = styled.div`
    display: block;
    box-sizing: border-box;
    padding: 10px 20px 10px 20px;
`;
const MRModalBodyInner = styled.div`
    border-radius: 4px;
    padding: 15px;
    
`;
const MRModalLabel = styled.div`
    color: #030303;
    font-weight: 600;
    margin-top: 15px;
    font-size: 15px;
    margin-bottom: 15px;
`;
const ModalNameLabelDivReference = styled.div`
    height: auto;
    width: 100%;    
    font-weight: 600;
    color: #282828;    
    display:inline;
`;
const MRModalFooter = styled.div`
    position: relative;
    background-color: white;
    height: 80px;
    border-style: solid;
    border-width: 1px;
    border-color: #f3f7fb;
    width: 100%;
    border-top: 1px solid #DFDFDF;
`;
const MRModalNextBtn = styled.button`
    display: inline-block;
    box-sizing: border-box;
    background-color: #11ABA6;
    color: #ffffff;
    width: 145px;
    height: 45px;
    border-radius: 4px;
    border: 1px solid #11ABA6;
    margin-left: 5px;
    font-size: 15px;
    margin-left: 10px;
    float: right;
    cursor: pointer;
`;
const MRModalRemoveBtn = styled.button`
    display: inline-block;
    box-sizing: border-box;
    background-color: #E75C71;
    color: #ffffff;
    width: 145px;
    height: 45px;
    border-radius: 4px;
    border: 1px solid #E75C71;
    margin-left: 5px;
    font-size: 15px;
    margin-left: 10px;
    float: right;
    cursor: pointer;
`;
const MRModalSaveCloseBtn = styled.button`
    display: inline-block;
    box-sizing: border-box;
    background-color: #143151;
    color: #ffffff;
    width: 145px;
    height: 45px;
    border-radius: 4px;
    border: 1px solid #143151;
    margin-left: 5px;
    font-size: 15px;
    margin-left: 10px;
    float: right;
    cursor: pointer;
`;
const Radiolevel = styled.label`
    cursor: pointer;
    text-transform: uppercase;
    border: 1px solid #ccc;
    width: 59px;
    height: 20px;
    padding: 6px 0;
    text-align: center;
    display: inline-block;
    -webkit-transition: all 0.4s;
    -moz-transition: all 0.4s;
    transition: all 0.4s;    
`;
const MRModalTextarea = styled.textarea`
    width: 99%;
    border: 1px solid #ffffff;
    border-radius: 3px;
    box-shadow: 0 0 4px #c4c4c4;
    padding: 5px;
    color: #000000;
    font-family: 'Montserrat', sans-serif;
`;
const MRModalCancelBtn = styled.button`
    display: inline-block;
    box-sizing: border-box;
    background-color: #ffffff;
    color: #545454;
    border: 2px solid #dadada;
    width: 145px;
    height: 45px;
    border-radius: 4px;
    font-size: 15px;
    float: right;
    cursor: pointer;
`;

const RegModalNameInput = styled.input`
    height: 28px;
    width: 100%;
    border: 1px solid #ffffff;
    border-radius: 5px;
    box-shadow: 0 0 4px #c4c4c4;
    padding: 5px;
    color: #6d6d6d;
    background-color: #EEEEEE;
`;
const RegDismisOuterContainer = styled.div`
    display: block;
    box-sizing: border-box;
    width: 100%;    
`;
const OverviewInfoHeader = styled.div`
    padding: 12px 15px;
    background-color: #04ada8;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;    
    width: 96%;
    margin: 0 auto;
    margin-top: 30px;    
`;
const OverviewInfoBody = styled.div`
	padding: 15px;
    background-color: #ffffff;
    border: 1px solid #d7dddf;
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
    width: 96%;
    margin: 0 auto;
    margin-bottom: 30px;
`;
const InactiveOverlay = styled.div`
  display: block;
  box-sizing: border-box;
  height: 1178px;
  width: 100%;
  box-shadow: 0 0 20px #a7a6a6;
  position: absolute;
  left: -10%;
  top: -5px;
  z-index: 1000;
  opacity: 0.8;
`;
const ScoreContainer = styled.div`
    display: block;
    box-sizing: border-box;
    height: auto;    
    z-index: 1001;
    background-color: #f7f7f7;
    border-radius: 5px;
    box-shadow: 0 0 20px #a7a6a6;
    width: 100%;
    margin: 0px auto;
    padding: 15px;
    left: 15%;   
`

const ScoreHeaderContainer = styled.div`
    background-color: #36aba6;    
    height: auto;
    padding: 10px;
    color: #ffffff;
    border: 1px solid #36aba6;
    border-top-left-radius: 3px;
    border-top-right-radius: 3px;
`;
const ScoreBodyContainer = styled.div`
  padding: 10px;
  text-align: left;
  overflow-y: scroll;
  height: 555px;
`;
const HeaderButton = styled.div`
    color: #ffffff;
    width: 30px;
    height: 29px;
    float: right;
    font-size: 20px;
    cursor: pointer;
`;
const MRModalInput = styled.input`
    height: 25px;
    width: 99%;
    box-shadow: rgb(196, 196, 196) 0px 0px 4px;
    color: #545454;
    font-family: montserrat;
    font-size: 14px;
    font-weight: 600;
    border-width: 1px;
    border-style: solid;
    border-color: rgb(255, 255, 255);
    border-image: initial;
    border-radius: 3px;
    padding: 6px;
`;
const MRModalSelect = styled.select`
    box-sizing: border-box;
    height: 40px;
    width: 100%;
    background-color: #fff;
    border: 1px solid #ffffff;
    border-radius: 3px;
    box-shadow: 0 0 4px #c4c4c4;
    font-family: Montserrat,sans-serif;
    color: #444444;    
    padding: 10px;
    font-weight: 600;
`;
const ModalNameLabelDiv = styled.div`
    height: auto;
    width: 100%;
    padding-top: 22px;
    font-weight: 600;
    color: #282828;
`;
const ModalNameInputDiv = styled.div`
    height: auto;
    width: 100%;    
    color: #282828;
    font-family: 'Montserrat', sans-serif;
`;

const UserHr = styled.div`
border-top: 2px solid #E9EBED;
`;
const SpacerXL = styled.div`
    height: 20px;
`;
const SpacerL = styled.div`
    height: 10px;
`;
const SpacerS = styled.div`
    height: 5px;
`;
const CSLDateCover = styled.div`   
    margin-top: 8px;
    width: 40%;
    border-radius: 5px;
    border-style: solid;
    border-width: 1px;
    border-color: #282828;
    background-color: #ffffff;
    height: 25px;
    padding-top: 2px;
    padding-left: 2px;
    // float: left;
    position: relative;
`;
const ModalContainerGrid = styled.div`
  display: grid;
  grid-template-columns: 20% calc(80% - 15px);
  grid-gap: 5px;
  padding: 10px;
`;
const ModalNameLabelDivFin = styled.div`
  height: auto;
  width: 100%;
  padding-top: 10px;
  font-weight: 600;
  color: #1a3552;
`;
const ModalNameInputDivFin = styled.div`
  height: auto;
  width: 100%;
  padding-top: 10px;
  color: #1a3552;
  font-family: 'Montserrat', sans-serif;
`;
const MRDataContainer = styled.div`
  display: block;
  box-sizing: border-box;
  height: auto;
  width: 100%;
  margin: 0 auto;
  margin-top: 30px;
  background-color: #e7f0f7;
  // margin-bottom: 10px;
  border: 1px solid #d0d0d0;
  border-radius: 5px;
  padding: 5px 10px;
`;
const DescriptionInputDiv = styled.div`
  height: auto;
  width: 100%;
  color: #1a3552;
  font-family: 'Montserrat', sans-serif;
`;

const MRTasksContainer = styled.div`
  display: block;
  box-sizing: border-box;
  height: auto;
  //width: 92%;
  margin: 0 auto;
  // top: 30px;
  margin-bottom: 10px;
  // border-radius: 5px;
  //padding: 5px 10px;
`;
const MRSuccessContainer = styled.div`
  box-sizing: border-box;
  margin-top: 30px;
  background-color: #e2f0d9;
  border: 1px solid #6c9450;
  border-radius: 5px;
  padding: 10px 20px;
`;
const MRDangerContainer = styled.div`
  box-sizing: border-box;
  margin-top: 30px;
  background-color: #eccaca;
	border: 1px solid #b75050;
  border-radius: 5px;
  padding: 10px 20px;
`;

class GMGiftsApprovalModal extends React.Component
{
    state = {

        giftStatus: "received",
        recipientlist: [],
        recipientName: "",
        recipientNameAll: "",
        isOtherRecipientName: false,
        donorName: "",
        giftTypeid: 0,
        giftDescription: "",
        giftTypeList: [],
        currency: "",
        minvalue: 0,
        fixedMinvalue: 0,
        finalMinValueCheck: 0,

        giftTypename: "",

        Ref_ID: null,
        submit_form: null,
        curlane: null,
        taskid: null,
        showModal: false,
        review_list: [],
        followupTask: [],
        ready: false,
        showfollowupclose: true,
        groupcompanyName: "",
        dicloserid: 0,
        showRemovalReason: false,
        showApprovalReason: false,
        removalType: "",
        showDescription: false,
        reasonDescription: "",
        removal_readonly: false,
        header_message: "",
        reasonID: 0,
        module_config: null,
        binFiles: [],
        cur_files_json: null,
        is_disabled: false,
        isLoadedIcon: false,
        giftedDate: new Date(),
        ischangesFollowup: false,
        assign_data: null,
        isCompletetoUpdate: false,
        alert_param: null,
        loading: false,

        app_levels: null,
        approval_state: null,
        approver_entitled: null,
        approval_data: [],
        manager_id: null,
        approval_level: "none",
        specificUserID: 0,
        giftGivenTo: '',
        app_currency: "",
        app_minvalue: 0,
        isShowAppValue: false,
        isUserlevelApproval: false,
        followupTasksList: [],
        headerFollowupText: '',
        recipientlistObject: {}
    };

    componentDidMount()
	{
        let index = Store.getStoreData('q_index') === null ? 0 : Store.getStoreData('q_index');
        console.log('index in componentDidMount', index);
        let review_list = Store.getStoreData('reviewee_list');
        let form_data = {};
        let assign_data = {}
        if(index >= 0) {
            form_data = Store.getStoreData('cur_q_modal_data');
            //console.log('form_data', form_data);

            let contracts = Store.getStoreData('contacts');
            let otherObject = {id: "Other", name: "Other"}

            let removal_readonly = false
            if(form_data.cur_lane === "COMP_GIFTS_REMOVE") {
                removal_readonly =  true;
            }else if(form_data.cur_lane === "COMP_GIFTS_COMPLETE"){
                removal_readonly =  true;
            }

            let giftTypeList = [];
            if('gift_types' in Store.getStoreData('module_config')){
                giftTypeList = Store.getStoreData('module_config').gift_types;
            }


            let dicloserid = form_data.contact_id;
            let company_contacts = Store.getStoreData('contacts');
            let task_json = JSON.parse(form_data.task_json);

            let gifttypename = "";
            giftTypeList.forEach((item) => {
                if (item.giftTypeID === task_json.object_data.headerinfo.gift_typeid) {
                    gifttypename = item.giftTypeName;
                }
            });
            let gc_assoc = Store.getStoreData('gc_assoc');
            //let gcid = (parseInt(task_json.task_data.group_company_id)%100000)
            let gcid = parseInt(form_data.company_id) < 100000 ? parseInt(Store.getStoreData('gc_company')[0].id) : (parseInt(form_data.company_id)%100000);
            let gcname = "";
            if(gcid in gc_assoc){
                gcname = gc_assoc[gcid];
            }
            //console.log("gcname",gcname)
            let gc_users = [];
            let my_company = null;
            let gc_company = Store.getStoreData('gc_company');
            let companyUsers = {};
            for(let gc of gc_company) {
                if(gc.id === gcid){
                    my_company = gc;
                    companyUsers = gc.users;
                }
            }

            //new
            let recipientlistObject = {'Other': 'Other'}
            let contactsArray = [];
            if(Object.keys(companyUsers).length !== 0){
                Object.keys(companyUsers).map((d, index) => {
                    if(d in contracts){
                        let contactsObject = {}
                        // contactsObject.id = contracts[d];
                        contactsObject.id = d;
                        contactsObject.name = contracts[d];
                        contactsArray.push(contactsObject);

                        recipientlistObject[d.toString()] = contracts[d]
                    }
                })
            }
            let role = Store.getStoreData('role');
            if(role === "admin_manager" || role === "manager" || role === "gm"){
                contactsArray.push(otherObject);
            }
            let recipientlist = contactsArray.sort(function(a,b) {return (a.name > b.name) ? 1 : ((b.name > a.name) ? -1 : 0);} );
            //new



            //console.log('review_list', review_list)
            let com_roles = Store.getStoreData('userroles');
            for(let id of Object.keys(my_company.users)) {
                if (com_roles[id.toString()] !== undefined && com_roles[id.toString()] !== 'no_access' && id.toString() in company_contacts) {
                    gc_users.push({ID: id, ContactName: company_contacts[id.toString()]});
                }
            }

            let header_message = "This Gifts & Hospitality disclosure requires approval. Please review.";
            let module_config = Store.getStoreData('module_config');

            let isOtherRecipientName = false;
            if(task_json.object_data.headerinfo.check_recipient_value === 'Other'){
                isOtherRecipientName = true;
            }

            let minimumValue = 0;
            if('approval_level' in Store.getStoreData('module_config') && Store.getStoreData('module_config').approval_level.enable_enhanced_approval === false){
                if('general' in Store.getStoreData('module_config') && 'companyCurrencyDetails' in Store.getStoreData('module_config').general){
                    minimumValue = Store.getStoreData('module_config').general.companyCurrencyDetails.minvalue;
                }
            }else if('approval_level' in Store.getStoreData('module_config') && Store.getStoreData('module_config').approval_level.enable_enhanced_approval){
                if(!Store.getStoreData('module_config').approval_level.enable_approval_levels){
                    minimumValue = Store.getStoreData('module_config').approval_level.min_val_gift_types;
                }else{
                    if(Store.getStoreData('module_config').approval_level.applies_to_all_gift_types){
                        if(Store.getStoreData('module_config').approval_level.approval[0].elem.length === 0){
                            minimumValue = Store.getStoreData('module_config').approval_level.min_val_approval
                        }else{
                            minimumValue = Store.getStoreData('module_config').approval_level.approval[0].elem[0].received.value
                        }
                    }else{
                        minimumValue = Store.getStoreData('module_config').approval_level.min_val_approval_giftTypes;
                    }
                }
            }

            let currencyValue = "";
            if('general' in Store.getStoreData('module_config') && 'companyCurrencyDetails' in Store.getStoreData('module_config').general){
                currencyValue = Store.getStoreData('module_config').general.companyCurrencyDetails.currency;
            }

            let giftedDate = 'giftedDate' in task_json.object_data.headerinfo ? new Date(task_json.object_data.headerinfo.giftedDate) : new Date();

            assign_data.additional_fields = "additional_fields" in task_json.object_data.headerinfo ? task_json.object_data.headerinfo.additional_fields : [];

            //approval section start
            let app_levels = null;
            let approval_state = null;
            let approver_entitled = null;
            let approval_data = [];
            let approval_level = "none";
            if('approval_level' in task_json.object_data.headerinfo){
                approval_level = task_json.object_data.headerinfo.approval_level;
                app_levels = task_json.object_data.headerinfo.approval_level.split("-");
                approval_data = "approval_data" in task_json.object_data.headerinfo ? task_json.object_data.headerinfo.approval_data : [];
                approval_state = approval_data.length;
                approver_entitled = app_levels[approval_state];
                if ("approval_data" in task_json.object_data.headerinfo === false) {
                    approval_data = [{
                        comments: "",
                        performed_by: Store.getStoreData("contact_id"),
                        action_time: moment().unix(),
                        status: "",
                        attachments: []
                    }];
                } else {
                    approval_data.push({
                        comments: "",
                        performed_by: Store.getStoreData("contact_id"),
                        action_time: moment().unix(),
                        status: "",
                        attachments: []
                    })
                }
            }
            //approval section end
            console.log("approval_data===>", approval_data)
            console.log("approval_state===>", approval_state)
            let manager_id = Store.getStoreData('manager_id');

            let specificUserID = 'specificUserID' in task_json.object_data.headerinfo ? task_json.object_data.headerinfo.specificUserID : 0

            let contracts_upd = Store.getStoreData('contacts');
            let namecon = {};
            for(let k in contracts_upd){
                namecon[contracts_upd[k]] = k
            }

            let check_recipient_value = task_json.object_data.headerinfo.check_recipient_value;
            if('check_recipient_id' in task_json.object_data.headerinfo && check_recipient_value !== 'Other'){
                check_recipient_value = task_json.object_data.headerinfo.check_recipient_id;
            }else if(check_recipient_value !== 'Other'){
                check_recipient_value = namecon[check_recipient_value]
            }

            let isUserlevelApproval = 'isUserlevelApproval' in task_json.object_data.headerinfo ? task_json.object_data.headerinfo.isUserlevelApproval : false;
            // let isUserlevelApproval_new = isUserlevelApproval;
            // if(isUserlevelApproval && 'specificUserID' in task_json.object_data.headerinfo){
            //     isUserlevelApproval_new = false
            //     if(parseInt(task_json.object_data.headerinfo.specificUserID) === parseInt(Store.getStoreData("contact_id"))){
            //         isUserlevelApproval_new = true
            //     }
            // }

            let followupTasksList = Store.getStoreData('updated_followups');

            this.setState({ Ref_ID: task_json.object_data.ref_id,
                recipientlist: recipientlist,
                giftTypeList: giftTypeList,
                currency: task_json.object_data.headerinfo.currency !== "" ? task_json.object_data.headerinfo.currency : Store.getStoreData('currencyDetails').currency,
                giftStatus: task_json.object_data.headerinfo.gift_status,
                donorName: task_json.object_data.headerinfo.donor_name.replace(/&rsquo;&rsquo;/g, '\''),
                recipientName: check_recipient_value,
                recipientNameAll: task_json.object_data.headerinfo.recipient_name,
                isOtherRecipientName: isOtherRecipientName,
                minvalue: task_json.object_data.headerinfo.minvalue,
                giftTypeid: task_json.object_data.headerinfo.gift_typeid,
                giftDescription: task_json.object_data.headerinfo.gift_description,
                giftTypename: gifttypename,

                curlane: form_data.cur_lane,
                taskid: form_data.id,
                review_list: gc_users,
                groupcompanyName: gcname,
                dicloserid: dicloserid,
                removal_readonly: removal_readonly,
                header_message: header_message,
                module_config: module_config,
                cur_files_json: task_json.object_data.bin_files,
                giftedDate,
                assign_data: assign_data,

                app_levels,
                approval_state,
                approver_entitled,
                approval_data,
                manager_id,
                ready: true,
                approval_level,
                specificUserID,
                fixedMinvalue: minimumValue,
                finalMinValueCheck: minimumValue,
                giftGivenTo: 'giftGivenTo' in task_json.object_data.headerinfo ? task_json.object_data.headerinfo.giftGivenTo : '',
                app_currency: 'app_currency' in task_json.object_data.headerinfo ? task_json.object_data.headerinfo.app_currency : Store.getStoreData('currencyDetails').currency,
                app_minvalue: 'app_minvalue' in task_json.object_data.headerinfo ? task_json.object_data.headerinfo.app_minvalue : 0,
                isShowAppValue : 'isShowAppValue' in task_json.object_data.headerinfo ? task_json.object_data.headerinfo.isShowAppValue : false,
                isUserlevelApproval,
                followupTasksList,
                recipientlistObject
            });
        }
    }

    closeView = () => {
        if (document.referrer.endsWith("/tmview")) {
            window.location =  process.env.REACT_APP_TMVIEW_URL;
        } else {
            if(this.state.ischangesFollowup){
                if(Store.getStoreData('isreferenceUrl') === true){
                    if(Store.getStoreData('isReferenceEmail') === true){
                        window.location = "/";
                    }
                } else {
                    this.props.closeModalForApproval();
                }
            }else{
                if(Store.getStoreData('isreferenceUrl') === true){
                    if(Store.getStoreData('isReferenceEmail') === true){
                        window.location = "/";
                    }
                } else {
                    this.props.closeModalNormal();
                }
                // this.props.closeModalNormal();
            }
        }
    }
    handleAdditionalROD = (event) => {
        console.log(event.target.value)
        const {additional_records_of_disclosure} = this.state;
        additional_records_of_disclosure.enabled = event.target.value;
        if(event.target.value === 'No'){
            additional_records_of_disclosure.description = "";
        }
        this.setState({ additional_records_of_disclosure })
    }
    handleThiredParty = (event) => {
        console.log(event.target.value)
        const {disclosure_to_third_party} = this.state;
        disclosure_to_third_party.enabled = event.target.value;
        if(event.target.value === 'No'){
            disclosure_to_third_party.description = "";
        }
        this.setState({ disclosure_to_third_party })
    }

    handleChange = (evt) => {
        this.setState({[evt.target.name]: evt.target.value});
    }
    handleChangeValue = (evt) => {
        let isShowAppValue = false
        if(Store.getStoreData('currencyDetails').currency !== evt.target.value){
            isShowAppValue = true
        }
        this.setState({[evt.target.name]: evt.target.value, isShowAppValue});
    }
    handleChangeRemoval = (evt) => {
        console.log("key",evt.target.options.selectedIndex)
        let resultTypeValue = Store.getStoreData('module_config').result_types;
        let resultType = []
        for(let d of resultTypeValue){
            if(d.isActive !== 0){
                resultType.push(d);
            }
        }

        let curIndex = evt.target.options.selectedIndex;
        let showDescription = false;
        let reasonID = 0
        if(curIndex !== 0){
            if(resultType[curIndex - 1].isComments === 1){
                showDescription = true;
            }
            reasonID = resultType[curIndex - 1].resultTypeID
        }
        console.log("Sourav111",showDescription)
        this.setState({[evt.target.name]: evt.target.value, showDescription, reasonID});
    }
    handleChangeDTTP = (evt) => {
        const {disclosure_to_third_party} = this.state;
        disclosure_to_third_party.description = evt.target.value;
        this.setState({ disclosure_to_third_party })
    }
    handleChangeAROD = (evt) => {
        const {additional_records_of_disclosure} = this.state;
        //console.log("additional_records_of_disclosure",this.state.additional_records_of_disclosure)
        additional_records_of_disclosure.description = evt.target.value;
        this.setState({ additional_records_of_disclosure })
    }
    showAddFollowupTaskModal = (index) => (event) => {
        //event.preventDefault();
        //console.log("Sourav")
        Store.updateStore('ftask_index', -1);
        this.setState({ showModal: true, showfollowupclose: false, headerFollowupText: "Add new"});
    }
    closeFollowupModal = () => {
        this.setState({ showModal: false, showfollowupclose: true });
    }

    followupTaskValueFetch = (taskValue) => {
        console.log(taskValue)
        let ref_id = Utils.genKey(12);
        let task = {
            "task_data": { "module_id": "gifts", "last_action": "COMP_GIFTS_FOLLOWUP", "ref_id" : ref_id,"actiondate": Date.now(), "cur_assigned_to" : taskValue.assignedto, 'task_type' : 'COMP_GIFTS_FOLLOWUP', 'parent_task_id' : this.state.taskid, 'cur_lane': 'COMP_GIFTS_FOLLOWUP_ASSIGNMENT', due_date: taskValue.dueby},
            "object_data": {subject: taskValue.subject, note: taskValue.note},
            "action_data": [{ "action": "COMP_GIFTS_FOLLOWUP", "actiondate": Date.now(), "assigned_to": taskValue.assignedto, "cur_lane":"COMP_GIFTS_FOLLOWUP_ASSIGNMENT", due_by: taskValue.dueby, "note": taskValue.note }]
        };
        let postData = { "command": "createfollowuptask", "task": task, bin_files: taskValue.binFiles, parentTaskId: this.state.taskid};
        console.log("task follow-up", postData);
        let api = new APICall();
        this.setState({loading: true})
        api.command(postData, this.processAddTask);
    }
    processAddTask = (result) => {
        this.setState({loading: false})
        let msg = 'The follow-up task has been added.'
        let title = 'Alert'
        // alert("The follow-up task has been added.");
        let alert_param = {title: title, message: msg, ok_text: 'OK', confirm: false, alertHandler: this.samealertremoveHandler, stack: {id: 0}}
        this.setState({alert_param: alert_param})
        let command_data = { "command": "listfollowups", parent_id: this.state.taskid };
        console.log('followuptasks list command', command_data);
        let api = new APICall();
        api.command(command_data, this.updateFollowups);
    }
    updateFollowups = (result) => {
        Store.updateStore('updated_followups', result.result.followuptasks);
        console.log('result.result.followuptasks', result);
        //this.setState({child_task : result.result });
        this.setState({ showModal: false,
            followupTasksList: result.result.followuptasks,
            loading: false,
            ischangesFollowup: true,
            showfollowupclose: true
        });
    }

    showFollowUp = (task_id) => (event) => {
        event.preventDefault();
        // let postData = { command: "get_subtask", task_id: task_id};
        // this.setState({isLoadedIcon: true})
        // let api = new APICall();
        // api.command(postData, this.openFollowupModal);
    }

    editFollowupTask = (followupid) => (event) => {
        event.preventDefault();
        let postData = { command: "get_subtask", task_id: followupid};
        let api = new APICall();
        this.setState({loading: true})
        api.command(postData, this.editFollowupModal);
    }

    editFollowupModal = (result) => {
        console.log("followup_task",result.result.task);
        Store.updateStore('ftask_index', 0);
        Store.updateStore('cur_followup_task', result.result.task.subtask)
        Store.updateStore('cur_parentTask', result.result.task.parenttask)
        this.setState({ showModal: true, loading: false, headerFollowupText: "Edit"})
    }

    deleteChildTask = (taskid) => (event) => {
        event.preventDefault();
        let alert_param = {title: 'Warning', message: 'Are you sure you want to delete this record?', ok_text: 'Yes', cancel_text: 'No', confirm: true,
                           alertHandler: this.deleteConfirmChildTask, stack: {taskid: taskid}}
        this.setState({alert_param: alert_param})
    }

    deleteConfirmChildTask = (result, stack) => {
        if(!result) {
            this.setState({alert_param: null})
            return
        }
        let postData = {command: "remove_followup_task", task_id: stack.taskid };
        console.log("postData",postData)
        this.setState({loading: true})
        let api = new APICall();
        api.command(postData, this.processRemoveFollowupData);
    }

    processRemoveFollowupData = (result) => {
        this.setState({alert_param: null, ischangesFollowup: true})
        let alert_param = {title: 'Success', message: 'Follow-up task deleted successfully', ok_text: 'Ok', confirm: false,
                           alertHandler: this.updateprocessallchildtask, stack: {}}
        this.setState({alert_param: alert_param, loading: false})
    }

    processMyfollowupTaskData = () => {
        var taskdata = this.state.followupTasksList;
        console.log("taskdata",taskdata)
        let has_admin = Store.getStoreData('role') === 'admin_manager' ? this.state.removal_readonly === true ? false : true : false
        let ret = {data: [], columns: []};
        let i = 0;
        ret.columns =[{Header: '#', accessor: 'index', width: 30, headerStyle: {textAlign: 'left',fontWeight: "600"}},
                        {Header: 'Assigned To', accessor: 'assign_to', width: 150, headerStyle: {textAlign: 'left',fontWeight: "600"}},
                        {Header: 'Notes', accessor: 'notes', minWidth: 300, headerStyle: {textAlign: 'left',fontWeight: "600"}},
                        {Header: 'Due Date', accessor: 'due_date', width: 200, headerStyle: {textAlign: 'left',fontWeight: "600"}},
                        {Header: 'Status', accessor: 'status', width: 150, headerStyle: {textAlign: 'center', fontWeight: "600"}, style: {textAlign: 'center'}, Cell: cellInfo => (<div><MRButton style={{cursor: 'default',backgroundColor: `${cellInfo.value.color}`}}>{cellInfo.value.text}</MRButton></div>)},
                        {Header : 'Action', Cell: row => (
                            <div style={{display: "inline-flex"}}>
                                {/* <MRButton style={{width: '80px',cursor: 'pointer'}} onClick={this.showFollowUp(row.original.id)}>View</MRButton>   */}
                                {
                                    (() => {
                                        if(row.original.status_value !== "Completed"){
                                            return(<div style={{display: "inline-flex"}}>
                                                <div style={{fontSize: "20px",marginLeft: "15px",cursor: 'pointer', color: "#282828", fontWeight: "bold"}} onClick={this.editFollowupTask(row.original.id)}><MdModeEdit /></div>
                                                <div style={{fontSize: "20px",marginLeft: "15px",cursor: 'pointer', color: "#282828", fontWeight: "bold"}} onClick={this.deleteChildTask(row.original.id)}><MdDelete /></div>
                                            </div>)
                                        }
                                    })()
                                }
                            </div>
                         ), width: 120, show: has_admin ? true : false, headerStyle: {textAlign: 'center',fontWeight: "600"}}
                    ];
        for(let d of taskdata) {
            i++;
            let tdata = JSON.parse(d.task_json).action_data;
            let taskdata1 = JSON.parse(d.task_json).task_data;
            let taskdataobject = JSON.parse(d.task_json).object_data;
            let status = "Pending";
            if(d.last_action === 'COMP_GIFTS_FOLLOWUP_DISMISS'){
                status = "Dismissed";
            }else if(d.last_action === 'COMP_GIFTS_FOLLOWUP_COMPLETE'){
                status = "Completed";
            }
            //console.log("d.cur_assigned_to",taskdata1.cur_assigned_to)
            let assigntoName = Store.getStoreData('contacts')[taskdata1.cur_assigned_to]
            let notes = taskdataobject.note;
            let due_date_str = d.due_date ? d.due_date : '1970-01-01T00:00:00.000Z';

            const c_date = new Date(due_date_str);
            let day = c_date.getDate().toString().length === 1 ? "0"+c_date.getDate().toString() : c_date.getDate().toString();
            let month = (c_date.getMonth() + 1).toString().length === 1 ? "0"+(c_date.getMonth() + 1).toString() : (c_date.getMonth() + 1).toString();
            let year = c_date.getFullYear().toString();
            const due_date = day+"/"+month+"/"+year;

            var dd   = parseInt(due_date_str.substring(8,12));
            var mm  = parseInt(due_date_str.substring(5,7));
            var yyyy   = parseInt(due_date_str.substring(0,4));
            console.log('day, month, year', dd,mm,yyyy)

            let due_date_status = new Date(yyyy, mm-1, dd);
            var due_date_status_final = new Date ( due_date_status );
            due_date_status_final.setHours ( due_date_status.getHours() + 23 );
            due_date_status_final.setMinutes ( due_date_status.getMinutes() + 59 );
            due_date_status_final.setSeconds ( due_date_status.getSeconds() + 59 );


            let today = new Date();
            console.log('due_date_status', due_date_status_final, 'today',today)
            let text = due_date_status_final > today ? status : 'Overdue';
            let color = due_date_status_final > today ? '#2E8B57' : '#FF0000';
            if(status === "Pending"){
                text = due_date_status_final > today ? status : 'Overdue';
                color = due_date_status_final > today ? '#2E8B57' : '#FF0000';
            }else if(status === "Completed"){
                text = status;
                color = '#2E8B57';
            }
            let elem = {
                'id' : d.id,
                'index' : i,
                'assign_to' : assigntoName,
                'notes' : notes,
                'due_date' : due_date,
                'status' : {text: text, color: color},
                'status_value': text
               };
            ret.data.push(elem);
        }
        return ret;
    }

    showfollowtaskModal = (row) => (event) => {
        event.preventDefault();
        Store.updateStore('ftask_index', row.index);
        this.setState({ showModal: true });
    }

    submitApproveGiftDirect = (submit_form) => {
        let problems = false
        let message = ''
        const  module_config = Store.getStoreData('module_config');
        if(this.state.giftTypeid.toString() === "0"){
            problems = true
            message += `Please select gift type.<br>`
        }
        if(this.state.recipientName === "") {
            problems = true
            message += `Please select recipient name.<br>`
        }
        if(this.state.recipientName === "Other" && this.state.recipientNameAll === ""){
            problems = true
            message += `Please add name of other recipient.<br>`
        }
        if(this.state.giftStatus === "received" && this.state.donorName === ""){
                problems = true
                message += `Please add donor name.<br>`
        }
        if(this.state.isShowAppValue && (this.state.app_minvalue === "" || parseInt(this.state.app_minvalue) === 0)){
                problems = true
                message += `Enter approximate value.<br>`
            }
        if(this.state.giftDescription === ""){
                problems = true
                message += `Please provide a description of the gift/hospitality.<br>`
        }
        if(module_config.custom_fields.enabled){
            let fields = [];
            let is_numeric = false;
            let numericFields = [];
            if (module_config.custom_fields.additional_fields.length !== 0) {
                this.state.assign_data.additional_fields.forEach((item) => {
                    if(item.displayFor === this.state.giftStatus){
                        if (item.required === true && item.value === "") {
                            fields.push(item.nickname);
                        }
                        if(item.value){
                            if(item.type === 'Numeric') {
                                if(item.value.length > 0) {
                                    if(isNaN(item.value)){
                                        is_numeric = true;
                                        numericFields.push(item.nickname);
                                    }
                                }
                            }
                        }
                    }
                    if(item.displayFor === 'both'){
                        if (item.required === true && item.value === "") {
                            fields.push(item.nickname);
                        }
                        if(item.value){
                            if(item.type === 'Numeric') {
                                if(item.value.length > 0) {
                                    if(isNaN(item.value)){
                                        is_numeric = true;
                                        numericFields.push(item.nickname);
                                    }
                                }
                            }
                        }
                    }
                })
            }
            if (fields.length !== 0) {
                let err_msg = "";
                fields.forEach((item) => {
                    err_msg = err_msg + item + ".<br>";
                })
                err_msg = err_msg.substring(0, err_msg.length - 2);
                problems = true
                message += err_msg +`<br>`
            }
            if(is_numeric) {
                let err_msg_new = "";
                numericFields.forEach((item) => {
                    err_msg_new = err_msg_new + item + ", ";
                })
                err_msg_new = err_msg_new.substring(0, err_msg_new.length - 2);
                if (numericFields.length === 1) {
                    err_msg_new = "<br>Please put a numeric value in the "+ err_msg_new + " field. ";
                } else {
                    err_msg_new = "<br>Please put numeric values in "+ err_msg_new + " fields. ";
                }
                problems = true
                message += err_msg_new +`<br>`
            }
        }

        if(problems) {
            let alert_param = {title: 'Warning', message: message, ok_text: 'OK', confirm: false,
                       alertHandler: this.samealertremoveHandler, stack: {}, align: 'left'}
            this.setState({alert_param: alert_param})
            return;
        }

        if(parseInt(this.state.minvalue) < parseInt(this.state.finalMinValueCheck)){
            // let alert_param = {title: 'Alert', message: 'This value is less than the minimum value of reportable gifts/hospitality. Do you want to proceed?', ok_text: 'OK', cancel_text: 'Cancel', confirm: true,
            //                 alertHandler: this.processConfirmationAddNew, stack: {submit_form: submit_form}}
            // this.setState({alert_param: alert_param})
            let stack = {submit_form: submit_form}
            this.processConfirmationAddNew(true, stack)
        }else{

            let repNameAll = this.state.recipientNameAll
            let contracts = Store.getStoreData('contacts');
            let reciname = this.state.recipientName;
            let check_recipient_id = 0
            if(this.state.recipientName !== 'Other'){
                repNameAll = contracts[this.state.recipientName]
                reciname = contracts[this.state.recipientName]
                check_recipient_id = this.state.recipientName;
            }

            let headerinfo = {};
            headerinfo.gift_status = this.state.giftStatus;
            // headerinfo.recipient_name = this.state.recipientNameAll;
            headerinfo.donor_name = this.state.donorName;
            headerinfo.gift_typeid = parseInt(this.state.giftTypeid);
            headerinfo.currency = this.state.currency;
            headerinfo.minvalue = this.state.minvalue;
            headerinfo.gift_description = this.state.giftDescription;
            // headerinfo.check_recipient_value = this.state.recipientName;
            headerinfo.recipient_name = repNameAll;
            headerinfo.check_recipient_value = reciname;
            headerinfo.check_recipient_id = check_recipient_id;
            headerinfo.giftedDate = this.state.giftedDate;
            headerinfo.additional_fields = this.state.assign_data.additional_fields;
            headerinfo.approval_level = this.state.approval_level;
            headerinfo.specificUserID = this.state.specificUserID;
            headerinfo.giftGivenTo = this.state.giftGivenTo;
            headerinfo.app_currency = this.state.app_currency;
            headerinfo.app_minvalue = this.state.app_minvalue;
            headerinfo.isShowAppValue = this.state.isShowAppValue;

            let final_object_data = {};
            final_object_data.headerinfo = headerinfo;
            final_object_data.ref_id = this.state.Ref_ID;
            let bin_files = this.state.cur_files_json === null ? this.state.binFiles : [...this.state.binFiles, ...this.state.cur_files_json];
            let task_json = {};
            if(submit_form === 1) {
                // if(this.state.removalType === ""){
                //     let msg = 'You must select a Result Type first.'
                //     let alert_param = {title: 'Warning', message: msg, ok_text: 'OK', confirm: false, alertHandler: this.samealertremoveHandler, stack: {id: 0}}
                //     this.setState({alert_param: alert_param})
                //     return;
                // }
                // if(this.state.showDescription && this.state.reasonDescription === ""){
                //     let msg = 'Please provide a reason for approval.'
                //     let alert_param = {title: 'Warning', message: msg, ok_text: 'OK', confirm: false, alertHandler: this.samealertremoveHandler, stack: {id: 0}}
                //     this.setState({alert_param: alert_param})
                //     return;
                // }

                //checking

                if (this.state.app_levels !== null) {
                    if(this.state.app_levels.length === 1){
                        let alert_param = {title: 'Confirm', message: 'The gift will be marked as approved and the discloser updated. Do you want to continue?', ok_text: 'Yes', cancel_text: 'No', confirm: true,
                        alertHandler: this.acceptGiftAlert, stack: {final_object_data: final_object_data, bin_files: bin_files, submit_form: submit_form}}
                        this.setState({alert_param: alert_param})
                    }else {
                        if (this.state.approval_data.length === 2) {
                            let alert_param = {title: 'Confirm', message: 'The gift will be marked as approved and the discloser updated. Do you want to continue?', ok_text: 'Yes', cancel_text: 'No', confirm: true, alertHandler: this.acceptGiftAlert, stack: {final_object_data: final_object_data, bin_files: bin_files, submit_form: submit_form}}
                            this.setState({alert_param: alert_param})
                        } else {
                            let stack = {final_object_data: final_object_data, bin_files: bin_files, submit_form: submit_form}
                            this.acceptGiftAlert(true, stack)
                        }
                    }
                }else{
                    let alert_param = {title: 'Confirm', message: 'The gift will be marked as approved and the discloser updated. Do you want to continue?', ok_text: 'Yes', cancel_text: 'No', confirm: true, alertHandler: this.acceptGiftAlert, stack: {final_object_data: final_object_data, bin_files: bin_files, submit_form: submit_form}}
                    this.setState({alert_param: alert_param})
                }
                // let alert_param = {title: 'Confirm', message: 'The gift will be marked as approved and the discloser updated. Do you want to continue?', ok_text: 'Yes', cancel_text: 'No', confirm: true, alertHandler: this.acceptGiftAlert, stack: {final_object_data: final_object_data, bin_files: bin_files, submit_form: submit_form}}
                // this.setState({alert_param: alert_param})
            }else{
                task_json = {
                    "task_data": { "module_id": "gifts", "last_action": "COMP_GIFTS_TEAMSAVE", "actiondate": Date.now(),
                    'system_id' : '1001', 'cur_lane' : 'COMP_GIFTS_TEAMSAVE',
                    'task_type' : 'COMP_GIFTS', 'parent_task_id' : 0 },
                    "object_data": final_object_data,
                    "action_data": { "action": "COMP_GIFTS_TEAMSAVE", "actiondate": Date.now(), "assigned_to": 0, "cur_lane":"COMP_GIFTS_TEAMSAVE"}
                };

                // console.log("task jason", JSON.stringify(task_json));
                let curmytaskdata = Store.getStoreData('cur_q_modal_data');
                let curtask_jsonValue = JSON.parse(curmytaskdata.task_json);
                let from_api = 0
                if('from_api' in curtask_jsonValue.object_data){
                    from_api = curtask_jsonValue.object_data.from_api;
                }
                let postData = {command: "update_gifts_task", task_json: task_json, bin_files: bin_files, submit: submit_form, taskid: this.state.taskid, dicloser_id: this.state.dicloserid, from_api: from_api };
                console.log("postData",postData)
                this.setState({submit_form, isLoadedIcon: true, loading: true})
                let api = new APICall();
                api.command(postData, this.processUpdateApproveGiftsData);
            }
        }
    }

    saveAndCloseApproveGift = (submit_form) => (event) => {
        event.preventDefault();
        let problems = false
        let message = ''
        const  module_config = Store.getStoreData('module_config');
        if(this.state.giftTypeid.toString() === "0"){
            problems = true
            message += `Please select gift type.<br>`
        }
        if(this.state.recipientName === "") {
            problems = true
            message += `Please select recipient name.<br>`
       }
       if(this.state.recipientName === "Other" && this.state.recipientNameAll === ""){
            problems = true
            message += `Please add name of other recipient.<br>`
       }
       if(this.state.giftStatus === "received" && this.state.donorName === ""){
            problems = true
            message += `Please add donor name.<br>`
       }
       if(this.state.isShowAppValue && (this.state.app_minvalue === "" || parseInt(this.state.app_minvalue) === 0)){
            problems = true
            message += `Enter approximate value.<br>`
        }
       if(this.state.giftDescription === ""){
            problems = true
            message += `Please provide a description of the gift/hospitality.<br>`
       }
       if(module_config.custom_fields.enabled){
            let fields = [];
            let is_numeric = false;
            let numericFields = [];
            if (module_config.custom_fields.additional_fields.length !== 0) {
                this.state.assign_data.additional_fields.forEach((item) => {
                    if(item.displayFor === this.state.giftStatus){
                        if (item.required === true && item.value === "") {
                            fields.push(item.nickname);
                        }
                        if(item.value){
                            if(item.type === 'Numeric') {
                                if(item.value.length > 0) {
                                    if(isNaN(item.value)){
                                        is_numeric = true;
                                        numericFields.push(item.nickname);
                                    }
                                }
                            }
                        }
                    }
                    if(item.displayFor === 'both'){
                        if (item.required === true && item.value === "") {
                            fields.push(item.nickname);
                        }
                        if(item.value){
                            if(item.type === 'Numeric') {
                                if(item.value.length > 0) {
                                    if(isNaN(item.value)){
                                        is_numeric = true;
                                        numericFields.push(item.nickname);
                                    }
                                }
                            }
                        }
                    }
                })
            }
            if (fields.length !== 0) {
                let err_msg = "";
                fields.forEach((item) => {
                    err_msg = err_msg + item + ".<br>";
                })
                err_msg = err_msg.substring(0, err_msg.length - 2);
                problems = true
                message += err_msg +`<br>`
            }
            if(is_numeric) {
                let err_msg_new = "";
                numericFields.forEach((item) => {
                    err_msg_new = err_msg_new + item + ", ";
                })
                err_msg_new = err_msg_new.substring(0, err_msg_new.length - 2);
                if (numericFields.length === 1) {
                    err_msg_new = "<br>Please put a numeric value in the "+ err_msg_new + " field. ";
                } else {
                    err_msg_new = "<br>Please put numeric values in "+ err_msg_new + " fields. ";
                }
                problems = true
                message += err_msg_new +`<br>`
            }
        }

        if(problems) {
            let alert_param = {title: 'Warning', message: message, ok_text: 'OK', confirm: false,
                       alertHandler: this.samealertremoveHandler, stack: {}, align: 'left'}
            this.setState({alert_param: alert_param})
            return;
        }

        let form_data = Store.getStoreData('cur_q_modal_data');
        let task_json = JSON.parse(form_data.task_json);
        let headerinfo = JSON.parse(form_data.task_json).object_data.headerinfo;

        let repNameAll = this.state.recipientNameAll
        let contracts = Store.getStoreData('contacts');
        let reciname = this.state.recipientName;
        let check_recipient_id = 0
        if(this.state.recipientName !== 'Other'){
            repNameAll = contracts[this.state.recipientName]
            reciname = contracts[this.state.recipientName]
            check_recipient_id = this.state.recipientName;
        }

        headerinfo['gift_status'] = this.state.giftStatus;
        headerinfo['donor_name'] = this.state.donorName;
        headerinfo['gift_typeid'] = parseInt(this.state.giftTypeid);
        headerinfo['currency'] = this.state.currency;
        headerinfo['minvalue'] = this.state.minvalue;
        headerinfo['gift_description'] = this.state.giftDescription;
        headerinfo['recipient_name'] = repNameAll;
        headerinfo['check_recipient_value'] = reciname;
        headerinfo['check_recipient_id'] = check_recipient_id;
        headerinfo['giftedDate'] = this.state.giftedDate;
        headerinfo['additional_fields'] = this.state.assign_data.additional_fields;
        headerinfo['specificUserID'] = this.state.specificUserID;
        headerinfo['giftGivenTo'] = this.state.giftGivenTo;
        headerinfo['app_currency'] = this.state.app_currency;
        headerinfo['app_minvalue'] = this.state.app_minvalue;

        let action_data = {
            task_id: this.state.taskid,
            cur_lane: this.state.curlane,
            assigned_to: 0,
            action: this.state.curlane,
            other_description: "save and close after completion or rejection",
            actiondate: Date.now()
        };

        task_json['object_data']['headerinfo'] = headerinfo
        task_json['action_data'] = action_data

        console.log("task_json===>", task_json)

        let postData = { command: "saveandclose_gifts_task", task_json: task_json, bin_files: [], taskid: this.state.taskid };
        console.log("postData",postData)
        this.setState({submit_form, isLoadedIcon: true, loading: true})
        let api = new APICall();
        api.command(postData, this.processSaveandCloseApproveGiftsData);
    }

    processSaveandCloseApproveGiftsData = (result) => {
        this.setState({loading: false})
        console.log("result", result)
        let alert_param = {title: 'Alert', message: 'This Gifts & Hospitality has been saved successfully.', ok_text: 'OK', confirm: false, alertHandler: this.alertremoveHandler, stack: {id: 0}}
        this.setState({alert_param: alert_param})
    }

    submitApproveGift = (submit_form) => (event) => {
        event.preventDefault();
        let problems = false
        let message = ''
        const  module_config = Store.getStoreData('module_config');
        if(this.state.giftTypeid.toString() === "0"){
            problems = true
            message += `Please select gift type.<br>`
        }
        if(this.state.recipientName === "") {
            problems = true
            message += `Please select recipient name.<br>`
       }
       if(this.state.recipientName === "Other" && this.state.recipientNameAll === ""){
            problems = true
            message += `Please add name of other recipient.<br>`
       }
       if(this.state.giftStatus === "received" && this.state.donorName === ""){
            problems = true
            message += `Please add donor name.<br>`
       }
       if(this.state.isShowAppValue && (this.state.app_minvalue === "" || parseInt(this.state.app_minvalue) === 0)){
            problems = true
            message += `Enter approximate value.<br>`
        }
       if(this.state.giftDescription === ""){
            problems = true
            message += `Please provide a description of the gift/hospitality.<br>`
       }
       if(module_config.custom_fields.enabled){
            let fields = [];
            let is_numeric = false;
            let numericFields = [];
            if (module_config.custom_fields.additional_fields.length !== 0) {
                this.state.assign_data.additional_fields.forEach((item) => {
                    if(item.displayFor === this.state.giftStatus){
                        if (item.required === true && item.value === "") {
                            fields.push(item.nickname);
                        }
                        if(item.value){
                            if(item.type === 'Numeric') {
                                if(item.value.length > 0) {
                                    if(isNaN(item.value)){
                                        is_numeric = true;
                                        numericFields.push(item.nickname);
                                    }
                                }
                            }
                        }
                    }
                    if(item.displayFor === 'both'){
                        if (item.required === true && item.value === "") {
                            fields.push(item.nickname);
                        }
                        if(item.value){
                            if(item.type === 'Numeric') {
                                if(item.value.length > 0) {
                                    if(isNaN(item.value)){
                                        is_numeric = true;
                                        numericFields.push(item.nickname);
                                    }
                                }
                            }
                        }
                    }
                })
            }
            if (fields.length !== 0) {
                let err_msg = "";
                fields.forEach((item) => {
                    err_msg = err_msg + item + ".<br>";
                })
                err_msg = err_msg.substring(0, err_msg.length - 2);
                problems = true
                message += err_msg +`<br>`
            }
            if(is_numeric) {
                let err_msg_new = "";
                numericFields.forEach((item) => {
                    err_msg_new = err_msg_new + item + ", ";
                })
                err_msg_new = err_msg_new.substring(0, err_msg_new.length - 2);
                if (numericFields.length === 1) {
                    err_msg_new = "<br>Please put a numeric value in the "+ err_msg_new + " field. ";
                } else {
                    err_msg_new = "<br>Please put numeric values in "+ err_msg_new + " fields. ";
                }
                problems = true
                message += err_msg_new +`<br>`
            }
        }

        if(problems) {
            let alert_param = {title: 'Warning', message: message, ok_text: 'OK', confirm: false,
                       alertHandler: this.samealertremoveHandler, stack: {}, align: 'left'}
            this.setState({alert_param: alert_param})
            return;
        }

        if(parseInt(this.state.minvalue) < parseInt(this.state.finalMinValueCheck)){
            // let alert_param = {title: 'Alert', message: 'This value is less than the minimum value of reportable gifts/hospitality. Do you want to proceed?', ok_text: 'OK', cancel_text: 'Cancel', confirm: true,
            //                 alertHandler: this.processConfirmationAddNew, stack: {submit_form: submit_form}}
            // this.setState({alert_param: alert_param})
            let stack = {submit_form: submit_form}
            this.processConfirmationAddNew(true, stack)
        }else{

            let repNameAll = this.state.recipientNameAll
            let contracts = Store.getStoreData('contacts');
            let reciname = this.state.recipientName;
            let check_recipient_id = 0
            if(this.state.recipientName !== 'Other'){
                repNameAll = contracts[this.state.recipientName]
                reciname = contracts[this.state.recipientName]
                check_recipient_id = this.state.recipientName;
            }

            let headerinfo = {};
            headerinfo.gift_status = this.state.giftStatus;
            // headerinfo.recipient_name = this.state.recipientNameAll;
            headerinfo.donor_name = this.state.donorName;
            headerinfo.gift_typeid = parseInt(this.state.giftTypeid);
            headerinfo.currency = this.state.currency;
            headerinfo.minvalue = this.state.minvalue;
            headerinfo.gift_description = this.state.giftDescription;
            // headerinfo.check_recipient_value = this.state.recipientName;
            headerinfo.recipient_name = repNameAll;
            headerinfo.check_recipient_value = reciname;
            headerinfo.check_recipient_id = check_recipient_id;
            headerinfo.giftedDate = this.state.giftedDate;
            headerinfo.additional_fields = this.state.assign_data.additional_fields;
            headerinfo.approval_level = this.state.approval_level;
            headerinfo.specificUserID = this.state.specificUserID;
            headerinfo.giftGivenTo = this.state.giftGivenTo;
            headerinfo.app_currency = this.state.app_currency;
            headerinfo.app_minvalue = this.state.app_minvalue;
            headerinfo.isShowAppValue = this.state.isShowAppValue;

            let final_object_data = {};
            final_object_data.headerinfo = headerinfo;
            final_object_data.ref_id = this.state.Ref_ID;
            let bin_files = this.state.cur_files_json === null ? this.state.binFiles : [...this.state.binFiles, ...this.state.cur_files_json];
            let task_json = {};
            if(submit_form === 1) {
                if(this.state.removalType === ""){
                    let msg = 'You must select a Result Type first.'
                    let alert_param = {title: 'Warning', message: msg, ok_text: 'OK', confirm: false, alertHandler: this.samealertremoveHandler, stack: {id: 0}}
                    this.setState({alert_param: alert_param})
                    return;
                }
                if(this.state.showDescription && this.state.reasonDescription === ""){
                    let msg = 'Please provide a reason for approval.'
                    let alert_param = {title: 'Warning', message: msg, ok_text: 'OK', confirm: false, alertHandler: this.samealertremoveHandler, stack: {id: 0}}
                    this.setState({alert_param: alert_param})
                    return;
                }

                //checking

                if (this.state.app_levels !== null) {
                    if(this.state.app_levels.length === 1){
                        let alert_param = {title: 'Confirm', message: 'The gift will be marked as approved and the discloser updated. Do you want to continue?', ok_text: 'Yes', cancel_text: 'No', confirm: true,
                        alertHandler: this.acceptGiftAlert, stack: {final_object_data: final_object_data, bin_files: bin_files, submit_form: submit_form}}
                        this.setState({alert_param: alert_param})
                    }else {
                        if (this.state.approval_data.length === 2) {
                            let alert_param = {title: 'Confirm', message: 'The gift will be marked as approved and the discloser updated. Do you want to continue?', ok_text: 'Yes', cancel_text: 'No', confirm: true, alertHandler: this.acceptGiftAlert, stack: {final_object_data: final_object_data, bin_files: bin_files, submit_form: submit_form}}
                            this.setState({alert_param: alert_param})
                        } else {
                            let stack = {final_object_data: final_object_data, bin_files: bin_files, submit_form: submit_form}
                            this.acceptGiftAlert(true, stack)
                        }
                    }
                }else{
                    let alert_param = {title: 'Confirm', message: 'The gift will be marked as approved and the discloser updated. Do you want to continue?', ok_text: 'Yes', cancel_text: 'No', confirm: true, alertHandler: this.acceptGiftAlert, stack: {final_object_data: final_object_data, bin_files: bin_files, submit_form: submit_form}}
                    this.setState({alert_param: alert_param})
                }
                // let alert_param = {title: 'Confirm', message: 'The gift will be marked as approved and the discloser updated. Do you want to continue?', ok_text: 'Yes', cancel_text: 'No', confirm: true, alertHandler: this.acceptGiftAlert, stack: {final_object_data: final_object_data, bin_files: bin_files, submit_form: submit_form}}
                // this.setState({alert_param: alert_param})
            }else{
                task_json = {
                    "task_data": { "module_id": "gifts", "last_action": "COMP_GIFTS_TEAMSAVE", "actiondate": Date.now(),
                    'system_id' : '1001', 'cur_lane' : 'COMP_GIFTS_TEAMSAVE',
                    'task_type' : 'COMP_GIFTS', 'parent_task_id' : 0 },
                    "object_data": final_object_data,
                    "action_data": { "action": "COMP_GIFTS_TEAMSAVE", "actiondate": Date.now(), "assigned_to": 0, "cur_lane":"COMP_GIFTS_TEAMSAVE"}
                };

                // console.log("task jason", JSON.stringify(task_json));
                let curmytaskdata = Store.getStoreData('cur_q_modal_data');
                let curtask_jsonValue = JSON.parse(curmytaskdata.task_json);
                let from_api = 0
                if('from_api' in curtask_jsonValue.object_data){
                    from_api = curtask_jsonValue.object_data.from_api;
                }
                let postData = {command: "update_gifts_task", task_json: task_json, bin_files: bin_files, submit: submit_form, taskid: this.state.taskid, dicloser_id: this.state.dicloserid, from_api: from_api };
                console.log("postData",postData)
                this.setState({submit_form, isLoadedIcon: true, loading: true})
                let api = new APICall();
                api.command(postData, this.processUpdateApproveGiftsData);
            }
        }
    }

    processConfirmationAddNew = (result, stack) => {
        if(!result) {
            this.setState({alert_param: null, cur_gc_id:null,giftStatus: "received",recipientName: "",recipientNameAll: "",donorName: "",giftTypeid: 0,giftDescription: ""})
            return
        }
        this.setState({alert_param: null})

        let repNameAll = this.state.recipientNameAll
        let contracts = Store.getStoreData('contacts');
        let reciname = this.state.recipientName;
        let check_recipient_id = 0
        if(this.state.recipientName !== 'Other'){
            repNameAll = contracts[this.state.recipientName]
            reciname = contracts[this.state.recipientName]
            check_recipient_id = this.state.recipientName;
        }


        let headerinfo = {};
        headerinfo.gift_status = this.state.giftStatus;
        // headerinfo.recipient_name = this.state.recipientNameAll;
        headerinfo.donor_name = this.state.donorName;
        headerinfo.gift_typeid = parseInt(this.state.giftTypeid);
        headerinfo.currency = this.state.currency;
        headerinfo.minvalue = this.state.minvalue;
        headerinfo.gift_description = this.state.giftDescription;
        // headerinfo.check_recipient_value = this.state.recipientName;
        headerinfo.recipient_name = repNameAll;
        headerinfo.check_recipient_value = reciname;
        headerinfo.check_recipient_id = check_recipient_id;
        headerinfo.giftedDate = this.state.giftedDate;
        headerinfo.additional_fields = this.state.assign_data.additional_fields;
        headerinfo.approval_level = this.state.approval_level;
        headerinfo.specificUserID = this.state.specificUserID;
        headerinfo.giftGivenTo = this.state.giftGivenTo;
        headerinfo.app_currency = this.state.app_currency;
        headerinfo.app_minvalue = this.state.app_minvalue;
        headerinfo.isShowAppValue = this.state.isShowAppValue;

        let final_object_data = {};
        final_object_data.headerinfo = headerinfo;
        final_object_data.ref_id = this.state.Ref_ID;
        let bin_files = this.state.cur_files_json === null ? this.state.binFiles : [...this.state.binFiles, ...this.state.cur_files_json];
        let task_json = {};
        let submit_form = stack.submit_form
        if(submit_form === 1) {
            if(this.state.removalType === ""){
                let msg = 'You must select a Result Type first.'
                let alert_param = {title: 'Warning', message: msg, ok_text: 'OK', confirm: false, alertHandler: this.samealertremoveHandler, stack: {id: 0}}
                this.setState({alert_param: alert_param})
                return;
            }
            if(this.state.showDescription && this.state.reasonDescription === ""){
                let msg = 'Please provide a reason for approval.'
                let alert_param = {title: 'Warning', message: msg, ok_text: 'OK', confirm: false, alertHandler: this.samealertremoveHandler, stack: {id: 0}}
                this.setState({alert_param: alert_param})
                return;
            }
            let alert_param = {title: 'Confirm', message: 'The gift will be marked as approved and the discloser updated. Do you want to continue?', ok_text: 'Yes', cancel_text: 'No', confirm: true, alertHandler: this.acceptGiftAlert, stack: {final_object_data: final_object_data, bin_files: bin_files, submit_form: submit_form}}
            this.setState({alert_param: alert_param})
        }else{
            task_json = {
                "task_data": { "module_id": "gifts", "last_action": "COMP_GIFTS_TEAMSAVE", "actiondate": Date.now(),
                'system_id' : '1001', 'cur_lane' : 'COMP_GIFTS_TEAMSAVE',
                'task_type' : 'COMP_GIFTS', 'parent_task_id' : 0 },
                "object_data": final_object_data,
                "action_data": { "action": "COMP_GIFTS_TEAMSAVE", "actiondate": Date.now(), "assigned_to": 0, "cur_lane":"COMP_GIFTS_TEAMSAVE"}
            };

            // console.log("task jason", JSON.stringify(task_json));
            let curmytaskdata = Store.getStoreData('cur_q_modal_data');
            let curtask_jsonValue = JSON.parse(curmytaskdata.task_json);
            let from_api = 0
            if('from_api' in curtask_jsonValue.object_data){
                from_api = curtask_jsonValue.object_data.from_api;
            }
            let postData = {command: "update_gifts_task", task_json: task_json, bin_files: bin_files, submit: submit_form, taskid: this.state.taskid, dicloser_id: this.state.dicloserid, from_api: from_api };
            console.log("postData",postData)
            this.setState({submit_form, isLoadedIcon: true, loading: true})
            let api = new APICall();
            api.command(postData, this.processUpdateApproveGiftsData);
        }
    }

    alertremoveHandler = (result,stack) => {
        this.setState({alert_param: null})
        if(Store.getStoreData('isreferenceUrl') === true){
            if(Store.getStoreData('isReferenceEmail') === true){
                window.location = "/";
            }
        } else {
            window.location.reload();
        }
    }

    samealertremoveHandler = (result,stack) => {
        this.setState({alert_param: null})
    }

    alertupdateHandler = (result,stack) => {
        this.setState({alert_param: null})
        console.log("reasonID",stack.reasonID);
        let resultType = Store.getStoreData('module_config').result_types;
        let removalTypes = JSON.parse(JSON.stringify(resultType));
        let changemodule = 0
        removalTypes.forEach((item) => {
            if (item.resultTypeID === stack.reasonID && item.isDelete !== 1) {
                item.isDelete = 1;
                changemodule = 1
            }
        });

        console.log("modified submitedShareUserList", removalTypes);
        //console.log("modified submitedShareUserList", removalTypes);
        if(changemodule === 1){
            let {module_config} = this.state;
            module_config.result_types = removalTypes;
            const postData = {command: "save_module_config", module_config: module_config};
            const api = new APICall();
            api.command(postData, this.afterUpdatemoduleconfig);
        }else{
            if(Store.getStoreData('isreferenceUrl') === true){
                if(Store.getStoreData('isReferenceEmail') === true){
                    window.location = "/";
                }
            } else {
                window.location.reload();
            }
        }
    }

    rejectGiftAlert = (result,stack) => {
        if(!result) {
           this.setState({alert_param: null})
           return
       }

       let form_data = Store.getStoreData('cur_q_modal_data');
       let task_json = JSON.parse(form_data.task_json);
       let headerinfo = JSON.parse(form_data.task_json).object_data.headerinfo;

       let repNameAll = this.state.recipientNameAll
       let contracts = Store.getStoreData('contacts');
       let reciname = this.state.recipientName;
       let check_recipient_id = 0
       if(this.state.recipientName !== 'Other'){
           repNameAll = contracts[this.state.recipientName]
           reciname = contracts[this.state.recipientName]
           check_recipient_id = this.state.recipientName;
       }

       headerinfo['gift_status'] = this.state.giftStatus;
       headerinfo['donor_name'] = this.state.donorName;
       headerinfo['gift_typeid'] = parseInt(this.state.giftTypeid);
       headerinfo['currency'] = this.state.currency;
       headerinfo['minvalue'] = this.state.minvalue;
       headerinfo['gift_description'] = this.state.giftDescription;
       headerinfo['recipient_name'] = repNameAll;
       headerinfo['check_recipient_value'] = reciname;
       headerinfo['check_recipient_id'] = check_recipient_id;
       headerinfo['giftedDate'] = this.state.giftedDate;
       headerinfo['additional_fields'] = this.state.assign_data.additional_fields;
       headerinfo['specificUserID'] = this.state.specificUserID;
       headerinfo['giftGivenTo'] = this.state.giftGivenTo;
       headerinfo['app_currency'] = this.state.app_currency;
       headerinfo['app_minvalue'] = this.state.app_minvalue;
       headerinfo['isShowAppValue'] = this.state.isShowAppValue;

       let action_data = {
           task_id: this.state.taskid,
           cur_lane: "COMP_GIFTS_REMOVE",
           assigned_to: 0,
           action: "COMP_GIFTS_REMOVE",
           performed_by: Store.getStoreData('contact_id'),
           removal_type:  this.state.removalType,
           removal_description: this.state.reasonDescription
       };

       task_json['object_data']['headerinfo'] = headerinfo
       task_json['object_data']['removal_type'] = this.state.removalType;
       task_json['object_data']['removal_description'] = this.state.reasonDescription;
       task_json['action_data'] = action_data

       let postData = { "command": "removalTask", "SystemID": 1001, task_json: task_json, task_id: this.state.taskid, dicloser_id: this.state.dicloserid };
       console.log("postData",postData);
       let api = new APICall();
       this.setState({isLoadedIcon: true, alert_param: null, loading: true})
       api.command(postData, this.processUpdateRemoveGiftsData);
   }

    acceptGiftAlert = (result,stack) => {
        if(!result) {
            this.setState({alert_param: null})
            return
        }
        stack.final_object_data.headerinfo.approval_type = this.state.removalType;
        stack.final_object_data.headerinfo.approval_description = this.state.reasonDescription;
        let apov_lvl = 'none';
        let task_json = {}
        if (this.state.app_levels !== null) {
            if(this.state.app_levels.length === 1){
                stack.final_object_data.headerinfo.approval_data = this.state.approval_data;
                task_json = {
                    "task_data": { "module_id": "gifts", "last_action": "COMP_GIFTS_COMPLETE", "actiondate": Date.now(),
                    'system_id' : '1001', 'cur_lane' : 'COMP_GIFTS_COMPLETE',
                    "cur_assigned_to" : 0, 'task_type' : 'COMP_GIFTS', 'parent_task_id' : 0 },
                    "object_data": stack.final_object_data,
                    "action_data": { "task_id": this.state.taskid,"action": "COMP_GIFTS_COMPLETE", "actiondate": Date.now(), "assigned_to": 0, "cur_lane":"COMP_GIFTS_COMPLETE","approval_type":  this.state.removalType,
                    "approval_description": this.state.reasonDescription}
                }
            }else {
                if (this.state.approval_data.length === 2) {
                    stack.final_object_data.headerinfo.approval_data = this.state.approval_data;
                    task_json = {
                        "task_data": { "module_id": "gifts", "last_action": "COMP_GIFTS_COMPLETE", "actiondate": Date.now(),
                        'system_id' : '1001', 'cur_lane' : 'COMP_GIFTS_COMPLETE',
                        "cur_assigned_to" : 0, 'task_type' : 'COMP_GIFTS', 'parent_task_id' : 0 },
                        "object_data": stack.final_object_data,
                        "action_data": { "task_id": this.state.taskid,"action": "COMP_GIFTS_COMPLETE", "actiondate": Date.now(), "assigned_to": 0, "cur_lane":"COMP_GIFTS_COMPLETE","approval_type":  this.state.removalType,
                        "approval_description": this.state.reasonDescription}
                    }
                } else {
                    stack.final_object_data.headerinfo.approval_data = this.state.approval_data;
                    task_json = {
                        "task_data": { "module_id": "gifts", "last_action": "COMP_GIFTS_REGISTER", "actiondate": Date.now(),
                        'system_id' : '1001', 'cur_lane' : 'COMP_GIFTS_REGISTER',
                        "cur_assigned_to" : 0, 'task_type' : 'COMP_GIFTS', 'parent_task_id' : 0 },
                        "object_data": stack.final_object_data,
                        "action_data": { "task_id": this.state.taskid,"action": "COMP_GIFTS_REGISTER", "actiondate": Date.now(), "assigned_to": 0, "cur_lane":"COMP_GIFTS_REGISTER","approval_type":  this.state.removalType,
                        "approval_description": this.state.reasonDescription}
                    }

                    //new email to ar-lm
                    if(stack.final_object_data.headerinfo.approval_level === 'ar-lm'){
                        apov_lvl = 'lm'
                    }else if(stack.final_object_data.headerinfo.approval_level === 'lm-ar'){
                        apov_lvl = 'ar'
                    }
                    //new email to lm-ar
                }
            }
        }else{
            task_json = {
                "task_data": { "module_id": "gifts", "last_action": "COMP_GIFTS_COMPLETE", "actiondate": Date.now(),
                'system_id' : '1001', 'cur_lane' : 'COMP_GIFTS_COMPLETE',
                "cur_assigned_to" : 0, 'task_type' : 'COMP_GIFTS', 'parent_task_id' : 0 },
                "object_data": stack.final_object_data,
                "action_data": { "task_id": this.state.taskid,"action": "COMP_GIFTS_COMPLETE", "actiondate": Date.now(), "assigned_to": 0, "cur_lane":"COMP_GIFTS_COMPLETE","approval_type":  this.state.removalType,
                "approval_description": this.state.reasonDescription}
            }
        }

        //new email to su and lm
        console.log("apov_lvl===>", apov_lvl)
        //new email to su and lm

        let curmytaskdata = Store.getStoreData('cur_q_modal_data');
        let curtask_jsonValue = JSON.parse(curmytaskdata.task_json);
        let from_api = 0
        if('from_api' in curtask_jsonValue.object_data){
            from_api = curtask_jsonValue.object_data.from_api;
        }
        let postData = {command: "update_gifts_task", task_json: task_json, bin_files: stack.bin_files, submit: stack.submit_form, taskid: this.state.taskid, dicloser_id: this.state.dicloserid, from_api: from_api,
                        apov_lvl: apov_lvl, gc_id: curmytaskdata.company_id};
        console.log("postData",postData)
        this.setState({submit_form: stack.submit_form, isLoadedIcon: true, loading: true})
        let api = new APICall();
        api.command(postData, this.processUpdateApproveGiftsData);
        this.setState({alert_param: null})
    }


    processUpdateApproveGiftsData = (result) => {
        this.setState({loading: false})
        console.log("result", result)
        let msg = '';
        let title = 'Alert';
        if(this.state.submit_form === 1){
            msg = 'Gifts & Hospitality Confirmed Successfully.'
            // alert('Gifts & Hospitality Confirmed Successfully');
            let alert_param = {title: title, message: msg, ok_text: 'OK', confirm: false, alertHandler: this.alertupdateHandler, stack: {reasonID: this.state.reasonID}}
            this.setState({alert_param: alert_param})
        }else {
            msg = 'This Gifts & Hospitality has been saved but not confirmed. You can revisit this later and confirm.'
            // alert('This Gifts & Hospitality has been saved but not confirmed. You can revisit this later and confirm.');
            let alert_param = {title: title, message: msg, ok_text: 'OK', confirm: false, alertHandler: this.alertremoveHandler, stack: {id: 0}}
            this.setState({alert_param: alert_param})
            // window.location.reload();
        }

    }

    openRemoval = (event) => {
        event.preventDefault();
        this.setState({showRemovalReason: true, removalType: ""});
    }

    openApproveGift = (event) => {
        event.preventDefault();
        if(this.state.approval_data.length !== 0){
            // if (this.state.approval_data[this.state.approval_state].comments === "") {
            //     let alert_param = {title: 'Warning', message: 'Comments field is mandatory and cannot be left blank.', ok_text: 'OK', confirm: false, alertHandler: this.samealertremoveHandler, stack: {id: 0}}
            //     this.setState({alert_param: alert_param})
            //     return false;
            // }
            if (this.state.approval_data.length === 2) {
                if (this.state.approval_data[0].performed_by === this.state.approval_data[1].performed_by) {
                    // alert("Same person cannot provide first and second approval.");
                    let alert_param = {title: 'Warning', message: 'Same person cannot provide first and second approval.', ok_text: 'OK', confirm: false, alertHandler: this.samealertremoveHandler, stack: {id: 0}}
                    this.setState({alert_param: alert_param})
                    return false;
                }
            }
        }
        //new part
        if (this.state.app_levels !== null) {
            if(this.state.app_levels.length === 1){
                this.setState({showApprovalReason: true, removalType: ""});
            }else{
                if (this.state.approval_data.length === 2) {
                    this.setState({showApprovalReason: true, removalType: ""});
                }else{
                    console.log("Sourav 12")
                    this.submitApproveGiftDirect(1);
                }
            }
        }else{
            this.setState({showApprovalReason: true, removalType: ""});
        }
        //new part
        // this.setState({showApprovalReason: true, removalType: ""});
    }

    closeApproval = (event) => {
        event.preventDefault();
        this.setState({showApprovalReason: false});
    }

    closeRemoval = (event) => {
        event.preventDefault();
        this.setState({showRemovalReason: false});
    }

    submitReasonRemoval = (event) => {
        event.preventDefault();
        let msg = '';
        let title = 'Warning';
        if(this.state.removalType === ""){
            msg = 'You must select a Result Type first.'
            // alert('You must select a Result Type first.');
            let alert_param = {title: title, message: msg, ok_text: 'OK', confirm: false, alertHandler: this.samealertremoveHandler, stack: {id: 0}}
            this.setState({alert_param: alert_param})
            return;
        }
        if(this.state.showDescription && this.state.reasonDescription === ""){
            msg = 'Please provide a reason for approval.'
            // alert('Please provide a reason for approval.');
            let alert_param = {title: title, message: msg, ok_text: 'OK', confirm: false, alertHandler: this.samealertremoveHandler, stack: {id: 0}}
            this.setState({alert_param: alert_param})
            return;
        }
        let alert_param = {title: 'Confirm', message: 'The gift will be removed from the register and the discloser updated. Do you want to continue?', ok_text: 'Yes', cancel_text: 'No', confirm: true, alertHandler: this.rejectGiftAlert, stack: {id: 0}}
        this.setState({alert_param: alert_param})
    }

    processUpdateRemoveGiftsData = (result) => {
        this.setState({loading: false})
        console.log("result", result)
        let msg = '';
        let title = 'Alert';
        msg = 'The gift has been removed.'
        // alert('The gift has been removed.');
        let alert_param = {title: title, message: msg, ok_text: 'OK', confirm: false, alertHandler: this.alertupdateHandler, stack: {reasonID: this.state.reasonID}}
        this.setState({alert_param: alert_param})
    }

    afterUpdatemoduleconfig = (result) => {
        console.log(result);
        if(Store.getStoreData('isreferenceUrl') === true){
            if(Store.getStoreData('isReferenceEmail') === true){
                window.location = "/";
            }
        } else {
            window.location.reload();
        }
        // window.location.reload();
   }

    updateRemovalTask = (event) => {
        event.preventDefault();
        let header_message = "You are amending and updating a Gifts & Hospitality that has already been submitted.";
        this.setState({removal_readonly: false, isCompletetoUpdate: true, header_message: header_message})
    }

    showalertFollowup = (event) => {
        event.preventDefault();
        alert("Adding follow-up tasks to a removed Gift is not allowed.")
    }

    filesLoaded = (files) => {
        let contact_id = Store.getStoreData('contact_id');
        for(let i=0; i < files.length; i++) {
            files[i].name = files[i].name.replace('&', '');
            //console.log('File_name', files[i].name);
            if(!('user_id' in files[i])) {
                files[i].user_id = contact_id;
                files[i].upload_date = Date.now();
            }

        }
        //console.log('files in Modal', files);
        this.setState({binFiles: files});
    }

    handleChangeRecipient = (evt) => {
        console.log("evt==>", evt.target.value)
        if(evt.target.value != 'Other'){
        //    this.setState({recipientName: evt.target.value,recipientNameAll: evt.target.value,isOtherRecipientName: false})
           this.setState({recipientName: evt.target.value, isOtherRecipientName: false})
        }else{
            this.setState({isOtherRecipientName: true, recipientName: evt.target.value, recipientNameAll: ""})
        }
    }

    onChangeGiftedDate = (date) => {
        this.setState({giftedDate: date});
    }

    generateFieldreadOnly = (data) => {
        let value = data.value
        if(data.type === 'Date'){
            if(data.value !== ''){
                value = moment(data.value).format('DD/MM/YYYY')
            }else{
                value = '';
            }
        }else if(data.type === 'Dropdown' && data.value === 'other'){
            value = data.other_value;
        }
    	return (
    		<ModalContainerGrid key={data.name}>
    			<ModalNameLabelDivFin>{data.nickname}</ModalNameLabelDivFin>
                <ModalNameInputDivFin>{value}</ModalNameInputDivFin>
    		</ModalContainerGrid>
    	);
    }

    generateApproval = (data) => {
    	return (<div key={data.performed_by}>
            <hr/>
    		<ModalContainerGrid >
    			<ModalNameLabelDivFin style={{paddingTop: "0px"}}>Approver</ModalNameLabelDivFin>
                <ModalNameInputDivFin style={{paddingTop: "0px"}}>{data.performed_by in Store.getStoreData('ctslists') ? Store.getStoreData('ctslists')[data.performed_by] : ''}</ModalNameInputDivFin>
                <ModalNameLabelDivFin>Approved</ModalNameLabelDivFin>
                <ModalNameInputDivFin>{moment.unix(data.action_time).format('DD/MM/YYYY')}</ModalNameInputDivFin>
                <ModalNameLabelDivFin>Approver Comments</ModalNameLabelDivFin>
                <ModalNameInputDivFin>{data.comments}</ModalNameInputDivFin>
    		</ModalContainerGrid>
        </div>);
    }

    generateField = (data, data_container) => {
    	return (
    		<div key={data.name}>
    			<MRModalLabel>
    			{
    				(() => {
    					if (data.required) {
    						return (<div>{data.nickname}<span style={{color: "red", paddingLeft: "5px", fontSize: "8px"}}><FaStarOfLife  title={data.nickname+" required"}/></span></div>);
    					} else {
    						return data.nickname;
    					}
    				})()
    			}
    			</MRModalLabel>
    			{
    				(() => {
    					let fieldhtml = <div></div>;
    					switch(data.type) {
    						case "Alpha":
    							fieldhtml = this.createAlphaField(data, data_container);
    							break;
    						case "Numeric":
    							fieldhtml = this.createNumericField(data, data_container);
    							break;
                            case "Dropdown":
                                fieldhtml = this.createDropdownField(data, data_container);
                                break;
                            case "Date":
                                fieldhtml = this.createDateField(data, data_container);
                                break;
    						default:
    							fieldhtml = fieldhtml;
    					}
                        if (data_container === "additional_fields")
    					return fieldhtml;
    				})()
    			}
    		</div>
    	);
    }

    createDateField = (data, data_container) => {
        console.log("Data==>", data)
        let curValue = data.value !== '' ? new Date(data.value) : new Date();
		return (<CSLDateCover id={"date-"+data.name} style={{display: "inline-block"}}>
                    <DatePicker
                            onChange={this.onChangeCustomDate(data.name)}
                            clearIcon={null}
                            locale={"en-GB"}
                            calendarIcon={<FaCalendarAlt style={{color: '#243E57'}} />}
                            value={curValue}
                    />
                </CSLDateCover>);
    }

    onChangeCustomDate = (name) => (date) => {
        console.log("name+++==>", name)
        //event.preventDefault();
        let assign_data = JSON.parse(JSON.stringify(this.state.assign_data));
        console.log("assign_data===>", assign_data)
        assign_data.additional_fields.forEach((item) => {
            if (item.name === name) {
                item.value = date;
            }
        })
        console.log("ModifyAssigndata+++==>", assign_data)
        this.setState({assign_data: assign_data});
    }

    createAlphaField = (data, data_container) => {
		return (<CSLTextInput id={"text-"+data.name} handleTextChange={this.handleTextChange} name={data.name} value={data.value} entity="additional_fields" field="value" />);
    }

    handleTextChange = (obj) => {
		console.log("obj:", obj);
		let assign_data = JSON.parse(JSON.stringify(this.state.assign_data));
		if (obj.entity === null) {
			assign_data[obj.name] = obj.value;
		} else {
			assign_data[obj.entity].forEach(item => {
				if (item.name === obj.name) item[obj.field] = obj.value;
			})
		}
        this.setState({assign_data: assign_data});
	}

    createNumericField = (data, data_container) => {
    	return (<MRModalInput id={"number-"+data.name} type="text" min={0} placeholder="Please enter the numeric value" name={data.name} value={data.value} onChange={this.handleAddlField(data.type, data_container)} />);
    }

    handleAddlField = (field_type, data_container) => (event) => {
        event.preventDefault();
        let assign_data = JSON.parse(JSON.stringify(this.state.assign_data));
        console.log("event.target.name===>", event.target.name)
        console.log("assign_data===>", assign_data)
        console.log("data_container===>", data_container)
        if (event.target.name === "smcr_role") {
            assign_data[event.target.name] = event.target.value;
            assign_data[data_container].forEach((item) => {
                if (item.name === event.target.name) {
                    item.value = event.target.value;
                }
            })
        } else {
            assign_data[data_container].forEach((item) => {
                if (item.name === event.target.name) {
                    item.value = event.target.value;
                }
            })
        }
        console.log("ModifyAssigndata+++==>", assign_data)
        this.setState({assign_data: assign_data});
    }

    createDropdownField = (data, data_container) => {
    	return (
            <div>
        		<MRModalSelect id={"select-"+data.name} onChange={this.handleAddlField(data.type, data_container)} name={data.name} value={data.value}>
                    <option key="sel" value="">Select</option>
        		    {
        		        data.dropdown_options.map((opt, index) => {
        		            return(
    	    		            <option key={index} value={opt}>{opt}</option>
        		            );
        		        })
        		    }
                    {
                        (() => {
                            if (data_container === "additional_fields") {
                                if ("other" in data) {
                                    if (data.other === true) {
                                        return (<option value="other">Other</option>);
                                    }
                                }
                            }
                        })()
                    }
        		</MRModalSelect>
                {
                    (() => {
                        if (data_container === "additional_fields") {
                            if ("other" in data) {
                                if (data.other === true && data.value === "other") {
                                    return (
                                        <div style={{marginTop: "15px"}}>
                                            <div style={{float: "left", width: "20%", boxSizing: "border-box", paddingTop: "12px"}}>
                                                <MRModalLabel style={{margin: "0px"}}>Please give details</MRModalLabel>
                                            </div>
                                            <div style={{float: "left", width: "80%", boxSizing: "border-box"}}>
                                                <CSLTextInput id={"text-"+data.name} entity="additional_fields" name={data.name} value={data.other_value} handleTextChange={this.handleTextChange} field="other_value"/>
                                            </div>
                                            <div style={{clear: "both"}}></div>
                                        </div>
                                    );
                                }
                            }
                        }
                    })()
                }
            </div>
    	);
    }

    changeApprovalComments = (event) => {
        event.preventDefault();
        let approval_data = JSON.parse(JSON.stringify(this.state.approval_data));
        approval_data[this.state.approval_state].comments = event.target.value;
        this.setState({approval_data});
    }

    editrefreshTaskValue = (taskValue) => {
        console.log(taskValue)
        let currentfollowupData = Store.getStoreData('cur_followup_task');
        let followupid = currentfollowupData.id;
        let task_json = JSON.parse(currentfollowupData.task_json);
        task_json['object_data'].subject = taskValue.subject;
        task_json['object_data'].note = taskValue.note;
        task_json['task_data'].cur_assigned_to = taskValue.assignedto;
        task_json['task_data'].due_date = taskValue.dueby;
        task_json['action_data'] = { "action": "COMP_GIFTS_FOLLOWUP", "actiondate": Date.now(), "assigned_to": taskValue.assignedto, "cur_lane":"COMP_GIFTS_FOLLOWUP_ASSIGNMENT", due_by: taskValue.dueby, "note": taskValue.note };
        let postData = {command: "update_followup_task", task_json: task_json, task_id: followupid, bin_files: taskValue.binFiles };
        console.log(postData);
        this.setState({loading: true})
        const api = new APICall();
        api.command(postData, this.processEditFollowupTask);
    }

    processEditFollowupTask = (result) => {
        let alert_param = {title: 'Success', message: 'The follow-up task has been updated successfully.', ok_text: 'Ok', confirm: false,
                           alertHandler: this.updateprocessallchildtask, stack: {}}
        this.setState({alert_param: alert_param, loading: false})
    }

    updateprocessallchildtask = (result, stack) => {
        console.log(result,stack)
        this.setState({alert_param: null})
        let command_data = { "command": "listfollowups", parent_id: this.state.taskid };
        console.log('followuptasks list command', command_data);
        let api = new APICall();
        this.setState({loading: true})
        api.command(command_data, this.updateallChildTask);
    }

    updateallChildTask = (result) => {
        Store.updateStore('updated_followups', result.result.followuptasks);
        console.log('result.result.current_child_lists', result);
        this.setState({ showModal: false,
            followupTasksList: result.result.followuptasks,
            loading: false
        });
    }
    
    getUserNameFromId = (contactId) =>{
        return Store.getStoreData('contacts')[contactId];
    }

    render()
    {
        let curmytaskdata = Store.getStoreData('cur_q_modal_data');
        //console.log("curmytaskdata",curmytaskdata)
        let task_json = JSON.parse(curmytaskdata.task_json);
        const discloserdate_obj = new Date(curmytaskdata.due_date);
        const discloserdate_day = discloserdate_obj.getDate() < 10 ? "0"+discloserdate_obj.getDate().toString() : discloserdate_obj.getDate();
        const discloserdate_month = discloserdate_obj.getMonth()+1 < 10 ? `0${(discloserdate_obj.getMonth() + 1).toString()}` : discloserdate_obj.getMonth()+1;
        const discloserdate_year = discloserdate_obj.getFullYear();
        const discloserdate_date = `${discloserdate_day}/${discloserdate_month}/${discloserdate_year}`;

        const gifteddate_obj = new Date(this.state.giftedDate);
        const gifteddate_day = gifteddate_obj.getDate() < 10 ? "0"+gifteddate_obj.getDate().toString() : gifteddate_obj.getDate();
        const gifteddate_month = gifteddate_obj.getMonth()+1 < 10 ? `0${(gifteddate_obj.getMonth() + 1).toString()}` : gifteddate_obj.getMonth()+1;
        const gifteddate_year = gifteddate_obj.getFullYear();
        const gifteddate_date = `${gifteddate_day}/${gifteddate_month}/${gifteddate_year}`;

        let updatedUserName = "";
        let updateduserDate = "";

        const addedByUser = this.getUserNameFromId(curmytaskdata.contact_id)
        
        let isUpdate = 0;
        if('updated_by' in task_json.object_data){
            updatedUserName = Store.getStoreData('contacts')[task_json.object_data.updated_by];
            isUpdate = 1;
        }else{
            if(task_json.action_data.length !==0){
                if('performed_by' in task_json.action_data[task_json.action_data.length - 1]){
                    updatedUserName = Store.getStoreData('contacts')[task_json.action_data[task_json.action_data.length - 1].performed_by];
                }
            }
        }
        //console.log("updatedUserName==>", updatedUserName)


        if('updated_at' in task_json.object_data){
            updateduserDate = moment.unix(task_json.object_data.updated_at).format('DD/MM/YYYY')
        }else{
            if(task_json.action_data.length !==0){
                if('actiondate' in task_json.action_data[task_json.action_data.length - 1]){
                    let actionUnix = moment(task_json.action_data[task_json.action_data.length - 1].actiondate).unix();
                    updateduserDate = moment.unix(actionUnix).format('DD/MM/YYYY')
                }
            }
        }
        let approvalType = "";
        let isApprovalType = 0;
        let approvalDescription = "";
        let isApprovalDescription = 0;
        if('headerinfo' in task_json.object_data && 'approval_type' in task_json.object_data.headerinfo){
            approvalType = task_json.object_data.headerinfo.approval_type;
            isApprovalType = 1;
        }
        if('headerinfo' in task_json.object_data && 'approval_description' in task_json.object_data.headerinfo && task_json.object_data.headerinfo.approval_description !== ''){
            approvalDescription = task_json.object_data.headerinfo.approval_description;
            isApprovalDescription = 1
        }

        let status = "";
        if(curmytaskdata.cur_lane === "COMP_GIFTS_REGISTER") {
            status =  "Registered";
        }else if(curmytaskdata.cur_lane === "COMP_GIFTS_COMPLETE") {
            status =  "Confirmed";
        }else if(curmytaskdata.cur_lane === "COMP_GIFTS_REMOVE") {
            status = "Removed"
        }
        let discloserName = Store.getStoreData('contacts')[curmytaskdata.contact_id];
        let discloserEmail = Store.getStoreData('contactsemail')[curmytaskdata.contact_id];

        if('requester_name' in task_json.object_data){
            discloserName = task_json.object_data.requester_name;
        }
        if('email' in task_json.object_data){
            discloserEmail = task_json.object_data.email;
        }
        let resultType = []
        let resultTypeValue = Store.getStoreData('module_config').result_types;
        for(let d of resultTypeValue){
            if(d.isActive !== 0){
                resultType.push(d);
            }
        }

        let updatedFollowups = Store.getStoreData('updated_followups');
        let module_config = Store.getStoreData('module_config');
        if(!this.state.ready){
            return(<div></div>)
        }
        let contacts = Store.getStoreData('contacts')
        let approval_data = "approval_data" in task_json.object_data.headerinfo ? task_json.object_data.headerinfo.approval_data : [];
        return (
            <div>
                {
                    (() => {
                        if(this.state.loading){
                            return <div><CSLLoader style={{position: 'absolute'}}/></div>
                        }
                    })()
                }
                <MRModalContainer>
                    <MRModalHeader>
                        <MRModalHeaderText>{this.state.Ref_ID} - Gifts & Hospitality</MRModalHeaderText>
                        <MRModalHeaderCloseBtn onClick={this.closeView}><FaTimes /></MRModalHeaderCloseBtn>
                        <div style={{clear: "both"}}></div>
                        {/* <MRModalSubHeaderText>Fill out the below fields to fully submit your Gifts & Hospitality disclosure.</MRModalSubHeaderText> */}
                        <div style={{clear: "both"}}></div>
                    </MRModalHeader>
                    <ScoreBodyContainer>
                    <MRModalBody>
                        <div style={{clear: "both"}}></div>
                        <div style={{position: "relative",padding: "35px 35px"}}>
                            {
                                (()=> {
                                    const isUpdateModal = !this.state.removal_readonly;
                                    if(isUpdateModal) {
                                        return (
                                            <div>
                                                <MRModalBodyInner style={{
                                                    backgroundColor: "#E7F0F7",
                                                    border: "1px solid #D0D0D0",
                                                    color: "#1A3552",
                                                    fontWeight: "bold"
                                                }}>
                                                    This entry was added by {addedByUser}
                                                </MRModalBodyInner>
                                                <SpacerXL/>
                                            </div>
                                        )
                                    }
                                })()
                            }
                            {
                                (() => {
                                    if(this.state.removal_readonly){
                                        return(
                                            // <MRModalBodyInner style={{backgroundColor: "#EDF0F1", border: "1px solid #D6DDDF",color: "#33435E", fontWeight: "bold"}}>
                                            //     This Gifts & Hospitality has been submitted.
                                            // </MRModalBodyInner>
                                            <MRModalBodyInner style={{backgroundColor: "#E7F0F7", border: "1px solid #D0D0D0",color: "#1A3552", fontWeight: "bold"}}>
                                                This entry was updated by {updatedUserName} on {updateduserDate}
                                            </MRModalBodyInner>
                                        )
                                    }else{
                                        if(isUpdate === 1){
                                            return(<div><MRModalBodyInner style={{backgroundColor: "#E7F0F7", border: "1px solid #D0D0D0",color: "#1A3552", fontWeight: "bold"}}>
                                            This entry was updated by {updatedUserName} on {updateduserDate}
                                            </MRModalBodyInner>
                                            <SpacerXL/>
                                            <MRModalBodyInner style={{backgroundColor: "#F6EBD6", border: "1px solid #F8DAA3",color: "#E3A527", fontWeight: "bold"}}>
                                                {this.state.header_message}
                                            </MRModalBodyInner>
                                            </div>)
                                        }else{
                                            if(this.state.isCompletetoUpdate){
                                                return(<div><MRModalBodyInner style={{backgroundColor: "#E7F0F7", border: "1px solid #D0D0D0",color: "#1A3552", fontWeight: "bold"}}>
                                                This entry was updated by {updatedUserName} on {updateduserDate}
                                                </MRModalBodyInner>
                                                <SpacerXL/>
                                                <MRModalBodyInner style={{backgroundColor: "#F6EBD6", border: "1px solid #F8DAA3",color: "#E3A527", fontWeight: "bold"}}>
                                                        {this.state.header_message}
                                                    </MRModalBodyInner>
                                                </div>)
                                            }else{
                                                return(
                                                    <MRModalBodyInner style={{backgroundColor: "#F6EBD6", border: "1px solid #F8DAA3",color: "#E3A527", fontWeight: "bold"}}>
                                                        {this.state.header_message}
                                                    </MRModalBodyInner>
                                                )
                                            }
                                        }
                                    }
                                })()
                            }
                            {
                                (() => {
                                    if(!this.state.removal_readonly){
                                        return(<div>
                                            <SpacerS/>
                                            <MRModalLabel
                                                style={{display: "inline-block", marginLeft: "5px"}}> Company
                                                -</MRModalLabel>
                                            <ModalNameLabelDivReference> {this.state.groupcompanyName}</ModalNameLabelDivReference>
                                        </div>)
                                    }
                                })()
                            }

                            {
                                (() => {
                                    if (this.state.removal_readonly) {
                                        let recname = '';
                                        if (this.state.recipientName in this.state.recipientlistObject) {
                                            recname = this.state.recipientlistObject[this.state.recipientName.toString()]
                                            if(this.state.recipientName === 'Other'){
                                                recname = 'Other - ' + this.state.recipientNameAll
                                            }
                                        }

                                        return(
                                            <div>
                                                <MRDataContainer>
                                                    <ModalContainerGrid>
                                                        <ModalNameLabelDivFin>Company Name</ModalNameLabelDivFin>
                                                        <ModalNameInputDivFin>{this.state.groupcompanyName}</ModalNameInputDivFin>
                                                        <ModalNameLabelDivFin>Added Date</ModalNameLabelDivFin>
                                                        <ModalNameInputDivFin>{discloserdate_date}</ModalNameInputDivFin>
                                                        <ModalNameLabelDivFin>Gifted Date</ModalNameLabelDivFin>
                                                        <ModalNameInputDivFin>{gifteddate_date}</ModalNameInputDivFin>
                                                        <ModalNameLabelDivFin>Added By</ModalNameLabelDivFin>
                                                        <ModalNameInputDivFin>{addedByUser}</ModalNameInputDivFin>
                                                        <ModalNameLabelDivFin>Status</ModalNameLabelDivFin>
                                                        <ModalNameInputDivFin>{status}</ModalNameInputDivFin>
                                                        <ModalNameLabelDivFin>Given/Received</ModalNameLabelDivFin>
                                                        <ModalNameInputDivFin>{this.state.giftStatus  === 'received' ? 'Received' : 'Given'}</ModalNameInputDivFin>
                                                        <ModalNameLabelDivFin>{this.state.giftStatus === "received" ? 'Recipient' : 'Given By'}</ModalNameLabelDivFin>
                                                        <ModalNameInputDivFin>{recname}</ModalNameInputDivFin>
                                                        <ModalNameLabelDivFin>{this.state.giftStatus === "received" ? 'Donor' : 'Given To'}</ModalNameLabelDivFin>
                                                        <ModalNameInputDivFin>{this.state.giftStatus === "received" ? this.state.donorName : this.state.giftGivenTo}</ModalNameInputDivFin>
                                                        <ModalNameLabelDivFin>Type</ModalNameLabelDivFin>
                                                        <ModalNameInputDivFin>{this.state.giftTypename}</ModalNameInputDivFin>
                                                        <ModalNameLabelDivFin>Value</ModalNameLabelDivFin>
                                                        <ModalNameInputDivFin>{this.state.minvalue}</ModalNameInputDivFin>
                                                        <ModalNameLabelDivFin>Description</ModalNameLabelDivFin>
                                                        <DescriptionInputDiv>
                                                            <div style={{fontFamily: "'Montserrat', sans-serif",marginTop: "10px",whiteSpace: "pre-line"}}>{this.state.giftDescription}</div>
                                                        </DescriptionInputDiv>

                                                    </ModalContainerGrid>
                                                        {
                                                            (() => {
                                                                if (this.state.assign_data.additional_fields.length !== 0) {
                                                                    let custom_html = [];
                                                                    this.state.assign_data.additional_fields.forEach((item) => {
                                                                        if(item.displayFor === 'both' || item.displayFor === this.state.giftStatus){
                                                                            const field = this.generateFieldreadOnly(item);
                                                                            custom_html.push(field);
                                                                        }
                                                                    })
                                                                    return custom_html;
                                                                }
                                                            })()
                                                        }
                                                        {
                                                            (() => {
                                                                if(this.state.approval_state !== null && approval_data.length !== 0){
                                                                    let approval_html = [];
                                                                    approval_data.forEach((item) => {
                                                                        const field = this.generateApproval(item);
                                                                        approval_html.push(field);
                                                                    })
                                                                    return approval_html;
                                                                }
                                                            })()
                                                        }

                                                        {
                                                            (() => {
                                                                if(isApprovalType === 1){
                                                                    return(<ModalContainerGrid>
                                                                    {/* <ModalNameLabelDivFin style={{paddingTop: "0px"}}>Approval Reason </ModalNameLabelDivFin> */}
                                                                    <ModalNameLabelDivFin style={{paddingTop: "0px"}}>Result</ModalNameLabelDivFin>
                                                                    <ModalNameInputDivFin style={{paddingTop: "0px"}}>{approvalType}</ModalNameInputDivFin>
                                                                    {/* {
                                                                        (() => {
                                                                            if(isApprovalDescription === 1){
                                                                                return(<ModalNameInputDivFin >{approvalType} - {approvalDescription}</ModalNameInputDivFin>)
                                                                            }else{
                                                                                return(<ModalNameInputDivFin >{approvalType}</ModalNameInputDivFin>)
                                                                            }
                                                                        })()
                                                                    } */}
                                                                    </ModalContainerGrid> )
                                                                }
                                                            })()
                                                        }
                                                        {
                                                            (() => {
                                                                if(isApprovalDescription === 1){
                                                                    return(<ModalContainerGrid style={{padding: "0px 10px 10px 10px"}}>
                                                                        <ModalNameLabelDivFin style={{paddingTop: "0px"}}>Reason for Approval</ModalNameLabelDivFin>
                                                                        <ModalNameInputDivFin style={{paddingTop: "0px"}}>{approvalDescription}</ModalNameInputDivFin>
                                                                    </ModalContainerGrid>)
                                                                }
                                                            })()
                                                        }

                                                </MRDataContainer>
                                                {
                                                    (() => {
                                                        if(this.state.cur_files_json !== null && this.state.cur_files_json.length !== 0){
                                                            return(<div>
                                                                    <ModalNameLabelDiv>Attachments</ModalNameLabelDiv>
                                                                    <ModalNameInputDiv>
                                                                        <Dropzone onFilesAdded={this.filesLoaded} initFiles={this.state.cur_files_json} addnew={false}/>
                                                                    </ModalNameInputDiv>
                                                            </div>)
                                                        }
                                                    })()
                                                }
                                                <RegDismisOuterContainer>
                                                    <OverviewInfoHeader>
                                                        <div style={{float: "left", color: "#ffffff", fontWeight: "600"}}>Follow Up Tasks</div>
                                                        <div style={{float: "right", color: "#ffffff", fontSize: "17px"}} ><FaPlusCircle /></div>
                                                        <div style={{clear: "both"}}></div>
                                                    </OverviewInfoHeader>
                                                    {
                                                        (() => {
                                                            if(updatedFollowups.length !== 0) {
                                                                return(
                                                                    <OverviewInfoBody>
                                                                        <CSLTable add={false} processData={this.processMyfollowupTaskData} headerText={''} tableRows="3" refreshCallback={this.refreshCallback} />
                                                                    </OverviewInfoBody>
                                                                )
                                                            }else {
                                                                return(
                                                                <OverviewInfoBody>
                                                                    <div style={{textAlign: "center",fontStyle: "italic", color: "#dddddd"}}>There are no follow-up tasks assigned.</div>
                                                                </OverviewInfoBody>
                                                                );
                                                            }
                                                        })()
                                                    }
                                                </RegDismisOuterContainer>
                                            </div>
                                        )
                                    }else{
                                        return(
                                            <div>
                                                <MRModalLabel>Type</MRModalLabel>
                                                <MRModalSelect id='type' name="giftTypeid" onChange={this.handleChange} value={this.state.giftTypeid}>
                                                    <option value={0}>Select</option>
                                                    {
                                                        this.state.giftTypeList.map((gf,index) => {
                                                            return(
                                                                <option key={index} value={gf.giftTypeID}>{gf.giftTypeName}</option>
                                                            );
                                                        })
                                                    }
                                                </MRModalSelect>
                                                <SpacerS/>
                                                <MRModalLabel>Was the gift received or given?</MRModalLabel>
                                                <MRModalSelect id='received_given' name="giftStatus" onChange={this.handleChange} value={this.state.giftStatus} >
                                                    <option value="received">Received</option>
                                                    <option value="given">Given</option>
                                                </MRModalSelect>
                                                <SpacerS/>
                                                <MRModalLabel>Gifted Date</MRModalLabel>
                                                <CSLDateCover id="gifted_date" style={{display: "inline-block"}}>
                                                    <DatePicker
                                                            onChange={this.onChangeGiftedDate}
                                                            clearIcon={null}
                                                            calendarIcon={<FaCalendarAlt style={{color: '#243E57'}} />}
                                                            locale={"en-GB"}
                                                            value={this.state.giftedDate}
                                                    />
                                                </CSLDateCover>
                                                <SpacerS/>
                                                {
                                                    (() => {
                                                        if(this.state.giftStatus === "received"){
                                                            return(<MRModalLabel>Recipient</MRModalLabel>)
                                                        }else{
                                                            return(<MRModalLabel>Given By</MRModalLabel>)
                                                        }
                                                    })()
                                                }
                                                <MRModalSelect id='recipient' name="recipientName" onChange={this.handleChangeRecipient} value={this.state.recipientName} >
                                                    <option value="">Select</option>
                                                    {
                                                        this.state.recipientlist.map((rc,index) => {
                                                            return(
                                                            <option key={index} value={rc.id}>{rc.name}</option>
                                                            );
                                                        })
                                                    }
                                                </MRModalSelect>
                                                {
                                                    (() => {
                                                        if(this.state.isOtherRecipientName){
                                                            return(<div>
                                                                <SpacerS/>
                                                                <MRModalLabel>Please provide details</MRModalLabel>
                                                                <MRModalInput id='recipient_name' type="text" name="recipientNameAll" onChange={this.handleChange} value={this.state.recipientNameAll} />
                                                            </div>)
                                                        }
                                                    })()
                                                }
                                                {
                                                    (() => {
                                                        if(this.state.giftStatus === "given"){
                                                            return(<div>
                                                                <MRModalLabel style={{marginTop: "30px"}}>Given To</MRModalLabel>
                                                                <MRModalTextarea id='giftGivenTo' rows="6" name="giftGivenTo" value={this.state.giftGivenTo} onChange={this.handleChange} readOnly={this.state.isdesable}/>
                                                            </div>)
                                                        }
                                                    })()
                                                }
                                                {
                                                    (() => {
                                                        if(this.state.giftStatus === "received"){
                                                            return(<div>
                                                                <SpacerS/>
                                                                <MRModalLabel>Donor</MRModalLabel>
                                                                <MRModalInput id='donor' type="text" placeholder="Enter donor name..." name="donorName" onChange={this.handleChange} value={this.state.donorName} />
                                                            </div>)
                                                        }
                                                    })()
                                                }
                                                <SpacerS/>
                                                <div style={{display: "inline-block", width: "50%"}}>
                                                    <MRModalLabel>Value</MRModalLabel>
                                                    {/* <MRModalInput style={{display: "inline-block",width: "10%"}} value={this.state.currency} readOnly/> */}
                                                    <MRModalSelect style={{display: "inline-block",width: "30%"}} name="currency" onChange={this.handleChangeValue} value={this.state.currency}>
                                                        {currency_codes.map((cc) => (
                                                            <option key={cc.code} value={cc.code}>
                                                            {cc.code}
                                                            </option>
                                                        ))}
                                                    </MRModalSelect>
                                                    <MRModalInput style={{width: "25%",marginLeft: "30px"}} id='value' type="number" step="any" name="minvalue" min="0" onKeyDown={blockInvalidChar} onChange={this.handleChange} value={this.state.minvalue} />
                                                </div>
                                                {/* Appropixmat value */}
                                                {
                                                    (() => {
                                                        if(this.state.isShowAppValue){
                                                            return(
                                                            <div style={{display: "inline-block", width: "50%"}}>
                                                                <MRModalLabel>Approximate {this.state.app_currency} value</MRModalLabel>
                                                                <MRModalInput style={{display: "inline-block",width: "25%"}} value={this.state.app_currency} readOnly/>
                                                                <MRModalInput style={{width: "25%",marginLeft: "30px"}} id='app_value' type="number" step="any" name="app_minvalue" min="0" onKeyDown={blockInvalidChar} onChange={this.handleChange} value={this.state.app_minvalue} />
                                                            </div>
                                                            )
                                                        }
                                                    })()
                                                }
                                                <SpacerS/>
                                                {/* custom field start */}
                                                {
                                                    (() => {
                                                        if('custom_fields' in module_config && module_config.custom_fields.enabled){
                                                            if (this.state.assign_data.additional_fields.length !== 0) {
                                                                let custom_html = [];
                                                                this.state.assign_data.additional_fields.forEach((item) => {
                                                                    if(item.displayFor === 'both' || item.displayFor === this.state.giftStatus){
                                                                        const field = this.generateField(item, "additional_fields");
                                                                        custom_html.push(field);
                                                                    }
                                                                })
                                                                return custom_html;
                                                            }
                                                        }
                                                    })()
                                                }
                                                {/* custom field end */}
                                                <SpacerS/>
                                                <MRModalLabel style={{marginTop: "30px"}}>Description</MRModalLabel>
                                                <MRModalLabel style={{fontWeight: "100",fontSize: "13px",color: "#919292"}}>Please provide a description of the gift/hospitality</MRModalLabel>
                                                <MRModalTextarea id='description' rows="6" name="giftDescription" value={this.state.giftDescription} onChange={this.handleChange} />
                                                <SpacerS/>
                                                {
                                                    (() => {
                                                        if(this.state.cur_files_json !== null && this.state.cur_files_json.length !== 0){
                                                            return(<div>
                                                                    <MRModalLabel>Attachments</MRModalLabel>
                                                                    <ModalNameInputDiv>
                                                                        <Dropzone onFilesAdded={this.filesLoaded} initFiles={this.state.cur_files_json} addnew={false}/>
                                                                    </ModalNameInputDiv>
                                                            </div>)
                                                        }
                                                    })()
                                                }
                                                <MRTasksContainer>
                                                    {this.state.approval_state !== null && this.state.approval_data.length !== 0 && this.state.approval_state >= 1 && (this.state.approval_level === "ar-lm" || this.state.approval_level === "lm-ar") &&
                                                    <MRSuccessContainer>
                                                        {/* {
                                                            (() => {
                                                                if(this.state.approval_level === "lm"){
                                                                    return(<div style={{ color: "#6c9451", fontWeight: "600" }}>One Level Approval</div>)
                                                                }else if(this.state.approval_level === "ar-lm" || this.state.approval_level === "lm-ar"){
                                                                    return(<div style={{ color: "#6c9451", fontWeight: "600" }}>Two Level Approval</div>)
                                                                }else if(this.state.approval_level === "lm-ar"){
                                                                    return(<div style={{ color: "#6c9451", fontWeight: "600" }}>Two Level Approval</div>)
                                                                }
                                                            })()
                                                        } */}
                                                        {
                                                            (() => {
                                                                // if(this.state.approval_level === "ar-lm" || this.state.approval_level === "lm-ar"){
                                                                    if(curmytaskdata.cur_lane === 'COMP_GIFTS_COMPLETE'){
                                                                        return(<div>
                                                                            <div style={{ color: "#6c9451", fontWeight: "600" }}>Two Level Approval</div>
                                                                            <div style={{ color: "#6c9451", marginTop: "5px" }}>This Gift Register has received first level approval.</div>
                                                                            <div style={{ color: "#6c9451", marginTop: "20px" }}>
                                                                            <div style={{float: "left", width: "15%", fontWeight: "600"}}>Reviewer:</div>
                                                                            <div style={{float: "left", width: "85%"}}>
                                                                                {contacts[this.state.approval_data[0].performed_by]}
                                                                                </div>
                                                                            <div style={{clear: "both"}}></div>
                                                                            </div>
                                                                            <div style={{ color: "#6c9451", marginTop: "5px" }}>
                                                                            <div style={{float: "left", width: "15%", fontWeight: "600"}}>Approved:</div>
                                                                            <div style={{float: "left", width: "85%"}}>
                                                                                {moment.unix(this.state.approval_data[0].action_time).format('DD/MM/YYYY hh:mm a')}
                                                                            </div>
                                                                            <div style={{clear: "both"}}></div>
                                                                            </div>
                                                                            <div style={{ color: "#6c9451", marginTop: "5px" }}>
                                                                            <div style={{float: "left", width: "15%", fontWeight: "600"}}>Comments:</div>
                                                                            <div style={{float: "left", width: "85%"}}>
                                                                                {this.state.approval_data[0].comments}
                                                                            </div>
                                                                            <div style={{clear: "both"}}></div>
                                                                            </div>
                                                                        </div>)
                                                                    }else{
                                                                        return(<div>
                                                                            <div style={{ color: "#6c9451", fontWeight: "600" }}>Two Level Approval</div>
                                                                            <div style={{ color: "#6c9451", marginTop: "5px" }}>This Gift Register has received first level approval.</div>
                                                                            <div style={{ color: "#6c9451", marginTop: "20px" }}>
                                                                            <div style={{float: "left", width: "15%", fontWeight: "600"}}>Reviewer:</div>
                                                                            <div style={{float: "left", width: "85%"}}>
                                                                                {contacts[this.state.approval_data[this.state.approval_state - 1].performed_by]}
                                                                                </div>
                                                                            <div style={{clear: "both"}}></div>
                                                                            </div>
                                                                            <div style={{ color: "#6c9451", marginTop: "5px" }}>
                                                                            <div style={{float: "left", width: "15%", fontWeight: "600"}}>Approved:</div>
                                                                            <div style={{float: "left", width: "85%"}}>
                                                                                {moment.unix(this.state.approval_data[this.state.approval_state - 1].action_time).format('DD/MM/YYYY hh:mm a')}
                                                                            </div>
                                                                            <div style={{clear: "both"}}></div>
                                                                            </div>
                                                                            <div style={{ color: "#6c9451", marginTop: "5px" }}>
                                                                            <div style={{float: "left", width: "15%", fontWeight: "600"}}>Comments:</div>
                                                                            <div style={{float: "left", width: "85%"}}>
                                                                                {this.state.approval_data[this.state.approval_state - 1].comments}
                                                                            </div>
                                                                            <div style={{clear: "both"}}></div>
                                                                            </div>
                                                                        </div>)
                                                                    }
                                                                // }
                                                                // if(curmytaskdata.cur_lane === 'COMP_GIFTS_COMPLETE' && this.state.approval_level === "ar-lm" || this.state.approval_level === "lm-ar"){
                                                                //     console.log("approval_data==>", this.state.approval_data, '-----', this.state.approval_state)
                                                                //     return(<div>
                                                                //         <div style={{ color: "#6c9451", marginTop: "5px" }}>This Gift Register has received first level approval.</div>
                                                                //         <div style={{ color: "#6c9451", marginTop: "20px" }}>
                                                                //         <div style={{float: "left", width: "15%", fontWeight: "600"}}>Reviewer:</div>
                                                                //         <div style={{float: "left", width: "85%"}}>
                                                                //             {contacts[this.state.approval_data[0].performed_by]}
                                                                //             </div>
                                                                //         <div style={{clear: "both"}}></div>
                                                                //         </div>
                                                                //         <div style={{ color: "#6c9451", marginTop: "5px" }}>
                                                                //         <div style={{float: "left", width: "15%", fontWeight: "600"}}>Approved:</div>
                                                                //         <div style={{float: "left", width: "85%"}}>
                                                                //             {moment.unix(this.state.approval_data[0].action_time).format('DD/MM/YYYY hh:mm a')}
                                                                //         </div>
                                                                //         <div style={{clear: "both"}}></div>
                                                                //         </div>
                                                                //         <div style={{ color: "#6c9451", marginTop: "5px" }}>
                                                                //         <div style={{float: "left", width: "15%", fontWeight: "600"}}>Comments:</div>
                                                                //         <div style={{float: "left", width: "85%"}}>
                                                                //             {this.state.approval_data[0].comments}
                                                                //         </div>
                                                                //         <div style={{clear: "both"}}></div>
                                                                //         </div>
                                                                //     </div>)
                                                                // }else{
                                                                //     return(<div>
                                                                //         <div style={{ color: "#6c9451", marginTop: "5px" }}>This Gift Register has received first level approval.</div>
                                                                //         <div style={{ color: "#6c9451", marginTop: "20px" }}>
                                                                //         <div style={{float: "left", width: "15%", fontWeight: "600"}}>Reviewer:</div>
                                                                //         <div style={{float: "left", width: "85%"}}>
                                                                //             {contacts[this.state.approval_data[this.state.approval_state - 1].performed_by]}
                                                                //             </div>
                                                                //         <div style={{clear: "both"}}></div>
                                                                //         </div>
                                                                //         <div style={{ color: "#6c9451", marginTop: "5px" }}>
                                                                //         <div style={{float: "left", width: "15%", fontWeight: "600"}}>Approved:</div>
                                                                //         <div style={{float: "left", width: "85%"}}>
                                                                //             {moment.unix(this.state.approval_data[this.state.approval_state - 1].action_time).format('DD/MM/YYYY hh:mm a')}
                                                                //         </div>
                                                                //         <div style={{clear: "both"}}></div>
                                                                //         </div>
                                                                //         <div style={{ color: "#6c9451", marginTop: "5px" }}>
                                                                //         <div style={{float: "left", width: "15%", fontWeight: "600"}}>Comments:</div>
                                                                //         <div style={{float: "left", width: "85%"}}>
                                                                //             {this.state.approval_data[this.state.approval_state - 1].comments}
                                                                //         </div>
                                                                //         <div style={{clear: "both"}}></div>
                                                                //         </div>
                                                                //     </div>)
                                                                // }
                                                            })()
                                                        }

                                                    </MRSuccessContainer>
                                                    }
                                                    {
                                                        (() => {
                                                            if (this.state.approval_level === "lm") {
                                                                if (this.state.manager_id === null) {
                                                                    return (
                                                                    <MRDangerContainer>
                                                                        <div style={{ color: "#a91414", fontWeight: "600" }}>First Level Approval</div>
                                                                        <div style={{ color: "#b75050", marginTop: "5px" }}>This Gift Register requires Line Manager approval but the employee does not have a Line Manager set up</div>
                                                                        <div style={{ color: "#b75050", marginTop: "15px" }}>Please add a Line Manager to the employee or provide the approval.</div>
                                                                    </MRDangerContainer>
                                                                    )
                                                                }
                                                            }
                                                        })()
                                                    }
                                                    {
                                                        (() => {
                                                            if (this.state.approval_level === "ar-lm") {
                                                                if (this.state.approval_state !== null && this.state.approval_state >= 1 && this.state.manager_id === null) {
                                                                    return (
                                                                    <MRDangerContainer>
                                                                        <div style={{ color: "#a91414", fontWeight: "600" }}>Two Level Approval</div>
                                                                        <div style={{ color: "#b75050", marginTop: "5px" }}>This Gift Register requires Line Manager approval but the employee does not have a Line Manager set up</div>
                                                                        <div style={{ color: "#b75050", marginTop: "15px" }}>Please add a Line Manager to the employee or provide the second level approval. Note the same person cannot provide first and second approval.</div>
                                                                    </MRDangerContainer>
                                                                    )
                                                                }
                                                            }
                                                        })()
                                                    }
                                                    {
                                                        (() => {
                                                            if (this.state.approval_level === "lm-ar") {
                                                                if (this.state.approval_state !== null && this.state.approval_state === 0 && this.state.manager_id === null) {
                                                                    return (
                                                                    <MRDangerContainer>
                                                                        <div style={{ color: "#a91414", fontWeight: "600" }}>Two Level Approval</div>
                                                                        <div style={{ color: "#b75050", marginTop: "5px" }}>This Gift Register requires Line Manager approval but the employee does not have a Line Manager set up</div>
                                                                        <div style={{ color: "#b75050", marginTop: "15px" }}>Please add a Line Manager to the employee or provide the first level approval. Note the same person cannot provide first and second approval.</div>
                                                                    </MRDangerContainer>
                                                                    )
                                                                }
                                                            }
                                                        })()
                                                    }
                                                    {
                                                        (() => {
                                                            if(this.state.curlane !== 'COMP_GIFTS_COMPLETE' && this.state.approval_data.length !== 0){
                                                                if(Store.getStoreData('role') === 'gm'){
                                                                    if(!(this.state.approval_level === "am")){
                                                                        if(this.state.approval_level === 'su'){
                                                                            if(this.state.isUserlevelApproval){
                                                                                if(parseInt(task_json.object_data.headerinfo.specificUserID) === parseInt(Store.getStoreData("contact_id"))){
                                                                                    return(<div>
                                                                                        <MRModalLabel style={{ marginTop: "50px" }}>Comments</MRModalLabel>
                                                                                        <MRModalTextarea rows="5" name="gifts_comments" readOnly={this.state.readOnly} value={this.state.approval_data[this.state.approval_state].comments} onChange={this.changeApprovalComments} />
                                                                                    </div>)
                                                                                }
                                                                            }else{
                                                                                return(<div>
                                                                                    <MRModalLabel style={{ marginTop: "50px" }}>Comments</MRModalLabel>
                                                                                    <MRModalTextarea rows="5" name="gifts_comments" readOnly={this.state.readOnly} value={this.state.approval_data[this.state.approval_state].comments} onChange={this.changeApprovalComments} />
                                                                                </div>)
                                                                            }
                                                                        }else{
                                                                            return(<div>
                                                                                <MRModalLabel style={{ marginTop: "50px" }}>Comments</MRModalLabel>
                                                                                <MRModalTextarea rows="5" name="gifts_comments" readOnly={this.state.readOnly} value={this.state.approval_data[this.state.approval_state].comments} onChange={this.changeApprovalComments} />
                                                                            </div>)
                                                                        }
                                                                    }
                                                                }else{
                                                                    if(this.state.approval_level === 'su'){
                                                                        if(this.state.isUserlevelApproval){
                                                                            if(parseInt(task_json.object_data.headerinfo.specificUserID) === parseInt(Store.getStoreData("contact_id"))){
                                                                                return(<div>
                                                                                    <MRModalLabel style={{ marginTop: "50px" }}>Comments</MRModalLabel>
                                                                                    <MRModalTextarea rows="5" name="gifts_comments" readOnly={this.state.readOnly} value={this.state.approval_data[this.state.approval_state].comments} onChange={this.changeApprovalComments} />
                                                                                </div>)
                                                                            }
                                                                        }else{
                                                                            return(<div>
                                                                                <MRModalLabel style={{ marginTop: "50px" }}>Comments</MRModalLabel>
                                                                                <MRModalTextarea rows="5" name="gifts_comments" readOnly={this.state.readOnly} value={this.state.approval_data[this.state.approval_state].comments} onChange={this.changeApprovalComments} />
                                                                            </div>)
                                                                        }
                                                                    }else{
                                                                        return(<div>
                                                                            <MRModalLabel style={{ marginTop: "50px" }}>Comments</MRModalLabel>
                                                                            <MRModalTextarea rows="5" name="gifts_comments" readOnly={this.state.readOnly} value={this.state.approval_data[this.state.approval_state].comments} onChange={this.changeApprovalComments} />
                                                                        </div>)
                                                                    }
                                                                }
                                                                // return(<div>
                                                                //     <MRModalLabel style={{ marginTop: "50px" }}>Comments</MRModalLabel>
                                                                //     <MRModalTextarea rows="5" name="gifts_comments" readOnly={this.state.readOnly} value={this.state.approval_data[this.state.approval_state].comments} onChange={this.changeApprovalComments} />
                                                                // </div>)
                                                            }
                                                        })()
                                                    }

                                                </MRTasksContainer>

                                                <hr style={{marginTop: "20px", color: "#CCCFD4"}}/>
                                                <RegDismisOuterContainer>
                                                    <OverviewInfoHeader>
                                                        <div style={{float: "left", color: "#ffffff", fontWeight: "600"}}>Follow Up Tasks</div>
                                                        {
                                                            (() => {
                                                                    if(this.state.showfollowupclose){
                                                                        if(this.state.curlane !== "COMP_GIFTS_REMOVE"){
                                                                            return(<div style={{float: "right", color: "#ffffff", fontSize: "17px", cursor: "pointer"}} onClick={this.showAddFollowupTaskModal(-1)}><FaPlusCircle /></div>)
                                                                        }else{
                                                                            return(<div style={{float: "right", color: "#ffffff", fontSize: "17px", cursor: "pointer"}} onClick={this.showalertFollowup}><FaPlusCircle /></div>)
                                                                        }
                                                                    }
                                                                // }
                                                            })()
                                                        }
                                                        <div style={{clear: "both"}}></div>
                                                    </OverviewInfoHeader>
                                                    {
                                                        (() => {
                                                            if(this.state.showModal) {
                                                                return(
                                                                    <div>
                                                                    <GMFollowupTask headerText={this.state.headerFollowupText} taskId={this.state.taskid} revieweeList={this.state.review_list} closeFollowupTaskModal={this.closeFollowupModal} refreshTaskValue={this.followupTaskValueFetch} editrefreshTaskValue={this.editrefreshTaskValue}/>
                                                                    </div>
                                                                );
                                                            }
                                                        })()
                                                    }
                                                    {
                                                        (() => {
                                                            if(updatedFollowups.length !== 0) {
                                                                return(
                                                                    <OverviewInfoBody>
                                                                        <CSLTable add={false} processData={this.processMyfollowupTaskData} headerText={''} tableRows="3" refreshCallback={this.refreshCallback} />
                                                                    </OverviewInfoBody>
                                                                )
                                                            }else {
                                                                return(
                                                                <OverviewInfoBody>
                                                                    <div style={{textAlign: "center",fontStyle: "italic", color: "#dddddd"}}>There are no follow-up tasks assigned.</div>
                                                                </OverviewInfoBody>
                                                                );
                                                            }
                                                        })()
                                                    }
                                                </RegDismisOuterContainer>
                                            </div>
                                        )
                                    }
                                })()
                            }
                        </div>
                    </MRModalBody>
                    </ScoreBodyContainer>
                    <MRModalFooter style={{backgroundColor: this.state.showApprovalReason || this.state.showRemovalReason ? "#F3F7FB" : "white", borderTop: this.state.showApprovalReason || this.state.showRemovalReason ? "none" : "1px solid #DFDFDF"}}>
                        {
                            (() => {
                                //console.log("this.state.showRemovalReason===>", this.state.showApprovalReason)
                                if(this.state.removal_readonly){
                                    return(<div style={{position: "relative",padding: "17px"}}>
                                        {
                                            (()=> {
                                                if(Store.getStoreData('role') !== 'gm'){
                                                    return(<MRModalSaveCloseBtn onClick={this.updateRemovalTask}>Update</MRModalSaveCloseBtn>)
                                                }
                                            })()
                                        }
                                        <MRModalCancelBtn onClick={this.closeView}>Cancel</MRModalCancelBtn>
                                    </div>)
                                }else{
                                    if(Store.getStoreData('q_index') >= 0) {
                                        if(this.state.curlane === "COMP_GIFTS_TEAMSAVE") {
                                            return(<div style={{position: "relative",padding: "17px"}}>
                                                <MRModalNextBtn >Submit</MRModalNextBtn>
                                                <MRModalSaveCloseBtn >Save and Close</MRModalSaveCloseBtn>
                                                <MRModalCancelBtn onClick={this.closeView}>Cancel</MRModalCancelBtn>
                                            </div>);
                                        }else if(this.state.curlane === "COMP_GIFTS_COMPLETE") {
                                            return(
                                                    <div style={{position: "relative",padding: "17px"}}>
                                                        {/* {
                                                            (() => {
                                                                if(!this.state.showRemovalReason){
                                                                    return(<MRModalNextBtn onClick={this.openApproveGift}>Approve</MRModalNextBtn>)
                                                                }else{
                                                                    return(<MRModalNextBtn style={{backgroundColor: "#D4D4D4", color: "#ACACAC", border: "1px solid #D4D4D4",cursor: "default"}}>Approve</MRModalNextBtn>)
                                                                }
                                                            })()
                                                        }
                                                        {
                                                            (() => {
                                                                    if(status !== "Removed"){
                                                                        if(!this.state.showApprovalReason){
                                                                            return(<MRModalRemoveBtn onClick={this.openRemoval}>Reject</MRModalRemoveBtn>)
                                                                        }else{
                                                                            return(<MRModalRemoveBtn style={{backgroundColor: "#D4D4D4", color: "#ACACAC", border: "1px solid #D4D4D4",cursor: "default"}}>Reject</MRModalRemoveBtn>)
                                                                        }
                                                                    }
                                                            })()
                                                        } */}
                                                        {
                                                            (() => {
                                                                if(!this.state.showRemovalReason && !this.state.showApprovalReason){
                                                                    return(<div>
                                                                        <MRModalSaveCloseBtn onClick={this.saveAndCloseApproveGift(0)}>Save and Close</MRModalSaveCloseBtn>
                                                                        <MRModalCancelBtn onClick={this.closeView}>Cancel</MRModalCancelBtn>
                                                                    </div>)
                                                                }else{
                                                                    return(<div>
                                                                        <MRModalSaveCloseBtn style={{backgroundColor: "#D4D4D4", color: "#ACACAC", border: "1px solid #D4D4D4",cursor: "default"}}>Save and Close</MRModalSaveCloseBtn>
                                                                        <MRModalCancelBtn style={{backgroundColor: "#D4D4D4", color: "#ACACAC", border: "1px solid #D4D4D4",cursor: "default"}}>Cancel</MRModalCancelBtn>
                                                                    </div>)
                                                                }
                                                            })()
                                                        }
                                                    </div>
                                                )
                                        }else {
                                            if(Store.getStoreData('role') === 'gm'){
                                                if(!(this.state.approval_level === "am")){
                                                    if(this.state.approval_level === 'su'){
                                                        if(this.state.isUserlevelApproval){
                                                            if(parseInt(task_json.object_data.headerinfo.specificUserID) === parseInt(Store.getStoreData("contact_id"))){
                                                                if(this.state.showfollowupclose){
                                                                    return(
                                                                        <div style={{position: "relative",padding: "17px"}}>
                                                                            {
                                                                                (() => {
                                                                                    if(!this.state.showRemovalReason){
                                                                                        return(<MRModalNextBtn onClick={this.openApproveGift}>Approve</MRModalNextBtn>)
                                                                                    }else{
                                                                                        return(<MRModalNextBtn style={{backgroundColor: "#D4D4D4", color: "#ACACAC", border: "1px solid #D4D4D4",cursor: "default"}}>Approve</MRModalNextBtn>)
                                                                                    }
                                                                                })()
                                                                            }

                                                                            {
                                                                                (() => {
                                                                                        if(status !== "Removed"){
                                                                                            if(!this.state.showApprovalReason){
                                                                                                return(<MRModalRemoveBtn onClick={this.openRemoval}>Reject</MRModalRemoveBtn>)
                                                                                            }else{
                                                                                                return(<MRModalRemoveBtn style={{backgroundColor: "#D4D4D4", color: "#ACACAC", border: "1px solid #D4D4D4",cursor: "default"}}>Reject</MRModalRemoveBtn>)
                                                                                            }
                                                                                        }
                                                                                })()
                                                                            }
                                                                            {
                                                                                (() => {
                                                                                    if(!this.state.showRemovalReason && !this.state.showApprovalReason){
                                                                                        return(<div>
                                                                                            <MRModalSaveCloseBtn onClick={this.submitApproveGift(0)}>Save and Close</MRModalSaveCloseBtn>
                                                                                            <MRModalCancelBtn onClick={this.closeView}>Cancel</MRModalCancelBtn>
                                                                                        </div>)
                                                                                    }else{
                                                                                        return(<div>
                                                                                            <MRModalSaveCloseBtn style={{backgroundColor: "#D4D4D4", color: "#ACACAC", border: "1px solid #D4D4D4",cursor: "default"}}>Save and Close</MRModalSaveCloseBtn>
                                                                                            <MRModalCancelBtn style={{backgroundColor: "#D4D4D4", color: "#ACACAC", border: "1px solid #D4D4D4",cursor: "default"}}>Cancel</MRModalCancelBtn>
                                                                                        </div>)
                                                                                    }
                                                                                })()
                                                                            }

                                                                        </div>
                                                                    )
                                                                }
                                                            }else{
                                                                return(<div style={{position: "relative",padding: "17px"}}>
                                                                    <MRModalCancelBtn onClick={this.closeView}>Cancel</MRModalCancelBtn>
                                                                </div>)
                                                            }
                                                        }else{
                                                            if(this.state.showfollowupclose){
                                                                return(
                                                                    <div style={{position: "relative",padding: "17px"}}>
                                                                        {
                                                                            (() => {
                                                                                if(!this.state.showRemovalReason){
                                                                                    return(<MRModalNextBtn onClick={this.openApproveGift}>Approve</MRModalNextBtn>)
                                                                                }else{
                                                                                    return(<MRModalNextBtn style={{backgroundColor: "#D4D4D4", color: "#ACACAC", border: "1px solid #D4D4D4",cursor: "default"}}>Approve</MRModalNextBtn>)
                                                                                }
                                                                            })()
                                                                        }

                                                                        {
                                                                            (() => {
                                                                                    if(status !== "Removed"){
                                                                                        if(!this.state.showApprovalReason){
                                                                                            return(<MRModalRemoveBtn onClick={this.openRemoval}>Reject</MRModalRemoveBtn>)
                                                                                        }else{
                                                                                            return(<MRModalRemoveBtn style={{backgroundColor: "#D4D4D4", color: "#ACACAC", border: "1px solid #D4D4D4",cursor: "default"}}>Reject</MRModalRemoveBtn>)
                                                                                        }
                                                                                    }
                                                                            })()
                                                                        }
                                                                        {
                                                                            (() => {
                                                                                if(!this.state.showRemovalReason && !this.state.showApprovalReason){
                                                                                    return(<div>
                                                                                        <MRModalSaveCloseBtn onClick={this.submitApproveGift(0)}>Save and Close</MRModalSaveCloseBtn>
                                                                                        <MRModalCancelBtn onClick={this.closeView}>Cancel</MRModalCancelBtn>
                                                                                    </div>)
                                                                                }else{
                                                                                    return(<div>
                                                                                        <MRModalSaveCloseBtn style={{backgroundColor: "#D4D4D4", color: "#ACACAC", border: "1px solid #D4D4D4",cursor: "default"}}>Save and Close</MRModalSaveCloseBtn>
                                                                                        <MRModalCancelBtn style={{backgroundColor: "#D4D4D4", color: "#ACACAC", border: "1px solid #D4D4D4",cursor: "default"}}>Cancel</MRModalCancelBtn>
                                                                                    </div>)
                                                                                }
                                                                            })()
                                                                        }

                                                                    </div>
                                                                )
                                                            }
                                                        }
                                                    }else{
                                                        if(this.state.showfollowupclose){
                                                            return(
                                                                <div style={{position: "relative",padding: "17px"}}>
                                                                    {
                                                                        (() => {
                                                                            if(!this.state.showRemovalReason){
                                                                                return(<MRModalNextBtn onClick={this.openApproveGift}>Approve</MRModalNextBtn>)
                                                                            }else{
                                                                                return(<MRModalNextBtn style={{backgroundColor: "#D4D4D4", color: "#ACACAC", border: "1px solid #D4D4D4",cursor: "default"}}>Approve</MRModalNextBtn>)
                                                                            }
                                                                        })()
                                                                    }

                                                                    {
                                                                        (() => {
                                                                                if(status !== "Removed"){
                                                                                    if(!this.state.showApprovalReason){
                                                                                        return(<MRModalRemoveBtn onClick={this.openRemoval}>Reject</MRModalRemoveBtn>)
                                                                                    }else{
                                                                                        return(<MRModalRemoveBtn style={{backgroundColor: "#D4D4D4", color: "#ACACAC", border: "1px solid #D4D4D4",cursor: "default"}}>Reject</MRModalRemoveBtn>)
                                                                                    }
                                                                                }
                                                                        })()
                                                                    }
                                                                    {
                                                                        (() => {
                                                                            if(!this.state.showRemovalReason && !this.state.showApprovalReason){
                                                                                return(<div>
                                                                                    <MRModalSaveCloseBtn onClick={this.submitApproveGift(0)}>Save and Close</MRModalSaveCloseBtn>
                                                                                    <MRModalCancelBtn onClick={this.closeView}>Cancel</MRModalCancelBtn>
                                                                                </div>)
                                                                            }else{
                                                                                return(<div>
                                                                                    <MRModalSaveCloseBtn style={{backgroundColor: "#D4D4D4", color: "#ACACAC", border: "1px solid #D4D4D4",cursor: "default"}}>Save and Close</MRModalSaveCloseBtn>
                                                                                    <MRModalCancelBtn style={{backgroundColor: "#D4D4D4", color: "#ACACAC", border: "1px solid #D4D4D4",cursor: "default"}}>Cancel</MRModalCancelBtn>
                                                                                </div>)
                                                                            }
                                                                        })()
                                                                    }

                                                                </div>
                                                            )
                                                        }
                                                    }
                                                }
                                                return(<div style={{position: "relative",padding: "17px"}}>
                                                        <MRModalCancelBtn onClick={this.closeView}>Cancel</MRModalCancelBtn>
                                                    </div>)
                                            }else{
                                                if(this.state.approval_level === 'su'){
                                                    if(this.state.isUserlevelApproval){
                                                        if(parseInt(task_json.object_data.headerinfo.specificUserID) === parseInt(Store.getStoreData("contact_id"))){
                                                            if(this.state.showfollowupclose){
                                                                return(
                                                                    <div style={{position: "relative",padding: "17px"}}>
                                                                        {
                                                                            (() => {
                                                                                if(!this.state.showRemovalReason){
                                                                                    return(<MRModalNextBtn onClick={this.openApproveGift}>Approve</MRModalNextBtn>)
                                                                                }else{
                                                                                    return(<MRModalNextBtn style={{backgroundColor: "#D4D4D4", color: "#ACACAC", border: "1px solid #D4D4D4",cursor: "default"}}>Approve</MRModalNextBtn>)
                                                                                }
                                                                            })()
                                                                        }

                                                                        {
                                                                            (() => {
                                                                                    if(status !== "Removed"){
                                                                                        if(!this.state.showApprovalReason){
                                                                                            return(<MRModalRemoveBtn onClick={this.openRemoval}>Reject</MRModalRemoveBtn>)
                                                                                        }else{
                                                                                            return(<MRModalRemoveBtn style={{backgroundColor: "#D4D4D4", color: "#ACACAC", border: "1px solid #D4D4D4",cursor: "default"}}>Reject</MRModalRemoveBtn>)
                                                                                        }
                                                                                    }
                                                                            })()
                                                                        }
                                                                        {
                                                                            (() => {
                                                                                if(!this.state.showRemovalReason && !this.state.showApprovalReason){
                                                                                    return(<div>
                                                                                        <MRModalSaveCloseBtn onClick={this.submitApproveGift(0)}>Save and Close</MRModalSaveCloseBtn>
                                                                                        <MRModalCancelBtn onClick={this.closeView}>Cancel</MRModalCancelBtn>
                                                                                    </div>)
                                                                                }else{
                                                                                    return(<div>
                                                                                        <MRModalSaveCloseBtn style={{backgroundColor: "#D4D4D4", color: "#ACACAC", border: "1px solid #D4D4D4",cursor: "default"}}>Save and Close</MRModalSaveCloseBtn>
                                                                                        <MRModalCancelBtn style={{backgroundColor: "#D4D4D4", color: "#ACACAC", border: "1px solid #D4D4D4",cursor: "default"}}>Cancel</MRModalCancelBtn>
                                                                                    </div>)
                                                                                }
                                                                            })()
                                                                        }

                                                                    </div>
                                                                )
                                                            }
                                                        }else{
                                                            return(<div style={{position: "relative",padding: "17px"}}>
                                                                <MRModalCancelBtn onClick={this.closeView}>Cancel</MRModalCancelBtn>
                                                            </div>)
                                                        }
                                                    }else{
                                                        if(this.state.showfollowupclose){
                                                            return(
                                                                <div style={{position: "relative",padding: "17px"}}>
                                                                    {
                                                                        (() => {
                                                                            if(!this.state.showRemovalReason){
                                                                                return(<MRModalNextBtn onClick={this.openApproveGift}>Approve</MRModalNextBtn>)
                                                                            }else{
                                                                                return(<MRModalNextBtn style={{backgroundColor: "#D4D4D4", color: "#ACACAC", border: "1px solid #D4D4D4",cursor: "default"}}>Approve</MRModalNextBtn>)
                                                                            }
                                                                        })()
                                                                    }

                                                                    {
                                                                        (() => {
                                                                                if(status !== "Removed"){
                                                                                    if(!this.state.showApprovalReason){
                                                                                        return(<MRModalRemoveBtn onClick={this.openRemoval}>Reject</MRModalRemoveBtn>)
                                                                                    }else{
                                                                                        return(<MRModalRemoveBtn style={{backgroundColor: "#D4D4D4", color: "#ACACAC", border: "1px solid #D4D4D4",cursor: "default"}}>Reject</MRModalRemoveBtn>)
                                                                                    }
                                                                                }
                                                                        })()
                                                                    }
                                                                    {
                                                                        (() => {
                                                                            if(!this.state.showRemovalReason && !this.state.showApprovalReason){
                                                                                return(<div>
                                                                                    <MRModalSaveCloseBtn onClick={this.submitApproveGift(0)}>Save and Close</MRModalSaveCloseBtn>
                                                                                    <MRModalCancelBtn onClick={this.closeView}>Cancel</MRModalCancelBtn>
                                                                                </div>)
                                                                            }else{
                                                                                return(<div>
                                                                                    <MRModalSaveCloseBtn style={{backgroundColor: "#D4D4D4", color: "#ACACAC", border: "1px solid #D4D4D4",cursor: "default"}}>Save and Close</MRModalSaveCloseBtn>
                                                                                    <MRModalCancelBtn style={{backgroundColor: "#D4D4D4", color: "#ACACAC", border: "1px solid #D4D4D4",cursor: "default"}}>Cancel</MRModalCancelBtn>
                                                                                </div>)
                                                                            }
                                                                        })()
                                                                    }

                                                                </div>
                                                            )
                                                        }
                                                    }
                                                }else{
                                                    if(this.state.showfollowupclose){
                                                        return(
                                                            <div style={{position: "relative",padding: "17px"}}>
                                                                {
                                                                    (() => {
                                                                        if(!this.state.showRemovalReason){
                                                                            return(<MRModalNextBtn onClick={this.openApproveGift}>Approve</MRModalNextBtn>)
                                                                        }else{
                                                                            return(<MRModalNextBtn style={{backgroundColor: "#D4D4D4", color: "#ACACAC", border: "1px solid #D4D4D4",cursor: "default"}}>Approve</MRModalNextBtn>)
                                                                        }
                                                                    })()
                                                                }

                                                                {
                                                                    (() => {
                                                                            if(status !== "Removed"){
                                                                                if(!this.state.showApprovalReason){
                                                                                    return(<MRModalRemoveBtn onClick={this.openRemoval}>Reject</MRModalRemoveBtn>)
                                                                                }else{
                                                                                    return(<MRModalRemoveBtn style={{backgroundColor: "#D4D4D4", color: "#ACACAC", border: "1px solid #D4D4D4",cursor: "default"}}>Reject</MRModalRemoveBtn>)
                                                                                }
                                                                            }
                                                                    })()
                                                                }
                                                                {
                                                                    (() => {
                                                                        if(!this.state.showRemovalReason && !this.state.showApprovalReason){
                                                                            return(<div>
                                                                                <MRModalSaveCloseBtn onClick={this.submitApproveGift(0)}>Save and Close</MRModalSaveCloseBtn>
                                                                                <MRModalCancelBtn onClick={this.closeView}>Cancel</MRModalCancelBtn>
                                                                            </div>)
                                                                        }else{
                                                                            return(<div>
                                                                                <MRModalSaveCloseBtn style={{backgroundColor: "#D4D4D4", color: "#ACACAC", border: "1px solid #D4D4D4",cursor: "default"}}>Save and Close</MRModalSaveCloseBtn>
                                                                                <MRModalCancelBtn style={{backgroundColor: "#D4D4D4", color: "#ACACAC", border: "1px solid #D4D4D4",cursor: "default"}}>Cancel</MRModalCancelBtn>
                                                                            </div>)
                                                                        }
                                                                    })()
                                                                }

                                                            </div>
                                                        )
                                                    }
                                                }
                                            }
                                        }
                                    }
                                }
                            })()
                        }
                    <div style={{clear: "both"}}></div>
                    </MRModalFooter>
            {
                (() => {
                    if(this.state.showRemovalReason){
                        return(<div>
                            <MRModalBody>
                                <hr style={{marginTop: "10px", color: "#E3E4E4"}}/>
                                <div style={{clear: "both"}}></div>
                                <div style={{position: "relative",padding: "35px 35px"}}>
                                    <MRModalLabel style={{marginTop: "25px",display: "inline-block"}}>Result</MRModalLabel><span style={{marginLeft: "5px",color: "#38B8B5"}}>*</span>
                                    <MRModalSelect style={{width: "33%",minWidth: "200px", marginLeft: "10px"}} name="removalType" onChange={this.handleChangeRemoval} value={this.state.removalType} >
                                    <option value="">Select</option>
                                    {
                                        resultType.map((r, index) => {
                                            return (<option key={index} value={r.resultTypeName}>{r.resultTypeName}</option>);
                                        })
                                    }
                                    </MRModalSelect>
                                    {
                                        (() => {
                                            if(this.state.showDescription){
                                                return(
                                                    <div>
                                                        <MRModalLabel style={{marginTop: "25px",display: "inline-block"}}>Reason for Rejection</MRModalLabel><span style={{marginLeft: "5px",color: "#38B8B5"}}>*</span>
                                                        <MRModalTextarea rows="5" name="reasonDescription" value={this.state.reasonDescription} onChange={this.handleChange} />
                                                    </div>
                                                )
                                            }
                                        })()
                                    }
                                </div>
                            </MRModalBody>
                            <MRModalFooter>
                                <div style={{position: "relative",padding: "17px"}}>
                                    <MRModalNextBtn onClick={this.submitReasonRemoval}>Confirm</MRModalNextBtn>
                                    <MRModalCancelBtn onClick={this.closeRemoval}>Cancel</MRModalCancelBtn>
                                </div>
                                <div style={{clear: "both"}}></div>
                            </MRModalFooter>
                        </div>)
                    }
                })()
            }
            {
                (() => {
                    if(this.state.showApprovalReason){
                        return(<div>
                            <MRModalBody>
                                <hr style={{marginTop: "10px", color: "#E3E4E4"}}/>
                                <div style={{clear: "both"}}></div>
                                <div style={{position: "relative",padding: "35px 35px"}}>
                                    <MRModalLabel style={{marginTop: "25px",display: "inline-block"}}>Result</MRModalLabel><span style={{marginLeft: "5px",color: "#38B8B5"}}>*</span>
                                    <MRModalSelect style={{width: "33%",minWidth: "200px", marginLeft: "10px"}} name="removalType" onChange={this.handleChangeRemoval} value={this.state.removalType} >
                                    <option value="">Select</option>
                                    {
                                        resultType.map((r, index) => {
                                            return (<option key={index} value={r.resultTypeName}>{r.resultTypeName}</option>);
                                        })
                                    }
                                    </MRModalSelect>
                                    {
                                        (() => {
                                            if(this.state.showDescription){
                                                return(
                                                    <div>
                                                        <MRModalLabel style={{marginTop: "25px",display: "inline-block"}}>Reason for Approval</MRModalLabel><span style={{marginLeft: "5px",color: "#38B8B5"}}>*</span>
                                                        <MRModalTextarea rows="5" name="reasonDescription" value={this.state.reasonDescription} onChange={this.handleChange} />
                                                    </div>
                                                )
                                            }
                                        })()
                                    }
                                </div>
                            </MRModalBody>
                            <MRModalFooter>
                                <div style={{position: "relative",padding: "17px"}}>
                                    <MRModalNextBtn onClick={this.submitApproveGift(1)}>Confirm</MRModalNextBtn>
                                    <MRModalCancelBtn onClick={this.closeApproval}>Cancel</MRModalCancelBtn>
                                </div>
                                <div style={{clear: "both"}}></div>
                            </MRModalFooter>
                        </div>)
                    }
                })()
            }
        </MRModalContainer>
        <AlertBox alertParam = {this.state.alert_param}/>
        </div>);
    }
}

export default GMGiftsApprovalModal;
