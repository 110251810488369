import React from 'react';
import styled from 'styled-components';
import CSLTable from '../Common/CSLTable';
import { FaPencilAlt,FaEdit, FaTimes,FaPlusCircle } from "react-icons/fa";
import { IoIosCheckboxOutline, IoIosSquareOutline, IoIosWarning } from "react-icons/io";
import ResultConfigModal from './ResultConfigModal';
import Store from '../../Common/Store'
import AlertBox from '../Common/AlertBox'


const SearchInput = styled.input`
    height: 30px;
    width: 98%;
    border: 1px solid #ffffff;
    border-radius: 3px;
    box-shadow: 0 0 4px #c4c4c4;
    padding: 5px;
    color: #222222;
    font-size: 15px;
    font-family: 'Montserrat', sans-serif;
`;
const MRButton = styled.button`
    box-sizing: border-box;
    background-color: #1E3E62;
    color: #ffffff;
    border: 1px solid #1E3E62;
    width: 37%;
    font-size: 13px;
    height: 43px;
    border-radius: 4px;
    display: inline-block;
    margin-left: 5px;
`;
const MRModalLabel = styled.div`
    color: #212121;
    font-weight: 600;
    margin-top: 10px;
    margin-bottom: 10px;
    display: inline-block;
`;
const ExportButton = styled.button`
    box-sizing: border-box;
    background-color: #213E61;
    color: #F2F2F2;
    border: 1px solid #213E61;
    width: 125px;
    font-size: 14px;
    height: 32px;
    border-radius: 5px;
    margin-right: 3px;
    margin-top: 19px;
`;

const EmailContainer = styled.div`
	width: 100%;
`;
const EmailInnerContainer = styled.div`
    background-color: #F4F5F7;
	padding: 30px;
`;
const EmailBoldLabel = styled.div`
	font-weight: 600;
	color: #595959;
	margin-top: 5px;
`;
const EmailTopHeader = styled.div`
	font-weight: 600;
	color: #595959;
	margin-top: 5px;
	width:100%;
	height: 58px;
	padding: 20px;
	border-radius: 4px;
	border: 1px solid #e0e0e0;
	background-color: #ffffff;
	box-sizing: border-box;	
`;

class ResultTypes extends React.Component
{
    state = {
        showActiveCheckbox: false,
        result_types: null,
        show_result_types_array: null,
        result_types_search: null,
        resultActiveArrayAll: null,
        resultInactiveArrayAll: null,
        ready: false,
        show_result_type_modal: false,
        standard_result_types: null,
        curid: 0,
        content: null,
        alert_param: null
    };
    constructor(props) {
        super(props);        
    }

    componentDidMount()
	{		
		let resultActiveArrayAll = [];		
		let resultInactiveArrayAll = [];
		for(let i=0;i<this.props.result_types.length;i++){
			if(this.props.result_types[i].isActive === 1){
				resultActiveArrayAll.push(this.props.result_types[i])
			}else{
				resultInactiveArrayAll.push(this.props.result_types[i])
			}
		}
		let resultValue = [];
		if(this.props.general.result_type_active_inactive){
			resultValue = resultInactiveArrayAll
			console.log("Sourav")
		}else{			
			resultValue = resultActiveArrayAll
			console.log("Sourav1")
		}
		this.setState({showActiveCheckbox: this.props.general.result_type_active_inactive,
			result_types: this.props.result_types,
			show_result_types_array: resultValue,
			result_types_search: this.props.result_types,
			resultActiveArrayAll: resultActiveArrayAll,
            resultInactiveArrayAll: resultInactiveArrayAll,
            standard_result_types: this.props.standard_result_types,
            ready: true});
		console.log("reason_for_removal===>",this.props.result_types);
    }
    
    componentDidUpdate(prevProps)
	{
		if (prevProps !== this.props) {
            let resultActiveArrayAll = [];		
            let resultInactiveArrayAll = [];
            for(let i=0;i<this.props.result_types.length;i++){
                if(this.props.result_types[i].isActive === 1){
                    resultActiveArrayAll.push(this.props.result_types[i])
                }else{
                    resultInactiveArrayAll.push(this.props.result_types[i])
                }
            }
            let resultValue = [];
            if(this.props.general.result_type_active_inactive){
                resultValue = resultInactiveArrayAll
                console.log("Sourav")
            }else{			
                resultValue = resultActiveArrayAll
                console.log("Sourav1")
            }
            this.setState({showActiveCheckbox: this.props.general.result_type_active_inactive,
                result_types: this.props.result_types,
                show_result_types_array: resultValue,
                result_types_search: this.props.result_types,
                resultActiveArrayAll: resultActiveArrayAll,
                resultInactiveArrayAll: resultInactiveArrayAll,
                standard_result_types: this.props.standard_result_types,
                ready: true});
            console.log("reason_for_removal===>",this.props.result_types);			
		}
    }
    
    searchValue = (event) => {
		event.preventDefault();
        console.log("Sourav",event.target.value);
		console.log("fddsf",this.state.result_types_search)
		if(!this.state.showActiveCheckbox){
			let search_string = event.target.value;
			if (search_string === "") {
				let result_type = this.state.resultActiveArrayAll;            
				this.setState({show_result_types_array: result_type});
			}else {
				let all_value = this.state.resultActiveArrayAll;
				let filtered_values = [];
				all_value.forEach((result) => {
					if (result.resultTypeName.toLowerCase().includes(search_string.toLowerCase())) {
						filtered_values.push(result);
					}                
				})            
				let result_type = filtered_values;

				this.setState({show_result_types_array: result_type})
			}
		}else{
			let search_string = event.target.value;
			if (search_string === "") {
				let result_type = this.state.resultInactiveArrayAll;            
				this.setState({show_result_types_array: result_type});
			}else {
				let all_value = this.state.resultInactiveArrayAll;
				let filtered_values = [];
				all_value.forEach((result) => {
					if (result.resultTypeName.toLowerCase().includes(search_string.toLowerCase())) {
						filtered_values.push(result);
					}                
				})            
				let result_type = filtered_values;

				this.setState({show_result_types_array: result_type})
			}
		}
    }
    
    handleActiveInactive = (value) => (event) => {        
        console.log("value",value)  
		let data = value === 1 ? true : false;
		this.props.changeActiveInactive(data);		       
    }

    tableData = () => {
        console.log("this.state.show_result_types_array",this.state.show_result_types_array)
        let ret = {data: [], columns: []};
        ret.columns =[
                        {Header: 'Name', accessor: 'name', minWidth: 120, headerStyle: {textAlign: 'left'}},
                        {'Header' : 'Req. Comments', accessor: 'commentsreq', Cell: row => (
							<div>
								   {
									   (()=> {
										   if (row.original.req_comments === 1) {
											   return(<div style={{fontSize:"21px", float: "left"}} ><IoIosCheckboxOutline /></div>);
										   } else {
											   return(<div  style={{fontSize:"21px", float: "left"}} ><IoIosSquareOutline /></div>);
										   }
									   })()
								   }
							</div>
						 ), headerStyle:{textAlign: 'center'}},					
						{Header: 'Status', accessor: 'status', minWidth: 80, headerStyle: {textAlign: 'left'}},
						{'Header' : 'Action', accessor: 'action', Cell: row => (
							<div>
								<FaPencilAlt  onClick={this.openModal(row.original.id)} style={{cursor: 'pointer',fontSize: '17px',color: '#b4b5b4',display :'inline-block',marginRight: "5px"}} /> 
								{
									(() => {
										if(row.original.isdelete === 0){
											return(<FaTimes onClick={this.deleteResultType(row.original.id)} style={{cursor: 'pointer',fontSize: '18px',color: '#b4b5b4',display :'inline-block'}}/>)
										}else{
											return(<FaTimes onClick={this.notDeleteResultType} style={{cursor: 'pointer',fontSize: '18px',color: '#b4b5b4',display :'inline-block'}}/>)
										}
									})()
								}
								
							</div>
						  ), width: 60, headerStyle: {textAlign: 'center'}}, 
                        ];

        let result_type_sort_array = this.state.show_result_types_array.sort(function(a,b) {return (a.resultTypeName > b.resultTypeName) ? 1 : ((b.resultTypeName > a.resultTypeName) ? -1 : 0);} );
		result_type_sort_array.forEach((item) => {
			let statusValue = item.isActive === 1 ? "Active" : "Inactive"
			let elem = {id: item.resultTypeID, name: item.resultTypeName, req_comments: item.isComments, status: statusValue, isdelete: item.isDelete};
			ret.data.push(elem);
        })    

        let client_result_types_export = [];        
        Object.keys(this.props.result_types).map((d, key) => {
			//console.log("Key",key)
			let resultTypesObjectexport = {};			
            resultTypesObjectexport.type = this.props.result_types[d].resultTypeName;            
            resultTypesObjectexport.status = this.props.result_types[d].isActive == 1 ? "Active" : "Inactive";
            resultTypesObjectexport.id = d;
			client_result_types_export.push(resultTypesObjectexport);
		})        
        console.log("client_result_types_export",client_result_types_export);
        Store.updateStore('table_result_cur_data', client_result_types_export) 
        return ret;
    }
    
    samealertremoveHandler = (result,stack) => {
        this.setState({alert_param: null})
    }
    
    notDeleteResultType = () => {
        let msg = '';
        let title = 'Alert';
        msg = 'This Result Type has already been used.'
// 		alert("This Result Type has already been used.")
        let alert_param = {title: title, message: msg, ok_text: 'OK', confirm: false, alertHandler: this.samealertremoveHandler, stack: {id: 0}}
        this.setState({alert_param: alert_param})
	}

    deleteResultType = (id) => (event) => {
        event.preventDefault(); 
        let msg = 'Are you sure you want to delete this Result Type from the list?'
        let title = 'Confirm'
        let alert_param = {title: title, message: msg, ok_text: 'Yes', cancel_text: 'No', confirm: true, alertHandler: this.deleteResultTypeAlert, stack: {id: id}}
        this.setState({alert_param: alert_param})                   
//         if (window.confirm('Are you sure you want to delete this Result Type from the list?')) {
//           	console.log("id",id)
//           	let {result_types} = this.state;
// 			let newreason = [];
// 			for(let i=0;i<this.state.result_types.length;i++)
// 			{
// 				if(this.state.result_types[i].resultTypeID !== id){
// 					newreason.push(this.state.result_types[i])
// 				}
// 			}			 
// 			console.log("delete===>",newreason);   
// 			this.props.deleteResultTypeValue(newreason);        
// 		}         		  
    }
    
    deleteResultTypeAlert = (result, stack) => {
        if(!result) {
            this.setState({alert_param: null})
            return
        }
        console.log("id",stack.id)
        let {result_types} = this.state;
		let newreason = [];
		for(let i=0;i<this.state.result_types.length;i++)
		{
			if(this.state.result_types[i].resultTypeID !== stack.id){
				newreason.push(this.state.result_types[i])
			}
		}			 
		console.log("delete===>",newreason);   
		this.props.deleteResultTypeValue(newreason);
		this.setState({alert_param: null})
    }

    openModal = (id) => (event) => {
		event.preventDefault();     
		console.log("id",id); 
		const content = this.state.result_types.find((item) => {
			return item.resultTypeID === id;
		})
		this.setState({content,curid: id,show_result_type_modal: true});        
	}
    
    addResultType = (event) => {
		event.preventDefault();
		this.setState({show_result_type_modal: true,curid: 0})
    }
    closeModal = () => {		
		this.setState({show_result_type_modal: false})
    }
    
    saveResultTypeValue = (obj) => {
		this.setState({show_result_type_modal: false})
		this.props.saveResultValue(obj)
    }
    
    // importType = (event) => {
    //     event.preventDefault();
    //     console.log("standard_result_types",this.state.standard_result_types)
    //     if (window.confirm('Are you sure you want to import the Result Types?')) {
    //         let show_result_types_array = this.state.result_types;
    //         this.state.standard_result_types.forEach((result) => {
    //             show_result_types_array.push(result);               
    //         })
    //         this.props.importResultValue(show_result_types_array)            
    //     }
    // }

    updateResultTypeValue = (content) => {
		console.log("content:", content);
		//console.log("this.state",this.state.reason_for_removal)
		let {result_types} = this.state;
		let newreason = [];
		result_types.forEach((item) => {
			if (item.resultTypeID === content.resultTypeID) {
				item = content;
				console.log("matched", item);
			}
			newreason.push(item);
		})
		console.log("resons:", newreason);
		this.setState({show_result_type_modal: false})
		this.props.updateResultValue(newreason);		
	}
    
    
    render()    
	{
        if (!this.state.ready) {
			return (<div>Loading...</div>);
		}          
		return(
        <div>            
            <div style={{width: "100%"}}>
                {
                    (() => {
                        if (this.state.show_result_type_modal) {
                            return (<ResultConfigModal closeModal={this.closeModal} curid={this.state.curid} content={JSON.parse(JSON.stringify(this.state.content))} updateResultTypeValue={this.updateResultTypeValue} saveResultTypeValue={this.saveResultTypeValue}/>);
                        }
                    })()
                }
                    <div style={{padding: "30px 30px 0px 30px", backgroundColor: "#F4F5F7"}}>    
                        <div>
                            <SearchInput style={{width: "100%",marginTop: "2px"}} placeholder="Search..." onChange={this.searchValue}/>
                        </div>
                                       
                        <MRModalLabel>Show Inactive?</MRModalLabel>
                        {
                            (() => {
                                if(!this.state.showActiveCheckbox){
                                    return(<div  style={{cursor: "pointer", fontSize:"21px",display: "inline-block",marginLeft: "5px"}} onClick={this.handleActiveInactive(1)} ><IoIosSquareOutline /></div>)
                                }else{
                                    return(<div  style={{cursor: "pointer", fontSize:"21px",display: "inline-block",marginLeft: "5px"}} onClick={this.handleActiveInactive(0)} ><IoIosCheckboxOutline /></div>)
                                }
                            })()
                        }  
                    </div>                   
                        <EmailInnerContainer>                            
                            <EmailTopHeader style={{display: "inline-block" , fontWeight: 700}}>
                                Configure Options
                                <div style={{float:'right'}}>
                                    <div onClick={this.addResultType} style={{float: "left", cursor: "pointer",fontSize: "20px",color: "#04ADA8"}}>
                                        <FaPlusCircle  /> 
                                    </div>	
                                </div>
                            </EmailTopHeader>								
                            <CSLTable add={false} processData={this.tableData} tableRows="10" />
                        </EmailInnerContainer>                    
            </div>
            <AlertBox alertParam = {this.state.alert_param}/>
        </div>
        );
	}
}

export default ResultTypes;