let currency_codes = [
    { code: "AED", currency: "UAE Dirham" },        
    { code: "AMD", currency: "Armenian Dram" },        
    { code: "ANG", currency: "Netherlands Antillean Guilder" },        
    { code: "ARS", currency: "Argentine Peso" },        
    { code: "AUD", currency: "Australian Dollar" }, 

    { code: "BBD", currency: "Barbados Dollar" },        
    { code: "BGN", currency: "Bulgarian Lev" },        
    { code: "BHD", currency: "Bahraini Dinar" },        
    { code: "BMD", currency: "Bermudian Dollar" },        
    { code: "BND", currency: "Brunei Dollar" },        
    { code: "BOB", currency: "Boliviano" },        
    { code: "BSD", currency: "Belarusian Ruble" },  

    { code: "CAD", currency: "Canadian Dollar" },        
    { code: "CDF", currency: "Congolese Franc" },        
    { code: "CHF", currency: "Swiss Franc" },        
    { code: "CLP", currency: "Chilean Peso" },        
    { code: "CNY", currency: "Yuan Renminbi" },        
    { code: "CUP", currency: "Cuban Peso" },        
    { code: "CZK", currency: "Czech Koruna" },

    { code: "DKK", currency: "Danish Krone" },        
    { code: "DOP", currency: "Dominican Peso" },        
    { code: "DZD", currency: "Algerian Dinar" }, 

    { code: "EGP", currency: "Egyptian Pound" },        
    { code: "EUR", currency: "Euro" },

    { code: "FJD", currency: "Fiji Dollar" },   

    { code: "GBP", currency: "Pound Sterling" },        
    { code: "GIP", currency: "Gibraltar Pound" },

    { code: "HKD", currency: "Hong Kong Dollar" },        
    { code: "HRK", currency: "Kuna" },        
    { code: "HUF", currency: "Forint" },

    { code: "IDR", currency: "Rupiah" },        
    { code: "ILS", currency: "New Israeli Sheqel" },        
    { code: "INR", currency: "Indian Rupee" },        
    { code: "ISK", currency: "Iceland Krona" },

    { code: "JMD", currency: "Jamaican Dollar" },        
    { code: "JOD", currency: "Jordanian Dinar" },

    { code: "KPW", currency: "North Korean Won" },        
    { code: "KRW", currency: "Won" },        
    { code: "KWD", currency: "Kuwaiti Dinar" },   

    { code: "LBP", currency: "Lebanese Pound" },        
    { code: "LKR", currency: "Sri Lanka Rupee" },        
    { code: "LRD", currency: "Liberian Dollar" },        
    { code: "LYD", currency: "Libyan Dinar" },

    { code: "MAD", currency: "Moroccan Dirham" },        
    { code: "MDL", currency: "Moldovan Leu" },        
    { code: "MUR", currency: "Mauritius Rupee" },        
    { code: "MXN", currency: "Mexican Peso" },        
    { code: "MYR", currency: "Malaysian Ringgit" },        
    { code: "MZN", currency: "Mozambique Metical" },

    { code: "NAD", currency: "Namibia Dollar" },        
    { code: "NOK", currency: "Norwegian Krone" },        
    { code: "NZD", currency: "New Zealand Dollar" },

    { code: "OMR", currency: "Rial Omani" }, 

    { code: "PKR", currency: "Pakistan Rupee" },        
    { code: "PLN", currency: "Zloty" },

    { code: "QAR", currency: "Qatari Rial" },

    { code: "RON", currency: "Romanian Leu" },
    { code: "RSD", currency: "Serbian Dinar" },        
    { code: "RUB", currency: "Russian Ruble" },

    { code: "SAR", currency: "Saudi Riyal" },        
    { code: "SCR", currency: "Seychelles Rupee" },        
    { code: "SDG", currency: "Sudanese Pound" },        
    { code: "SEK", currency: "Swedish Krona" },        
    { code: "SGD", currency: "Singapore Dollar" },        
    { code: "SLL", currency: "Leone" },        
    { code: "SOS", currency: "Somali Shilling" },
    { code: "SRD", currency: "Surinam Dollar" },        
    { code: "SSP", currency: "South Sudanese Pound" }, 

    { code: "TND", currency: "Tunisian Dinar" },        
    { code: "TRY", currency: "Turkish Lira" },        
    { code: "TWD", currency: "New Taiwan Dollar" },        
    { code: "TZS", currency: "Tanzanian Shilling" }, 

    { code: "UAH", currency: "Hryvnia" },        
    { code: "UGX", currency: "Uganda Shilling" },        
    { code: "USD", currency: "US Dollar" },
    { code: "UZS", currency: "Uzbekistan Sum" },  

    { code: "VES", currency: "Bolívar Soberano" },        
    { code: "VND", currency: "Dong" },   

    { code: "ZAR", currency: "Rand" },        
    { code: "ZMW", currency: "Zambian Kwacha" },        
    { code: "ZWL", currency: "Zimbabwe Dollar" }
  ]
module.exports = currency_codes