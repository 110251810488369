import React, { Component } from 'react'
import styled from "styled-components";

const PageBtn = styled.button`
	display: inline-block;
	box-sizing: border-box;
	color: #1A73E8;
	border-radius: 4px;
	font-size: 10px;
	margin-left: 10px;
	float: left;
	cursor: pointer;
  	background-color: #ffffff;
  	border: 1px solid #1A73E8;
  	padding: 9px;
`;

export default class Pagination extends Component {
state = {data : [] , Pagination_container : [] , currentPageindex : 0 , show_Data:3}
constructor(props){
    super(props)
}

componentDidMount(){
    let data = this.props.data
    let arr = [0]
    let datafiltered
    if(this.props.nested){
     datafiltered = data.filter((elem)=>elem[Object.keys(elem)[0]].parent)
    }else{
        datafiltered = data
    }
    console.log('datafiltered' ,datafiltered)
    datafiltered.map((elem , index)=>{
        if(((index +1) % this.state.show_Data === 0 && (index+1 < datafiltered.length))){
            arr.push(Object.keys(datafiltered[index+1])[0])
        }
    })
    console.log('arr****' , arr)
    this.setState({Pagination_container : arr , data})
    this.firstpageload(arr[1])
}

changePage = (arrelem , arr , index)=>{
    this.setState({currentPageindex:index})
    console.log('data~~~~~~~~~~~~~~' , this.state.data)

    if(index+1 === arr.length){
        this.props.changePage(arrelem , this.state.data.length)
    }else{
        this.props.changePage(arrelem , arr[index+1])
    }

}
firstpageload = (p)=>{
    this.props.changePage(0 , p)
}
btnStyle = (i , pg_arr)=>{
    let display = pg_arr.length > 1 ?'': 'none' 
    if(i === this.state.currentPageindex){
        let backgroundColor ='#1A73E8'
        let color = '#ffffff'
        return {backgroundColor , color , display}
    }else{
        return {display}
    }
}
  render() {
    
    return (
      <div style={{display:'flex'}}>
          {this.state.Pagination_container.map((elem , i)=>{
              return(
                <PageBtn onClick={()=>this.changePage(elem , this.state.Pagination_container , i)} style={this.btnStyle(i , this.state.Pagination_container)}>{i+1}</PageBtn>
               )
          })}
       
     </div>
    )
  }
}
