import React from 'react';
import styled from 'styled-components';
import Utils from '../../Common/Utils';
import { FaPlusCircle} from 'react-icons/fa';
import { Container, Draggable } from 'react-smooth-dnd';
import CSLToggle from './../Common/CSLToggle';
import VEllipsisMenuFolder from "./../Common/VerticalEllipsisMenuFolder";
import AddEditCustomField from './AddEditCustomField';
import AlertBox from '../Common/AlertBox';

const EmailContainer = styled.div`
	width: 100%;
`;
const EmailInnerContainer = styled.div`
	background-color: #F4F5F7;
	padding: 30px;
`;
const EmailBoldLabel = styled.div`
	font-weight: 600;
	color: #595959;
	margin-top: 5px;
`;
const SubHeading = styled.div`
	font-style: italic;
	font-size: 12px;
	color: #adacac;
	margin-top: 5px;
`;
const EmailLabel = styled.div`
	color: #595959;
	margin-top: 5px;
`;
const ConfigBtn = styled.button`
    box-sizing: border-box;
    background-color: #37ada7;
    color: #ffffff;
    border: 1px solid #37ada7;
    font-size: 13px;
    border-radius: 4px;
    padding: 5px 20px;
    cursor: pointer;
`;
const SelectedRadio = styled.div`
	background-color: #37ADA7;
	border: 1px solid #1f615e;
	padding: 5px 10px;
	color: #ffffff;
	display: inline-block;
	cursor: pointer;
`;
const UnselectedRadio = styled.div`
	background-color: #F4F4F4;
	border: 1px solid #8c8c8c;
	padding: 5px 10px;
	color: #262626;
	display: inline-block;
	cursor: pointer;
`;
const RadioBlock = styled.div`
	margin-top: 10px;
`;
const Clearfix = styled.div`
	clear: both;
`;
const FloatingSection = styled.div`
	float: left;
	box-sizing: border-box;
`;
const BoldLabel = styled.div`
	font-weight: 600;
`;
const FooterButtonsContainer = styled.div`
	text-align: right;
	padding-top: 25px;
	width: 90%;
`;
const CancelBtn = styled.button`
	padding: 10px 20px;
	background-color: #ffffff;
	border: 1px solid #ffffff;
	border-radius: 2px;
	color: #333333;
	cursor: pointer;
`;
const SaveBtn = styled.button`
	padding: 10px 30px;
	background-color: #37ADA7;
	border: 1px solid #37ADA7;
	border-radius: 2px;
	color: #ffffff;
	cursor: pointer;
	margin-left: 15px;
`;

const SerialHeader = styled.div`
  text-align: center;
  flex: 30 0 auto;
  width: 4%;
  max-width: 4%;
  cursor: pointer;
  padding: 5px 5px;
  line-height: normal;
  position: relative;
  border: 1px solid rgba(0, 0, 0, 0.05);
  transition: box-shadow .3s cubic-bezier(.175, .885, .32, 1.275);
  box-shadow: inset 0 0 0 0 transparent;
  white-space: nowrap;
  text-overflow: ellipsis;
  -webkit-box-flex: 1;
  font-weight: bold;
  height: 35px;
`;

const FieldNameTitleHeader = styled.div`
  text-align: left;
  flex: 100 0 auto;
//   width: 160px;
  min-width: 30%;
  cursor: pointer;
  overflow: visible;
  padding: 5px 5px;
  line-height: normal;
  position: relative;
  border: 1px solid rgba(0, 0, 0, 0.05);
  transition: box-shadow .3s cubic-bezier(.175, .885, .32, 1.275);
  box-shadow: inset 0 0 0 0 transparent;
  white-space: nowrap;
  text-overflow: ellipsis;
  -webkit-box-flex: 1;
  height: 35px;
  font-weight: bold;
`;

const FieldTypeTitleHeader = styled.div`
  text-align: left;
  flex: 100 0 auto;
//   width: 140px;
  min-width: 16%;
  cursor: pointer;
  overflow: visible;
  padding: 5px 5px;
  line-height: normal;
  position: relative;
  border: 1px solid rgba(0, 0, 0, 0.05);
  transition: box-shadow .3s cubic-bezier(.175, .885, .32, 1.275);
  box-shadow: inset 0 0 0 0 transparent;
  white-space: nowrap;
  text-overflow: ellipsis;
  -webkit-box-flex: 1;
  height: 35px;
  font-weight: bold;
`;
const DisplayForTitleHeader = styled.div`
  text-align: left;
  flex: 100 0 auto;
//   width: 80px;
  min-width: 16%;
  cursor: pointer;
  overflow: visible;
  padding: 5px 5px;
  line-height: normal;
  position: relative;
  border: 1px solid rgba(0, 0, 0, 0.05);
  transition: box-shadow .3s cubic-bezier(.175, .885, .32, 1.275);
  box-shadow: inset 0 0 0 0 transparent;
  white-space: nowrap;
  text-overflow: ellipsis;
  -webkit-box-flex: 1;
  height: 35px;
  font-weight: bold;
`;
const RequiredTitleHeader = styled.div`
  text-align: left;
  flex: 100 0 auto;
width: 30px;
min-width: 13%;
  cursor: pointer;
  overflow: visible;
  padding: 5px 5px;
  line-height: normal;
  position: relative;
  border: 1px solid rgba(0, 0, 0, 0.05);
  transition: box-shadow .3s cubic-bezier(.175, .885, .32, 1.275);
  box-shadow: inset 0 0 0 0 transparent;
  white-space: nowrap;
  text-overflow: ellipsis;
  -webkit-box-flex: 1;
  height: 35px;
  font-weight: bold;
`;

const ActionHeader = styled.div`
  text-align: center;
  flex: 55 0 auto;
//   width: 70px;
	min-width: 7%;
//   max-width: 55px;
  overflow: hidden;
  cursor: pointer;
  white-space: nowrap;
  text-overflow: ellipsis;
  padding: 5px 5px;
  line-height: normal;
  position: relative;
  border: 1px solid rgba(0, 0, 0, 0.05);
  transition: box-shadow .3s cubic-bezier(.175, .885, .32, 1.275);
  box-shadow: inset 0 0 0 0 transparent;
  height: 35px;
  font-weight: bold;
`;

const ElipsisHeader = styled.div`
  text-align: center;
  flex: 55 0 auto;
//   width: 20px;
//   max-width: 20px;
  min-width: 4%;
  overflow: hidden;
  cursor: pointer;
  white-space: nowrap;
  text-overflow: ellipsis;
  padding: 5px 5px;
  line-height: normal;
  position: relative;
  border: 1px solid rgba(0, 0, 0, 0.05);
  transition: box-shadow .3s cubic-bezier(.175, .885, .32, 1.275);
  box-shadow: inset 0 0 0 0 transparent;
  height: 35px;
  font-weight: bold;
`;


const TypesBoldLabel = styled.div`
  font-weight: 600;
  color: #595959;
  margin-top: 4px;
  display: "inline-block";  
  margin-left: '12px'
`;

const AddButton = styled.div`
  width: 30px;
  height: 30px;
  margin: 0px;
  float: right;
  padding-left: 10px;
  font-size: 16px;
  cursor: pointer;
`;
const InactiveOverlay = styled.div`
  display: block;
  box-sizing: border-box;
  height: 150%;
  width: 100%;
  background-color: #ffffff;
  position: absolute;
  left: -2%;
  top: -46px;
  z-index: 1000;
  opacity: 0.8;
`;
const EmailTopHeader = styled.div`
	font-weight: 600;
	color: #595959;
	margin-top: 5px;
	width:100%;
	height: 58px;
	padding: 20px;
	border-radius: 4px;
	border: 1px solid #e0e0e0;
	background-color: #ffffff;
	box-sizing: border-box;	
`;
class CustomFields extends React.Component
{
	state = {custom_fields: null, final_custom_field: null, arrayAdditionalField: {}, show_add_edit_custom_modal: false, curid: 0, content: null, alert_param: null};

	componentDidMount()
	{
		let arrayAdditionalField = Object.values(this.props.custom_fields.additional_fields);
		console.log("arrayAdditionalField==>", arrayAdditionalField)
		this.setState({custom_fields: this.props.custom_fields, arrayAdditionalField,final_custom_field: this.props.custom_fields});
	}

	componentDidUpdate(prevProps)
	{
		if (this.props !== prevProps) {
			let arrayAdditionalField = Object.values(this.props.custom_fields.additional_fields);
			//console.log("arrayAdditionalField==>", arrayAdditionalField)
			this.setState({custom_fields: this.props.custom_fields, arrayAdditionalField, final_custom_field: this.props.custom_fields});
		}
	}

	switchRadio = (key) => (event) => {
		event.preventDefault();
		let custom_fields = JSON.parse(JSON.stringify(this.state.custom_fields));
		custom_fields[key] = custom_fields[key] ? false : true;
		//this.setState({custom_fields, final_custom_field: custom_fields});
		this.props.updateCustomFields(custom_fields);
	}	

	addAdditionalField = (event) => {
		event.preventDefault();
		console.log("addAdditionalField");
		let custom_fields = JSON.parse(JSON.stringify(this.state.custom_fields));
		// const utils = new Utils();
		const randtxt = Utils.genKey(10);
		custom_fields.additional_fields.push({name: randtxt, nickname: "Untitled Field", type: "Alpha", dropdown_options: [], required: false, other: false});
		this.setState({custom_fields, final_custom_field: custom_fields});
		//this.props.updateCustomFields(custom_fields);
	}

	updateCustomFieldsAll = (custom) => {
		console.log("custom",custom)
		this.setState({final_custom_field: custom})
	}

	updateCustomFieldsAllNew = (custom) => {
		console.log("custom",custom)
		this.setState({custom_fields: custom, final_custom_field: custom})
	}

	closePage = () => {
		this.props.closePage()
	}

	updateCustomModuleconfig = () => {
		console.log("sourav")
		this.props.updateCustomFields(this.state.final_custom_field)
	}
	getKey() {
        return this.keyCount++;
    }
	toggleStatus = (state, id) => { 
		this.state.arrayAdditionalField[id.toString()].isActive = state ? 1 : 0;
		this.props.updateDragCustomField(this.factory(this.state.arrayAdditionalField));
    }

	factory = (a) => {
        //debugger;
        const obj = {}
        let c = a.forEach((q, i) => {
            obj[i + 1] = q
        })
        return obj;
    }

	applyDrag = (arr, dragResult) => {
        console.log("arr===>", arr);
        console.log("dragResult===>", dragResult);
        const { removedIndex, addedIndex, payload } = dragResult;
        if (removedIndex === null && addedIndex === null) return arr;

        const result = [...arr];
        let itemToAdd = payload;

        if (removedIndex !== null) {
            itemToAdd = result.splice(removedIndex, 1)[0];
        }

        if (addedIndex !== null) {
            result.splice(addedIndex, 0, itemToAdd);
        }

        // //apply to module config		
        this.props.updateDragCustomField(this.factory(result));		
        return result;
    };

	saveAdditionalFieldsValue = (obj) => {

		let arrayAdditionalField = this.state.arrayAdditionalField;
		arrayAdditionalField.push(obj)
		this.setState({arrayAdditionalField, show_add_edit_custom_modal: false})
		this.props.updateSaveCustomAdditionalField(this.factory(arrayAdditionalField));			
    }

	updateAdditionalFieldsValue = (content) => {
		console.log("content:", content);
		//console.log("this.state",this.state.reason_for_removal)
		let {arrayAdditionalField} = this.state;
		let newreason = [];
		arrayAdditionalField.forEach((item) => {
			if (item.name === content.name) {
				item = content;			
			}
			newreason.push(item);
		})
		console.log("resons:", newreason);
		this.setState({arrayAdditionalField: newreason, show_add_edit_custom_modal: false})
		this.props.updateCustomAdditionalField(this.factory(newreason));			
	}

	addfield = (event) => {
        event.preventDefault();
        this.setState({ show_add_edit_custom_modal: true, curid: 0 });
    }

	closeModal = () => {		
		this.setState({show_add_edit_custom_modal: false})
    }

	editDelete = (curid) => (obj) => {
		console.log("curid==>", curid)
		console.log("obj==>", obj)
		if(obj === 'Edit Custom Field'){
			const content = this.state.arrayAdditionalField.find((item) => {
				return item.name === curid;
			})
			this.setState({content,curid: curid,show_add_edit_custom_modal: true});  
		}else if(obj === 'Delete'){
			let alert_param = {title: 'Warning', message: 'Are you sure you want to delete this custom field?', ok_text: 'Yes', cancel_text: 'No', confirm: true, 
						alertHandler: this.updatedeleteCustomField, stack: {id: curid}}
			this.setState({alert_param: alert_param})
			// if (window.confirm('Are you sure you want to delete this custom field?')) {
			// 	console.log("id",curid)				
			// 	let newreason = [];
			// 	for(let i=0;i<this.state.arrayAdditionalField.length;i++)
			// 	{
			// 		if(this.state.arrayAdditionalField[i].name !== curid){
			// 			newreason.push(this.state.arrayAdditionalField[i])
			// 		}
			// 	}			 
			//  	console.log("delete===>",newreason); 
			// 	this.setState({arrayAdditionalField: newreason})
			// 	this.props.deleteCustomAdditionalField(this.factory(newreason));  				        
		 	// }
		}
	  };

	  updatedeleteCustomField = (result, stack) => {  
        if(!result) {
            this.setState({alert_param: null})
            return
        }
        console.log("id",stack.id)           	
        let newreason = [];
		for(let i=0;i<this.state.arrayAdditionalField.length;i++)
		{
			if(this.state.arrayAdditionalField[i].name !== stack.id){
				newreason.push(this.state.arrayAdditionalField[i])
			}
		}			 
		console.log("delete===>",newreason); 
		this.setState({alert_param: null})  
		this.props.deleteCustomAdditionalField(this.factory(newreason));  		
    }

	render()
	{
		if (this.state.custom_fields === null) {
			return (<div>Loading...</div>);
		}
		return (
			<div>
				{
                    (() => {
                        if (this.state.show_add_edit_custom_modal) {
                            return (<div style={{position:'absolute', left:'2%', top:'50px', width:'100%', height:'600px'}}><InactiveOverlay /><AddEditCustomField closeModal={this.closeModal} data={this.state.custom_fields} curid={this.state.curid} saveAdditionalFieldsValue={this.saveAdditionalFieldsValue} content={JSON.parse(JSON.stringify(this.state.content))} updateAdditionalFieldsValue={this.updateAdditionalFieldsValue}/></div>);
                        }
                    })()
                }
				<EmailContainer>
					<EmailInnerContainer>
						<EmailBoldLabel>Enable Custom Fields</EmailBoldLabel>
						<SubHeading>Configure customisable fields to be utilised within the Gift process</SubHeading>
						{
							(() => {
								if (this.state.custom_fields.enabled) {
									return (
										<RadioBlock style={{marginBottom: "30px"}}>
											<SelectedRadio>Yes</SelectedRadio>
											<UnselectedRadio onClick={this.switchRadio('enabled')}>No</UnselectedRadio>																						
										</RadioBlock>
									);
								} else {
									return (
										<RadioBlock style={{marginBottom: "30px"}}>											
											<UnselectedRadio onClick={this.switchRadio('enabled')}>Yes</UnselectedRadio>		
											<SelectedRadio>No</SelectedRadio>									
										</RadioBlock>
									);
								}
							})()
						}
						<div styled={{clear:"both"}}></div>
						{
							(() => {
								if (this.state.custom_fields.enabled) {
									return(<div>
										{/* <div style={{ display: "flex", justifyContent: 'space-between' }}>
											<div>
												<TypesBoldLabel>{"Custom Fields"}</TypesBoldLabel>								
											</div>
											<div style={{cursor: 'pointer',fontSize: '17px',marginRight: '15px',color: "#04ADA8"}} onClick={this.addfield}>
												<FaPlusCircle title={"Custom Fields"} />
											</div>
										</div> */}
										<EmailTopHeader style={{display: "inline-block" , fontWeight: 700}}>
											Custom Fields
											<div style={{float:'right'}}>
												<div onClick={this.addfield} style={{float: "left", cursor: "pointer",fontSize: "20px",color: "#04ADA8"}}>
													<FaPlusCircle title={"Custom Fields"} />
												</div>	
											</div>
										</EmailTopHeader>	
										{
											(() => {
												if(this.state.arrayAdditionalField.length !== 0){
													return(<div style={{ display: "flex", backgroundColor: '#F5F5FD'}}>
													<SerialHeader>#</SerialHeader>
													<FieldNameTitleHeader style={{width: "20%"}}>Field Name</FieldNameTitleHeader>							
													<FieldTypeTitleHeader >Field Type</FieldTypeTitleHeader>							
													<DisplayForTitleHeader>Display For</DisplayForTitleHeader>							
													<RequiredTitleHeader>Required?</RequiredTitleHeader>							
													<ActionHeader style={{borderRight: "none"}}>Status</ActionHeader>
													<ElipsisHeader style={{borderLeft: "none"}}></ElipsisHeader>
												</div>)
												}
											})()
										}
																
										<Container
											onDrop={e => this.setState({ arrayAdditionalField: this.applyDrag(this.state.arrayAdditionalField, e) })}
											>
											{(this.state.arrayAdditionalField).map((q, i) => {                                        
												return (
													<Draggable>
														<div key={this.getKey()} style={{ display: 'flex' }}>
															<SerialHeader style={{fontWeight: 400, color: '#282828', backgroundColor: '#ffffff', height: "auto"}}>
																{i + 1}
															</SerialHeader>
															<FieldNameTitleHeader style={{backgroundColor: 'white',borderBottom: '0',whiteSpace: 'pre-wrap',wordBreak: 'break-all', fontWeight: "unset", width: "20%", height: "auto"}}>
																{q.nickname}
															</FieldNameTitleHeader>
															<FieldTypeTitleHeader style={{backgroundColor: 'white',borderBottom: '0',whiteSpace: 'pre-wrap',wordBreak: 'break-all', fontWeight: "unset", width: "8%", height: "auto"}}>
																{q.type}
															</FieldTypeTitleHeader>
															<DisplayForTitleHeader style={{backgroundColor: 'white',borderBottom: '0',whiteSpace: 'pre-wrap',wordBreak: 'break-all', fontWeight: "unset", height: "auto"}}>
																{q.displayFor === 'received' ? 'Received' : q.displayFor === 'both' ? 'Both':'Given'}
															</DisplayForTitleHeader>
															<RequiredTitleHeader style={{backgroundColor: 'white',borderBottom: '0',whiteSpace: 'pre-wrap',wordBreak: 'break-all', fontWeight: "unset", height: "auto"}}>
																{q.required === true ? 'Yes' : 'No'}
															</RequiredTitleHeader>
															<ActionHeader style={{backgroundColor: 'white',borderBottom: '0',whiteSpace: 'pre-wrap',wordBreak: 'break-all', fontWeight: "unset", borderRight: "none", height: "auto"}}>
																<AddButton
																	style={{
																		marginRight: '30px',
																		display: 'flex',
																		alignItems: 'center',
																		justifyContent: 'center',
																		width: '14px'
																	}}>
																	<CSLToggle
																		checked={q.isActive === 1 ? true : false}
																		id={i}
																		onClick={this.toggleStatus}
																	/>
																</AddButton>												
															</ActionHeader>
															<ElipsisHeader style={{backgroundColor: 'white',borderBottom: '0',whiteSpace: 'pre-wrap',wordBreak: 'break-all', fontWeight: "unset", borderLeft: "none", height: "auto"}}>												
																<VEllipsisMenuFolder editDelete={this.editDelete(q.name)} options={["Edit Custom Field", "Delete"]} />																							
															</ElipsisHeader>
														</div>
													</Draggable>
												);
											})}
										</Container>
									</div>);
								}
							})()
						}
					</EmailInnerContainer>
				</EmailContainer>
				<AlertBox alertParam = {this.state.alert_param}/>				
			</div>
		);
	}
}

export default CustomFields;