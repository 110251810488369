import React from 'react';
import styled from 'styled-components';
import CSLTable from '../Common/CSLTable';
import RoleChanger from './RoleChanger';

const SearchInput = styled.input`
    height: 30px;
    width: 100%;
    border: 1px solid #ffffff;
    border-radius: 3px;
    box-shadow: 0 0 4px #c4c4c4;
    padding: 5px;
    color: #222222;
    font-size: 15px;
    font-family: 'Montserrat', sans-serif;
`;
const PermissionDropdown = styled.select`
    display: block;
    box-sizing: border-box;
    height: 40px;
    width: 100%;
    background-color: #fff;
    border: 1px solid #ffffff;
    border-radius: 3px;
    box-shadow: 0 0 4px #c4c4c4;
    font-family: Montserrat,sans-serif;
    color: #222222;
`;

const EmailInnerContainer = styled.div`
    background-color: #F4F5F7;
	padding: 30px;
`;
const EmailBoldLabel = styled.div`
	font-weight: 600;
	color: #595959;
	margin-top: 5px;
`;

const EmailTopHeader = styled.div`
	font-weight: 600;
	color: #595959;
	margin-top: 5px;
	width:100%;
	height: 58px;
	padding: 20px;
	border-radius: 4px;
	border: 1px solid #e0e0e0;
	background-color: #ffffff;
	box-sizing: border-box;	
`;

class Permissions extends React.Component
{
	state = {permissions: null};

	componentDidMount()
	{
		console.log("this.props.permissions",this.props.permissions)
		this.setState({permissions: this.props.permissions});
	}

	componentDidUpdate(prevProps) {
		if (prevProps !== this.props) {
			this.setState({permissions: this.props.permissions});
		}
	}

	changeRole = (obj) => {
		let permissions = JSON.parse(JSON.stringify(this.state.permissions));
		permissions.company_users.forEach((user) => {
			if (parseInt(user.user_id) === parseInt(obj.user_id)) {
				user.role = obj.role;
			}
		})
		this.props.updatePermissions(permissions, obj);
		// this.setState({permissions});
		// console.log("Role changed");
	}

	tableData = () => {
        let ret = {data: [], columns: []};
        ret.columns =[
                        {Header: 'Name', accessor: 'name', headerStyle: {textAlign: 'left'}},
                        {'Header' : 'Permission Level', filterable: false, Cell: row => (
                           <RoleChanger key={row.original.user_id} roles={this.state.permissions.roles} active_role={row.original.active_role} user_id={row.original.user_id} changeRole={this.changeRole} />
						), headerStyle:{textAlign: 'left'}}];
		let companyUser = this.state.permissions.company_users.sort(function(a,b) {return (a.name > b.name) ? 1 : ((b.name > a.name) ? -1 : 0);} );
		companyUser = companyUser.sort(function(a,b) {return (a.name.toLowerCase() > b.name.toLowerCase()) ? 1 : ((b.name.toLowerCase() > a.name.toLowerCase()) ? -1 : 0);} )
        companyUser.forEach((user) => {
        	let elem = {user_id: user.user_id, company_id: user.company_id, name: user.name, active_role: user.role};
        	ret.data.push(elem);
        })
        return ret;
	}

	searchUsers = (event) => {
		event.preventDefault();
		this.props.searchUsers(event.target.value);
	}

	changeAllRoles = (event) => {
		event.preventDefault();
		this.props.changeAllRoles(event.target.value);
	}

	render()
	{
		if (this.state.permissions === null) {
			return (<div>Loading...</div>);
		}

		return (
			<div style={{width: "100%"}}>
				<div style={{padding: "30px 30px 0px 30px", backgroundColor: "#F4F5F7"}}> 
					<div>
						<SearchInput placeholder="Search..." onChange={this.searchUsers} />
					</div>
					<div style={{marginTop: "20px"}}>
						
							<div style={{fontWeight: "600", color: "#222222", marginBottom: "10px"}}>Set permission level for all chosen users</div>
							<PermissionDropdown onChange={this.changeAllRoles}>
								<option key="0" value="0">Select</option>
								{
									this.state.permissions.roles.map((role) => {
										return (<option key={role.name} value={role.name}>{role.nickname}</option>);
									})
								}
							</PermissionDropdown>
						
					</div>
				</div>
				<EmailInnerContainer>						
					<EmailTopHeader style={{display: "inline-block" , fontWeight: 700}}>
						Users						
					</EmailTopHeader>									
					<CSLTable add={false} processData={this.tableData} headerText={''} tableRows={20} refreshCallback={this.refreshState} />
				</EmailInnerContainer> 				
			</div>
		);
	}
}

export default Permissions;