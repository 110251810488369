import Store from './Store.js';
/* global fetch */
// import { trackPromise } from 'react-promise-tracker';
import Utils from '../Common/Utils';
import moment from 'moment';
import EventBus from './EventBus.js';
import http from 'mcc-front-aux/dist/httpCommon';

const cache_commands = {index : 1}
const cache_renew_time = 15 * 60 * 60

class APICall {
  constructor() {
    this.url = Store.getStoreData('api_url');
    this.is_admin = Store.getStoreData('is_admin');
    this.is_debug = Store.getStoreData('is_debug');
    this.module_name = Store.getStoreData('module_name');
  }

  strRawToWeb(jsonDataString) {
    jsonDataString = jsonDataString.replace(/&/g, "--and--");
    jsonDataString = jsonDataString.replace(/\+/g, "--plus--");
    jsonDataString = jsonDataString.replace(/'+/g, "--quote--");
    jsonDataString = jsonDataString.replace(/\n/g, "--newline--");
    jsonDataString = jsonDataString.replace(/\r/g, "--creturn--");

    return jsonDataString
  }

  strWebToRaw(result) {
    result = result.replace(/(--plus--)/g, "+");
    result = result.replace(/(--quote--)/g, "'");
    result = result.replace(/(--and--)/g, "&");
    result = result.replace(/(--newline--)/g, "\n");
    result = result.replace(/(--creturn--)/g, "\r");
    result = result.replace(/'+/g, "'");
    return result
  }

  command(postData,callback, incremental_callback){
    postData.env = "select";

    let now_time = moment().unix();
    let module_name = Store.getStoreData("module_name");
    let command = postData.command;
    let env = this.is_admin ? "csl" : "select";
    let command_id = `${module_name}-${command}`
    console.log("command_id", command_id);

    let jsonDataString = this.strRawToWeb(JSON.stringify(postData))


    let incremental = typeof incremental_callback !== "undefined" ? true : false;
    if (command in cache_commands) {
      let cached_result = Store.getStoreData(command_id);
      let cached_time = Store.getStoreData(command_id + "-last_cached");
      if (cached_result !== null) {
        console.log("cached_result", cached_result);
        postData.incremental = "incremental" in postData ? postData.incremental : incremental;
        try {
          let str_cached_result = JSON.stringify(cached_result);
          if (cached_result.error_code === 0) callback(cached_result);
        } catch (err) {
          console.log(err);
        }
      }
    }

    console.log('APICall incremental_callback, postData', incremental_callback, postData)

    // let httpVerb = {method: "POST",mode: "cors",headers: { "Content-Type": "application/x-www-form-urlencoded" },body: "jsondata=" + jsonDataString};

    // Add in the json command if it exists
    let postURL = this.url
    if('command' in postData) {
      postURL += `/${postData.command}`
    }


    http.post(postURL, {jsondata: jsonDataString}).then(res => {

      // Everything else is unchanged from the below (now omitted) fetch code, except for the first line.
      // The res.data.body field is equivalent to what they've been expecting from JSON.stringify(result); so, replace result = JSON.stringify(result) like so.
      res = res.data.body

      res = this.strWebToRaw(res);
      res = JSON.parse(res);

      if (command in cache_commands) {
        console.log(`${command_id} store size (kb),`,parseInt(JSON.stringify(res).length/1024));
        Store.updateStore(command_id, res);
        Store.updateStore(command_id + "-last_cached", now_time);
      }
      if (incremental) {
        incremental_callback(res);
      } else {
        if('error_code' in res && res.error_code != 0){
          EventBus.raiseEvent('system_err', res.error_msg)
        }
        callback(res);
      }
    }).catch((error) => {
      console.error("raiseevent error:", error);
      EventBus.raiseEvent('system_err', 'An error has occurred. Please contact your System Administrator for further assistance.')
      return null
    })

    // fetch(url, httpVerb)
    //   .then((res) => res.json())
    //   .then((result) => {
    //     result = this.strWebToRaw(JSON.stringify(result));
    //     result = JSON.parse(result);
    //     if (command in cache_commands) {
    //       console.log(`${command_id} store size (kb),`,parseInt(JSON.stringify(result).length/1024));
    //       Store.updateStore(command_id, result);
    //       Store.updateStore(command_id + "-last_cached", now_time);
    //     }
    //     if (incremental) {
    //       incremental_callback(result);
    //     } else {
    //       if('error_code' in result && result.error_code != 0){
    //         EventBus.raiseEvent('system_err', result.error_msg)
    //       }
    //       callback(result);
    //     }
    //   })
    //   .catch((error) => {
    //     console.log("raiseevent error:", error);
    //     EventBus.raiseEvent('system_err', 'An error has occurred. Please contact your System Administrator for further assistance.')
    //     return null
    //   })

  }

  command_old(postData, callback, incremental_callback) {
    postData.env = "select";

    console.log('APICall module, command', Store.getStoreData('module_name'), postData.command)
    let now_time = moment().unix()
    let module_name = Store.getStoreData('module_name')
    let command = postData.command
    let command_id = `${module_name}-${command}`
    let jsonDataString = JSON.stringify(postData).replace(/&/g, '--and--');
    let incremental = (typeof incremental_callback !== 'undefined') ? true : false
    jsonDataString = jsonDataString.replace(/\+/g, '--plus--');
    jsonDataString = jsonDataString.replace(/'+/g, '--quote--');
    jsonDataString = jsonDataString.replace(/\n/g, '');
    jsonDataString = jsonDataString.replace(/\r/g, '');
    if(command in cache_commands) {
      let cached_result = Store.getStoreData(command_id)
      // console.log('cached_result_parsed', JSON.parse(cached_result))
      let cached_time = Store.getStoreData(command_id + '-last_cached')
      console.log('cached_time, now_time, now_time - cached_time, cache_renew_time', cached_time, now_time, now_time - cached_time, cache_renew_time)
      // if(cached_time !== null && cached_result !== null && now_time - cached_time < cache_renew_time) {
      if(cached_result !== null){
        console.log('cached_result', cached_result)
        postData.incremental = 'incremental' in postData ? postData.incremental : incremental
        if(cached_result.error_code === 0)callback(cached_result)
        //callback(cached_result)
        // return
      }
    }
    console.log('APICall incremental_callback, postData', incremental_callback, postData)


    if(!this.is_debug)this.url += `msgw/${this.module_name}/api/command`;
    let httpVerb = { method: "POST", mode: "cors", headers: { "Content-Type": "application/x-www-form-urlencoded" }, body: "jsondata=" + jsonDataString };
    (fetch(this.url, httpVerb)
      .then(res => res.json())
      .then(result => {
        result = JSON.stringify(result);
        result = result.replace(/(--plus--)/g, "+");
        result = result.replace(/(--quote--)/g, "'");
        result = result.replace(/(--and--)/g, "&");
        result = result.replace(/'+/g,"'");
        result = JSON.parse(result);
        if(command in cache_commands){
          console.log(`${command_id} store size (kb),`,parseInt(JSON.stringify(result).length/1024));
          Store.updateStore(command_id, result)
          Store.updateStore(command_id + '-last_cached', now_time)
        }
        if(incremental){
          incremental_callback(result);
        } else {
          callback(result);
        }

      }).catch(error => {
        console.log(error);
        return(null)
      }));
  }

  async commandWithoutCallback(postData, callback){
    postData.env =  "select";

    let jsonDataString = JSON.stringify(postData).replace(/&/g, '--and--');
    jsonDataString = jsonDataString.replace(/\+/g, '--plus--');
    jsonDataString = jsonDataString.replace(/'+/g, '--quote--');
    jsonDataString = jsonDataString.replace(/\n/g, '');
    jsonDataString = jsonDataString.replace(/\r/g, '');
    this.url = Store.getStoreData('api_url');

    let httpVerb = { method: "POST", mode: "cors", headers: { "Content-Type": "application/x-www-form-urlencoded" }, body: "jsondata=" + jsonDataString };
    Utils.log('result httpverb', httpVerb)
    fetch(this.url, httpVerb)
      .then((res) => res.json())
      .then((result) => {
        result = JSON.stringify(result);
        result = result.replace(/(--plus--)/g, "+");
        result = result.replace(/(--quote--)/g, "'");
        result = result.replace(/(--and--)/g, "&");
        result = result.replace(/'+/g,"'");
        result = JSON.parse(result);
        return result;
      })
      .catch((error) => {
        console.log(error);
        return null;
      })
  }



  async commandWithoutCallback_old(postData, callback) {
    if (this.is_debug) {
      postData.env = this.is_admin ? "csl" : "select";
      if(!this.is_admin) {
        postData['CSC_CL_companyID'] = 758;
        postData['CSC_CL_contactID'] = 3694;

      }
    }
    let jsonDataString = JSON.stringify(postData).replace(/&/g, '--and--');
    jsonDataString = jsonDataString.replace(/\+/g, '--plus--');
    jsonDataString = jsonDataString.replace(/'+/g, '--quote--');
    jsonDataString = jsonDataString.replace(/\n/g, '');
    jsonDataString = jsonDataString.replace(/\r/g, '');
    this.url = Store.getStoreData('api_url');
    if(!this.is_debug)this.url += `msgw/${this.module_name}/api/command`;
    let httpVerb = { method: "POST", mode: "cors", headers: { "Content-Type": "application/x-www-form-urlencoded" }, body: "jsondata=" + jsonDataString };
    Utils.log('result httpverb', httpVerb)
    (fetch(this.url, httpVerb)
      .then(res => res.json())
      .then(result => {
        result = JSON.stringify(result);
        result = result.replace(/(--plus--)/g, "+");
        result = result.replace(/(--quote--)/g, "'");
        result = result.replace(/(--and--)/g, "&");
        result = result.replace(/'+/g,"'");
        result = JSON.parse(result);
        callback(result);
      }));
  }
}

export default APICall;
