import React from 'react';
import styled from 'styled-components';
import OutsideClickHandler from 'react-outside-click-handler';
import { IoIosCheckboxOutline, IoIosSquareOutline, IoIosWarning } from "react-icons/io";
import { FaSort , FaTimes } from "react-icons/fa";

const DropContainer = styled.div`
	min-height: 40px;
	width: 100%;
	border: 1px solid #DBDBDB;
	border-radius: 4px;
	box-sizing: border-box;
	padding: 6px 10px;
	background-color: #ffffff;
	cursor: pointer;
`;
const Section85 = styled.div`
	float: left;
	width: 85%;
	box-sizing: border-box;
	padding-right: 20px;
	position: relative;
	display:flex;
	flex-wrap: wrap;

`;
const Section15 = styled.div`
	float: left;
	width: 15%;
	box-sizing: border-box;
`;
const OptionsContainer = styled.div`
	box-sizing: border-box;
    width: 100%;
    background-color: #ffffff;   
    border: 1px solid #DBDBDB;
    z-index: 1;
    overflow-y: auto;
    box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2);
    margin-top: 5px;
    height: 250px;    
`;
const OptionInput = styled.input`
	width:95%;
	border:1px solid #DBDBDB;
	height:30px
`
const SingleOption = styled.div`
	box-sizing: border-box;
	padding: 8px 10px;
	border-bottom: 1px solid #DBDBDB;
	cursor: pointer;
	&:hover {
		background-color: #DBDBDB;
		border-bottom: 1px solid #ffffff;
	}
`;

const OptionBtn = styled.div`
  box-sizing: border-box;
  margin-right: 10px;
  font-size: 15px;
  cursor: pointer;
  font-weight: 200;
  color: #FFFFFF;
  background-color:#1B3451;
  width:auto;
  padding:5px;
  border-radius:5px;
  position:relative;
  margin-bottom:10px;
  display:flex
`;
const Clearfix = styled.div`
  clear: both;
`;

class CslSimpleDropDownSearchable extends React.Component
{	
	state = {show_options: false , selectedList:[] , selectedIndexContainer:[] , options:[], optionsAll: [], rowchecked: false};

	componentDidMount(){		
		this.setState({selectedList:this.props.selected , options:this.props.options, optionsAll: this.props.options})
	}
	componentDidUpdate(prev_props){
		if(this.props !== prev_props){			
			this.setState({selectedList:this.props.selected , options:this.props.options, optionsAll: this.props.options})
		}
	}
	toggleOptions = (event) => {
		event.preventDefault();
		const show_options = this.state.show_options ? false : true;
		this.setState({show_options});
	}

	makeSelected = (data , index) => (event) => {
		event.preventDefault();		
		let curData = this.state.selectedList;
		console.log("Data===>", data, "selected_item===>", curData)		
		if(!data.isCheck){
			data.isCheck = true
			curData.push(data)
		}else{
			data.isCheck = false
			let upData = []
			for(let d of curData){
				if(d.id !== data.id){
					upData.push(d)
				}
			}
			curData = upData
		}		
		this.props.setSelection(curData);				
	}
	
	deleteSelected = (data , i)=>{
		let curData = this.state.selectedList;
		let option = this.state.optionsAll;  
		let updateSelectedItem = []
		for(let d of curData){
			if(d.id !== data.id){
				updateSelectedItem.push(d)
			}
		}
		let updtOptions = []
		for(let d of option){
			if(d.id === data.id){
				d.isCheck = false				
			}
			updtOptions.push(d)
		}				
		this.props.deleteSelected(updateSelectedItem, updtOptions)
	}

	Search = (event) => {
		event.preventDefault();		
		let search_string = event.target.value;
		if (search_string === "") {	
			let option = this.state.optionsAll;        
			this.setState({options: option});
		}else {
			let all_value = this.state.optionsAll;
			let filtered_values = [];
			all_value.forEach((op) => {
				if (op.name.toLowerCase().includes(search_string.toLowerCase())) {
					filtered_values.push(op);
				}                
			})            
			let option = filtered_values;
			this.setState({options: option})
		}	
	}

	selectAll = (event) => {
        let rowchecked = this.state.rowchecked;
        rowchecked = rowchecked ? false : true;
        let optionsAll = this.state.optionsAll;        
        
		if(rowchecked) {   
			let optionsArray = [];  
			for(let d of optionsAll){
				let optionObject = {};
				optionObject.id = d.id;
				optionObject.name = d.name;
				optionObject.isCheck = true
				optionsArray.push(optionObject);
			}  
			this.setState({rowchecked: rowchecked})
			this.props.deleteSelected(optionsArray, optionsArray)
		}else{                
			let optionsArray = [];  
			for(let d of optionsAll){
				let optionObject = {};
				optionObject.id = d.id;
				optionObject.name = d.name;
				optionObject.isCheck = false
				optionsArray.push(optionObject);
			}  
			this.setState({rowchecked: rowchecked})
			this.props.deleteSelected([], optionsArray)
		}        
   	}

	render()
	{
		return (
			<OutsideClickHandler onOutsideClick={() => {this.setState({show_options: false})}}>
				<div style={{position: "relative"}}>
					<DropContainer onClick={this.toggleOptions}>
						<Section85 style={{paddingTop: "5px"}}>
							
							{this.state.selectedList.map((elem , index)=>{
								return (
									<OptionBtn key={index}>
									 <span style={{paddingRight:'5px'}}>
									 {elem['name']}
									 </span>
									 <div style={{marginLeft:'10px'}}>
									 <FaTimes style={{float:'right' , cursor:'pointer'}} onClick={()=>this.deleteSelected(elem, index)} />

									 </div>
									</OptionBtn>
									)
							})}
						
						</Section85>
						<Section15 style={{textAlign: "right", paddingTop: "3px"}}>
							<FaSort style={{fontSize: "20px"}} />
						</Section15>
						<div style={{clear: "both"}}></div>
					</DropContainer>
					{
						(() => {
							if (this.state.show_options) {
								return (
									<OptionsContainer style={{height: this.props.options.length > 7 ? "250px" : "auto" , overflowX:'hidden'}}>
										<div style={{width:'100%', padding:'10px' }}>
										 <OptionInput onChange={(e)=>this.Search(e)} placeholder='Search ..' />
										</div>
										<div style={{width:'100%', border:'1px solid #DBDBDB' , paddingTop:'10px' , paddingBottom:'10px' }}>
											{
												(() => {
													if(this.state.rowchecked){
														return(<div  style={{cursor: "pointer", fontSize:"21px", float: "left", marginLeft: "10px"}} onClick={this.selectAll}><IoIosCheckboxOutline /></div>);
													}else{
														return(<div style={{cursor: "pointer", fontSize:"21px", float: "left", marginLeft: "10px"}} onClick={this.selectAll}><IoIosSquareOutline /></div>)
													}
												})()
											}											
											<span style={{margin:'10px', fontSize:'18px' , fontWeight:700}}>{this.props.header}</span>
										</div>
										{
											this.state.options.map((item , index) => {
												return (
												  <SingleOption key={item.id} onClick={this.makeSelected(item , index)}>
													<div>
															{
																(()=> {
																	if (item.isCheck) {
																		return(<div style={{cursor: "pointer", fontSize:"21px", float: "left"}} ><IoIosCheckboxOutline /></div>);
																	} else {
																		return(<div  style={{cursor: "pointer", fontSize:"21px", float: "left"}} ><IoIosSquareOutline /></div>);
																	}
																})()
															}
													</div>
														
														<span style={{margin:'10px', fontSize:'14px'}}>{item.name}</span>
												  </SingleOption>);
											})
										}
									</OptionsContainer>
								);
							}
						})()
					}
				<Clearfix></Clearfix>
				</div>
			</OutsideClickHandler>
		);
	}
}

export default CslSimpleDropDownSearchable;