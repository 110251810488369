    let approval_options = [
          { id: "ar", name: "Approver Role" },
          { id: "lm", name: "Line Manager" },
          { id: "ar-lm", name: "Approver Role then Line Manager" },
          { id: "lm-ar", name: "Line Manager then Approver Role" },
          { id: "su", name: "Specific User" },
          { id: "nr", name: "Not required (Auto-approve)" }          
        ]
    
    module.exports = approval_options
    
    