import React from 'react';
import Store from '../../Common/Store'
import styled from 'styled-components';
import {FaTimes, FaPlusCircle, FaLock} from 'react-icons/fa';
import {IoIosArrowForward, IoIosLock} from 'react-icons/io';
import General from './General';
import GiftsTypes from './GiftsTypes';
import Approval from './Approval';
import ResultTypes from './ResultTypes';
import Permissions from './Permissions';
import APICall from '../../Common/APICall.js';
import { Link } from 'react-router-dom';
import Email from './Email';
import CSLLoader from '../Common/CSLLoader';
import CustomFields from './CustomFields';
import AlertBox from '../Common/AlertBox'
import Reload from '../../Common/Reload.js';

const ReviewBodyContainer = styled.div`
    padding-top: 2px;
    width: 100%;
    background-color:#F4F5F7;
`;
const ReviewLeftContainer = styled.div`
    float: left;
    padding-right: 0px;
    width: 20%;
    box-sizing: border-box;
`;
const ReviewRightContainer = styled.div`
    float: right;
    padding-left: 0px;
    width: 80%;
    box-sizing: border-box;
`;
const ReviewTab = styled.div`
    background-color: #ffffff;
    color: ${process.env.DEFAULT_TEXT_COLOR};
    padding: 17px 20px;
    cursor: pointer;
    font-family: Roboto-Medium, "Roboto Medium", Roboto, sans-serif;
    font-weight: 600;
`;
const CloseBtn = styled.div`
    float: right;
    font-size: 20px;
    color: #9c9c9c;
    cursor: pointer;
    padding-top: 20px;
    padding-right: 30px;
`;
const FooterButtonsContainer = styled.div`
	text-align: left;
	padding-top: 25px;
	//width: 100%;
	background-color:#F4F5F7;
	padding-bottom:25px;
`;
const CancelBtn = styled.button`
	padding: 10px 20px;
	background-color: #ffffff;
	border: 1px solid #EBECEC;
	border-radius: 2px;
	color: #333333;
	cursor: pointer;
    margin-left: 30px
`;
const SaveBtn = styled.button`
	padding: 10px 30px;
	background-color: #37ADA7;
	border: 1px solid #37ADA7;
	border-radius: 2px;
	color: #ffffff;
	cursor: pointer;
	margin-left: 15px;
`;
const ErrorBar = styled.div`
    background-color: #F6DBDB;
    color: #DE8D8E;
    font-weight: 600;
    padding: 10px 20px;
`;
const ReviewTab_inactive = styled.div`
    background-color: #ffffff;
    color: #dedede;
    padding: 10px 20px;
`;
const ExportButton = styled.button`
    box-sizing: border-box;
    background-color: #213E61;
    color: #F2F2F2;
    border: 1px solid #213E61;
    width: 125px;
    font-size: 14px;
    height: 32px;
    border-radius: 5px;
    margin-right: 3px;
    float: left;
    cursor: pointer;
    margin-left: 30px
`;

//new
const DashboardModalHeader = styled.div`
    border-width: 0px;
    background-color: white;
    border-left-width: 10px;
    border-style: solid;
    border-bottom-style: none;
    border-color: #11aba6;
    display: block;
    padding-top: 5px;
    font-size: 14px;
    font-weight: 700;
    height: 68px;
    color: #67788c;
    width: calc(100% - 10px);
`;

class ModuleConfigHOC extends React.Component
{
	state = {
		active_section_id: "general",
		dirty: false,
        permissions: {},
		module_config: null,
        all_company_users: null,
        changed_user_roles: {},
        requester: "",
        ready: false,
        standard_gift_type: null,
        standard_result_type: null,
        alert_param: null,
        allActiveUsers: {},
        server_name: 'local'
	};
	constructor(props) {
        super(props);
        if (Store.getStoreData('role') === 'admin_manager') {
            const postData = { command: "list_company_and_users" };
            const api = new APICall();
            api.command(postData, this.getInitialPermission);
        }
    }

    getInitialPermission = (result) => {
        let requester = this.props.match ? this.props.match.params.requester : "";
        //console.log("requester:", requester);

        let contactlist = JSON.parse(localStorage.getItem('contactlist'));
        let user_roles = contactlist.result.user_roles['gifts'];
        let cls = contactlist.result.contactlist;

        let company_users = [];
        // for(let key in cls){
        //     if(user_roles[key.toString()] !== undefined){
        //         let cu = {};
        //         cu['email'] = cls[key].EmailAddress;
        //         cu['user_id'] = key;
        //         cu['name'] = cls[key].ContactName;
        //         cu['role'] = key in user_roles ? user_roles[key] : 'no_access';
        //         company_users.push(cu)
        //     }
        // }
        for(let d of result.activeUsers){
            //if(user_roles[d.ID.toString()] !== undefined){
                let cu = {};
                cu['email'] = d.EmailAddress;
                cu['user_id'] = d.ID;
                cu['name'] = d.ContactName;
                cu['role'] = d.ID in user_roles ? user_roles[d.ID] : 'no_access';
                company_users.push(cu)
            //}
        }
        console.log("company_users===>", company_users);
        console.log("result ===>", result);
        const permissions = {
            user_roles: user_roles,
            roles: result.roles,
            company_users: company_users

        };
        const all_company_users = JSON.parse(JSON.stringify(company_users));
        // console.log("result", result);
        // const permissions = {
        //     user_roles: result.user_roles,
        //     gc_companies: result.gc_companies,
        //     roles: result.roles,
        //     company_users: result.company_users
        // };
        // const all_company_users = JSON.parse(JSON.stringify(result.company_users));
        let allActiveUsers = Store.getStoreData('contacts');
        let server_name = result.server_name
        this.setState({permissions, all_company_users, server_name, allActiveUsers, module_config: result.moduleconfig, requester,standard_gift_type: result.standard_gift_type,standard_result_type: result.standard_result_type,ready: true});
    }

	returnSection = (section) => (event) => {
        event.preventDefault();
        if (section === "approval") {
            let cts = JSON.parse(localStorage.getItem('contactlist'));
            let user_roles = cts.result.user_roles['gifts'];
            let contacts_all = {};
            for(let k in cts.result.contactlist){
                let clists =  cts.result.contactlist;
                if(user_roles[k.toString()] !== undefined && clists[k.toString()].IsActive === true && clists[k.toString()].IsDeleted === false){
                    if (cts.result.user_roles['gifts'][k.toString()] !== 'no_access' ) {
                        contacts_all[k.toString()] = cts.result.contactlist[k.toString()].ContactName;
                    }
                }
            }
            console.log("user_roles", user_roles)
            this.setState({active_section_id: section, allActiveUsers: contacts_all});
        }else{
            this.setState({active_section_id: section});
        }
	}

    afterUpdate = (result) => {
        console.log(result);
        let msg = '';
        let title = 'Alert';
        msg = result.error_msg
        // alert(result.error_msg);
        let alert_param = {title: title, message: msg, ok_text: 'OK', confirm: false, alertHandler: this.samealertremoveHandler, stack: {id: 0}}
        this.setState({alert_param: alert_param, module_config: result.result})
        // this.setState({module_config: result.result});
        let gifts_index = Store.getStoreData('gifts-index')
        if(gifts_index !== null && 'result' in gifts_index && 'module_config' in gifts_index.result){
            gifts_index.result.module_config = result.result;
        }
        console.log('gifts_index==>', gifts_index);
        Store.updateStore('gifts-index', gifts_index)
    }

    afterRoleUpdate = (result) => {
        let msg = '';
        let title = 'Alert';
        msg = result.error_msg
        // alert(result.error_msg);
        let alert_param = {title: title, message: msg, ok_text: 'OK', confirm: false, alertHandler: this.samealertremoveHandler, stack: {id: 0}}
        this.setState({alert_param: alert_param})
    }

    updateEmail = (obj) => {
        let {module_config} = this.state;
        module_config.email = obj;
        console.log("modulecong",module_config)
        console.log("moduleemail",obj)
        const postData = {command: "save_module_config", module_config: module_config};
        const api = new APICall();
        api.command(postData, this.afterChangeEmail);
    }

    afterChangeEmail = (result) => {
        console.log(result);
        let msg = '';
        let title = 'Alert';
        msg = 'Changes updated successfully.'
        // alert("Changes updated successfully.")
        let alert_param = {title: title, message: msg, ok_text: 'OK', confirm: false, alertHandler: this.samealertremoveHandler, stack: {id: 0}}
        this.setState({alert_param: alert_param, module_config: result.result})
        // this.setState({module_config: result.result});
    }

    closemodal = () => {
        let url = "/";
        if (this.state.requester !== "") {
            const reqarr = this.state.requester.split("_");
            url = `/${reqarr[0]}/${reqarr[1]}`;
        }
        // console.log("Backurl:", url);
        window.location.replace(url);
    }

    updateCurrencyValue = (value) => {
        console.log("moduleemail",value)
        let {module_config} = this.state;
        module_config.general.companyCurrencyDetails.minvalue = value
        this.setState({module_config});
    }

    updateApprovalMinimumValue = (obj) => {
        console.log("moduleemail",obj)
        let {module_config} = this.state;
        module_config.approval_level = obj
        this.setState({module_config});
    }

    changeActiveInactive = (obj) => {
        let {module_config} = this.state;
        module_config.general.gift_type_active_inactive = obj;
        const postData = {command: "save_module_config", module_config: module_config};
        const api = new APICall();
        api.command(postData, this.afterUpdateEmailRemainder);
    }

    changeActiveInactiveresulttype = (obj) => {
        let {module_config} = this.state;
        module_config.general.result_type_active_inactive = obj;
        const postData = {command: "save_module_config", module_config: module_config};
        const api = new APICall();
        api.command(postData, this.afterUpdateEmailRemainder);
    }

    saveGiftValue = (obj) => {
        console.log("object",obj);
        let {module_config} = this.state;
        module_config.gift_types.push(obj)
        const postData = {command: "save_module_config", module_config: module_config};
        const api = new APICall();
        api.command(postData, this.afteraddGiftValue);
    }
    afteraddGiftValue = (result) => {
        console.log(result);
        let msg = '';
        let title = 'Alert';
        msg = 'Gift and Hospitality Type added successfully.'
        // alert("Gift and Hospitality Type added successfully")
        let alert_param = {title: title, message: msg, ok_text: 'OK', confirm: false, alertHandler: this.samealertremoveHandler, stack: {id: 0}}
        this.setState({alert_param: alert_param, module_config: result.result})
        // this.setState({module_config: result.result});
       let gifts_index = Store.getStoreData('gifts-index')
        if(gifts_index !== null && 'result' in gifts_index && 'module_config' in gifts_index.result){
            gifts_index.result.module_config = result.result;
        }
        console.log('gifts_index==>', gifts_index);
        Store.updateStore('gifts-index', gifts_index)
   }
    saveApprovalValue = (array) => {
        console.log("object",array);
        let {module_config} = this.state;
        module_config.approval_level.approval[0].elem = array;
        const postData = {command: "save_module_config", module_config: module_config};
        const api = new APICall();
        api.command(postData, this.afteraddApprovalValue);
    }
    saveApprovalValueType = (array) => {
        console.log("object",array);
        let {module_config} = this.state;
        module_config.approval_level.approval_giftTypes = array;
        const postData = {command: "save_module_config", module_config: module_config};
        const api = new APICall();
        api.command(postData, this.afteraddApprovalValue);
    }
    deleteApprovalValueType = (array) => {
        console.log("object",array);
        let {module_config} = this.state;
        module_config.approval_level.approval_giftTypes = array;
        const postData = {command: "save_module_config", module_config: module_config};
        const api = new APICall();
        api.command(postData, this.afterDeleteApprovalTypeValue);
    }
    afterDeleteApprovalTypeValue = (result) => {
        console.log(result);
        let alert_param = {title: 'Success', message: 'Approval deleted successfully.', ok_text: 'OK', confirm: false, alertHandler: this.samealertremoveHandler, stack: {id: 0}}
        this.setState({alert_param: alert_param, module_config: result.result})
        // this.setState({module_config: result.result});
       let gifts_index = Store.getStoreData('gifts-index')
        if(gifts_index !== null && 'result' in gifts_index && 'module_config' in gifts_index.result){
            gifts_index.result.module_config = result.result;
        }
        console.log('gifts_index==>', gifts_index);
        Store.updateStore('gifts-index', gifts_index)
   }
    afteraddApprovalValue = (result) => {
        console.log(result);
        let alert_param = {title: 'Success', message: 'Approval added successfully.', ok_text: 'OK', confirm: false, alertHandler: this.samealertremoveHandler, stack: {id: 0}}
        this.setState({alert_param: alert_param, module_config: result.result})
        // this.setState({module_config: result.result});
       let gifts_index = Store.getStoreData('gifts-index')
        if(gifts_index !== null && 'result' in gifts_index && 'module_config' in gifts_index.result){
            gifts_index.result.module_config = result.result;
        }
        console.log('gifts_index==>', gifts_index);
        Store.updateStore('gifts-index', gifts_index)
   }
    updateApprovalValue = (array) => {
        console.log("object",array);
        let {module_config} = this.state;
        module_config.approval_level.approval[0].elem = array;
        const postData = {command: "save_module_config", module_config: module_config};
        const api = new APICall();
        api.command(postData, this.afterUpdateApprovalValue);
    }

    updateApprovalValueType = (array) => {
        console.log("object",array);
        let {module_config} = this.state;
        module_config.approval_level.approval_giftTypes = array;
        const postData = {command: "save_module_config", module_config: module_config};
        const api = new APICall();
        api.command(postData, this.afterUpdateApprovalValue);
    }
    afterUpdateApprovalValue = (result) => {
        console.log(result);
        let alert_param = {title: 'Success', message: 'Approval updated successfully.', ok_text: 'OK', confirm: false, alertHandler: this.samealertremoveHandler, stack: {id: 0}}
        this.setState({alert_param: alert_param, module_config: result.result})
        // this.setState({module_config: result.result});
       let gifts_index = Store.getStoreData('gifts-index')
        if(gifts_index !== null && 'result' in gifts_index && 'module_config' in gifts_index.result){
            gifts_index.result.module_config = result.result;
        }
        console.log('gifts_index==>', gifts_index);
        Store.updateStore('gifts-index', gifts_index)
   }
    deleteApprovalValue = (array) => {
        console.log("object",array);
        let {module_config} = this.state;
        module_config.approval_level.approval[0].elem = array;
        const postData = {command: "save_module_config", module_config: module_config};
        const api = new APICall();
        api.command(postData, this.afterDeleteApprovalValue);
    }
    afterDeleteApprovalValue = (result) => {
        console.log(result);
        let alert_param = {title: 'Success', message: 'Approval deleted successfully.', ok_text: 'OK', confirm: false, alertHandler: this.samealertremoveHandler, stack: {id: 0}}
        this.setState({alert_param: alert_param, module_config: result.result})
        // this.setState({module_config: result.result});
       let gifts_index = Store.getStoreData('gifts-index')
        if(gifts_index !== null && 'result' in gifts_index && 'module_config' in gifts_index.result){
            gifts_index.result.module_config = result.result;
        }
        console.log('gifts_index==>', gifts_index);
        Store.updateStore('gifts-index', gifts_index)
   }

    saveUserLevelApproval = (obj) => {
        console.log("object",obj);
        let {module_config} = this.state;
        module_config.user_level_approval = obj
        const postData = {command: "save_module_config", module_config: module_config};
        const api = new APICall();
        api.command(postData, this.afteraddUserLevelApproval);
    }
    afteraddUserLevelApproval = (result) => {
        console.log(result);
        let msg = 'User level approval added successfully.';
        let title = 'Success';
        let alert_param = {title: title, message: msg, ok_text: 'OK', confirm: false, alertHandler: this.samealertremoveHandler, stack: {id: 0}}
        this.setState({alert_param: alert_param, module_config: result.result})
    }
    updateUserLevelApproval = (obj) => {
        console.log("object",obj);
        let {module_config} = this.state;
        module_config.user_level_approval = obj
        const postData = {command: "save_module_config", module_config: module_config};
        const api = new APICall();
        api.command(postData, this.afterUpdateUserLevelApproval);
    }

    afterUpdateUserLevelApproval = (result) => {
        console.log(result);
        let msg = 'User level approval updated successfully.';
        let title = 'Success';
        let alert_param = {title: title, message: msg, ok_text: 'OK', confirm: false, alertHandler: this.samealertremoveHandler, stack: {id: 0}}
        this.setState({alert_param: alert_param, module_config: result.result})
    }

    deletedUserLevelApproval = (obj) => {
        console.log("object",obj);
        let {module_config} = this.state;
        module_config.user_level_approval = obj
        const postData = {command: "save_module_config", module_config: module_config};
        const api = new APICall();
        api.command(postData, this.afterDeleteUserLevelApproval);
    }

    afterDeleteUserLevelApproval = (result) => {
        console.log(result);
        let msg = 'User level approval deleted successfully.';
        let title = 'Success';
        let alert_param = {title: title, message: msg, ok_text: 'OK', confirm: false, alertHandler: this.samealertremoveHandler, stack: {id: 0}}
        this.setState({alert_param: alert_param, module_config: result.result})
    }

   saveResultValue = (obj) => {
        console.log("object",obj);
        let {module_config} = this.state;
        module_config.result_types.push(obj)
        const postData = {command: "save_module_config", module_config: module_config};
        const api = new APICall();
        api.command(postData, this.afteraddResultValue);
    }
    afteraddResultValue = (result) => {
        console.log(result);
        let msg = '';
        let title = 'Alert';
        msg = 'Result Type added successfully.'
        // alert("Result Type added successfully")
        let alert_param = {title: title, message: msg, ok_text: 'OK', confirm: false, alertHandler: this.samealertremoveHandler, stack: {id: 0}}
        this.setState({alert_param: alert_param,module_config: result.result})
    // this.setState({module_config: result.result});
    }

   updateGiftValue = (arr) => {
        console.log("object",arr);
        let {module_config} = this.state;
        module_config.gift_types = arr;
        const postData = {command: "save_module_config", module_config: module_config};
        const api = new APICall();
        api.command(postData, this.afterUpdategiftValue);
    }
    afterUpdategiftValue = (result) => {
        console.log(result);
        let msg = '';
        let title = 'Alert';
        msg = 'Gift and hospitality type updated successfully.'
        // alert("Gift and hospitality type updated successfully.")
        let alert_param = {title: title, message: msg, ok_text: 'OK', confirm: false, alertHandler: this.samealertremoveHandler, stack: {id: 0}}
        this.setState({alert_param: alert_param, module_config: result.result})
        // this.setState({module_config: result.result});
        let gifts_index = Store.getStoreData('gifts-index')
        if(gifts_index !== null && 'result' in gifts_index && 'module_config' in gifts_index.result){
            gifts_index.result.module_config = result.result;
        }
        console.log('gifts_index==>', gifts_index);
        Store.updateStore('gifts-index', gifts_index)
    }

    updateResultValue = (arr) => {
        console.log("object",arr);
        let {module_config} = this.state;
        module_config.result_types = arr;
        const postData = {command: "save_module_config", module_config: module_config};
        const api = new APICall();
        api.command(postData, this.afterUpdateresultValue);
    }
    afterUpdateresultValue = (result) => {
        console.log(result);
        let msg = '';
        let title = 'Alert';
        msg = 'Result Type updated successfully.'
        // alert("Result Type updated successfully")
        let alert_param = {title: title, message: msg, ok_text: 'OK', confirm: false, alertHandler: this.samealertremoveHandler, stack: {id: 0}}
        this.setState({alert_param: alert_param, module_config: result.result})
    // this.setState({module_config: result.result});
    }

    deleteGiftTypeValue = (arr) => {
        console.log("object",arr);
        let {module_config} = this.state;
        module_config.gift_types = arr;
        const postData = {command: "save_module_config", module_config: module_config};
        const api = new APICall();
        api.command(postData, this.afterDeletegiftValue);
    }
    afterDeletegiftValue = (result) => {
        console.log(result);
        let msg = '';
        let title = 'Alert';
        msg = 'Gift and Hospitality Type deleted successfully.'
        // alert("Gift and Hospitality Type deleted successfully")
        let alert_param = {title: title, message: msg, ok_text: 'OK', confirm: false, alertHandler: this.samealertremoveHandler, stack: {id: 0}}
        this.setState({alert_param: alert_param, module_config: result.result})
        // this.setState({module_config: result.result});
        let gifts_index = Store.getStoreData('gifts-index')
        if(gifts_index !== null && 'result' in gifts_index && 'module_config' in gifts_index.result){
            gifts_index.result.module_config = result.result;
        }
        console.log('gifts_index==>', gifts_index);
        Store.updateStore('gifts-index', gifts_index)
    }

    deleteResultTypeValue = (arr) => {
        console.log("object",arr);
        let {module_config} = this.state;
        module_config.result_types = arr;
        const postData = {command: "save_module_config", module_config: module_config};
        const api = new APICall();
        api.command(postData, this.afterDeleteresultValue);
    }
    afterDeleteresultValue = (result) => {
        console.log(result);
        let msg = '';
        let title = 'Alert';
        msg = 'Result Type deleted successfully.'
        // alert("Result Type deleted successfully")
        let alert_param = {title: title, message: msg, ok_text: 'OK', confirm: false, alertHandler: this.samealertremoveHandler, stack: {id: 0}}
        this.setState({alert_param: alert_param, module_config: result.result})
        // this.setState({module_config: result.result});
    }

   importGiftValue = (value) => {
        console.log("object",value);
        let {module_config} = this.state;
        module_config.gift_types = value;
        module_config.general.standard_gift_type_enable = false;
        const postData = {command: "save_module_config", module_config: module_config};
        const api = new APICall();
        api.command(postData, this.afterimportGiftValue);
   }

   afterimportGiftValue = (result) => {
    console.log(result);
    let msg = '';
    let title = 'Alert';
    msg = 'Gift and Hospitality Type imported successfully.'
    // alert("Gift and Hospitality Type import successfully")
    let alert_param = {title: title, message: msg, ok_text: 'OK', confirm: false, alertHandler: this.samealertremoveHandler, stack: {id: 0}}
    this.setState({alert_param: alert_param, module_config: result.result})
    // this.setState({module_config: result.result});
    let gifts_index = Store.getStoreData('gifts-index')
    if(gifts_index !== null && 'result' in gifts_index && 'module_config' in gifts_index.result){
        gifts_index.result.module_config = result.result;
    }
    console.log('gifts_index==>', gifts_index);
    Store.updateStore('gifts-index', gifts_index)
}

importResultValue = (value) => {
    console.log("object",value);
    let {module_config} = this.state;
    module_config.result_types = value;
    module_config.general.standard_result_type_enable = false;
    const postData = {command: "save_module_config", module_config: module_config};
    const api = new APICall();
    api.command(postData, this.afterimportResultValue);
}

afterimportResultValue = (result) => {
    console.log(result);
    let msg = '';
    let title = 'Alert';
    msg = 'Result Type imported successfully.'
    // alert("Result Type import successfully")
    let alert_param = {title: title, message: msg, ok_text: 'OK', confirm: false, alertHandler: this.samealertremoveHandler, stack: {id: 0}}
    this.setState({alert_param: alert_param, module_config: result.result})
    // this.setState({module_config: result.result});
}

    updateEmailRemainder = (obj) => {
        let {module_config, dirty} = this.state;
        console.log("old_config", this.state);
        const old_config = {...module_config};
        // console.log("old_config", old_config);
        module_config.general = obj;
        const new_config = {...module_config};
        const postData = {command: "save_module_config", module_config: module_config};
        const api = new APICall();
        api.command(postData, this.afterUpdateEmailRemainder);
    }
    afterUpdateEmailRemainder = (result) => {
        console.log(result);
       this.setState({module_config: result.result});
   }
    updatePermissions = (obj, single_obj) => {
        // console.log("single_obj:", single_obj);
        let changed_user_roles = JSON.parse(JSON.stringify(this.state.changed_user_roles));
        changed_user_roles[single_obj.user_id] = single_obj.role;
        // console.log("changed_user_roles:", changed_user_roles);
        this.setState({permissions: obj, changed_user_roles});
        // console.log("Updated Permission:", obj);
    }

    searchUsers = (search_string) => {
        if (search_string === "") {
            let permissions = JSON.parse(JSON.stringify(this.state.permissions));
            permissions.company_users = this.state.all_company_users;
            this.setState({permissions});
        } else {
            let all_company_users = JSON.parse(JSON.stringify(this.state.all_company_users));
            let filtered_company_users = [];
            all_company_users.forEach((user) => {
                if (user.name.toLowerCase().includes(search_string.toLowerCase()) || user.role.includes(search_string.toLowerCase())) {
                    filtered_company_users.push(user);
                }
            })
            let permissions = JSON.parse(JSON.stringify(this.state.permissions));
            permissions.company_users = filtered_company_users;
            // console.log("in search:", permissions.company_users);
            this.setState({permissions});
        }
    }

    changeAllRoles = (role) => {
        let permissions = JSON.parse(JSON.stringify(this.state.permissions));
        if (role !== "0") {
            permissions.company_users.forEach((user) => {
                user.role = role;
            })
        }else{
            permissions.company_users = this.state.all_company_users;
        }
        let changed_user_roles = {};
        permissions.company_users.forEach((user) => {
            changed_user_roles[user.user_id] = user.role;
        })
        // console.log("change all roles:", permissions.company_users);
        this.setState({permissions, changed_user_roles});
    }

    updateModuleconfig = (event) => {
        event.preventDefault();
        let msg = '';
        let title = 'Alert';
        let {module_config, changed_user_roles} = this.state;
        console.log("modulecong",module_config)
        if(module_config.general.companyCurrencyDetails.minvalue === ""){
            msg = 'Minimum value should not be blank.'
            // alert("Minimum value should not be blank.")
            let alert_param = {title: title, message: msg, ok_text: 'OK', confirm: false, alertHandler: this.samealertremoveHandler, stack: {id: 0}}
            this.setState({alert_param: alert_param})
            return;
        }
        const api = new APICall();
        let postData = {command: "save_module_config", module_config: module_config };
        if (this.state.active_section_id === "permissions") {
            postData = {command: "save_user_role", changed_user_roles: changed_user_roles};
            api.command(postData, this.afterRoleUpdate);
            Reload.RestoreStorage(changed_user_roles); // add this line
        } else {
            api.command(postData, this.afterUpdate);
        }
    }

    closePage = (event) => {
        event.preventDefault();
        // console.log("requester:", this.state.requester);
        let url = "/";
        if (this.state.requester !== "") {
            const reqarr = this.state.requester.split("_");
            url = `/${reqarr[0]}/${reqarr[1]}`;
        }
        // console.log("Backurl:", url);
        window.location.replace(url);
    }

    exportTogiftTypeCSV  = (event)=>{
        event.preventDefault();
        var cur_table_data = Store.getStoreData('table_gift_cur_data');
        console.log("table_gift_cur_data",cur_table_data)
        var cur_table_arr = [];
        let headerCSV = {type: "GIFT AND HOSPITALITY TYPE", status: "STATUS", id: "1234"}
        cur_table_data.unshift(headerCSV);
        for(let i = 0; i<  cur_table_data.length; i++){
          var container = [];
          container = Object.values(cur_table_data[i])
          if(container.length > 0);
        //   container.shift();
            container.pop()

          cur_table_arr.push(container)
        }
        console.log("cur_table_arr",cur_table_arr)
        let csvContent = "data:text/csv;charset=utf-8,"
            + cur_table_arr.map(e => e.join(", ")).join("\n");
        var encodedUri = encodeURI(csvContent);
        var link = document.createElement("a");
        link.setAttribute("href", encodedUri);
        link.setAttribute("download", "Gift_and_Hospitality_Types.csv");
        document.body.appendChild(link);
        link.click();
      }

      exportToresultTypeCSV  = (event)=>{
        event.preventDefault();
        var cur_table_data = Store.getStoreData('table_result_cur_data');
        console.log("table_result_cur_data",cur_table_data)
        var cur_table_arr = [];
        let headerCSV = {type: "RESULT TYPE", status: "STATUS", id: "1234"}
        cur_table_data.unshift(headerCSV);
        for(let i = 0; i<  cur_table_data.length; i++){
          var container = [];
          container = Object.values(cur_table_data[i])
          if(container.length > 0);
        //   container.shift();
            container.pop()

          cur_table_arr.push(container)
        }
        console.log("cur_table_arr",cur_table_arr)
        let csvContent = "data:text/csv;charset=utf-8,"
            + cur_table_arr.map(e => e.join(", ")).join("\n");
        var encodedUri = encodeURI(csvContent);
        var link = document.createElement("a");
        link.setAttribute("href", encodedUri);
        link.setAttribute("download", "Result_Types.csv");
        document.body.appendChild(link);
        link.click();
      }

    updateGeneral = (obj) => {
        let {module_config} = this.state;
        module_config.general = obj;
        const postData = {command: "save_module_config", module_config: module_config};
        const api = new APICall();
        api.command(postData, this.aftergeneralUpdate);
    }
    updateApprovalGeneral = (obj) => {
        let {module_config} = this.state;
        module_config.approval_level = obj;
        const postData = {command: "save_module_config", module_config: module_config};
        const api = new APICall();
        api.command(postData, this.aftergeneralUpdate);
    }
    aftergeneralUpdate = (result) => {
        this.setState({module_config: result.result});
    }
    afterDragCustomUpdate = (result) => {
        this.setState({module_config: result.result});
        let gifts_index = Store.getStoreData('gifts-index')
        if(gifts_index !== null && 'result' in gifts_index && 'module_config' in gifts_index.result){
            gifts_index.result.module_config = result.result;
        }
        console.log('gifts_index==>', gifts_index);
        Store.updateStore('gifts-index', gifts_index)
    }

    updateDragCustomField = (obj) => {
        let module_config = JSON.parse(JSON.stringify(this.state.module_config));
        module_config.custom_fields.additional_fields = obj;
        //this.setState({module_config});
        const postData = {command: "save_module_config", module_config: module_config};
        const api = new APICall();
        api.command(postData, this.afterDragCustomUpdate);
	}



    updateSaveCustomAdditionalField = (obj) => {
        let module_config = JSON.parse(JSON.stringify(this.state.module_config));
        module_config.custom_fields.additional_fields = obj;
        //this.setState({module_config});
        const postData = {command: "save_module_config", module_config: module_config};
        const api = new APICall();
        api.command(postData, this.afterAddCustomAdditionalField);
	}

    afterAddCustomAdditionalField = (result) => {
        let msg = '';
        let title = 'Alert';
        msg = 'Custom Field added successfully.'
        // alert("Custom Field added successfully.")
        let alert_param = {title: title, message: msg, ok_text: 'OK', confirm: false, alertHandler: this.samealertremoveHandler, stack: {id: 0}}
        this.setState({alert_param: alert_param, module_config: result.result})
        // this.setState({module_config: result.result});
        let gifts_index = Store.getStoreData('gifts-index')
        if(gifts_index !== null && 'result' in gifts_index && 'module_config' in gifts_index.result){
            gifts_index.result.module_config = result.result;
        }
        console.log('gifts_index==>', gifts_index);
        Store.updateStore('gifts-index', gifts_index)
    }

    updateCustomAdditionalField = (obj) => {
        let module_config = JSON.parse(JSON.stringify(this.state.module_config));
        module_config.custom_fields.additional_fields = obj;
        //this.setState({module_config});
        const postData = {command: "save_module_config", module_config: module_config};
        const api = new APICall();
        api.command(postData, this.afterEditCustomAdditionalField);
	}

    samealertremoveHandler = (result,stack) => {
        this.setState({alert_param: null})
    }

    afterEditCustomAdditionalField = (result) => {
        let msg = '';
        let title = 'Alert';
        msg = 'Custom Field updated successfully.'
        // alert("Custom Field updated successfully.")
        let alert_param = {title: title, message: msg, ok_text: 'OK', confirm: false, alertHandler: this.samealertremoveHandler, stack: {id: 0}}
        this.setState({alert_param: alert_param})
        this.setState({module_config: result.result});
        let gifts_index = Store.getStoreData('gifts-index')
        if(gifts_index !== null && 'result' in gifts_index && 'module_config' in gifts_index.result){
            gifts_index.result.module_config = result.result;
        }
        console.log('gifts_index==>', gifts_index);
        Store.updateStore('gifts-index', gifts_index)
    }

    deleteCustomAdditionalField = (obj) => {
        let module_config = JSON.parse(JSON.stringify(this.state.module_config));
        module_config.custom_fields.additional_fields = obj;
        const postData = {command: "save_module_config", module_config: module_config};
        const api = new APICall();
        api.command(postData, this.afterDeleteCustomAdditionalField);
	}

    afterDeleteCustomAdditionalField = (result) => {
        this.setState({module_config: result.result});
        let msg = '';
        let title = 'Alert';
        msg = 'Custom Field deleted successfully.'
        // alert("Custom Field deleted successfully.")
        let alert_param = {title: title, message: msg, ok_text: 'OK', confirm: false, alertHandler: this.samealertremoveHandler, stack: {id: 0}}
        this.setState({alert_param: alert_param})
        let gifts_index = Store.getStoreData('gifts-index')
        if(gifts_index !== null && 'result' in gifts_index && 'module_config' in gifts_index.result){
            gifts_index.result.module_config = result.result;
        }
        console.log('gifts_index==>', gifts_index);
        Store.updateStore('gifts-index', gifts_index)
    }

    updateCustomFields = (obj) => {
        let module_config = JSON.parse(JSON.stringify(this.state.module_config));
        module_config.custom_fields = obj;
        //this.setState({module_config});
        const postData = {command: "save_module_config", module_config: module_config};
        const api = new APICall();
        api.command(postData, this.aftercustomfieldUpdate);
	}

    aftercustomfieldUpdate = (result) => {
        this.setState({module_config: result.result});
        let gifts_index = Store.getStoreData('gifts-index')
        if(gifts_index !== null && 'result' in gifts_index && 'module_config' in gifts_index.result){
            gifts_index.result.module_config = result.result;
        }
        console.log('gifts_index==>', gifts_index);
        Store.updateStore('gifts-index', gifts_index)
    }

    render()
	{  if (Store.getStoreData('role') !== 'admin_manager') {
		return (
			<ErrorBar>
				<div style={{float: "left"}}>You are not authorised to access this page. If you think this is in error, please contact your administrator.</div>
				<div style={{float: "right", fontSize: "16px", cursor: "pointer"}} onClick={this.closePage}>x</div>
				<div style={{clear: "both"}}></div>
			</ErrorBar>
		);
	}
	if (this.state.module_config === null) {
		return <div><CSLLoader /></div>
    }
	return (
		<div style={{padding: "2px 2px 2px 2px"}}>
            <DashboardModalHeader>
                <CloseBtn onClick={this.closePage}><FaTimes /></CloseBtn>
                <div style={{clear: "both"}}></div>
            </DashboardModalHeader>
			<ReviewBodyContainer>
				<ReviewLeftContainer style={{position: "sticky", top: "2px"}}>
					<ReviewTab onClick={this.returnSection("general")} style={{marginTop: "2px", backgroundColor:this.state.active_section_id === "general"?"#11aba6" : "#ffffff"}}>
						{
							(() => {
								if (this.state.active_section_id === "general") {
									return (<div style={{float: "left", fontWeight: "600", color:"#fff"}}>General</div>);
								} else {
									return (<div style={{float: "left",color: "#1a3552"}}>General</div>);
								}
							})()
						}
						<div style={{float: "right", color: "#39b3ad", marginTop: "2px", paddingLeft: "30px"}}></div>
						<div style={{clear: "both"}}></div>
					</ReviewTab>
                    <ReviewTab onClick={this.returnSection("email")} style={{marginTop: "2px", backgroundColor:this.state.active_section_id === "email"?"#11aba6" : "#ffffff"}}>
						{
							(() => {
								if (this.state.active_section_id === "email") {
									return (<div style={{float: "left", fontWeight: "600", color:"#fff"}}>Email</div>);
								} else {
									return (<div style={{float: "left", color: "#1a3552"}}>Email</div>);
								}
							})()
						}
						<div style={{float: "right", color: "#39b3ad", marginTop: "2px", paddingLeft: "30px"}}></div>
						<div style={{clear: "both"}}></div>
					</ReviewTab>
					<ReviewTab onClick={this.returnSection("gift_and_hospitality_types")} style={{marginTop: "2px", backgroundColor:this.state.active_section_id === "gift_and_hospitality_types"?"#11aba6" : "#ffffff"}}>
						{
							(() => {
								if (this.state.active_section_id === "gift_and_hospitality_types") {
									return (<div style={{float: "left", fontWeight: "600", color:"#fff"}}>Gift and Hospitality Types</div>);
								} else {
									return (<div style={{float: "left",color: "#1a3552"}}>Gift and Hospitality Types</div>);
								}
							})()
						}
						<div style={{float: "right", color: "#39b3ad", marginTop: "2px", paddingLeft: "30px"}}></div>
						<div style={{clear: "both"}}></div>
					</ReviewTab>
                    <ReviewTab onClick={this.returnSection("approval")} style={{marginTop: "2px", backgroundColor:this.state.active_section_id === "approval"?"#11aba6" : "#ffffff"}}>
						{
							(() => {
								if (this.state.active_section_id === "approval") {
									return (<div style={{float: "left", fontWeight: "600", color:"#fff"}}>Approval</div>);
								} else {
									return (<div style={{float: "left",color: "#1a3552"}}>Approval</div>);
								}
							})()
						}
						<div style={{float: "right", color: "#39b3ad", marginTop: "2px", paddingLeft: "30px"}}></div>
						<div style={{clear: "both"}}></div>
					</ReviewTab>
                    <ReviewTab onClick={this.returnSection("custom_fields")} style={{marginTop: "2px", backgroundColor:this.state.active_section_id === "custom_fields"?"#11aba6" : "#ffffff"}}>
						{
							(() => {
								if (this.state.active_section_id === "custom_fields") {
									return (<div style={{float: "left", fontWeight: "600", color:"#fff"}}>Custom Fields</div>);
								} else {
									return (<div style={{float: "left",color: "#1a3552"}}>Custom Fields</div>);
								}
							})()
						}
						<div style={{float: "right", color: "#39b3ad", marginTop: "2px", paddingLeft: "30px"}}></div>
						<div style={{clear: "both"}}></div>
					</ReviewTab>
                    <ReviewTab onClick={this.returnSection("result_types")} style={{marginTop: "2px", backgroundColor:this.state.active_section_id === "result_types"?"#11aba6" : "#ffffff"}}>
						{
							(() => {
								if (this.state.active_section_id === "result_types") {
									return (<div style={{float: "left", fontWeight: "600", color:"#fff"}}>Result Types</div>);
								} else {
									return (<div style={{float: "left",color: "#1a3552"}}>Result Types</div>);
								}
							})()
						}
						<div style={{float: "right", color: "#39b3ad", marginTop: "2px", paddingLeft: "30px"}}></div>
						<div style={{clear: "both"}}></div>
					</ReviewTab>
					{/*<ReviewTab onClick={this.returnSection("permissions")} style={{marginTop: "2px", backgroundColor:this.state.active_section_id === "permissions"?"#11aba6" : "#ffffff"}}>*/}
					{/*	{*/}
					{/*		(() => {*/}
					{/*			if (this.state.active_section_id === "permissions") {*/}
					{/*				return (<div style={{float: "left", fontWeight: "600", color:"#fff"}}>Permissions</div>);*/}
					{/*			} else {*/}
					{/*				return (<div style={{float: "left",color: "#1a3552"}}>Permissions</div>);*/}
					{/*			}*/}
					{/*		})()*/}
					{/*	}*/}
					{/*	<div style={{float: "right", color: "#39b3ad", marginTop: "2px", paddingLeft: "30px"}}></div>*/}
					{/*	<div style={{clear: "both"}}></div>*/}
					{/*</ReviewTab>*/}
				</ReviewLeftContainer>
				<ReviewRightContainer>
					{
						(() => {
							if (this.state.active_section_id === "general") {
								return (<General general={JSON.parse(JSON.stringify(this.state.module_config.general))} updateCurrencyValue={this.updateCurrencyValue} updateGeneral={this.updateGeneral}/>);
                            }
                            if (this.state.active_section_id === "email") {
                                return (<Email email={JSON.parse(JSON.stringify(this.state.module_config.email))} variables={this.state.module_config.variables} general={JSON.parse(JSON.stringify(this.state.module_config.general))} updateGeneralSave={this.updateEmailRemainder} updateEmail={this.updateEmail} />);
                            }
							if (this.state.active_section_id === "gift_and_hospitality_types") {
								return (<GiftsTypes general={JSON.parse(JSON.stringify(this.state.module_config.general))} gift_types={JSON.parse(JSON.stringify(this.state.module_config.gift_types))} changeActiveInactive={this.changeActiveInactive} saveGiftValue={this.saveGiftValue} updateGiftValue={this.updateGiftValue} deleteGiftTypeValue={this.deleteGiftTypeValue} importGiftValue={this.importGiftValue} standard_gift_types={this.state.standard_gift_type}/>);
                            }
							if (this.state.active_section_id === "approval") {
								return (<Approval general={JSON.parse(JSON.stringify(this.state.module_config.general))} server_name={this.state.server_name} approval_level={JSON.parse(JSON.stringify(this.state.module_config.approval_level))} allActiveUsers={this.state.allActiveUsers} gift_types={JSON.parse(JSON.stringify(this.state.module_config.gift_types))} user_level_approval={JSON.parse(JSON.stringify(this.state.module_config.user_level_approval))} saveUserLevelApproval={this.saveUserLevelApproval} updateUserLevelApproval={this.updateUserLevelApproval} deletedUserLevelApproval={this.deletedUserLevelApproval} updateApprovalMinimumValue={this.updateApprovalMinimumValue} updateGeneral={this.updateGeneral} updateGiftValue={this.updateGiftValue} updateApprovalGeneral={this.updateApprovalGeneral} updateCurrencyValue={this.updateCurrencyValue} saveApprovalValue={this.saveApprovalValue} saveApprovalValueType={this.saveApprovalValueType} updateApprovalValue={this.updateApprovalValue} updateApprovalValueType={this.updateApprovalValueType} deleteApprovalValue={this.deleteApprovalValue} deleteApprovalValueType={this.deleteApprovalValueType}/>);
                            }
                            if (this.state.active_section_id === "custom_fields") {
								return (<CustomFields custom_fields={this.state.module_config.custom_fields} updateCustomFields={this.updateCustomFields} updateDragCustomField={this.updateDragCustomField} updateSaveCustomAdditionalField={this.updateSaveCustomAdditionalField} updateCustomAdditionalField={this.updateCustomAdditionalField} deleteCustomAdditionalField={this.deleteCustomAdditionalField}/>);
							}
                            if (this.state.active_section_id === "result_types") {
								return (<ResultTypes general={JSON.parse(JSON.stringify(this.state.module_config.general))} result_types={JSON.parse(JSON.stringify(this.state.module_config.result_types))} standard_result_types={this.state.standard_result_type} changeActiveInactive={this.changeActiveInactiveresulttype} updateResultValue={this.updateResultValue} deleteResultTypeValue={this.deleteResultTypeValue} importResultValue={this.importResultValue} saveResultValue={this.saveResultValue} />);
							}
							if (this.state.active_section_id === "permissions") {
								return (<Permissions permissions={JSON.parse(JSON.stringify(this.state.permissions))} updatePermissions={this.updatePermissions} searchUsers={this.searchUsers} changeAllRoles={this.changeAllRoles} />);
							}
						})()
					}
                    {
                        (() => {
                            if (this.state.active_section_id === "gift_and_hospitality_types") {
                                return (
                                    <FooterButtonsContainer>
                                        <ExportButton onClick={this.exportTogiftTypeCSV}>Export</ExportButton>
                                        <div style={{clear: "both"}}></div>
                                    </FooterButtonsContainer>
                                );
                            }
                            if (this.state.active_section_id === "result_types") {
                                return (
                                    <FooterButtonsContainer>
                                        <ExportButton onClick={this.exportToresultTypeCSV}>Export</ExportButton>
                                    </FooterButtonsContainer>
                                );
                            }
                            if (this.state.active_section_id !== "gift_and_hospitality_types" && this.state.active_section_id !== "result_types" && this.state.active_section_id !== "email" && this.state.active_section_id !== "custom_fields") {
                                return (
                                    <FooterButtonsContainer>
                                        <CancelBtn onClick={this.closePage}>CANCEL</CancelBtn>
                                        <SaveBtn onClick={this.updateModuleconfig}>SUBMIT</SaveBtn>
                                        <div style={{clear: "both"}}></div>
                                    </FooterButtonsContainer>
                                )
                            }
                        })()
                    }


				</ReviewRightContainer>
				<div style={{clear: "both"}}></div>
			</ReviewBodyContainer>
			<AlertBox alertParam = {this.state.alert_param}/>
		</div>
	);
}
}

export default ModuleConfigHOC;
