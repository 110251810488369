import React from 'react';
import styled from 'styled-components';
import Store from '../../Common/Store';
import CSLTable from '../Common/CSLTable';
import DatePicker from 'react-date-picker';
import APICall from '../../Common/APICall';
import GMGiftsApprovalModal from './GMGiftsApprovalModal';
import {RiCloseLine} from 'react-icons/ri'
import {IoIosArrowDown, IoIosArrowUp, IoIosTrash} from 'react-icons/io';
import GMAskGiftModal from './GMAskGiftModal';
import FollowupModal from './FollowupModal';
import CurrentFollowupListsModal from './CurrentFollowupListsModal';
import AdvancedFilter from '../Filter/AdvancedFilter';
import CSLbucketloader from '../Common/CSLbucketloader';
import CSLLoader from '../Common/CSLLoader';
import CSLRegisterTable from '../RDR/CSLRegisterTable';
import reportData from '../RDR/reportData';
import AlertBox from '../Common/AlertBox'

import * as XLSX from 'xlsx';
import * as FileSaver from 'file-saver';
import moment from 'moment';
import XcelLoader from './../../Common/XcelLoader';

const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
const fileExtension = '.xlsx';


const MRButton = styled.button`
    box-sizing: border-box;
    background-color: #37ada7;
    color: #ffffff;
    border: 1px solid #37ada7;
    width: 84px;
    font-size: 13px;
    height: 25px;
    border-radius: 4px;
`;
const OverviewInfoHeader = styled.div`
    padding: 12px 15px;
    background-color: #ffffff;
    border-top-left-radius: 3px;
    border-top-right-radius: 3px;    
    width: 96%;
    margin: 0 auto;
    margin-top: 30px;    
`;
const OverviewInfoBody = styled.div`
	padding: 15px;
    background-color: #ffffff;
    border: 1px solid #d7dddf;
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
    width: 96%;
    margin: 0 auto;
    margin-bottom: 30px;
`;
const BoxStyle = styled.div`
  float: left;
  width: 15px;
  height: 15px;
  margin: 2px;
  border: 1px solid rgba(0, 0, 0, .2);
  margin-right: 10px;
`;
const ConflictInput = styled.input`
    height: 30px;
    width: 100%;
    border: 1px solid #ffffff;
    border-radius: 3px;
    box-shadow: 0 0 4px #c4c4c4;
    padding: 5px;
    color: black;
`;
const ConflictSelect = styled.select`
    display: block;
    box-sizing: border-box;
    height: 40px;
    width: 100%;
    background-color: #fff;
    border: 1px solid #ffffff;
    border-radius: 3px;
    box-shadow: 0 0 4px #c4c4c4;
    font-family: Montserrat,sans-serif;
    color: #414141;
`;
const CSLDateCover = styled.div`
    height: 20px;
    width: 20%;
    border: 1px solid #ffffff;
    border-radius: 3px;
    box-shadow: 0 0 4px #c4c4c4;
    padding: 5px;
    color: #979797;
`;
const RegModalIssueDateLabel = styled.label`
    font-weight: 600;
    color: #282828;
    margin-bottom: 10px;
`;
const RegCancelButton = styled.button`
    display: inline-block;
    box-sizing: border-box;
    background-color: #ffffff;
    color: #909090;
    border: 2px solid #dadada;
    width: 100px;
    height: 35px;
    border-radius: 4px;
    font-size: 11px;
    cursor: pointer;
`;
const RegSubmitButton = styled.button`
    display: inline-block;
    box-sizing: border-box;
    background-color: #37ada7;
    color: #ffffff;
    width: 100px;
    height: 35px;
    border-radius: 4px;
    border: 1px solid #37ada7;
    margin-left: 5px;
    font-size: 11px;
    cursor: pointer;
`;
const ExportButton = styled.button`
    box-sizing: border-box;
    background-color: #213E61;
    color: #F2F2F2;
    border: 1px solid #213E61;
    width: 125px;
    font-size: 14px;
    height: 32px;
    border-radius: 5px;
    margin-right: 3px;
    margin-top: 19px;
    cursor: pointer;
`;
const InactiveOverlay = styled.div`
  display: block;
  box-sizing: border-box;
  height: 150%;
  width: 100%;
  background-color: #ffffff;
  position: absolute;
  left: -2%;
  top: -46px;
  z-index: 1000;
  opacity: 0.8;
`;
class GMGiftsList extends React.Component
{
    state = {
        isLoaded: false,
        pending_tasks: [],
        advanceFilter: false,
        show_modal: null,
        giftsFilterRecipient: "",
        giftsFilterType: "",
        giftsFilterStatus: "",
        filterRequestedFromDate: new Date(),
        filterRequestedToDate: new Date(),
        ready: false,
        giftsTaskList: null,
        giftsTaskListFilter: null,
        curGiftsid: 0,
        userRole: "",
        giftTypeList: [],
        view_count: 0,
        isLoadedIcon: false,
        alert_param: null,
        active_followup: {}
    };
    constructor(props) {
        super(props);
    }

    componentDidMount() {
        let myGiftsTask = Store.getStoreData('mytasks');
        let updateFromDate = new Date(this.state.filterRequestedFromDate.getFullYear(), this.state.filterRequestedFromDate.getMonth() - 12, this.state.filterRequestedFromDate.getDate());
        let task_id = this.props.taskId;
        console.log("task_id3===>",task_id)

        let giftTypeList = [];
        if('gift_types' in Store.getStoreData('module_config')){
            giftTypeList = Store.getStoreData('module_config').gift_types;
        }

        let modifyGiftsTasks = [];
        let filterGiftsTasks = [];
        let userrole = Store.getStoreData('role');
        for(let d of myGiftsTask){
            if(userrole === 'gm'){
                if('general' in Store.getStoreData('module_config') && 'show_parent_company_gifts' in Store.getStoreData('module_config').general && Store.getStoreData('module_config').general.show_parent_company_gifts === false){
                    if (d[1] !== Store.getStoreData('curCompanyID')) {
                        if(d[2] !== "COMP_GIFTS_REMOVE"){
                            modifyGiftsTasks.push(d)
                        }
                        filterGiftsTasks.push(d);
                    }
                }else{
                    if(d[2] !== "COMP_GIFTS_REMOVE"){
                        modifyGiftsTasks.push(d)
                    }
                    filterGiftsTasks.push(d)
                }
            }else{
                if(d[2] !== "COMP_GIFTS_REMOVE"){
                    modifyGiftsTasks.push(d)
                }
                filterGiftsTasks.push(d)
            }
        }


        // let updateMyGiftsTask = [];
        // myGiftsTask.forEach((item) => {
        //     if(item[2] !== "COMP_GIFTS_REMOVE"){
        //         updateMyGiftsTask.push(item)
        //     }
        // })
        // let userrole = Store.getStoreData('role');
        // let updateMyGiftsTaskNew = []
        // if(userrole === 'gm' && 'general' in Store.getStoreData('module_config') && 'show_parent_company_gifts' in Store.getStoreData('module_config').general && Store.getStoreData('module_config').general.show_parent_company_gifts === false){
        //     updateMyGiftsTask.forEach((value) => {
        //         if (value[1] !== Store.getStoreData('curCompanyID')) {
        //             updateMyGiftsTaskNew.push(value);
        //         }
        //     })
        // }else{
        //     updateMyGiftsTaskNew = updateMyGiftsTask;
        // }

        let followup_tasks = Store.getStoreData('followup_tasks');
        let active_followup = {};
        for(let d of followup_tasks){
            active_followup[d[0].toString()] = 1
        }

        this.setState({ giftsTaskList: modifyGiftsTasks,giftsTaskListFilter: filterGiftsTasks, filterRequestedFromDate: updateFromDate, userRole:userrole,giftTypeList: giftTypeList,
            active_followup})
        if(task_id > 0) {
            console.log("task id", task_id)
            this.goToTaskUrlBare(task_id)
        }else{
            this.setState({ready: true})
        }
    }

    componentDidUpdate(prevprops) {
        if(this.props === prevprops)return
        let myGiftsTask = Store.getStoreData('mytasks');

        let modifyGiftsTasks = [];
        let filterGiftsTasks = [];
        let userrole = Store.getStoreData('role');
        for(let d of myGiftsTask){
            if(userrole === 'gm'){
                if('general' in Store.getStoreData('module_config') && 'show_parent_company_gifts' in Store.getStoreData('module_config').general && Store.getStoreData('module_config').general.show_parent_company_gifts === false){
                    if (d[1] !== Store.getStoreData('curCompanyID')) {
                        if(d[2] !== "COMP_GIFTS_REMOVE"){
                            modifyGiftsTasks.push(d)
                        }
                        filterGiftsTasks.push(d);
                    }
                }else{
                    if(d[2] !== "COMP_GIFTS_REMOVE"){
                        modifyGiftsTasks.push(d)
                    }
                    filterGiftsTasks.push(d)
                }
            }else{
                if(d[2] !== "COMP_GIFTS_REMOVE"){
                    modifyGiftsTasks.push(d)
                }
                filterGiftsTasks.push(d)
            }
        }

        // let updateMyGiftsTask = [];
        // myGiftsTask.forEach((item) => {
        //     if(item[2] !== "COMP_GIFTS_REMOVE"){
        //         updateMyGiftsTask.push(item)
        //     }
        // })
        // let userrole = Store.getStoreData('role');
        // let updateMyGiftsTaskNew = []
        // if(userrole === 'gm' && 'general' in Store.getStoreData('module_config') && 'show_parent_company_gifts' in Store.getStoreData('module_config').general && Store.getStoreData('module_config').general.show_parent_company_gifts === false){
        //     updateMyGiftsTask.forEach((value) => {
        //         if (value[1] !== Store.getStoreData('curCompanyID')) {
        //             updateMyGiftsTaskNew.push(value);
        //         }
        //     })
        // }else{
        //     updateMyGiftsTaskNew = updateMyGiftsTask;
        // }
        this.setState({ giftsTaskList: modifyGiftsTasks,giftsTaskListFilter: filterGiftsTasks, ready: true })
    }

    goToTaskUrlBare = (taskID) => {
        let postData = { "command" : "getTaskCallbyTaskID", "task_id" : taskID }
        postData['gcs'] = Store.getStoreData('gc_filters');
        let api = new APICall();
        api.command(postData, this.processGiftsTaskAPIData);
    }

    processGiftsTaskAPIData = (result) => {
        console.log(result);
        if(result.error_code > 0) {
            let msg = result.error_msg;
            let title = 'Alert'
            let alert_param = {title: title, message: msg, ok_text: 'OK', confirm: false, alertHandler: this.samealertremoveHandler, stack: {id: 0}}
            this.setState({alert_param: alert_param})
            // alert(msg)
            return false;
          }
        let cur_lane = result.result.parenttask.cur_lane;
        let parentID = result.result.parenttask.id;
        if(cur_lane === 'COMP_GIFTS_FOLLOWUP_ASSIGNMENT' || cur_lane === 'COMP_GIFTS_FOLLOWUP_COMPLETE'){
            this.currentfollowupModal(parentID);
        }else if(cur_lane === 'COMP_GIFTS_TEAMSAVE'){
            this.processGiftsSaveTaskData(result);
        }else{
            this.processGiftsTaskData(result);
        }
    }

    currentfollowupModal = (task_id) => {
        //event.preventDefault();
        console.log('ROWS', task_id);
        let postData = { command: "GetSubTaskCallbyTaskID", task_id: task_id};
        postData['gcs'] = Store.getStoreData('gc_filters');
        let api = new APICall();
        api.command(postData, this.openCurrentFollowupModal);
    }
    openCurrentFollowupModal = (result) => {
        console.log(result);
        if(result.error_code > 0) {
            let msg = result.error_msg;
            let title = 'Alert'
            let alert_param = {title: title, message: msg, ok_text: 'OK', confirm: false, alertHandler: this.samealertremoveHandler, stack: {id: 0}}
            this.setState({alert_param: alert_param})
            // alert(msg)
            return false;
          }
        Store.updateStore('followup_task', result.result.task);
        this.setState({showModal: 'current_view_followup_modal',ready: true});
    }


    goToTaskUrl = (row) => (event) => {
        event.preventDefault();
        console.log('ROWS', row);
        this.setState({isLoadedIcon: true})
        this.showGiftsApprovalModal(row);
    }

    showGiftsApprovalModal = (row) => {
        if(this.props.taskId > 0) {
            let mytaskdata = Store.getStoreData('mytasks');
            let curlane = "";
            for(let d of mytaskdata){
            //for(let i=0;i<mytaskdata.length;i++){
                if(d[0] === parseInt(row)){
                    curlane = d[2]
                }
            }
            console.log("mytaskdata",curlane)
            Store.updateStore('q_index', 1);
            let postData = { "command" : "getTaskCallbyTaskID", "task_id" : row }
            postData['gcs'] = Store.getStoreData('gc_filters');
            let api = new APICall();
            if(curlane === "COMP_GIFTS_TEAMSAVE"){
                api.command(postData, this.processGiftsSaveTaskDataNew);
            }else{
                api.command(postData, this.processGiftsTaskDataNew);
            }
        }else{
            console.log('ROWS', row);
            Store.updateStore('q_index', row.index);
            console.log("giftsdata",row.original.id);
            let postData = { "command" : "get_task", "task_id" : row.original.id }
            let api = new APICall();
            if(row.row.status === 'Saved') {
                api.command(postData, this.processGiftsSaveTaskData);
            }else{
                api.command(postData, this.processGiftsTaskData);
            }
        }

    }
    processGiftsSaveTaskDataNew = (result) => {
        console.log('task result', result);
        if(result.error_code > 0) {
            let msg = result.error_msg;
            let title = 'Alert'
            let alert_param = {title: title, message: msg, ok_text: 'OK', confirm: false, alertHandler: this.samealertremoveHandler, stack: {id: 0}}
            this.setState({alert_param: alert_param})
            // alert(msg)
            return false;
          }
        Store.updateStore('cur_q_modal_data', result.result.parenttask);
        this.setState({showModal: "GMSaveGiftModal", ready: true});
    }
    processGiftsTaskDataNew = (result) => {
        console.log('task result', result);
        if(result.error_code > 0) {
            let msg = result.error_msg;
            let title = 'Alert'
            let alert_param = {title: title, message: msg, ok_text: 'OK', confirm: false, alertHandler: this.samealertremoveHandler, stack: {id: 0}}
            this.setState({alert_param: alert_param})
            // alert(msg)
            return false;
          }
        Store.updateStore('cur_q_modal_data', result.result.parenttask);
        this.setState({showModal: "GMApprovalGiftModal", ready: true});
    }
    processGiftsSaveTaskData = (result) => {
        console.log('task result', result);
        Store.updateStore('cur_q_modal_data', result.result.parenttask);
        this.setState({showModal: "GMSaveGiftModal", ready: true, isLoadedIcon: false});
    }
    processGiftsTaskData = (result) => {
        console.log('task result', result);
        Store.updateStore('cur_q_modal_data', result.result.parenttask);
        let allChildTasks = result.result.childtask.result;
        let update_followuptask = [];
        for(let d of allChildTasks){
            if(d.is_active === 1 && d.is_delete === 0){
                update_followuptask.push(d)
            }
        }
        Store.updateStore('updated_followups', update_followuptask);
        Store.updateStore('manager_id', result.result.manager_id);
        this.setState({showModal: "GMApprovalGiftModal", ready: true, isLoadedIcon: false});
    }
    processMygiftsValue = () => {
        const has_gc = Store.getStoreData('has_gc');
        var mytaskdata = Store.getStoreData('mytasks') === null ? [] : this.state.giftsTaskList;

        let ret = {data: [], columns: []};
        let i = 0;
        ret.columns =[
        {'Header' : 'Status', accessor: 'status', Cell: row => (
            <div>
                {
                    (() => {
                        if(row.original.status === "Approved"){
                            return( <div><BoxStyle style={{backgroundColor: "#04ADA8"}}/><div style={{padding: "1px"}}>{row.original.status}</div></div>)
                        }else if(row.original.status === "Declined"){
                            return( <div><BoxStyle style={{backgroundColor: "red"}}/><div style={{padding: "1px"}}>{row.original.status}</div></div>)
                        }else{
                            return( <div><BoxStyle style={{backgroundColor: "#234162"}}/><div style={{padding: "1px"}}>{row.original.status}</div></div>)
                        }
                    })()
                }

                </div>
          ), width: 170, headerStyle: {textAlign: 'left'}},
        {'Header' : 'ID', accessor: 'giftid', Cell: row => (
            <div style={{textDecoration: "underline",color: "#00B5B2", cursor: 'pointer'}} onClick={this.goToTaskUrl(row)}>{row.original.giftid}</div>
          ), width: 100, headerStyle: {textAlign: 'left'}},
        {Header: 'Received/Given', accessor: 'receivedorgiven', minWidth: 150, headerStyle: {textAlign: 'left'}},
        {Header: 'Type', accessor: 'gifttype', minWidth: 150, headerStyle: {textAlign: 'left'}},
        {Header: 'Recipient', accessor: 'recipientname', minWidth: 180, headerStyle: {textAlign: 'left'}},
        {Header: 'Company', accessor: 'company_name', minWidth: 300, headerStyle: {textAlign: 'left'}, show: has_gc ? true : false},
        {'Header' : 'Tasks', accessor: 'no_of_followup', Cell: row => (
            (() => {
                if(row.original.no_of_followup === '00'){
                    return(<div style={{textDecoration: "underline",color: "#00B5B2"}} >{row.original.no_of_followup}</div>)
                }else{
                    return(<div style={{textDecoration: "underline",cursor: "pointer",color: "#00B5B2"}} onClick={this.goToFollowupTask(row)}>{row.original.no_of_followup}</div>)
                }
            })()
          ), width: 70, headerStyle: {textAlign: 'center',fontWeight: "600"},style: {textAlign: 'center'}},
        {Header: 'Value', accessor: 'value', minWidth: 70, headerStyle: {textAlign: 'center',fontWeight: "600"},style: {textAlign: 'center'}, sortMethod: (a,b) => {return a - b}},
        {Header: 'Result', accessor: 'resultvalue', minWidth: 120, headerStyle: {textAlign: 'left',fontWeight: "600"}},
        {Header: 'Gifted Date', accessor: 'gifted_date', minWidth: 100, headerStyle: {textAlign: 'center',fontWeight: "600"},style: {textAlign: 'center'}, sortMethod: (a, b) => {
            if (a === b) {
                return 0;
            }
            let adue = a.split("/");
            const aReverse = parseInt(adue[2]) * 10000 + parseInt(adue[1]) * 100 + parseInt(adue[0]);
            let  bbdue = b.split("/");
            const bReverse = parseInt(bbdue[2]) * 10000 + parseInt(bbdue[1]) * 100 + parseInt(bbdue[0]);
            return aReverse > bReverse ? 1 : -1;
          }},
        {Header: 'Added Date', accessor: 'discloserdate', minWidth: 100, headerStyle: {textAlign: 'center',fontWeight: "600"},style: {textAlign: 'center'}, sortMethod: (a, b) => {
            if (a === b) {
                return 0;
            }
            let adue = a.split("/");
            const aReverse = parseInt(adue[2]) * 10000 + parseInt(adue[1]) * 100 + parseInt(adue[0]);
            let  bbdue = b.split("/");
            const bReverse = parseInt(bbdue[2]) * 10000 + parseInt(bbdue[1]) * 100 + parseInt(bbdue[0]);
            return aReverse > bReverse ? 1 : -1;
          }},
        {Header: 'Reviewer', accessor: 'reviewerName', minWidth: 120, headerStyle: {textAlign: 'center'},style: {textAlign: 'center'}},
        {Header: 'Reviewed', accessor: 'reviewedDate', minWidth: 100, headerStyle: {textAlign: 'center'},style: {textAlign: 'center'}},
        {'Header' : 'Action', Cell: row => (
            <div style={{display: "inline-flex"}}>
                <MRButton style={{cursor: 'pointer'}} onClick={this.goToTaskUrl(row)}>View</MRButton>
                {
                (() => {
                    if(this.state.userRole === 'manager' || this.state.userRole === 'admin_manager'){
                        return(<div style={{fontSize: "20px",marginLeft: "15px",cursor: 'pointer',}} onClick={this.deleteGiftsTask(row.original.id)}><IoIosTrash /></div>)
                    }
                })()
                }
            </div>
          ), width: 140, headerStyle: {textAlign: 'center',fontWeight: "600"}}];

        let gc_assoc = Store.getStoreData('gc_assoc');
        //console.log("gc_assoc==>", gc_assoc)
        let gc_company = "";
        for(let d of mytaskdata) {
            i++;
            gc_company = parseInt(d[1]) < 100000 ? Store.getStoreData('gc_company')[0].company_name : gc_assoc[(parseInt(d[1])%100000).toString()];
            let status = "";
            let numberOfFollowup = 0;
            if(d[3] !== null){
                let a = 0
                let childtasksIDs = JSON.parse(d[3])
                for(let k of childtasksIDs){
                    // console.log("dddd===>", k)
                    // console.log("dddd===>", this.state.active_followup)
                    if(k.toString() in this.state.active_followup){
                        a++
                    }
                }
              numberOfFollowup = a;
            }
            let resultvalue = "";
            //let task_json = JSON.parse(d.task_json);
            if(d[2] === "COMP_GIFTS_REGISTER") {
                //status =  "Registered";
                status =  "Requires Approval";
                resultvalue = "Undetermined";
            }else if(d[2] === "COMP_GIFTS_TEAMSAVE") {
                status = "Saved";
                resultvalue = "Undetermined";
            }else if(d[2] === "COMP_GIFTS_COMPLETE") {
                // status = "Confirmed"
                status = "Approved"
                resultvalue = "Accepted";
            }else if(d[2] === "COMP_GIFTS_REMOVE") {
                status = "Declined"
                resultvalue = "Undetermined";
            }

            let ref_id = "";
            //if('ref_id' in task_json.object_data) {
            if(d[5] !== null) {
                ref_id = d[5];
            }

            let giftTypeName = "";
            let giftTypeID = parseInt(d[6]);
            this.state.giftTypeList.forEach((item) => {
                if (item.giftTypeID === giftTypeID) {
                    giftTypeName = item.giftTypeName;
                }
            });

            let approval_rejection_text = "";
            let approval_rejection_description = "";
            if(d[8] === 1){
                approval_rejection_text = d[9];
                approval_rejection_description = d[20];
            }
            if(d[10] === 1){
                approval_rejection_text = d[11];
                approval_rejection_description = d[21];
            }

            let nooffollowup = numberOfFollowup < 10 ? "0"+numberOfFollowup.toString() : numberOfFollowup;

            let reviewerName = '-';
            let contracts = Store.getStoreData('contacts');
            if(d[22] !== 0 && d[22] in contracts){
                reviewerName = contracts[d[22]];
            }
            let reviewedDate = '-'
            if(d[2] === 'COMP_GIFTS_COMPLETE' || d[2] === 'COMP_GIFTS_REMOVE'){
                reviewedDate = moment.unix(d[23]).format('DD/MM/YYYY')
            }

            let recipientname = d[13];
            if(d[26] === 'Other'){
                recipientname = 'Other - '+d[13];
            }

            let elem = {
                    'id': d[0],
                    'status' : status,
                    'giftid' : ref_id,
                    'company_name' : gc_company,
                    'receivedorgiven' : d[12] === 'received' ? 'Received' : 'Given',
                    'gifttype' : giftTypeName,
                    'recipientname' : recipientname,
                    'no_of_followup' : nooffollowup,
                    'value' : d[14],
                    'resultvalue' : resultvalue,
                    'discloserdate' : moment.unix(d[17]).format('DD/MM/YYYY'),
                    'donor' : d[15],
                    'description' : d[16],
                    'approval_rejection_text': approval_rejection_text,
                    'approval_rejection_description': approval_rejection_description,
                    'gifted_date': d[18] !== '' ? moment.unix(d[18]).format('DD/MM/YYYY') : '-',
                    'additional_fields': d[19],
                    'reviewerName': reviewerName,
                    'reviewedDate': reviewedDate,
                    'addedBy': d[4],
                    'giftGivenTo': d[27]
                   };
            ret.data.push(elem);

            let sarr = [];
            // ret.data.forEach((item) => {
            //     if (item.status !== "Confirmed" && item.status !== "Declined") {
            //         sarr.push(item);
            //     }
            // })
            ret.data.forEach((item) => {
                if (item.status === "Saved") {
                    sarr.push(item);
                }
            })
            ret.data.forEach((item) => {
                if (item.status === "Requires Approval") {
                    sarr.push(item);
                }
            })
            ret.data.forEach((item) => {
                // if (item.status === "Confirmed") {
                if (item.status === "Approved") {
                    sarr.push(item);
                }
            })
            ret.data.forEach((item) => {
                if (item.status === "Declined") {
                    sarr.push(item);
                }
            })
            ret.data = sarr;
        }
        Store.updateStore('table_cur_data', ret.data)
        return ret;
    }

    deleteGiftsTask = (taskid) => (event) => {
        event.preventDefault();
        let msg = 'Are you sure you want to delete this record?'
        let title = 'Confirm'
        let alert_param = {title: title, message: msg, ok_text: 'Yes', cancel_text: 'No', confirm: true, alertHandler: this.deleteGiftAlert, stack: {taskid: taskid}}
        this.setState({alert_param: alert_param})
        // if (window.confirm('Are you sure you want to delete this record?')) {
        //     console.log('ROWS', taskid);
        //     let postData = {command: "remove_task", task_id: taskid };
        //     console.log("postData",postData)
        //     let api = new APICall();
        //     this.setState({isLoadedIcon: true})
        //     api.command(postData, this.processRemoveGiftsData);
        // }
    }

    deleteGiftAlert = (result,stack) => {
        if(!result) {
            this.setState({alert_param: null})
            return
        }
        this.setState({alert_param: null, isLoadedIcon: true})
        console.log('ROWS', stack.taskid);
        let postData = {command: "remove_task", task_id: stack.taskid };
        console.log("postData",postData)
        let api = new APICall();
        api.command(postData, this.processRemoveGiftsData);
    }
    processRemoveGiftsData = (result) => {
        let msg = 'The gift has been deleted.'
        let title = 'Confirm'
        let alert_param = {title: title, message: msg, ok_text: 'OK', confirm: false, alertHandler: this.alertremoveHandler, stack: {id: 0}}
        this.setState({alert_param: alert_param, isLoadedIcon: false})
        console.log("result", result)
        // alert('The gift has been deleted.');
        // window.location.reload();
    }

    alertremoveHandler = (result,stack) => {
        this.setState({alert_param: null})
        window.location.reload();
    }
    samealertremoveHandler = (result,stack) => {
        this.setState({alert_param: null})
    }
    goToFollowupTask = (row) => (event) => {
        event.preventDefault();
        let command_data = { "command": "listfollowups", parent_id: row.original.id };
        let api = new APICall();
        this.setState({isLoadedIcon: true})
        api.command(command_data, this.updateFollowups(row.original.giftid));
    }
    updateFollowups = (giftid) => (result) => {
        Store.updateStore('current_followup_lists', result.result.followuptasks);
        console.log('result.result.followuptasks', result);
        this.setState({ showModal: "current_followup_list", curGiftsid: giftid, isLoadedIcon: false});
    }

    advanceFiltershow = (val) => {
        //console.log("Sourav",abc)
        this.setState({advanceFilter: val})
    }
    closeModal = (event) => {
        //event.preventDefault();
        this.setState({
            showModal: null
        })
    }
    closeForApproval = (event) => {
        //event.preventDefault();
        let postData = { "command": "refreshTask", role: Store.getStoreData('role') };
        let api = new APICall();
        api.command(postData, this.processTask);
    }
    processTask = (result) => {
        console.log("result==>",result)
        let myGiftsTask = result.result['my_tasks'];
        let updateMyGiftsTask = [];
        myGiftsTask.forEach((item) => {
            if(item[2] !== "COMP_GIFTS_REMOVE"){
                updateMyGiftsTask.push(item)
            }
        })
        let userrole = Store.getStoreData('role');
        let updateMyGiftsTaskNew = []
        if(userrole === 'gm' && 'general' in Store.getStoreData('module_config') && 'show_parent_company_gifts' in Store.getStoreData('module_config').general && Store.getStoreData('module_config').general.show_parent_company_gifts === false){
            updateMyGiftsTask.forEach((value) => {
                if (value[1] !== Store.getStoreData('curCompanyID')) {
                    updateMyGiftsTaskNew.push(value);
                }
            })
        }else{
            updateMyGiftsTaskNew = updateMyGiftsTask;
        }

        Store.updateStore('followup_tasks', result.result['followuptasks']);
        let gifts_index = Store.getStoreData('gifts-index')
        if(gifts_index !== null && 'result' in gifts_index && 'followuptasks' in gifts_index.result){
            gifts_index.result.followuptasks = result.result['followuptasks'];
        }
        console.log('gifts_index==>', gifts_index);
        Store.updateStore('gifts-index', gifts_index)

        let followup_tasks = result.result['followuptasks'];
        let active_followup = {};
        for(let d of followup_tasks){
            active_followup[d[0].toString()] = 1
        }
        this.setState({
            giftsTaskList: updateMyGiftsTaskNew,
            active_followup,
            showModal: null
        })
    }

    handleChange = (evt) => {
        this.setState({[evt.target.name]: evt.target.value});
    }
    onRequestedFromDate = (date) => {
        this.setState({filterRequestedFromDate: date});
    }
    onRequestedToDate = (date) => {
        this.setState({filterRequestedToDate: date});
    }
    filterClear = () => {
        console.log("Sourav")
        let filterFromdate = new Date();
        let updateFromDate = new Date(filterFromdate.getFullYear(), filterFromdate.getMonth() - 12, 1);
        this.setState({ giftsFilterRecipient: "",
            giftsFilterType: "",
            giftsFilterStatus: "",
            filterRequestedFromDate: updateFromDate,
            filterRequestedToDate: new Date()})
    }
    filterSubmit = (filtergiftList) => {

        this.setState({giftsTaskList: filtergiftList})

    }

    exportToExcel = () => {
        let wb = {SheetNames:[], Sheets:{}};
        let open_tasks = Store.getStoreData('table_cur_data') === null ? [] : Store.getStoreData('table_cur_data');
        wb.SheetNames.push("Gifts Register Manager");
        wb.Sheets["Gifts Register Manager"] = XLSX.utils.json_to_sheet(XcelLoader.gifts_export_tasks(open_tasks));
        const format = "DD-MM-YYYY HH:mm:ss"
        let tm = moment().format(format);
        const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
        const data = new Blob([excelBuffer], {type: fileType});
        FileSaver.saveAs(data, 'Gifts_Register' + '-' + tm + fileExtension);
    }

    render()
    {
        let contracts = Store.getStoreData('contacts');
        let giftTypeList = [];
        if('gift_types' in Store.getStoreData('module_config')){
            giftTypeList = Store.getStoreData('module_config').gift_types;
        }

        let task_id = this.props.taskId;
        if(!this.state.ready) {
            return <div><CSLLoader style={{position: 'absolute'}}/></div>
        }
        if(task_id > 0) {
            return(<div>
                {
                (() => {
                    if (this.state.showModal !== null) {
                        switch(this.state.showModal) {
                            case 'GMApprovalGiftModal' : return <GMGiftsApprovalModal closeModal={this.closeModal} closeModalForApproval={this.closeForApproval} closeModalNormal={this.closeModal} />; break;
                            case 'GMSaveGiftModal' : return <GMAskGiftModal closeModal={this.closeModal} />; break;
                            case 'current_view_followup_modal' : return <FollowupModal closeModal={this.closeModal} />; break;
                        }
                    }
                })()
                }
            </div>)
        }
        return (
            <div>
                {
                    (() => {
                        if(this.state.isLoadedIcon){
                            return <div><CSLLoader style={{position: 'absolute'}}/></div>
                        }
                    })()
                }
                <div style={{boxSizing: "border-box", padding: "15px 10px 0px 10px"}}><AdvancedFilter contacts={Store.getStoreData('contacts')} gifttypelist={giftTypeList} giftstasklist={this.state.giftsTaskListFilter} filterSubmitValue={this.filterSubmit}/></div>

                <OverviewInfoHeader>
                    <div style={{float: "left", color: "#323232", fontWeight: "600"}}>Gifts & Hospitality Register</div>
                    <div style={{clear: "both"}}></div>
                </OverviewInfoHeader>
                <OverviewInfoBody>
                    <div  style={{position: 'relative'}}>
                    {
                        (() => {
                                if(Store.getStoreData('mytasks') === null) {
                                return <CSLbucketloader style={{position: 'absolute'}} />
                                }
                        })()
                    }
                    <div style={{clear: "both", height: '3px'}}></div>
                    {/*<CSLTable
                        add={false}
                        processData={this.processMygiftsValue}
                        headerText={''}
                        tableRows={10}
                        refreshCallback={this.refreshCallback}
                    />*/}
                    <CSLRegisterTable
                        data={reportData}
                        processData={this.processMygiftsValue}
                        export={true}
                        reportButton={true}
                        tableRows={10}
                        exportToCSV={this.exportToExcel}
                    />
                    </div>


                    <div style={{float: "left", color: "#04ADA8", fontWeight: "600", fontStyle: "italic",marginTop: "21px", marginLeft: "4px"}}>{Store.getStoreData('mytasks') === null ? 0 : this.state.giftsTaskList.length} Gifts Listed</div>
                    <div style={{float: "right"}}>
                    </div>
                    <div style={{clear: "both"}}></div>
                </OverviewInfoBody>
                {
                (() => {
                    if (this.state.showModal !== null) {
                        switch(this.state.showModal) {
                            case 'GMApprovalGiftModal' : return <div style={{position:'absolute', left:'2%', top:'50px', width:'100%', height:'600px'}}><InactiveOverlay /><GMGiftsApprovalModal closeModalForApproval={this.closeForApproval} closeModalNormal={this.closeModal}/></div>; break;
                            case 'GMSaveGiftModal' : return <div style={{position:'absolute', left:'2%', top:'50px', width:'100%', height:'600px'}}><InactiveOverlay /><GMAskGiftModal closeModal={this.closeModal} /></div>; break;
                            // case 'view_followup_modal' : return <FollowupModal closeModal={this.closeModal} />; break;
                            case 'current_followup_list' : return <CurrentFollowupListsModal curGiftsid={this.state.curGiftsid} closeModal={this.closeModal} />; break;
                        }
                    }
                })()
                }
                <AlertBox alertParam = {this.state.alert_param}/>
            </div>
        );
    }
}
export default GMGiftsList;
