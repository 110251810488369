import React from 'react';
import {FaTimes} from 'react-icons/fa';
import styled from 'styled-components';
import Toggle from 'react-toggle';
import "./ReactToggle.css";
import approval_options from './approval_options.js'
import AlertBox from '../Common/AlertBox';

const EmailContainer = styled.div`
	width: 596px;
	height:auto;
	top:75px;
	position: fixed;
	background-color: #f6f9fd;
    border-radius: 2px;
    box-shadow: 0 0 20px #a7a6a6;
	z-index: 1005;
	padding: 0px;
`;
const CancelBtn = styled.button`
    padding: 10px 30px;
	background-color: #ffffff;
	border: 1px solid #EBEBEB;
	border-radius: 2px;
	color: #282828;
	cursor: pointer;	
`;
const MRModalLabel = styled.div`
    color: #212121;
    font-weight: 600;
    margin-top: 10px;
    margin-bottom: 10px;
`;
const MRModalInput = styled.input`
    height: 25px;
    width: 80%;
    border: 1px solid #ffffff;
    border-radius: 3px;
    box-shadow: 0 0 4px #c4c4c4;
    padding: 5px;
    padding-right: 10px;
    padding-left: 10px;
    color: #000000;
`;
const MRModalHeader = styled.div`
    background-color: white;
    display: block;
    padding-top: 5px;
    font-size: 14px;
    font-weight: 700;
    height: 100px;    
    width: calc(100% - 10px);
    border-width: 1px 1px 1px 10px;    
    border-left: 9px solid #04ADA8; 
    border-bottom: 1px solid #DFDFDF;
`;
const MRModalHeaderText = styled.div`
    font-weight: 700;
    font-size: 22px;
    float: left;
    margin-top: 24px;
    margin-left: 35px;  
    color: #143151; 
`;
const MRModalHeaderCloseBtn = styled.div`
    position: absolute;
    top: 8px;
    right: 10px;
    box-sizing: border-box;
    margin-top: 24px;
    margin-right: 20px;
    font-size: 25px;
    cursor: pointer;
    font-weight: 200;
`;
const MRModalBody = styled.div`
	position: relative;
	display: block;
	box-sizing: border-box;
	background-color: #F1F6F7;
	padding: 35px 35px 35px 35px;
	border-radius: 4px;
`;
const SaveBtn = styled.button`
	padding: 10px 30px;
	background-color: #37ADA7;
	border: 1px solid #37ADA7;
	border-radius: 2px;
	color: #ffffff;
	cursor: pointer;
	margin-left: 15px;
`;  
const MRModalSubHeaderText = styled.div`
    font-weight: 700;
    font-size: 13px;
    float: left;
    margin-left: 35px;
    margin-bottom: 20px;
    margin-top: 10px;
    color: #636363;
`;
const DocFooter = styled.div`
    position: relative;
    background-color: white;
    border-style: solid;
    border-width: 1px;
    border-color: #e9ecef;
    height: 79px;
    width: 100%;
    margin-left: 0px;
    border-bottom-radius: 2px;
`;
const FooterButtonsContainer = styled.div`
	text-align: right;
	padding-top: 25px;
	width: 90%;
`; 

const MRModalSelect = styled.select`
	display: block;
	box-sizing: border-box;
	height: 40px;
	width: 50%;
	background-color: #fff;
	border: 1px solid #ffffff;
	border-radius: 3px;
	box-shadow: 0 0 4px #c4c4c4;
	font-family: "Montserrat", sans-serif;
	color: #000000;
	margin-top: 10px;
	margin-bottom: 10px;
`;
class GiftsConfigModal extends React.Component
{
	state = {ready: false,giftTypeName: "",isActive: 1,isDelete: 0,giftTypeID: null, approver_level: 'ar', alert_param: null};

	componentDidMount()
	{        
        if(this.props.curid !== 0) {
            console.log("content==>",this.props.content)            
            this.setState({ready:true,giftTypeID: this.props.content.giftTypeID,giftTypeName: this.props.content.giftTypeName,
            isActive: this.props.content.isActive,isDelete: this.props.content.isDelete, approver_level: this.props.content.approver_level})
        }else{
            let randNo = Math.floor(Math.random()*(99999-10000+1)+10000);
            this.setState({ready:true,giftTypeID: randNo})
        }
	}

	closeModal = (event) => {
		event.preventDefault();
		this.props.closeModal();
    }
    
    handleChange = (event) => {
        event.preventDefault();
        this.setState({[event.target.name]: event.target.value});
    }

    handleisDescription = (value) => (event) => {
        //event.preventDefault();
        console.log("value",value)  
        let data = value === 1 ? 0 : 1;      
        this.setState({isDescription: data})        
    }

    submitGiftType = (event) => {
        event.preventDefault();
        if(this.state.giftTypeName === "")
        {            
            let alert_param = {title: 'ERROR', message: 'Please provide a gift name.', ok_text: 'Ok', confirm: false, 
                           alertHandler: this.checkvalidation, stack: {}}
            this.setState({alert_param: alert_param}) 
            return
        }
        let giftObject = {}
        giftObject.giftTypeID = this.state.giftTypeID;
        giftObject.giftTypeName = this.state.giftTypeName;            
        giftObject.isActive = parseInt(this.state.isActive);
        giftObject.isDelete = parseInt(this.state.isDelete);
        giftObject.approver_level = this.state.approver_level;             
        if(this.props.curid !== 0) {
            this.props.updateGiftTypeValue(giftObject);
        }else{                
            this.props.saveGiftTypeValue(giftObject);
        }        
    }

    checkvalidation = (result, stack) => {        
        this.setState({alert_param: null})              
    }

    handleToggleChange = (arg)=>{
		let {isActive} = this.state;
		isActive = isActive === 1 ? 0 : 1;		
		this.setState({isActive})
	}

	render()
	{
		if (!this.state.ready) {
			return (<div>Loading...</div>);
		}
        let isStatusShow = 'isStatusShow' in this.props ? this.props.isStatusShow : false;
        let isApprovalFieldShow = 'isApprovalFieldShow' in this.props ? this.props.isApprovalFieldShow : false;
		return (<div>
			<EmailContainer>
                <MRModalHeader>
                    <MRModalHeaderText>Gift Type</MRModalHeaderText>
                    <MRModalHeaderCloseBtn onClick={this.closeModal}><FaTimes /></MRModalHeaderCloseBtn>
                    <div style={{clear: "both"}}></div>   
                    <MRModalSubHeaderText>Fill out the below fields to submit your gift type.</MRModalSubHeaderText>
                    <div style={{clear: "both"}}></div>             
                </MRModalHeader>            
                <MRModalBody>
                    <MRModalLabel>Name <span style={{marginLeft: "5px",color: "red"}}>*</span></MRModalLabel>
                    <MRModalInput name="giftTypeName" value={this.state.giftTypeName} onChange={this.handleChange}/>
                    {
                        (() => {
                            if(isStatusShow){
                                return(<div>
                                    <MRModalLabel style={{marginTop: "30px"}}>Status</MRModalLabel>                    
                                    <Toggle name="isActive"
                                        defaultChecked={this.state.isActive === 1 ? true : false} 
                                        icons={false}
                                        onChange={()=> this.handleToggleChange('isActive')} />
                                </div>)
                            }
                        })()
                    }                   
                    {
                        (() => {
                            if(isApprovalFieldShow){
                                return(<div>
                                    <MRModalLabel style={{marginTop: "30px"}}>Approval Required</MRModalLabel>
                                    <MRModalSelect onChange={this.handleChange} name="approver_level" value={this.state.approver_level}>
                                    {
                                        approval_options.map((a, i) => {
                                            return (<option key={i} value={a.id}>{a.name}</option>)
                                        })
                                    }
                                    </MRModalSelect>
                                </div>)
                            }
                        })()
                    }
                    
                </MRModalBody>                
                <div style={{clear: "both"}}></div> 
                <DocFooter style={{float: "right"}}>
                    <FooterButtonsContainer>   
                        <CancelBtn onClick={this.closeModal}>Cancel</CancelBtn>
                        <SaveBtn onClick={this.submitGiftType}>Submit</SaveBtn>
                    </FooterButtonsContainer>   
                </DocFooter>
			</EmailContainer>
            <AlertBox alertParam = {this.state.alert_param}/>
            </div>
		);
	}
}

export default GiftsConfigModal;