import React from 'react';
import {IoIosArrowUp, IoIosArrowDown, IoIosClose, IoIosTrash} from 'react-icons/io';
import { Icon } from 'react-icons-kit';
import {androidRemove} from 'react-icons-kit/ionicons/androidRemove';
import styled from 'styled-components';
import CslTypeAhead from './CslTypeAhead';
import CslDropDown from './CslDropDown';
import CslDatepicker from './CslDatepicker';
import Store from '../../Common/Store.js';
import moment from 'moment';

const FilterTopBar = styled.div`
	background-color: #ffffff;
	font-weight: 600;
	color: ${process.env.DEFAULT_TEXT_COLOR};
	padding: 15px 20px;
`;
const FilterText = styled.div`
	float: left;
`;
const FilterChevron = styled.div`
	float: right;
	font-size: 16px;
	cursor: pointer;
`;
const FilterBody = styled.div`
	background-color: #ffffff;
	padding: 15px 20px;
	margin-top: 2px;
	width: 100%;
	box-sizing: border-box;
`;
const Section45 = styled.div`
	float: left;
	width: 45%;
	box-sizing: border-box;
`;
const Section40 = styled.div`
	float: left;
	width: 30%;
	box-sizing: border-box;
	padding-right: 20px;
	position: relative;
`;
const Section20 = styled.div`
	float: left;
	width: 20%;
	box-sizing: border-box;
`;
const Section10 = styled.div`
	float: left;
	width: 10%;
	text-align: center;
	box-sizing: border-box;
	padding-top: 10px;
`;
const FieldLabel = styled.div`
	font-weight: 600;
	color: ${process.env.DEFAULT_TEXT_COLOR};
	padding-bottom: 15px;
`;
const FilterClearBtn = styled.button`
	background-color: #ffffff;
	border: 1px solid #DBDBDB;
	border-radius: 4px;
	color: ${process.env.DEFAULT_TEXT_COLOR};
	float: left;
    padding: 12px 13%;
    cursor: pointer;
`;
const FilterApplyBtn = styled.button`
	background-color: #4FB2AF;
	border: 1px solid #4FB2AF;
	border-radius: 4px;
	color: #ffffff;
	float: right;
    padding: 12px 18%;
    cursor: pointer;
`;

class AdvancedFilter extends React.Component
{

	state = {show_filter: false,contacts: null, gift_types: null, result: null, giftsFilterRecipient: {},giftsFilterType: {},giftsFilterStatus: "", giftsFilterReceivedGiven: "", giftsFilterValuerange: "",
	giftsFilterResult: {}, isclear: false, statusData: [], selectedStatusData: {},receivedgivenData: [], selectedreceivedgivenData: {},
	valuerangeData: [], selectedvaluerangeData: {}, minValue: 0, maxValue: 0,filterRequestedFromDate: new Date(),filterRequestedToDate: new Date(), updateFromDate: null,
	company_lists: [], filtercompany: {} };

	componentDidMount()
	{
		//console.log("contacts:", this.props.gifttypelist);		
		let contacts = [];
		Object.entries(this.props.contacts).forEach(([key, value]) => {
			contacts.push({id: key, label: value});
		})
		let gift_types = [];
		this.props.gifttypelist.forEach((item) => {
			gift_types.push({id: item.giftTypeID, label: item.giftTypeName});
		})
		let result = [{id: 1, label: "Undetermined"},{id: 2, label: "Accepted"}];
		

		let statusData = [
			{id: 0, label: "select status"},		
			{id: 5, label: "All"},
			{id: 2, label: "Approved"},
			// {id: 4, label: "Declined"},				
			// {id: 1, label: "Requires Approval"},			
			// {id: 3, label: "Saved"}	
		]

		let role = Store.getStoreData('role');
		if(role === 'team'){
			statusData.push({id: 1, label: "Requires Approval"}, {id: 3, label: "Saved"})
		}else{
			statusData.push({id: 4, label: "Declined"}, {id: 1, label: "Requires Approval"}, {id: 3, label: "Saved"})
		}

		let selectedStatusData = {id: 0, label: "select status"}

		let receivedgivenData = [
			{id: 0, label: "select"},
			{id: 1, label: "Received"},
			{id: 2, label: "Given"}			
		]
		let selectedreceivedgivenData = {id: 0, label: "select"}

		let valuerangeData = [
			{id: 0, label: "select value"},
			{id: 1, label: "0-100"},
			{id: 2, label: "100-250"},
			{id: 3, label: "250-500"},
			{id: 4, label: "500-1000"},
			{id: 5, label: "1000+"}			
		]
		let selectedvaluerangeData = {id: 0, label: "select value"}

		let updateFromDate = new Date(this.state.filterRequestedFromDate.getFullYear(), this.state.filterRequestedFromDate.getMonth() - 12, this.state.filterRequestedFromDate.getDate());

		let gc_company =  Store.getStoreData('gc_company');
        let company_lists = [];
        for(let k of gc_company){
            let obj = {"id": k.id, "label": k.company_name};  
            company_lists.push(obj);
        }

		company_lists = company_lists.sort(function(a,b) {return (a.label > b.label) ? 1 : ((b.label > a.label) ? -1 : 0);} );

		this.setState({contacts,gift_types,result,  statusData, selectedStatusData, receivedgivenData, selectedreceivedgivenData, valuerangeData, selectedvaluerangeData, updateFromDate,
			filterRequestedFromDate: updateFromDate, company_lists});
	}

	componentDidUpdate(prevProps) {
		if (this.props !== prevProps) {
			//console.log("contacts:", this.props.contacts);
			let contacts = [];
			Object.entries(this.props.contacts).forEach(([key, value]) => {
				contacts.push({id: key, label: value});
			})
			let gift_types = [];
			this.props.gifttypelist.forEach((item) => {
				gift_types.push({id: item.giftTypeID, label: item.giftTypeName});
			})
			let result = [{id: 1, label: "Undetermined"},{id: 2, label: "Accepted"}];
			this.setState({contacts,gift_types,result});
		}
	}

	toggleFilter = (event) => {
		event.preventDefault();
		let show_filter = this.state.show_filter ? false : true;
		this.setState({show_filter});
    }

	companyValue = (selected_options) => {        
        let filtertypeObject = {};
        if(selected_options.length !== 0){
            for(let i=0;i<selected_options.length;i++){
                filtertypeObject[selected_options[i].id] = selected_options[i].label;
            }
        }
        console.log("breachType",filtertypeObject)        
        this.setState({filtercompany: filtertypeObject})
    }
    
    ricipientValue = (selected_options) => {
        //console.log("selected_options",selected_options)
        let ricipientObject = {};
        if(selected_options.length !== 0){
            for(let i=0;i<selected_options.length;i++){
                ricipientObject[selected_options[i].label] = selected_options[i].id;
            }
        }
        console.log("ricipientObject",ricipientObject)
        //console.log("giftstasklist",this.props.giftstasklist)
        this.setState({giftsFilterRecipient: ricipientObject})
    }

    giftlistValue = (selected_options) => {
        //console.log("selected_options",selected_options)
        let filtertypeObject = {};
        if(selected_options.length !== 0){
            for(let i=0;i<selected_options.length;i++){
                filtertypeObject[selected_options[i].id] = selected_options[i].label;
            }
        }
        console.log("ricipientObject",filtertypeObject)        
        this.setState({giftsFilterType: filtertypeObject})
	}
	
	statusValue = (selected_options) => {
		console.log("selected_options",selected_options)
		let filterStatus = "";
		if(selected_options.id !== 0){
			filterStatus = selected_options.label;
		}
		this.setState({giftsFilterStatus: filterStatus})        
	}

	resultValue = (selected_options) => {
        //console.log("selected_options",selected_options)
        let resultObject = {};
        if(selected_options.length !== 0){
            for(let i=0;i<selected_options.length;i++){
                resultObject[selected_options[i].label] = selected_options[i].id;
            }
        }
        console.log("ricipientObject",resultObject)
        //console.log("giftstasklist",this.props.giftstasklist)
        this.setState({giftsFilterResult: resultObject})
	}
	
	receivedgivenValue = (selected_options) => {
		console.log("selected_options",selected_options)
		let filterrecgiven = "";
		if(selected_options.id !== 0){
			filterrecgiven = selected_options.label;
		}
		this.setState({giftsFilterReceivedGiven: filterrecgiven})        
	}

	rangeValue = (selected_options) => {
		console.log("selected_optionsValue",selected_options)
		let minValue = 0;
		let maxValue = 0;
		if(selected_options.id !== 0){
			if(selected_options.id === 1){
				minValue = 0;
				maxValue = 100;
			}else if(selected_options.id === 2){
				minValue = 100;
				maxValue = 250;
			}else if(selected_options.id === 3){
				minValue = 250;
				maxValue = 500;
			}else if(selected_options.id === 4){
				minValue = 500;
				maxValue = 1000;
			}else{
				minValue = 500;
				maxValue = 10000000000;
			}
		}		
		this.setState({minValue: minValue,maxValue: maxValue})        
	}

	onRequestedFromDate = (date) => {
        this.setState({filterRequestedFromDate: date});
	}
	onRequestedToDate = (date) => {
        this.setState({filterRequestedToDate: date});
    }

    filterSubmit = () => {           
        let giftTaskAll = this.props.giftstasklist
        console.log("giftsTaskListFilter",giftTaskAll);
		//company start
        let filterCompanyValue = [];
        if (Object.keys(this.state.filtercompany).length === 0) {
            filterCompanyValue = giftTaskAll;
        } else {
            giftTaskAll.forEach((item) => {
                if(item[25] === 0 && item[1] in this.state.filtercompany){
                    filterCompanyValue.push(item);
                }else if(item[25] !== 0 && item[25] in this.state.filtercompany){
                    filterCompanyValue.push(item);
                }
            })
        }
        //company end

		//Recipient start
        let filtergift = [];
        if (Object.keys(this.state.giftsFilterRecipient).length === 0) {
            filtergift = filterCompanyValue;
        } else {
            filterCompanyValue.forEach((item) => {
                 
                let recipientname = item[13];                           
                if (recipientname.toString() in this.state.giftsFilterRecipient) {
                    filtergift.push(item);
                }
            })
        }
		//Recipient end

		//Type start
        let giftFilterTypes = [];
        if (Object.keys(this.state.giftsFilterType).length === 0) {
            giftFilterTypes = filtergift;
        } else {
            filtergift.forEach((item) => {                 
                let giftType = item[6];                               
                if (giftType.toString() in this.state.giftsFilterType) {
                    giftFilterTypes.push(item);
                }
            })
        }
		//Type end
		
		//Status start
        let statusTypes = [];
        if (this.state.giftsFilterStatus === "") {
            statusTypes = giftFilterTypes;
        } else {
            giftFilterTypes.forEach((item) => {
                let status = "";
                if(item[2] === "COMP_GIFTS_REGISTER") {                    
                    status =  "Requires Approval";
                }else if(item[2] === "COMP_GIFTS_TEAMSAVE") {
                    status = "Saved";
                }else if(item[2] === "COMP_GIFTS_COMPLETE") {
                    status = "Approved"
                }else if(item[2] === "COMP_GIFTS_REMOVE"){
                    status = "Declined"
                }
				if(this.state.giftsFilterStatus === "All"){
					statusTypes.push(item);
				}else{
					if (this.state.giftsFilterStatus === status) {
						statusTypes.push(item);
					}
				}
            })
		}
		//Status end

		//Result start
		let resultValues = [];
        if (Object.keys(this.state.giftsFilterResult).length === 0) {
            resultValues = statusTypes;
        } else {
            statusTypes.forEach((item) => {				
				let resultvalue = "";
                if(item[2] === "COMP_GIFTS_REGISTER") {
                    resultvalue = "Undetermined";
                }else if(item[2] === "COMP_GIFTS_TEAMSAVE") {
                    resultvalue = "Undetermined";
                }else if(item[2] === "COMP_GIFTS_COMPLETE") {
                    resultvalue = "Accepted";
                }else if(item[2] === "COMP_GIFTS_REMOVE"){
                    resultvalue = "Undetermined";
                }
                if (resultvalue in this.state.giftsFilterResult) {
                    resultValues.push(item);
                }
            })
		}
		//Result end

		//Received/Given start
		let receivedgivenTypes = [];
        if (this.state.giftsFilterReceivedGiven === "") {
            receivedgivenTypes = resultValues;
        } else {
            resultValues.forEach((item) => {				
				let recigiven = item[12] === 'received' ? 'Received' : 'Given';                
                if (this.state.giftsFilterReceivedGiven === recigiven) {
                    receivedgivenTypes.push(item);
                }
            })
		}
		//Received/Given end

		//Min-Max Value start
		let ValueRange = [];		
		if(this.state.minValue === 0 && this.state.maxValue === 0){
			ValueRange = receivedgivenTypes			
		}else{
			receivedgivenTypes.forEach((item) => {                
                let value = item[14];                
                if(value.toString() >= this.state.minValue && value.toString() <= this.state.maxValue){
                    ValueRange.push(item);
                }
            })
        
		}		
		//Min-Max Value end

		// From - To Date start
        let formdatetoDate = [];
        if (this.state.filterRequestedFromDate === "" && this.state.filterRequestedToDate === "") {
            formdatetoDate = ValueRange;
        }else{
            ValueRange.forEach((item) => {                
                let actionDate = item[7];
                let toDate = this.state.filterRequestedToDate;
                toDate.setHours(23);
                toDate.setMinutes(59);
                toDate.setSeconds(59);
				let endDate = moment(toDate).unix()
				console.log("actionDate",actionDate)
                console.log("this.state.filterRequestedFromDate=>",moment(this.state.filterRequestedFromDate).unix())
				console.log("this.state.filterRequestedToDate=>",endDate)
                if(actionDate > moment(this.state.filterRequestedFromDate).unix() && actionDate < endDate){
                    formdatetoDate.push(item);
                }
            })
        }
		// From - To Date end
        console.log("filterconflict",formdatetoDate)
        this.props.filterSubmitValue(formdatetoDate)
        
    }

    filterClear = () => {        
		console.log("Sourav")   
		let filterFromdate = new Date();
        let updateFromDate = new Date(filterFromdate.getFullYear(), filterFromdate.getMonth() - 12, 1);     
		this.setState({ isclear: true, giftsFilterRecipient: {},giftsFilterType: {},giftsFilterStatus: "",giftsFilterResult: {}, giftsFilterReceivedGiven: "",minValue: 0, maxValue: 0,
		filterRequestedFromDate: updateFromDate,filterRequestedToDate: new Date(), filtercompany: {}})
		let giftTaskAll = this.props.giftstasklist
		let updateMyGiftsTask = [];
        giftTaskAll.forEach((item) => {
            if(item.cur_lane !== "COMP_GIFTS_REMOVE"){
                updateMyGiftsTask.push(item)
            }
        })
		this.props.filterSubmitValue(updateMyGiftsTask)		
	}
	
	changeClear = () => {
		this.setState({isclear: false})
	}

	render()
	{
		return (
			<div>
				<FilterTopBar>
					<FilterText>Advanced Filters</FilterText>
					<FilterChevron onClick={this.toggleFilter}>
					{
						(() => {
							if (this.state.show_filter) {
								return <IoIosArrowUp />
							} else {
								return <IoIosArrowDown />
							}
						})()
					}
					</FilterChevron>
					<div style={{clear: "both"}}></div>
				</FilterTopBar>
				{
					(() => {
						if (this.state.show_filter) {
							return (
								<FilterBody>
									<Section40>
										<FieldLabel>Company Name</FieldLabel>
											{
												(() => {
													if (this.state.company_lists.length !== 0) {
														return <CslTypeAhead data={this.state.company_lists} ricipientValue={this.companyValue} isclear={this.state.isclear} changeClear={this.changeClear}/>
													}
												})()
											}
									</Section40>
                                    <Section40>
										<FieldLabel>Recipient</FieldLabel>
											{
												(() => {
													if (this.state.contacts !== null) {
														return <CslTypeAhead data={this.state.contacts} ricipientValue={this.ricipientValue} isclear={this.state.isclear} changeClear={this.changeClear}/>
													}
												})()
											}
									</Section40>
                                    <Section40>
                                        <FieldLabel>Type</FieldLabel>
                                            {
                                                (() => {
                                                    if (this.state.contacts !== null) {
                                                        return <CslTypeAhead data={this.state.gift_types} ricipientValue={this.giftlistValue} isclear={this.state.isclear} changeClear={this.changeClear}/>
                                                    }
                                                })()
                                            }
									</Section40>                                    
                                    <div style={{clear: "both"}}></div>
									<Section40 style={{marginTop: "10px"}}>
										<FieldLabel>Result</FieldLabel>
											{
												(() => {
													if (this.state.result !== null) {
														return <CslTypeAhead data={this.state.result} ricipientValue={this.resultValue} isclear={this.state.isclear} changeClear={this.changeClear}/>
													}
												})()
											}
									</Section40>
									<Section20 style={{marginTop: "10px"}}>
										<FieldLabel>Received/Given</FieldLabel>
										<CslDropDown allData={this.state.receivedgivenData} selectedAllData={this.state.selectedreceivedgivenData} statusValue={this.receivedgivenValue} isclear={this.state.isclear} changeClear={this.changeClear}/>
									</Section20>
									<Section20 style={{marginTop: "10px",marginLeft: "20px"}}>
										<FieldLabel>Value</FieldLabel>
										<CslDropDown allData={this.state.valuerangeData} selectedAllData={this.state.selectedvaluerangeData} statusValue={this.rangeValue} isclear={this.state.isclear} changeClear={this.changeClear}/>
									</Section20>
									<Section20 style={{marginTop: "10px",marginLeft: "20px"}}>
										<FieldLabel>Status</FieldLabel>
										<CslDropDown allData={this.state.statusData} selectedAllData={this.state.selectedStatusData} statusValue={this.statusValue} isclear={this.state.isclear} changeClear={this.changeClear}/>
									</Section20>
									<div style={{clear: "both"}}></div>
									<Section40>
										<FieldLabel style={{marginTop: "20px"}}>Date</FieldLabel>
										<Section45><CslDatepicker updateFromDate={this.state.updateFromDate} ondateValue={this.onRequestedFromDate} isclear={this.state.isclear} changeClear={this.changeClear}/></Section45>
										<Section10><Icon icon={androidRemove}/></Section10>
										<Section45><CslDatepicker ondateValue={this.onRequestedToDate} isclear={this.state.isclear} changeClear={this.changeClear}/></Section45>
										<div style={{clear: "both"}}></div>
									</Section40>
									
                                    <Section20 style={{float: "right"}}>
										<div style={{paddingTop: "100px"}}>
											<FilterClearBtn onClick={this.filterClear}>Clear</FilterClearBtn>
											<FilterApplyBtn onClick={this.filterSubmit}>Apply</FilterApplyBtn>
											<div style={{clear: "both"}}></div>
										</div>
									</Section20>
									<div style={{clear: "both"}}></div>
								</FilterBody>
							);
						}
					})()
				}
			</div>
		);
	}
}

export default AdvancedFilter;