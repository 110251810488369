import React from 'react';
import styled from 'styled-components';
import { FaHome, FaCog, FaThLarge,FaWrench } from "react-icons/fa";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faHouseBlank, faSquareList, faGear } from '@fortawesome/pro-thin-svg-icons'
import Store from '../Common/Store'
import {Link} from "react-router-dom";
const HeadContainer = styled.div`
    display: block;    
    height: 35px;
    background-color: #ffffff;
    padding-left: 10px;
    padding-right: 10px;
`;
const HomeLogo = styled.div`
    float: left;
    margin-top: 7px;
    font-size: 16px;
    color: #282828;
`;
const HomeText = styled.div`
    float: left;
    margin-top: 7px;
    color: #282828;
    margin-left: 10px;
    font-size: 13px;
`;
const Categories = styled.div`
    float: right;
    font-size: 17px;
    background-color: #ebebeb;
    color: #c4c4c4;
    width: 25px;
    height: 25px;
    margin-left: 5px;
    margin-top: 4px;
    padding-left: 4px;
    padding-top: 3px;
    border-radius: 2px;
`;
class CSLHeader extends React.Component
{
    render()
    {
        let use_site_layout = false
        if(localStorage.getItem('site_layout')!==null){
        let site_layout = JSON.parse(localStorage.getItem('site_layout'));
        use_site_layout = site_layout.result.use_site_layout;
        }
        if(use_site_layout) return (<div></div>)
        const role = Store.getStoreData('role')
        return (
            <HeadContainer>
                <HomeLogo><Link to={'/'}><FontAwesomeIcon icon={faHouseBlank} /></Link></HomeLogo>
                <HomeText><span style={{fontWeight: "600"}}>{this.props.headerText}</span></HomeText>
                { role === 'admin_manager' &&
                    <Categories><a href={process.env.REACT_APP_AUDIT_URL}><FontAwesomeIcon icon={faSquareList} /></a></Categories>
                }
                { role === 'admin_manager' &&
                    <Categories><Link to={'/moduleconfig'}><FontAwesomeIcon icon={faGear} /></Link></Categories>
                }
                <div style={{clear: "both"}}></div>
            </HeadContainer>
        );
    }
}
export default CSLHeader;