import React from 'react';
import styled from 'styled-components';
import CSLTable from '../Common/CSLTable';
import { FaPlusCircle } from "react-icons/fa";
import GiftsConfigModal from './GiftsConfigModal';
import Store from '../../Common/Store'
import AlertBox from '../Common/AlertBox'
import Toggle from 'react-toggle';
import "./ReactToggle.css";
import CSLLoader from './../Common/CSLLoader';
import VerticalEllipsisMenuFolderGiftTypes from "./VerticalEllipsisMenuFolderGiftTypes";
import approval_options from './approval_options.js'

const SearchInput = styled.input`
    height: 30px;
    width: 98%;
    border: 1px solid #ffffff;
    border-radius: 3px;
    box-shadow: 0 0 4px #c4c4c4;
    padding: 5px;
    color: #222222;
    font-size: 15px;
    font-family: 'Montserrat', sans-serif;
`;
const MRButton = styled.button`
    box-sizing: border-box;
    background-color: #1E3E62;
    color: #ffffff;
    border: 1px solid #1E3E62;
    width: 37%;
    font-size: 13px;
    height: 43px;
    border-radius: 4px;
    display: inline-block;
    margin-left: 5px;
`;
const MRModalLabel = styled.div`
    color: #212121;
    font-weight: 600;
    margin-top: 10px;
    margin-bottom: 10px;
    display: inline-block;
`;
const ExportButton = styled.button`
    box-sizing: border-box;
    background-color: #213E61;
    color: #F2F2F2;
    border: 1px solid #213E61;
    width: 125px;
    font-size: 14px;
    height: 32px;
    border-radius: 5px;
    margin-right: 3px;
    margin-top: 19px;
`;

const EmailContainer = styled.div`
	width: 100%;
`;
const EmailInnerContainer = styled.div`
	background-color: #F4F5F7;
	padding: 30px;
`;
const EmailBoldLabel = styled.div`
	font-weight: 600;
	color: #595959;
	margin-top: 5px;
`;

const EmailTopHeader = styled.div`
	font-weight: 600;
	color: #595959;
	margin-top: 5px;
	width:100%;
	height: 58px;
	padding: 20px;
	border-radius: 4px;
	border: 1px solid #e0e0e0;
	background-color: #ffffff;
	box-sizing: border-box;	
`;
const InactiveOverlay = styled.div`
  display: block;
  box-sizing: border-box;
  height: 210%;
  width: 100%;
  background-color: #ffffff;
  position: absolute;
  left: 0%;
  top: -46px;
  z-index: 1000;
  opacity: 0.8;
`;
const RoleSelect = styled.select`
  display: block;
  box-sizing: border-box;
  height: 28px;
  width: 100%;
  background-color: #fff;
  border: 1px solid #ffffff;

  box-shadow: 0 0 4px #c4c4c4;
  font-family: "Montserrat", sans-serif;
  color: #222222;
`;

class GiftsTypes extends React.Component
{
    state = {
        showActiveCheckbox: false,
        gift_types: null,
        show_gift_types_array: null,
        gift_types_search: null,
        giftActiveArrayAll: null,
        giftInactiveArrayAll: null,
        ready: false,
        show_gift_type_modal: false,
        standard_gift_types: null,
        curid: 0,
        content: null,
        alert_param: null,
        isLoadedIcon: false,
        selected_approval_option: null
    };
    constructor(props) {
        super(props);        
    }

    componentDidMount()
	{		
		let giftActiveArrayAll = [];		
		let giftInactiveArrayAll = [];
		for(let i=0;i<this.props.gift_types.length;i++){
			if(this.props.gift_types[i].isActive === 1){
				giftActiveArrayAll.push(this.props.gift_types[i])
			}else{
				giftInactiveArrayAll.push(this.props.gift_types[i])
			}
		}
		let giftsValue = [];
		if(this.props.general.gift_type_active_inactive){
			giftsValue = giftInactiveArrayAll			
		}else{			
			giftsValue = giftActiveArrayAll			
		}
		this.setState({showActiveCheckbox: this.props.general.gift_type_active_inactive,
			gift_types: this.props.gift_types,
			// show_gift_types_array: giftsValue,
			show_gift_types_array: this.props.gift_types,
			gift_types_search: this.props.gift_types,
			giftActiveArrayAll: giftActiveArrayAll,
			giftInactiveArrayAll: giftInactiveArrayAll,
            standard_gift_types: this.props.standard_gift_types,
            ready: true
        });
		console.log("reason_for_removal===>",this.props.gift_types);
    }
    
    componentDidUpdate(prevProps)
	{
		if (prevProps !== this.props) {
			let giftActiveArrayAll = [];		
            let giftInactiveArrayAll = [];
            for(let i=0;i<this.props.gift_types.length;i++){
                if(this.props.gift_types[i].isActive === 1){
                    giftActiveArrayAll.push(this.props.gift_types[i])
                }else{
                    giftInactiveArrayAll.push(this.props.gift_types[i])
                }
            }
            let giftsValue = [];
            if(this.props.general.gift_type_active_inactive){
                giftsValue = giftInactiveArrayAll
                console.log("Sourav")
            }else{			
                giftsValue = giftActiveArrayAll
                console.log("Sourav1")
            }
            this.setState({showActiveCheckbox: this.props.general.gift_type_active_inactive,
                gift_types: this.props.gift_types,
                // show_gift_types_array: giftsValue,
                show_gift_types_array: this.props.gift_types,
                gift_types_search: this.props.gift_types,
                giftActiveArrayAll: giftActiveArrayAll,
                giftInactiveArrayAll: giftInactiveArrayAll,
                standard_gift_types: this.props.standard_gift_types,
                ready: true, 
                isLoadedIcon: false
            });
            console.log("reason_for_removal===>",this.props.gift_types);			
		}
    }
    
    searchValue = (event) => {
		event.preventDefault();
        console.log("Sourav",event.target.value);
		console.log("fddsf",this.state.gift_types_search)
		if(!this.state.showActiveCheckbox){
			let search_string = event.target.value;
			if (search_string === "") {
				let gift_type = this.state.giftActiveArrayAll;            
				this.setState({show_gift_types_array: gift_type});
			}else {
				let all_value = this.state.giftActiveArrayAll;
				let filtered_values = [];
				all_value.forEach((gift) => {
					if (gift.giftTypeName.toLowerCase().includes(search_string.toLowerCase())) {
						filtered_values.push(gift);
					}                
				})            
				let gift_type = filtered_values;

				this.setState({show_gift_types_array: gift_type})
			}
		}else{
			let search_string = event.target.value;
			if (search_string === "") {
				let gift_type = this.state.giftInactiveArrayAll;            
				this.setState({show_gift_types_array: gift_type});
			}else {
				let all_value = this.state.giftInactiveArrayAll;
				let filtered_values = [];
				all_value.forEach((gift) => {
					if (gift.giftTypeName.toLowerCase().includes(search_string.toLowerCase())) {
						filtered_values.push(gift);
					}                
				})            
				let gift_type = filtered_values;

				this.setState({show_gift_types_array: gift_type})
			}
		}
    }
    
    handleActiveInactive = (value) => (event) => {        
        console.log("value",value)  
		let data = value === 1 ? true : false;
		this.props.changeActiveInactive(data);		       
    }    

    tableData = () => {
        console.log("this.state.show_gift_types_array",this.state.show_gift_types_array)
        let ret = {data: [], columns: []};
        ret.columns =[
                        {Header: 'Name', accessor: 'name', minWidth: 120, headerStyle: {textAlign: 'left',fontWeight: "600"}, style: {fontWeight: "700", color: "#545454"}},	
                        // {
                        //     Header: "Approval Required",
                        //     accessor: "approver_level",
                        //     headerStyle: { textAlign: "center", fontWeight: "600"},
                        //     filterable: false,
                        //     sortable: false,
                        //     style: { whiteSpace: "unset" },
                        //     Cell: (row) => {
                        //       // console.log("row:", row);
                        //       return <RoleSelect style={{width: "98%"}} value={row.original.approver_level} key={row.original.index} onChange={this.handleLevelChange(row.original.id)} disabled={row.original.status === "Active" ? false : true}>
                        //         {approval_options.map((role) => (
                        //           <option key={role.id} value={role.id}>
                        //             {role.name}
                        //           </option>
                        //         ))}
                        //       </RoleSelect>
                        //       },
                        //     width: 250,                            
                        //   },
                        {'Header' : 'Status', Cell: row => (
							<div> 
							<Toggle
								style={{float:'left'}}
								defaultChecked={row.original.status === "Active" ? true : false}
								icons={false}	
                                onChange={this.handlenaSectionIncluded(row.original.id)}						
							/>
							</div>
						 ), filterable: false,
                         sortable: false,
                         headerStyle:{textAlign: 'center',fontWeight: "600"},style: {textAlign: 'center'}},							
                        {'Header' : '', Cell: row => (
                            <div>                                
                                  <VerticalEllipsisMenuFolderGiftTypes editDelete={this.showConfigModal(row.original.id, row.original.isdelete)} options={["Edit", "Delete"]} />	
                            </div>
                         ), minWidth: 40, filterable: false,
                         sortable: false,
                         headerStyle:{textAlign: 'left'},style: {textAlign: 'center'}}
                        ];

        let gift_type_sort_array = this.state.show_gift_types_array.sort(function(a,b) {return (a.giftTypeName > b.giftTypeName) ? 1 : ((b.giftTypeName > a.giftTypeName) ? -1 : 0);} );
		gift_type_sort_array.forEach((item) => {
			let statusValue = item.isActive === 1 ? "Active" : "Inactive"
			let elem = {id: item.giftTypeID, name: item.giftTypeName, status: statusValue, isdelete: item.isDelete, 'approver_level': item.approver_level,};
			ret.data.push(elem);
        })

        let client_gift_types_export = [];        
        Object.keys(this.props.gift_types).map((d, key) => {
			//console.log("Key",key)
			let giftTypesObjectexport = {};			
            giftTypesObjectexport.type = this.props.gift_types[d].giftTypeName;            
            giftTypesObjectexport.status = this.props.gift_types[d].isActive == 1 ? "Active" : "Inactive";
            giftTypesObjectexport.id = d;
			client_gift_types_export.push(giftTypesObjectexport);
		})        
        console.log("client_gift_types_export",client_gift_types_export);
        Store.updateStore('table_gift_cur_data', client_gift_types_export)
        //console.log("ret==>",ret.data)        
        return ret;
    }

    showConfigModal = (curid, isdelete) => (obj) => {
		console.log("curid==>", curid)
		console.log("obj==>", obj)
		if(obj === 'Edit'){			
            const content = this.state.gift_types.find((item) => {
                return item.giftTypeID === curid;
            })
            this.setState({content,curid: curid,show_gift_type_modal: true, headerText: "Edit"});
		}else if(obj === 'Delete'){
            if(isdelete === 0){
                let alert_param = {title: 'Warning', message: 'Are you sure you want to delete this gift type?', ok_text: 'Yes', cancel_text: 'No', confirm: true, 
                           alertHandler: this.deleteGiftTypeAlert, stack: {id: curid}}
                this.setState({alert_param: alert_param})
            }else{
                let alert_param = {title: 'ERROR', message: 'This Gift Type has already been used.', ok_text: 'Ok', confirm: false, 
                           alertHandler: this.samealertremoveHandler, stack: {}}
                this.setState({alert_param: alert_param})
            }			
		}
	};

    handlenaSectionIncluded = (section_id) => (event) => {
		console.log("content:", section_id);
		let {gift_types} = this.state;
		let newgifts = [];
		gift_types.forEach((item) => {
			if (item.giftTypeID === section_id) {
				item.isActive = item.isActive === 1 ? 0 : 1; 				
			}
			newgifts.push(item);
		})
		console.log("gifts:", newgifts);
        this.setState({isLoadedIcon: true})
		this.props.updateGiftValue(newgifts);
	}

    handleLevelChange = (section_id) => (event) => {
		console.log("content:", section_id);
		let {gift_types} = this.state;
		let newgifts = [];
		gift_types.forEach((item) => {
			if (item.giftTypeID === section_id) {
				item.approver_level = event.target.value; 				
			}
			newgifts.push(item);
		})
		console.log("gifts:", newgifts);
        this.setState({isLoadedIcon: true})
		this.props.updateGiftValue(newgifts);
	}
    
    samealertremoveHandler = (result,stack) => {
        this.setState({alert_param: null})
    }
    
    notDeleteGiftType = () => {
        let msg = 'This Gift Type has already been used.';
        let title = 'Warning';
// 		alert("This Gift Type has already been used.")
        let alert_param = {title: title, message: msg, ok_text: 'OK', confirm: false, alertHandler: this.samealertremoveHandler, stack: {id: 0}}
        this.setState({alert_param: alert_param})
	}

    deleteGiftType = (id) => (event) => {
        let msg = 'Are you sure you want to delete this gift type?';
        let title = 'Confirm';
        let alert_param = {title: title, message: msg, ok_text: 'Yes', cancel_text: 'No', confirm: true, alertHandler: this.deleteGiftTypeAlert, stack: {id: id}}
        this.setState({alert_param: alert_param})     		  
    }
    
    deleteGiftTypeAlert = (result, stack) => {
        if(!result) {
            this.setState({alert_param: null})
            return
        }
        console.log("id",stack.id)
        let {gift_types} = this.state;
		let newreason = [];
		for(let i=0;i<this.state.gift_types.length;i++)
		{
			if(this.state.gift_types[i].giftTypeID !== stack.id){
				newreason.push(this.state.gift_types[i])
			}
		}			 
		console.log("delete===>",newreason);   
		this.props.deleteGiftTypeValue(newreason); 
		this.setState({alert_param: null})
    }
    
    openModal = (id) => (event) => {
		event.preventDefault();     
		console.log("id",id); 
		const content = this.state.gift_types.find((item) => {
			return item.giftTypeID === id;
		})
		this.setState({content,curid: id,show_gift_type_modal: true});        
	}
    
    addGiftType = (event) => {
		event.preventDefault();
		this.setState({show_gift_type_modal: true,curid: 0})
    }
    closeModal = () => {		
		this.setState({show_gift_type_modal: false})
    }
    
    saveGiftTypeValue = (obj) => {
		this.setState({show_gift_type_modal: false})
		this.props.saveGiftValue(obj)
    }

    updateGiftTypeValue = (content) => {
		console.log("content:", content);
		//console.log("this.state",this.state.reason_for_removal)
		let {gift_types} = this.state;
		let newreason = [];
		gift_types.forEach((item) => {
			if (item.giftTypeID === content.giftTypeID) {
				item = content;
				console.log("matched", item);
			}
			newreason.push(item);
		})
		console.log("resons:", newreason);
		this.setState({show_gift_type_modal: false})
		this.props.updateGiftValue(newreason);		
	}

    
    
    
    render()    
	{
        if (!this.state.ready) {
			return (<div>Loading...</div>);
		}          
		return(
        <div>            
            <div style={{width: "100%"}}>
                {
                    (() => {
                        if (this.state.show_gift_type_modal) {
                            return (<div><InactiveOverlay style={{height: "105%"}}/><GiftsConfigModal closeModal={this.closeModal} curid={this.state.curid} content={JSON.parse(JSON.stringify(this.state.content))} updateGiftTypeValue={this.updateGiftTypeValue} saveGiftTypeValue={this.saveGiftTypeValue} isStatusShow={true}/></div>);
                        }
                    })()
                }
                {
                    (() => {
                        if(this.state.isLoadedIcon){
                            return <div><CSLLoader style={{position: 'absolute'}}/></div>
                        }
                    })()
                } 
                    {/* <div style={{padding: "30px 30px 0px 30px", backgroundColor: "#F4F5F7"}}>                        
                        <div>
                            <SearchInput style={{width: "100%",marginTop: "2px"}} placeholder="Search..." onChange={this.searchValue}/>
                        </div>
                                       
                        <MRModalLabel>Show Inactive?</MRModalLabel>
                        {
                            (() => {
                                if(!this.state.showActiveCheckbox){
                                    return(<div  style={{cursor: "pointer", fontSize:"21px",display: "inline-block",marginLeft: "5px"}} onClick={this.handleActiveInactive(1)} ><IoIosSquareOutline /></div>)
                                }else{
                                    return(<div  style={{cursor: "pointer", fontSize:"21px",display: "inline-block",marginLeft: "5px"}} onClick={this.handleActiveInactive(0)} ><IoIosCheckboxOutline /></div>)
                                }
                            })()
                        }  
                    </div>  */}
                <EmailContainer>                
                    <EmailInnerContainer>                            
                        <EmailTopHeader style={{display: "inline-block" , fontWeight: 700}}>
                            Gift and Hospitality Types
                            <div style={{float:'right'}}>
                                <div onClick={this.addGiftType} style={{float: "left", cursor: "pointer",fontSize: "20px",color: "#04ADA8"}}>
                                    <FaPlusCircle  /> 
                                </div>	
                            </div>
                        </EmailTopHeader>
                        <CSLTable add={false} processData={this.tableData} tableRows="10" />
                    </EmailInnerContainer>
                </EmailContainer> 
            </div>
            <AlertBox alertParam = {this.state.alert_param}/>
        </div>
        );
	}
}

export default GiftsTypes;