import React from 'react';
import styled from 'styled-components';
import CSLPreviewTable from './CSLPreviewTable';
import Store from '../../Common/Store.js';
import Utils from '../../Common/Utils.js';

const PreviewDiv = styled.div`
	margin-top: 30px;
`;

const HeaderText = styled.div`
	background-color: #04ADA8;
	padding: 20px;
	color: #ffffff;
	font-size: 12px;
	font-weight: 600;
    border-top-right-radius: 6px;
    border-top-left-radius: 6px;
`;

const TableHead = styled.div`
	float: left;
    background-color: #EDF0F1;
    padding: 10px 0px 10px 20px;
    font-weight: 600;
    font-size: smaller;
    box-sizing: border-box;
`;

const FootButton = styled.div`
	padding: 10px 15px;
    float: right;
    margin-right: 10px;
    border-radius: 5px;
    cursor: pointer;
`;

class CSLPreviewConfig extends React.Component
{
	state = {data: null, show_preview: true, stop_preview: true, gstruct: null, sep_width: null};

	componentDidMount()
	{
		//console.log("PreviewConfig data:", this.props.data);
		this.manipulate();
	}

	componentDidUpdate(prevProps) {
		
		if (prevProps !== this.props) {
			this.manipulate();
		}
	}

	prepareData = (data, raw_data, bucket_data, fields_assoc, level, field_types) => {
		data.level = level;
		//console.log('prepareData field_types', field_types)
		//console.log('prepareData raw_data', raw_data.sortby.selected)
		let row_sort_field = raw_data.sortby.selected.accessor
		let row_sort_fieldtype = field_types[row_sort_field]
		let row_sort_type = raw_data.sortby.selected.order
		//console.log('prepareData row_sort_field, row_sort_type, row_sort_fieldtype', row_sort_field, row_sort_type, row_sort_fieldtype)
		if(raw_data.groupby.selections.length > 0 && level < raw_data.groupby.selections.length) {
			data.field = raw_data.groupby.selections.length > 0 ? raw_data.groupby.selections[level].accessor : '';
			data.is_grouping = true;
			data.field = raw_data.groupby.selections[level].accessor;
			data.sort_order = raw_data.groupby.selections[level].sort_order;
			let field_type = field_types[data.field]
			// data.fieldname = fields_assoc[raw_data.groupby.selections[level].accessor];
			let fieldvalues = [];
			for(let item of bucket_data) {
				fieldvalues.push(item[data.field])
			}
			let unique_fieldvalues = fieldvalues.filter((item, i, ar) => ar.indexOf(item) === i);
			//console.log('fieldvalues, unique_fieldvalues, bucket_data', fieldvalues, unique_fieldvalues, bucket_data)
			data.buckets = []
			for(let uf of unique_fieldvalues) {
				//console.log('ReportModal uf', uf)
				let uf_sane = typeof uf === 'undefined' ? 'N/A' : uf
				if(field_type === 'date') {
					uf_sane = typeof uf === 'undefined' ? 'N/A' : uf
				}
				if(field_type === 'numeric') {
					uf_sane = typeof uf === 'undefined' ? 0 : uf
				}
				if(field_type === 'month') {
					uf_sane = typeof uf === 'undefined' ? 'N/A' : uf
				}
				let bucket = {fieldname: fields_assoc[data.field], fieldvalue: uf_sane, data_rows: []}
				
				for(let row of bucket_data) {
					if(row[data.field] === uf) {
						if(row_sort_field !== 'none'){
							let fv_sane = typeof row[row_sort_field] === 'undefined' ? 'N/A' : row[row_sort_field]
							if(row_sort_fieldtype === 'date') {
								fv_sane = typeof row[row_sort_field] === 'undefined' ? 'N/A' : row[row_sort_field]
							}
							if(row_sort_fieldtype === 'numeric') {
								fv_sane = typeof row[row_sort_field] === 'undefined' ? 0 : row[row_sort_field]
							}
							if(row_sort_fieldtype === 'month') {
								fv_sane = typeof row[row_sort_field] === 'undefined' ? 'N/A' : row[row_sort_field]
							}
							row.fieldvalue = fv_sane
						}
						bucket.data_rows.push(row)
					}
				}
				let sort_order = row_sort_type === 'Ascending' ? 'asc' : 'desc'
				if(row_sort_field !== 'none')bucket.data_rows = this.sortBuckets(JSON.parse(JSON.stringify(bucket.data_rows)), sort_order, row_sort_fieldtype);
				//console.log('prepareData bucket.data_rows',bucket.data_rows)
				data.buckets.push(bucket)
				//console.log('prepareData data level fieldvalue', JSON.parse(JSON.stringify(data)), level, uf)
				
				this.prepareData(bucket, raw_data, bucket.data_rows, fields_assoc, level+1, field_types)
			}
			if (data.buckets.length > 1) {
				//console.log('sortBuckets data.buckets data.field', data.buckets, data.field)
				
				data.buckets = this.sortBuckets(JSON.parse(JSON.stringify(data.buckets)), data.sort_order, field_type);
			}
			
		} else {
			data.is_grouping = false
			data.data_rows = []
			for(let row of bucket_data) {
				if(row_sort_field !== 'none'){
					let fv_sane = typeof row[row_sort_field] === 'undefined' ? 'N/A' : row[row_sort_field]
					if(row_sort_fieldtype === 'date') {
						fv_sane = typeof row[row_sort_field] === 'undefined' ? 'N/A' : row[row_sort_field]
					}
					if(row_sort_fieldtype === 'numeric') {
						fv_sane = typeof row[row_sort_field] === 'undefined' ? 0 : row[row_sort_field]
					}
					if(row_sort_fieldtype === 'month') {
						fv_sane = typeof row[row_sort_field] === 'undefined' ? 'N/A' : row[row_sort_field]
					}
					row.fieldvalue = fv_sane
				}
				data.data_rows.push(row)
			}
			let sort_order = row_sort_type === 'Ascending' ? 'asc' : 'desc'
			if(row_sort_field !== 'none')data.data_rows = this.sortBuckets(JSON.parse(JSON.stringify(data.data_rows)), sort_order, row_sort_fieldtype);
			//console.log('prepareData bucket.data_rows',data.data_rows)
			
			data.buckets = []
		}
		//console.log('prepareData End data level', JSON.parse(JSON.stringify(data)), level)
		return
	}

	sortBuckets = (group_buckets, sort_order, field_type) => {
		//console.log('sortBuckets group_buckets, field_type', group_buckets, field_type)
		if(field_type === 'date') {
			for(let g of group_buckets) {
				if(g.fieldvalue === 'N/A'){
					g.datevalue = 0
				} else {
					let date_parts = g.fieldvalue.split('/')
					g.datevalue = parseInt(date_parts[2])*10000 + parseInt(date_parts[1]) * 100 + parseInt(date_parts[0])
				}
			}
		}
		if(field_type === 'numeric') {
			for(let gb of group_buckets) {
				gb.numvalue = isNaN(gb.fieldvalue) ? -1 : gb.fieldvalue
			}
		}
		if (sort_order === "asc") {
			switch(field_type) {
				case 'alpha': group_buckets.sort((a, b) => a.fieldvalue.localeCompare(b.fieldvalue)); break;
				case 'numeric': group_buckets.sort((a, b) => a.numvalue - b.numvalue); break;
				case 'date': group_buckets.sort((a, b) => a.datevalue - b.datevalue); break;
				case 'month': group_buckets.sort((a, b) => this.monthNum(a.fieldvalue) - this.monthNum(b.fieldvalue)); break;
			}
			
		} else {
			switch(field_type) {
				case 'alpha': group_buckets.sort((a, b) => b.fieldvalue.localeCompare(a.fieldvalue)); break;
				case 'numeric': group_buckets.sort((a, b) => b.numvalue - a.numvalue); break;
				case 'date': group_buckets.sort((a, b) => b.datevalue - a.datevalue); break;
				case 'month': group_buckets.sort((a, b) => this.monthNum(b.fieldvalue) - this.monthNum(a.fieldvalue)); break;
			}
			
		}
		return JSON.parse(JSON.stringify(group_buckets));
	}

	monthNum = (month) => {
		let ret = 1;
		switch(month) {
			case 'January': ret = 1; break;
			case 'February': ret = 2; break;
			case 'March': ret = 3; break;
			case 'April': ret = 4; break;
			case 'May': ret = 5; break;
			case 'June': ret = 6; break;
			case 'July': ret = 7; break;
			case 'August': ret = 8; break;
			case 'September': ret = 9; break;
			case 'October': ret = 10; break;
			case 'November': ret = 11; break;
			default: ret = 12;
		}
		return ret;
	}

	prepareDataOld = (data, raw_data, bucket_data, fields_assoc, level) => {
		data.level = level;
		if(Utils.isObject(bucket_data)) {
			data.buckets = []
			data.is_grouping = true;
			data.field = raw_data.groupby.selections[level].accessor;
			// data.fieldname = fields_assoc[raw_data.groupby.selections[level].accessor];
			let fieldvalues = [];
			for(let item of bucket_data) {
				fieldvalues.push(bucket_data[data.field])
			}
			let unique_fieldvalues = fieldvalues.filter((item, i, ar) => ar.indexOf(item) === i);
			//console.log('unique_fieldvalues', unique_fieldvalues)
			// for(let key in bucket_data) {
			// 	let bucket = {fieldvalue: key, fieldname: fields_assoc[raw_data.groupby.selections[level].accessor]}
			// 	data.buckets.push(bucket)
			// 	let next_bucket_data = []
			// 	this.prepareData(bucket,raw_data, next_bucket_data, fields_assoc, level + 1)
			// }
		} else {
			data.is_grouping = false;
			data.data_rows = bucket_data;
			data.buckets = []
		}
		return
	}

	manipulate = () => {
		let groupings = null;
		Utils.log('widths manipulate this.props', this.props)
		if (this.props.data.groupby.selections.length !== 0) {
			groupings = {};
			this.props.data.groupby.selections.forEach((gs) => {
				groupings[gs.accessor] = [];
				this.props.data.data.forEach((d) => {
					if (groupings[gs.accessor].includes(d[gs.accessor]) === false) {
						groupings[gs.accessor].push(d[gs.accessor]);
					}
				})
			})
		}
		//console.log("groupings:", groupings);
		let gstruct = groupings === null ? this.props.data.data : {};
		if (groupings !== null) {
			for (let i=this.props.data.groupby.selections.length - 1; i>=0; i--) {
				if (i === this.props.data.groupby.selections.length - 1) {
					const x = {}
					groupings[this.props.data.groupby.selections[i].accessor].forEach((g) => {
						x[g] = [];
					})
					gstruct = JSON.parse(JSON.stringify(x));
				} else {
					let exist_gstruct = JSON.parse(JSON.stringify(gstruct));
					const y = {}
					groupings[this.props.data.groupby.selections[i].accessor].forEach((g) => {
						y[g] = exist_gstruct;
					})
					gstruct = JSON.parse(JSON.stringify(y));
				}
			}
		}
		let ogstruct = null;
		if (groupings !== null) {
			ogstruct = this.createGroups(JSON.parse(JSON.stringify(gstruct)), this.props.data.data, groupings);
		}
		//console.log("gstruct:", gstruct);
		//console.log("ogstruct:", ogstruct);
		const sep_width = (100 / (this.props.data.table_headers.length - this.props.data.groupby.selections.length)).toString() + "%";
		//console.log("sep_width:", sep_width);
		let preview_data = {};
		let fields_assoc = {}
		for(let f of this.props.data.groupby.fields) {
			fields_assoc[f.accessor] = f.nickname
		}

		let field_types = {}
		for(let h of this.props.data.table_headers) {
			field_types[h.accessor] = h.type
		}
		this.prepareData(preview_data, this.props.data, this.props.data.data, fields_assoc, 0, field_types)
		//console.log('PreviewConfig this.data', preview_data)
		let groupby_fields = {}
		for(let g of this.props.data.groupby.selections) {
			groupby_fields[g.accessor] = 1;
		}

		this.setState({data: this.props.data, sep_width, gstruct: ogstruct, preview_data: preview_data, fields: fields_assoc, groupby_fields: groupby_fields});
	}

	createGroups = (gstruct, data, groupings) => {
		Object.entries(gstruct).forEach(([key, value]) => {
			let new_data = [];
			let accessor = "";
			Object.entries(groupings).forEach(([gkey, gvalue]) => {
				if (gvalue.includes(key)) {
					accessor = gkey;
				}
			})
			data.forEach((d) => {
				if (d[accessor] === key) {
					new_data.push(d);
				}
			})
			let x = null;
			if (value.constructor !== Array) {
				gstruct[key] = this.createGroups(JSON.parse(JSON.stringify(value)), new_data, groupings);
			} else {
				gstruct[key] = JSON.parse(JSON.stringify(new_data));
			}
		})
		return gstruct;
	}

	showGenerate = (event) => {
		event.preventDefault();
		Store.updateStore('gstruct', JSON.parse(JSON.stringify(this.state.gstruct)));
		Store.updateStore('table_headers', JSON.parse(JSON.stringify(this.props.data.table_headers)));
		Store.updateStore('font_size_map', JSON.parse(JSON.stringify(this.props.data.font_size_map)));
		Store.updateStore('pdf_data', this.props.data)
		Store.updateStore('preview_data', this.state.preview_data)
		this.props.toggleGenerate();
	}

	render()
	{
		if (this.props.show_preview === true) {
			Utils.log('widths this.props.data, this.state', this.props.data, this.state)
			let widths = {}
			let total_width = 0
			for(let f of this.props.data.table_headers) {

				if(!(f.accessor in this.state.groupby_fields) && f.accessor !== 'task_id') {
					
					total_width += f.width
					Utils.log('widths render f, total_width', f, total_width)
				}
			}
			Utils.log('widths total_width', total_width)
			for(let f of this.props.data.table_headers) {
				if(!(f.accessor in this.state.groupby_fields) && f.accessor !== 'task_id') {
					Utils.log('widths render calc f', f)
					widths[f.accessor] = ((f.width / total_width) * 100).toString() + '%'
				}
			}

			return (
				<PreviewDiv>
					<HeaderText>Report Preview - Note that the report preview shows a limited dataset for preview purposes.</HeaderText>
					{
						this.props.data.table_headers.map((th, index) => {
							if(!(th.accessor in this.state.groupby_fields) && th.width > 0){
								return (
									<TableHead key={index} style={{width: widths[th.accessor]}}>{th.nickname}</TableHead>
								)
							}
						})
					}
					<div style={{clear: "both"}}></div>
					<CSLPreviewTable 
						previewData={this.state.preview_data}
						fields={this.state.fields}
						groupbyFields= {this.state.groupby_fields}
						groupbyHeaders={[]}
						cellWidth={this.state.sep_width}
						tableHeaders={this.props.data.table_headers}
						widths={widths}
					/>
					{
					// <TableBody
					// 	gstruct={this.state.gstruct}
					// 	table_headers={this.props.data.table_headers}
					// 	sep_width={this.state.sep_width}
					// 	indent="10"
					// />
					}
					<div style={{textAlign: "right", padding: "15px 20px", position: 'absolute', bottom: 2, left: 2, width: 'calc(60vw - 5px)'}}>
						<FootButton style={{backgroundColor: "#04ADA8", color: "#ffffff", marginRight: "0px"}} onClick={this.showGenerate}>Generate</FootButton>
						<FootButton style={{backgroundColor: "#ffffff"}} onClick={() => this.props.hideReport()}>Cancel</FootButton>
						<div style={{clear: "both"}}></div>
					</div>
				</PreviewDiv>
			);
		} else {
			return (<div style={{textAlign: "right", padding: "15px 20px", position: 'absolute', bottom: 2, left: 2, width: 'calc(60vw - 5px)'}}>
						<FootButton style={{backgroundColor: "#ffffff"}} onClick={() => this.props.hideReport()}>Cancel</FootButton>
						<div style={{clear: "both"}}></div>
					</div>);
		}
	}
}

export default CSLPreviewConfig;