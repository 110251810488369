import React, { Component } from 'react'
import './Table_style.css'
import Pagination from './Pagination';
import SimplePagination from './SimplePagination'
class TableHeader extends Component {
    constructor(props) {
        super(props)
    }
    tableheaderUi = (obj) => {
        let th_container = []
        for (let key in obj) {
            th_container.push(
                <th >{obj[key].elem}</th>
            )
        }
        return th_container
    }

    render() {
        return (
            <>
                {this.props.data.map((t) => {
                    return (
                        <tr>
                            {this.tableheaderUi(t)}
                        </tr>
                    )
                }
                )}
            </>
        )
    }
}

class TableBody extends Component {
    state = { holded: false, clientX: null, left: 100, hiddenrowindex: null, tabledata: null, ready: false }
     
    componentDidMount() {
        this.setState({ tabledata: this.props.data , ready:true})
    }
    componentDidUpdate(prev_props){
        if(this.props !== prev_props){
            this.setState({ tabledata: this.props.data })
        }
    }

    rowhide = (i) => {
        this.setState({ hiddenrowindex: i })
    }

    tablebodyUi = (obj) => {
        let td_container = []
        // console.log("obj==>", obj)
        for (let key in obj) {
            td_container.push(
                <td colSpan={obj[key].parent ? 4 : 1} style={{ display: !obj[key].parent && obj[key].ref === this.state.hiddenrowindex ? 'none' : '' }}>
                    {obj[key].elem}
                </td>
            )
        }
        return td_container   
    }
    
    render() {
        if (!this.state.ready) return <div>....loading</div>        
        return (
            <>
                {this.state.tabledata.map((t) => {
                   return (
                        <tr>
                            {this.tablebodyUi(t)}
                        </tr>
                    )
                }
               )}
            </>
        )
    }

}
class GenericTableFunction { 

    datasort = (data) => {
        let arr = []
        let obj = {}            
        if (typeof data === 'object') {                     
            for (let k in data) {
                obj['title'] = data[k]
                arr.push(obj)
                obj = {}
            }
        } else {            
            obj['title'] = data
            arr.push(obj)
        }       
        return arr
    }

    tabledataRestruc = (data) => {
        let sampleTableBodyStruc = {
            title: '',
            parent: true,
            dropdown: true,
            ref: null,
            elem: {}
        }

        let arr = []
        let index = 0
        for (let d of data) {
            if(d.title.length > 0){
            let parentObj = JSON.parse(JSON.stringify(sampleTableBodyStruc))
            parentObj.title = d.title
            parentObj.giftID = d.giftTypeID
            parentObj.ref = index
            arr.push(parentObj)
            }
            for (let el of d.elem) {                
                    let childObj = JSON.parse(JSON.stringify(sampleTableBodyStruc))
                    childObj['elem'] = el
                    childObj['parent'] = false
                    childObj['ref'] = index
                    childObj['gift_type_id'] = d.giftTypeID
                    childObj['id'] = el.id
                    childObj['status'] = el.status
                    arr.push(childObj)                
            }
            index++
        }       
        return arr
    }

    tableFunc = (data ,tableHeaderui_1,tableHeaderui_2,tableBodyui ,tableBodyHeaderUi, col_count , header_count  ) => {        
        let tableHeader = []
  
        for(let i=0 ; i<header_count ; i++){
            let tableHeader_obj ={}
            for(let j=0 ; j<col_count ; j++){
                tableHeader_obj[j] = {
                    elem:i==0? tableHeaderui_1(j):tableHeaderui_2(j)
                }
            }
            tableHeader.push(tableHeader_obj)
        }        
        let restructuredTableData = this.tabledataRestruc(data)
        let tableBody = []
        let rowindex = 0      
        console.log("restructuredTableData===>", restructuredTableData)  
        for (let t of restructuredTableData) {            
            if (t.parent) {
                let obj = {}
                obj[rowindex] = {elem :tableBodyHeaderUi(t.title , t.ref, t.giftID) , ref:t.ref , parent:true}
                tableBody.push(obj)  
            }else{
                let col_index = 0
                let objcol = {}                      
                for(let k in t.elem){
                        if(col_index <= 3){  
                            objcol[col_index] = {
                                elem: tableBodyui(col_index , this.datasort(t.elem[k]) ,rowindex, t.gift_type_id, t.id, t.status),
                                ref:t.ref,
                                parent:false
                            }
                        }
                    col_index++
                }
                tableBody.push(objcol)                
            }
            rowindex++
        }       
        return { tableHeader , tableBody }
    }
}

export default class CustomTable extends Component {
    state= {tabledata : [] , tableFunc:null}
    constructor(props){
        super(props)
        this.tableBodyRef = React.createRef();
        this.generic_table_function = new GenericTableFunction()

    }

    componentDidMount() {
       this.init()
    }
    componentDidUpdate(prevProps) {
        if (this.props !== prevProps) {                      
            this.init()
        }
    }
    
    init(){
        // console.log('Sourav', this.props.processTableData())
        let processTableData = this.props.processTableData()   
        let tableFunc = this.generic_table_function.tableFunc(processTableData.data , processTableData.tableHeaderui_primary , processTableData.tableHeaderui_secondary ,processTableData.tableBodyui ,processTableData.tableBodyHeaderUi, processTableData.row_count , processTableData.header_count )        
        this.setState({tabledata :tableFunc.tableBody ,tableFunc , ready:true })
        // let tabledataCount = tableFunc.tableBody.slice(0, 5)        
        // this.setState({tabledata :tabledataCount ,tableFunc , ready:true })        
    }

    changePage = (i, j) => {        
        let tabledata = this.state.tableFunc.tableBody.slice(i, j)
        this.setState({ tabledata })
    }
    rowhide =(toggledindex)=>{
        this.tableBodyRef.current.rowhide(toggledindex)
    }
    render() {
        if (!this.state.ready) return <div>....loading</div>  
        return (
            <div style={{ position: 'relative', width: 'auto' }}>
                <table style={{width: "100%"}}>
                    <TableHeader data={this.state.tableFunc.tableHeader} />
                    <TableBody ref={this.tableBodyRef} data={this.state.tabledata} />
               </table>
               {
				 (() => {
                    if(this.props.nested){
                       return <Pagination nested={this.props.nested} data={this.state.tableFunc.tableBody} changePage={this.changePage} />
                    }          
                  })()
                } 

            </div>
        )
    }
}
