import React from 'react';
import styled from 'styled-components';
import {FaTimes} from 'react-icons/fa';
import Utils from './../../Common/Utils';
import CslSimpleDropDown from './CslSimpleDropDown';
import AlertBox from './../Common/AlertBox';

const MRModalContainer = styled.div`
    display: block;
    box-sizing: border-box;
    height: auto;
    width: 60%;
    position: absolute;
    top: 38px;
    z-index: 1001;
    background-color: rgb(243, 247, 251);
    box-shadow: rgb(167, 166, 166) 0px 0px 5px;
    margin-bottom: 10px;
    border-radius: 5px;
    margin-left: 270px;
`;
const MRModalHeader = styled.div`
    background-color: white;
    display: block;
    padding-top: 5px;
    font-size: 14px;
    font-weight: 700;
    height: 100px;    
    width: calc(100% - 10px);
    border-width: 1px 1px 1px 10px;    
    border-left: 9px solid #04ADA8; 
    border-bottom: 1px solid #DFDFDF;
`;
const MRModalHeaderText = styled.div`
    font-weight: 700;
    font-size: 22px;
    float: left;
    margin-top: 24px;
    margin-left: 35px;  
    color: #143151; 
`;
const MRModalHeaderCloseBtn = styled.div`
    position: absolute;
    top: 8px;
    right: 10px;
    box-sizing: border-box;
    margin-top: 24px;
    margin-right: 20px;
    font-size: 25px;
    cursor: pointer;
    font-weight: 200;
`;
const MRModalBody = styled.div`
    display: block;
    box-sizing: border-box;
    padding: 10px 20px 10px 20px;
`;
const MRModalLabel = styled.div`
    color: #030303;
    font-weight: 600;
    margin-top: 15px;
    font-size: 15px;
    margin-bottom: 15px;
`;
const MRModalFooter = styled.div`
    position: relative;
    background-color: white;
    height: 80px;
    border-style: solid;
    border-width: 1px;
    border-color: #f3f7fb;
    width: 100%;
    border-top: 1px solid #DFDFDF;
`;
const MRModalNextBtn = styled.button`
    display: inline-block;
    box-sizing: border-box;
    background-color: #11ABA6;
    color: #ffffff;
    width: 145px;
    height: 45px;
    border-radius: 4px;
    border: 1px solid #11ABA6;
    margin-left: 5px;
    font-size: 15px;
    margin-left: 10px;
    float: right;
    cursor: pointer;
`;
const MRModalSaveCloseBtn = styled.button`
    display: inline-block;
    box-sizing: border-box;
    background-color: #143151;
    color: #ffffff;
    width: 145px;
    height: 45px;
    border-radius: 4px;
    border: 1px solid #143151;
    margin-left: 5px;
    font-size: 15px;
    margin-left: 10px;
    float: right;
    cursor: pointer;
`;
const MRModalTextarea = styled.textarea`
    width: 99%;
    border: 1px solid #ffffff;
    border-radius: 3px;
    box-shadow: 0 0 4px #c4c4c4;
    padding: 5px;
    color: #000000;
    font-family: 'Montserrat', sans-serif;
`;
const MRModalCancelBtn = styled.button`
    display: inline-block;
    box-sizing: border-box;
    background-color: #ffffff;
    color: #545454;
    border: 2px solid #dadada;
    width: 145px;
    height: 45px;
    border-radius: 4px;
    font-size: 15px;
    float: right;
    cursor: pointer;
`;
const ModalNameLabelDivReference = styled.div`
    height: auto;
    width: 100%;    
    font-weight: 600;
    color: #282828;    
    display:inline;
`;
const MRModalSelect = styled.select`
    box-sizing: border-box;
    height: 40px;
    width: 100%;
    background-color: #fff;
    border: 1px solid #ffffff;
    border-radius: 3px;
    box-shadow: 0 0 4px #c4c4c4;
    font-family: Montserrat,sans-serif;
    color: #444444;    
    padding: 10px;
    font-weight: 600;
`;
const MRModalInput = styled.input`
    height: 25px;
    width: 98%;
    box-shadow: rgb(196, 196, 196) 0px 0px 4px;
    color: #545454;
    font-family: montserrat;
    font-size: 14px;
    font-weight: 600;
    border-width: 1px;
    border-style: solid;
    border-color: rgb(255, 255, 255);
    border-image: initial;
    border-radius: 3px;
    padding: 6px;
`;
const ModalNameInputDiv = styled.div`
    height: auto;
    width: 100%;    
    color: #282828;
    font-family: 'Montserrat', sans-serif;
`;
const MRModalSubHeaderText = styled.div`
    font-weight: 700;
    font-size: 13px;
    float: left;
    margin-left: 35px;
    margin-bottom: 20px;
    margin-top: 10px;
    color: #636363;
`;
const SpacerS = styled.div`
    height: 5px;
`;
const CSLDateCover = styled.div`   
    margin-top: 8px;
    width: 40%;
    border-radius: 5px;
    border-style: solid;
    border-width: 1px;
    border-color: #282828;
    background-color: #ffffff;
    height: 25px;
    padding-top: 2px;
    padding-left: 2px;
    // float: left;
    position: relative;
`;

const SubHeading = styled.div`
	font-style: italic;
	font-size: 12px;
	color: #adacac;
	margin-top: 5px;
`;
const DropdownOptions = styled.textarea`
	border: 1px solid #ffffff;
    border-radius: 3px;
    background-color: #ffffff;
    box-shadow: 0 0 4px #c4c4c4;
    resize: none;
    padding: 5px;
    width: 98%;
    font-family: 'Montserrat',sans-serif;
    margin-top: 7px;
`;

class AddEditCustomField extends React.Component
{
    state = {
        name: "", 
        nickname: "", 
        type: "Alpha", 
        dropdown_options: "", 
        required: false,
        requiredValue: "No",
        requiredValueType: ["Yes", "No"],
        other: false,
        includeOther: "No",
        include_other_option: ["Yes", "No"],
        displayFor: "received",
        displayForValue: "Received",
        displayForValueType: ["Received", "Given", "Both"],
        isActive: 1,
        isDelete: 0,
        alert_param: null,
        ready: false   
    };
    
    componentDidMount()
	{
        if(this.props.curid !== 0) {         
            console.log("content==>",this.props.content) 

            let dropdown_options = "";
            if (this.props.content.dropdown_options.length !== 0) {
                this.props.content.dropdown_options.forEach((item, index) => {
                    if (index !== (this.props.content.dropdown_options.length - 1)) {
                        dropdown_options = dropdown_options + item + "\n";
                    } else {
                        dropdown_options = dropdown_options + item;
                    }
                })
            }

            this.setState({ready:true, 
                name: this.props.content.name, 
                nickname: this.props.content.nickname,
                type: this.props.content.type,
                dropdown_options: dropdown_options,
                required: this.props.content.required,
                requiredValue: this.props.content.required === false ? "No" : "Yes",
                displayFor: this.props.content.displayFor,
                displayForValue: this.props.content.displayFor === 'received' ? "Received" : this.props.content.displayFor === 'both' ? "Both" : "Given",
                isActive: this.props.content.isActive,
                isDelete: this.props.content.isDelete,
                includeOther: 'includeOther' in this.props.content ? this.props.content.includeOther : "No",
                other: 'other' in this.props.content ? this.props.content.other : false,
            })   
        }else{
            const randtxt = Utils.genKey(10);
		    let name = randtxt;            
            this.setState({name, ready: true})
        }       
    }   
    
    handleChange = (event) => {
        event.preventDefault();
        this.setState({[event.target.name]: event.target.value});
    }

    closeModal = (event) => {
		event.preventDefault();
		this.props.closeModal();
    }

    changeFieldType = (selected_type) => {
        console.log("selected_type===>", selected_type)
		this.setState({type: selected_type, dropdown_options: ""})
	}
    changeFieldDisplayForType = (selected_type) => {
        console.log("selected_type===>", selected_type)
        let displayFor = 'received';
        if(selected_type === "Given"){
            displayFor = 'given';
        }else if(selected_type === "Both"){
            displayFor = 'both';
        }
		this.setState({displayForValue: selected_type, displayFor})
	}
    changeFieldRequiredType = (selected_type) => {
        console.log("selected_type===>", selected_type)
        let required = selected_type === "Yes" ? true : false;
		this.setState({requiredValue: selected_type, required})
	}

    changeFieldIncludeOther = (selected_type) => {   
        console.log("selected_type===>", selected_type)
        let other = selected_type === "Yes" ? true : false;
		this.setState({includeOther: selected_type, other})
	}

    modifyDropdownOptions = (event) => {
		event.preventDefault();			
		let dropdown_options = this.state.dropdown_options;
		dropdown_options = event.target.value;
		this.setState({dropdown_options});		
	}

    submitCustomField = (event) => {
        event.preventDefault();
        if(this.state.nickname === "")
        {
            // alert("Please provide the field name.");
            let alert_param = {title: 'ERROR', message: 'Please provide the field name.', ok_text: 'Ok', confirm: false, 
                           alertHandler: this.checkvalidation, stack: {}}
            this.setState({alert_param: alert_param}) 
            return;
        }else if(this.state.type === "Dropdown" && this.state.dropdown_options === ''){
            let alert_param = {title: 'ERROR', message: 'Please provide at least one dropdown option', ok_text: 'Ok', confirm: false, 
                           alertHandler: this.checkvalidation, stack: {}}
            this.setState({alert_param: alert_param}) 
            return;
        }else{            
            const trimedvalue = this.state.dropdown_options;
            let optarr = trimedvalue.split("\n");
            if (optarr.length === 1 && optarr[0] === "") {
                optarr = [];
            }
            let updateedDropdown_options = optarr;

            let customFieldObject = {}
            customFieldObject.name = this.state.name;
            customFieldObject.nickname = this.state.nickname;            
            customFieldObject.type = this.state.type;            
            customFieldObject.dropdown_options = updateedDropdown_options;            
            customFieldObject.required = this.state.required;            
            customFieldObject.displayFor = this.state.displayFor;            
            customFieldObject.includeOther = this.state.includeOther;            
            customFieldObject.other = this.state.other;            
            customFieldObject.isActive = parseInt(this.state.isActive);
            customFieldObject.isDelete = parseInt(this.state.isDelete);            
            if(this.props.curid !== 0) {
                this.props.updateAdditionalFieldsValue(customFieldObject);
            }else{                              
                this.props.saveAdditionalFieldsValue(customFieldObject);
            }
        }
    }

    checkvalidation = (result, stack) => {        
        this.setState({alert_param: null})               
    }

    render()
    {    
        if(!this.state.ready){
            return(<div></div>)
        } 
        let headerText = "Add";
        if(this.props.curid !== 0) {
            headerText = "Edit";
        }           
        return (
            <div>                
                <MRModalContainer> 
                    <MRModalHeader>
                        <MRModalHeaderText>{headerText} Custom Field</MRModalHeaderText>
                        <MRModalHeaderCloseBtn onClick={this.closeModal}><FaTimes /></MRModalHeaderCloseBtn>
                        <div style={{clear: "both"}}></div>
                        <MRModalSubHeaderText>Fill out the below fields to submit your custom field.</MRModalSubHeaderText>
                        <div style={{clear: "both"}}></div>
                    </MRModalHeader>
                    <MRModalBody>
                        <div style={{clear: "both"}}></div>
                        <div style={{position: "relative",padding: "35px 35px"}}>
                            <MRModalLabel style={{display: "inline-block"}}>Field Name</MRModalLabel><span style={{marginLeft: "5px",color: "red", display: "inline-block"}}>*</span>
                            <MRModalInput type="text" name="nickname" onChange={this.handleChange} value={this.state.nickname} />
                            <SpacerS/>
                            <MRModalLabel>Field Type</MRModalLabel>  
                            <div>
                                <CslSimpleDropDown options={this.props.data.available_field_types} selected={this.state.type} changeFieldType={this.changeFieldType} />
                            </div>   
                            {
                                (() => {
                                    if (this.state.type === "Dropdown") {
                                        return (
                                            <div style={{padding: "10px 0px"}}>
                                                <SubHeading>Configure the dropdown options below. New line separates options.</SubHeading>
                                                <DropdownOptions rows="7" value={this.state.dropdown_options} onChange={this.modifyDropdownOptions} />
                                            </div>
                                        );
                                    }
                                })()
                            }                         
                            <SpacerS/>
                            <MRModalLabel>Display for Given/Received?</MRModalLabel>  
                            <div>
                                <CslSimpleDropDown options={this.state.displayForValueType} selected={this.state.displayForValue} changeFieldType={this.changeFieldDisplayForType} />
                            </div>  
                            <SpacerS/>                            
                            <div style={{display: "inline-block", width: "50%"}}>
                                <MRModalLabel>Required?</MRModalLabel>  
                                <div style={{width: "97%"}}>
                                    <CslSimpleDropDown options={this.state.requiredValueType} selected={this.state.requiredValue} changeFieldType={this.changeFieldRequiredType} />
                                </div>
                            </div>
                            {
                                (() => {
                                    if (this.state.type === "Dropdown") {
                                        return (<div style={{display: "inline-block", width: "50%"}}>
                                        <MRModalLabel>Include Other</MRModalLabel>  
                                        <div style={{width: "100%"}}>
                                            <CslSimpleDropDown options={this.state.include_other_option} selected={this.state.includeOther} changeFieldType={this.changeFieldIncludeOther} />
                                        </div>  
                                    </div> );
                                    }
                                })()
                            }                                                        
                        </div>
                    </MRModalBody>
                    <MRModalFooter>
                        <div style={{position: "relative",padding: "17px"}}>                    
                            <MRModalNextBtn onClick={this.submitCustomField}>Submit</MRModalNextBtn>
                            <MRModalCancelBtn onClick={this.closeModal}>Cancel</MRModalCancelBtn>
                        </div>             
                    </MRModalFooter>
                </MRModalContainer>
                <AlertBox alertParam = {this.state.alert_param}/>
            </div>);
    }
}

export default AddEditCustomField;