import React, { Component } from 'react'
import { FaCaretDown } from "react-icons/fa";
import CustomTable1 from './Table/Table';
import Toggle from 'react-toggle';
import "./ReactToggle.css";
import VerticalEllipsisMenuFolderApproval from "./VerticalEllipsisMenuFolderApproval";

class ApprovalTable extends React.Component
{
    state = {		
		toggledindex:null,
        data: [],
        ready: false        
	}

    constructor(props){
        super(props)
        this.tableRef = React.createRef();
    }

	componentDidMount()
	{  
        this.setState({data: this.props.data, ready: true})
	}

	componentDidUpdate(prevProps)
	{
		if (prevProps !== this.props) {
            this.setState({data: this.props.data, ready: true})
		}
	}	

    collapse=(ref)=>{
        let {toggledindex} = this.state
        if(toggledindex === ref){
            toggledindex = null
        }else{
            toggledindex = ref
        }
        this.setState({toggledindex})
        this.tableRef.current.rowhide(toggledindex)
    }

    tableHeaderui_1 = (headerindex) => {
        switch (headerindex) {
            case 0:
                return (
                    <div style={{ height: '20px', padding: '10px', backgroundColor: '#E7F6F6' }}>

                    </div>
                )
            case 1:
                return (
                    <div style={{ height: '20px', padding: '10px', backgroundColor: '#E7F6F6', textAlign: 'center' }}  >
                        Received
                    </div>
                )
            case 2:
                return (
                    <div style={{ height: '20px', padding: '10px', backgroundColor: '#E7F6F6', textAlign: 'center' }}>
                        Given
                    </div>
                )
            case 3:
                return (
                    <div style={{ height: '20px', padding: '10px', backgroundColor: '#E7F6F6' }}>

                    </div>
                )

        }
    }

    tableHeaderui_2 = (headerindex) => {
        switch (headerindex) {
            case 0:
                return (
                    <div style={{height:'20px', width: 'auto', padding: '10px', backgroundColor: '#F3F7FB' }}>

                    </div>
                )
            case 1:
                return (
                    <div style={{ width: 'auto', padding: '10px', backgroundColor: '#F3F7FB', display: 'flex' }}>
                        <div style={{ width: '55%', marginLeft: "10px" }}>
                            Value
                        </div>
                        <div style={{ width: '50%' }}>
                            Approvers
                        </div>
                    </div>
                )
            case 2:
                return (
                    <div style={{ width: 'auto', padding: '10px', backgroundColor: '#F3F7FB', display: 'flex' }}>
                        <div style={{ width: '55%', marginLeft: "10px" }}>
                            Value
                        </div>
                        <div style={{ width: '50%' }}>
                            Approvers
                        </div>
                    </div>
                )
            case 3:
                return (
                    <div style={{ width: 'auto', padding: '10px', backgroundColor: '#F3F7FB', textAlign: 'center' }}>
                        Status
                    </div>
                )
        }
    }

    handlenaSectionIncluded = (section_id) => (event) => {
		console.log("content:", section_id);
		this.props.statusCheck(section_id)
	}

    cellStyle = (status)=>{
        if(!status){
            return {color:"#BEC6CE"}
        }
    }

    tableBodyui = (col_index, data, rowindex, id, status) => {
        // console.log("col_index==>",col_index ,"data===>", data, "rowindex===>", rowindex)
        switch (col_index) {
            case 0:                
                return (
                    <div style={{ width: 'auto', height: '20px', padding: '10px' , backgroundColor: '#FFFFFF' }}>
                        <span style={this.cellStyle(status)}>{data[0].title}</span>
                    </div>
                )
            case 1:                
                return (
                    <div style={{ width: 'auto', height: '20px', padding: '10px', display: 'flex' , backgroundColor:  '#FFFFFF' }}>
                        <span style={{paddingLeft:'10px' , width:'50%'}}><span style={this.cellStyle(status)}>{data[0].title}</span></span>
                        <span style={{paddingLeft:'10px' , width:'50%'}}><span style={this.cellStyle(status)}>{data[1].title}</span></span>
                    </div>
                )
            case 2:
                return (
                    <div style={{ width: 'auto', height: '20px', padding: '10px', display: 'flex' , backgroundColor:'#FFFFFF' }}>
                        <span style={{paddingLeft:'10px' , width:'50%'}}><span style={this.cellStyle(status)}>{data[0].title}</span></span>
                        <span style={{paddingLeft:'10px' , width:'50%'}}><span style={this.cellStyle(status)}>{data[1].title}</span></span>
                    </div>
                )
            case 3:
                return (
                    <div style={{ width: 'auto', height: '20px', padding: '10px', display: 'flex' , backgroundColor: '#FFFFFF' }}>
                        <span style={{width:'50%', marginLeft: "30px"}}><Toggle
								style={{float:'left'}}
								defaultChecked={data[0].title === true ? true : false}
								icons={false}	
                                onChange={this.handlenaSectionIncluded(id)}						
							/></span>
                        <span style={{float:'right'}}><VerticalEllipsisMenuFolderApproval editDelete={this.showConfigModal(id)} options={["Edit", "Delete"]} /></span>
                    </div>
                )
        }
    }

    showConfigModal = (curid) => (obj) => {
		console.log("curid==>", curid)
		console.log("obj==>", obj)
        if(obj === 'Edit'){
            this.props.editApproval(curid)	
        }else if(obj === 'Delete'){
            this.props.deleteApproval(curid)	
        }	
	};

    tableBodyHeaderUi = (title , ref) => {
      
        return (
            <div style={{ width: '100%' , padding:'10px' , fontWeight:700 }}>
               <span><FaCaretDown onClick={()=>this.collapse(ref)} /> &nbsp; {title} </span> 
            </div>
        )
    }

    processTableData = () => {   
        let ret = {
            data : this.state.data,
            tableHeaderui_primary:this.tableHeaderui_1,
            tableHeaderui_secondary:this.tableHeaderui_2,
            tableBodyui:this.tableBodyui,
            tableBodyHeaderUi:this.tableBodyHeaderUi,
            row_count:4,
            header_count:2
        };
        return ret
    }

    render() {		
        if(!this.state.ready){
            return(<div>Loading...</div>)
        }        
        return (<CustomTable1 nested={false} ref={this.tableRef} processTableData={this.processTableData}  />)        
    }
}


export default ApprovalTable;
