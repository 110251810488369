import React from 'react';
import styled from 'styled-components';
import Store from '../../Common/Store.js';
import GMGiftsList from './GMGiftsList';
import GMAskGiftModal from './GMAskGiftModal';

const SubmitButton = styled.button`
    display: inline-block;
    box-sizing: border-box;
    background-color: #223B5A;
    color: #ffffff;
    width: 160px;
    height: 35px;
    border-radius: 4px;
    border: 1px solid #37ada7;
    margin-left: 5px;
    font-size: 11px;
    padding-left: 12px;
    cursor: pointer;
`;

const IndexContainer = styled.div`
    padding-top: 8px;
    padding-left: 5px;
`;

const InactiveOverlay = styled.div`
  display: block;
  box-sizing: border-box;
  height: 150%;
  width: 100%;
  background-color: #ffffff;
  position: absolute;
  left: -2%;
  top: -46px;
  z-index: 1000;
  opacity: 0.8;
`;


class GMIndex extends React.Component
{
    state = {
        isLoaded: false,
        pending_tasks: [],
        showModal: null
    };
    constructor(props) {
        super(props);
    }

    refreshCallback = () => {
        this.props.refreshCallback();
    }

    showAskQuestionModal = () => {
        Store.updateStore('OverlayHeight', `${document.body.scrollHeight.toString()}px`);
        Store.updateStore('view','team_ask_modal');
        this.props.refreshCallback();
    }

    showGiftModal = (row) => (event) => {       
        event.preventDefault();
        let index = row.index === null ? 0 : row.index;
        console.log('row in showAskQuestionModal', row);
        console.log('index in showAskQuestionModal', index);
        let q_index = index;
        Store.updateStore('q_index', q_index);
        this.setState({showModal: "GMAskGiftModal"})
    }
    
    closeModal = (event) => {
        //event.preventDefault();
        this.setState({
            showModal: null
        })
    }

    render()
    {
        let task_id = this.props.taskId !== 0 ? this.props.taskId : 0;
        console.log("task_id2===>",task_id)        
        return (
            <IndexContainer>
                {
                    (() => {
                        if(task_id === 0) {
                            return(<SubmitButton id='add_new_record' onClick={this.showGiftModal({index: -1})}>Add New Record</SubmitButton>)
                        }
                    })()
                }                
                <GMGiftsList add={false} taskId={task_id} refreshCallback={this.refreshCallback} />
                {
                (() => {
                    if (this.state.showModal !== null) {
                        switch(this.state.showModal) {
                            case 'GMAskGiftModal' : return <div style={{position:'absolute', left:'2%', top:'50px', width:'100%', height:'600px'}}><InactiveOverlay /><GMAskGiftModal closeModal={this.closeModal} /></div>; break;
                        }
                    }
                })()
                }
            </IndexContainer>
        );
    }
}
export default GMIndex;