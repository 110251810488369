import React from 'react';
import styled from 'styled-components';
import Store from '../../Common/Store';
import APICall from '../../Common/APICall';
import Dropzone from '../Common/Dropzone';
import AlertBox from '../Common/AlertBox'
import { FaTimes} from 'react-icons/fa';

const RegModalContainer = styled.div`
    display: block;
    box-sizing: border-box;
    height: auto;
    width: 97%;
    position: absolute;
    margin: 0 0;
    top: 8%;
    z-index: 1001;
    background-color: #f7f7f7;
    border-radius: 5px;
    box-shadow: 0 0 20px #a7a6a6;
    margin-left: 10px;
`;
const RegModalHeaderText = styled.div`
    float: left;
    box-sizing: border-box;
    margin-top: 16px;
    margin-left: 20px;
    color: #2C2C2C;
    font-size: 17px;
    font-weight: bold;
`;
const RegModalHeaderCloseBtn = styled.div`
    float: right;
    box-sizing: border-box;
    margin-top: 16px;
    margin-right: 30px;
    color: #9C9C9C;
    font-size: 16px;
    cursor: pointer;
`;

const RegCancelSubmitButtonOuterContainer = styled.div`
    display: block;
    box-sizing: border-box;
    padding-right: 20px;
    padding-bottom: 20px;
    text-align: right;
    margin-top: 30px;
`;
const RegCancelButton = styled.button`
    display: inline-block;
    box-sizing: border-box;
    background-color: #ffffff;
    color: #909090;
    border: 2px solid #dadada;
    width: 100px;
    height: 35px;
    border-radius: 4px;
    font-size: 11px;
    cursor: pointer;
`;
const RegSubmitButton = styled.button`
    display: inline-block;
    box-sizing: border-box;
    background-color: #37ada7;
    color: #ffffff;
    width: 100px;
    height: 35px;
    border-radius: 4px;
    border: 1px solid #37ada7;
    margin-left: 5px;
    font-size: 11px;
    cursor: pointer;
`;
const RegModalContainertop = styled.div`
    display: grid;
    grid-template-columns: 10% calc(90% - 15px);
    grid-gap: 15px;
    padding: 20px;
`;
const RegModalNameLabelDiv = styled.div`
    height: auto;
    width: 100%;
    padding-top: 7px;
    font-weight: 600;
    color: #979797;
`;
const RegModalNameInputDiv = styled.div`
    height: auto;
    width: 100%;
`;
const RegDismissalReason = styled.textarea`
    height: 130px;
    width: 98%;
    border: 1px solid #ffffff;
    border-radius: 3px;
    box-shadow: 0 0 4px #c4c4c4;
    padding: 5px;
    color: #444444;
    font-weight: 100;   
`;

const ModalNameLabelDivReference = styled.div`
    height: auto;
    width: 100%;
    padding-top: 48px;
    font-weight: 600;
    color: #979797;
    padding-left: 24px;
    display:inline;
`;
const ModalNameLabelDivReferenceID = styled.div`
    height: auto;
    width: 100%;
    padding-top: 48px;
    font-weight: 600;
    color: #979797;
    padding-left: 72px;
    display:inline;
`;

const MRModalLabel = styled.div`
    color: #212121;
    font-weight: 600;
    margin-top: 10px;
    margin-bottom: 10px;
    font-size: 14px;
`;
const MRModalTextarea = styled.textarea`
    width: 98%;
    border: 1px solid #ffffff;
    border-radius: 3px;
    box-shadow: 0 0 4px #c4c4c4;
    padding: 5px;
    color: #979797;
`;
const MRModalBody = styled.div`
    display: block;
    box-sizing: border-box;
    padding: 0px 20px 10px 20px;
`;
const RegDismisOuterContainer = styled.div`
    display: block;
    box-sizing: border-box;
    width: 100%;
    padding: 6px 20px 20px 20px;
`;
const OverviewInfoBody = styled.div`
    padding: 15px;
    background-color: #EDF0F1;
    border: 1px solid #E1E3E4;
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
    border-top-right-radius: 5px;
    border-top-left-radius: 5px;
    width: 95%;
    margin: 0 auto;
    margin-bottom: 30px; 
    display: inline-block;
    margin-top: 28px;   
`;

const Radiolevel = styled.label`
    cursor: pointer;
    text-transform: uppercase;
    border: 1px solid #ccc;
    width: 59px;
    height: 20px;
    padding: 6px 0;
    text-align: center;
    display: inline-block;
    -webkit-transition: all 0.4s;
    -moz-transition: all 0.4s;
    transition: all 0.4s;    
`;
const ModalNameLabelDiv = styled.div`
    height: auto;
    width: 100%;
    padding-top: 30px;
    font-weight: 600;
    color: #282828;
`;
const ModalNameInputDiv = styled.div`
    height: auto;
    width: 100%;
    padding-top:30px;
    color: #282828;
    font-family: 'Montserrat', sans-serif;
`;

class TeamFollowupModal extends React.Component
{

    state = {task: null, payload: {}, task_id: null, comments: "",task_title:"", task_note: "", ready: false, send_mail_config: false,
    curlane: '',binFiles: [], cur_files_json: null, is_disabled: false, alert_param: null };

    GetPayloadData = (parent_task) => {
        let payload = {};
        let parent_task_json = JSON.parse(parent_task.task_json);
        payload.Ref_ID = parent_task_json.object_data.ref_id;
        const discloserdate_obj = new Date(parent_task.due_date);
        const discloserdate_day = discloserdate_obj.getDate() < 10 ? "0"+discloserdate_obj.getDate().toString() : discloserdate_obj.getDate();
        const discloserdate_month = discloserdate_obj.getMonth()+1 < 10 ? `0${(discloserdate_obj.getMonth() + 1).toString()}` : discloserdate_obj.getMonth()+1;
        const discloserdate_year = discloserdate_obj.getFullYear();
        const discloserdate_date = `${discloserdate_day}/${discloserdate_month}/${discloserdate_year}`;
        payload.date_of_entry = discloserdate_date;
        let status = "";
        if(parent_task.cur_lane === "COMP_GIFTS_REGISTER") {
            status =  "Registered";
        }else if(parent_task.cur_lane === "COMP_GIFTS_COMPLETE") {
            status =  "Confirmed";
        }else if(parent_task.cur_lane === "COMP_GIFTS_REMOVE") {
            status = "Removed"
        }
        payload.status = status;
        payload.receive_or_given = parent_task_json.object_data.headerinfo.gift_status === 'received' ? 'Received' : 'Given';

        let giftTypeList = Store.getStoreData('module_config').gift_types;
        let gifttypename = "";
        giftTypeList.forEach((item) => {
            if (item.giftTypeID === parent_task_json.object_data.headerinfo.gift_typeid) {
                gifttypename = item.giftTypeName;
            }
        });

        payload.type = gifttypename;
        payload.value = parent_task_json.object_data.headerinfo.minvalue;
        payload.gift_description = parent_task_json.object_data.headerinfo.gift_description;

        return payload;
    }

	componentDidMount()
	{
        let {task,payload,task_id,task_title,task_note,curlane,ready} = this.state;
        let task_data = Store.getStoreData('followup_task');
        curlane = task_data.subtask.cur_lane;
        let followup_task = task_data.subtask;
        task_id = followup_task.id;
        let task_json = JSON.parse(followup_task.task_json);
        task_title = task_json.object_data.subject
        task_note = task_json.object_data.note
        let cur_files_json = [];
        if('bin_files' in task_json.object_data){
            cur_files_json = task_json.object_data.bin_files;
        }
        let task_command = "";
        if('commands' in task_json.object_data){
            task_command = task_json.object_data.commands;
        }
        let parent_task = task_data.parenttask;
        console.log("parent_task",followup_task)
        payload = this.GetPayloadData(parent_task);
        console.log("payload", task_data);
        this.setState({task: task_json,payload,task_id,task_title,task_note,comments: task_command,curlane,cur_files_json: cur_files_json,ready:true});
	}

	componentDidUpdate(prevProps)
	{
		if (prevProps !== this.props) {
            let {task,payload,task_id,task_title,task_note,curlane,ready} = this.state;
            let task_data = Store.getStoreData('followup_task');
            curlane = task_data.subtask.cur_lane;
            let followup_task = task_data.subtask;
            task_id = followup_task.id;
            let task_json = JSON.parse(followup_task.task_json);
            task_title = task_json.object_data.subject
            task_note = task_json.object_data.note
            let parent_task = task_data.parenttask;
            console.log("parent_task",parent_task)
            payload = this.GetPayloadData(parent_task);
            console.log("payload", task_data);
            this.setState({task: task_json,payload,task_id,task_title,task_note,curlane,ready:true});
		}
	}
    closeModal = (event) => {
        if (document.referrer.endsWith("/tmview")) {
            window.location =  process.env.REACT_APP_TMVIEW_URL;
        } else {
            //event.preventDefault();
            if(Store.getStoreData('isreferenceUrl') === true){
                if(Store.getStoreData('isReferenceEmail') === true){
                    window.location =  "/";
                }
            } else {
                this.props.closeModal();
            }
        }
    }

    UpdateSubTask = (event) => {
        event.preventDefault();
        let task_json = this.state.task;
        //console.log(task_json);
        task_json['task_data'].last_action = 'COMP_GIFTS_FOLLOWUP_COMPLETE';
        task_json['task_data'].cur_lane = 'COMP_GIFTS_FOLLOWUP_COMPLETE';
        task_json['task_data'].actiondate = Date.now();
        task_json['object_data'].commands = this.state.comments;
        task_json['action_data'] = {action: "COMP_GIFTS_FOLLOWUP_COMPLETE", actiondate: Date.now(), performed_by: Store.getStoreData('contact_id'), cur_lane: "COMP_GIFTS_FOLLOWUP_COMPLETE", comments: this.state.comments, assigned_to: 0 };
        let postData = {command: "update_gifts_followup_task", task_json: task_json, task_id: this.state.task_id };
        console.log(postData);
        const api = new APICall();
        api.command(postData, this.afterCompleted);
    }
    afterCompleted = (result) => {
        // console.log("Completed:", result);
        // alert('The follow-up task has been completed.');
        let alert_param = {title: 'Success', message: 'The follow-up task has been completed.', ok_text: 'OK', confirm: false, alertHandler: this.alertremoveHandler, stack: {id: 0}}
        this.setState({alert_param: alert_param})
        // window.location.reload(true);
    }

    alertremoveHandler = (result,stack) => {
        this.setState({alert_param: null})
        if(Store.getStoreData('isreferenceUrl') === true){
            if(Store.getStoreData('isReferenceEmail') === true){
                window.location = "/";
            }
        } else {
            this.props.closeModalForApproval();
        }
        // window.location.reload();
    }

    handleChange = (event) => {
        event.preventDefault();
        this.setState({[event.target.name]: event.target.value});
    }

    filesLoaded = (files) => {
        let contact_id = Store.getStoreData('contact_id');
        for(let i=0; i < files.length; i++) {
            files[i].name = files[i].name.replace('&', '');
            //console.log('File_name', files[i].name);
            if(!('user_id' in files[i])) {
                files[i].user_id = contact_id;
                files[i].upload_date = Date.now();
            }

        }
        //console.log('files in Modal', files);
        this.setState({binFiles: files});
    }

    render()
    {
        let curAssignTo = Store.getStoreData('followup_task').subtask.cur_assigned_to;
        let curUserID = Store.getStoreData('contact_id');
        let role = Store.getStoreData('role');
        console.log("task_data===>", role)
        if(!this.state.ready){
            return <div> Loading.....</div>
        }
        return(
            <div>
            <RegModalContainer>
                <div style={{marginTop: "17px"}}>
                    <RegModalHeaderText>{this.state.task_title}</RegModalHeaderText>
                    <RegModalHeaderCloseBtn onClick={this.closeModal}><FaTimes /></RegModalHeaderCloseBtn>
                </div>
                <RegDismisOuterContainer>
                    <OverviewInfoBody style={{marginBottom: "10px"}}>
                        <MRModalLabel>Task Description</MRModalLabel>
                    <div dangerouslySetInnerHTML={{__html: this.state.task_note}} style={{textAlign: "justify",color: "#6C6C6C"}}></div>
                    {
                        (() => {
                            if(this.state.cur_files_json !== null && this.state.cur_files_json.length !== 0){
                                return(<div>
                                        <ModalNameLabelDiv>Attachments</ModalNameLabelDiv>
                                        <ModalNameInputDiv>
                                            <Dropzone onFilesAdded={this.filesLoaded} initFiles={this.state.cur_files_json} addnew={false}/>
                                        </ModalNameInputDiv>
                                </div>)
                            }
                        })()
                    }
                    </OverviewInfoBody>
                    <OverviewInfoBody style={{marginBottom: "10px"}}>
                        <MRModalLabel>Date of Entry</MRModalLabel>
                        <div style={{fontFamily: "'Montserrat', sans-serif",marginBottom: "20px",marginTop: "20px",fontSize: "12px",color: "#6C6C6C"}}>{this.state.payload.date_of_entry}</div>
                        <MRModalLabel style={{marginTop: "30px"}}>Status</MRModalLabel>
                        <div style={{fontFamily: "'Montserrat', sans-serif",marginBottom: "20px",marginTop: "20px",fontSize: "12px",color: "#6C6C6C"}}>{this.state.payload.status}</div>
                        <MRModalLabel style={{marginTop: "30px"}}>Was the gift received or given?</MRModalLabel>
                        <div style={{fontFamily: "'Montserrat', sans-serif",marginBottom: "20px",marginTop: "20px",fontSize: "12px",color: "#6C6C6C"}}>{this.state.payload.receive_or_given}</div>
                        <MRModalLabel style={{marginTop: "30px"}}>Type</MRModalLabel>
                        <div style={{fontFamily: "'Montserrat', sans-serif",marginBottom: "20px",marginTop: "20px",fontSize: "12px",color: "#6C6C6C"}}>{this.state.payload.type}</div>
                        <MRModalLabel style={{marginTop: "30px"}}>Value</MRModalLabel>
                        <div style={{fontFamily: "'Montserrat', sans-serif",marginBottom: "20px",marginTop: "20px",fontSize: "12px",color: "#6C6C6C"}}>{this.state.payload.value}</div>
                        <MRModalLabel style={{marginTop: "30px"}}>Please provide a description of the gift/hospitality</MRModalLabel>
                        <div style={{fontFamily: "'Montserrat', sans-serif",marginBottom: "20px",marginTop: "20px",fontSize: "12px",color: "#6C6C6C",whiteSpace: "pre-line"}}>{this.state.payload.gift_description}</div>

                    </OverviewInfoBody>
                </RegDismisOuterContainer>
                <MRModalBody>
                {
                    (() => {
                        if(curAssignTo === curUserID && this.state.curlane !== "COMP_GIFTS_FOLLOWUP_COMPLETE"){
                            return(<div>
                                    <MRModalLabel>Comments</MRModalLabel>
                                    <RegDismissalReason name="comments" onChange={this.handleChange} value={this.state.comments}></RegDismissalReason>
                                    </div>)
                            }else if((role === "admin_manager" || role === "manager") && this.state.curlane !== "COMP_GIFTS_FOLLOWUP_COMPLETE"){
                                return(<div>
                                    <MRModalLabel>Comments</MRModalLabel>
                                    <RegDismissalReason name="comments" onChange={this.handleChange} value={this.state.comments}></RegDismissalReason>
                                    </div>)
                            }else{
                                return(<div>
                                    <MRModalLabel>Comments</MRModalLabel>
                                    <RegDismissalReason name="comments" value={this.state.comments} readOnly></RegDismissalReason>
                                    </div>)
                            }
                    })()
                }
                </MRModalBody>
                <RegCancelSubmitButtonOuterContainer>
                    <RegCancelButton onClick={this.closeModal}>Cancel</RegCancelButton>
                    {
                        (() => {
                            if(curAssignTo === curUserID && this.state.curlane !== "COMP_GIFTS_FOLLOWUP_COMPLETE"){
                                return(<RegSubmitButton onClick={this.UpdateSubTask}>Complete</RegSubmitButton>)
                            }else if((role === "admin_manager" || role === "manager") && this.state.curlane !== "COMP_GIFTS_FOLLOWUP_COMPLETE"){
                                return(<RegSubmitButton onClick={this.UpdateSubTask}>Complete</RegSubmitButton>)
                            }
                        })()
                    }

                </RegCancelSubmitButtonOuterContainer>
            </RegModalContainer>
            <AlertBox alertParam = {this.state.alert_param}/>
            </div>

        )
    }

}
export default TeamFollowupModal;
