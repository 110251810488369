import React from "react";
import styled from "styled-components";
import CslSimpleDropDown from './CslSimpleDropDown';
import CslSimpleDropDownSearchable from './CslSimpleDropDownSearchable';
import CslSimpleDropDownSearchable_given from './CslSimpleDropDownSearchable_given';
import { FaTimes , FaExclamationTriangle} from "react-icons/fa";
import Store from "../../Common/Store";
import AlertBox from '../Common/AlertBox';
import { blockInvalidChar } from "../Common/blockInvalidChar";

const EmailContainer = styled.div`
    width: 60%;
    height:auto;
    top:8%;
    position: absolute;
    background-color: #f6f9fd;
    border-radius: 2px;
    box-shadow: 0 0 20px #a7a6a6;
    z-index: 1005;
    padding: 0px;
`;
const CancelBtn = styled.button`
    padding: 10px 30px;
	background-color: #ffffff;
	border: 1px solid #EBEBEB;
	border-radius: 2px;
	color: #282828;
	cursor: pointer;	
`;
const MRModalLabel = styled.div`
    color: #212121;
    font-weight: 600;
    margin-top: 10px;
    margin-bottom: 10px;
`;
const MRModalInput = styled.input`
    height: 25px;
    width: 80%;
    border: 1px solid #ffffff;
    border-radius: 3px;
    box-shadow: 0 0 4px #c4c4c4;
    padding: 5px;
    padding-right: 10px;
    padding-left: 10px;
    color: #000000;
`;
const MRModalHeader = styled.div`
    background-color: white;
    display: block;
    padding-top: 5px;
    font-size: 14px;
    font-weight: 700;
    height: 100px;    
    width: calc(100% - 10px);
    border-width: 1px 1px 1px 10px;    
    border-left: 9px solid #04ADA8; 
    border-bottom: 1px solid #DFDFDF;
`;
const MRModalHeaderText = styled.div`
    font-weight: 700;
    font-size: 22px;
    float: left;
    margin-top: 24px;
    margin-left: 35px;  
    color: #143151; 
`;
const MRModalHeaderCloseBtn = styled.div`
    position: absolute;
    top: 8px;
    right: 10px;
    box-sizing: border-box;
    margin-top: 24px;
    margin-right: 20px;
    font-size: 25px;
    cursor: pointer;
    font-weight: 200;
`;
const MRModalBody = styled.div`
	position: relative;
	display: block;
	box-sizing: border-box;
	background-color: #F6F9FD;
	padding: 35px 35px 35px 35px;
	border-radius: 4px;
`;
const SaveBtn = styled.button`
	padding: 10px 30px;
	background-color: #37ADA7;
	border: 1px solid #37ADA7;
	border-radius: 2px;
	color: #ffffff;
	cursor: pointer;
	margin-left: 15px;
`;  
const MRModalSubHeaderText = styled.div`
    font-weight: 700;
    font-size: 13px;
    float: left;
    margin-left: 35px;
    margin-bottom: 20px;
    margin-top: 10px;
    color: #636363;
`;
const DocFooter = styled.div`
    position: relative;
    background-color: white;
    border-style: solid;
    border-width: 1px;
    border-color: #e9ecef;
    height: 79px;
    width: 100%;
    margin-left: 0px;
    border-bottom-radius: 2px;
`;
const FooterButtonsContainer = styled.div`
	text-align: right;
	padding-top: 25px;
	width: 95%;
`; 

const MRModalSelect = styled.select`
	display: block;
	box-sizing: border-box;
	height: 40px;
	width: 50%;
	background-color: #fff;
	border: 1px solid #ffffff;
	border-radius: 3px;
	box-shadow: 0 0 4px #c4c4c4;
	font-family: "Montserrat", sans-serif;
	color: #000000;
	margin-top: 10px;
	margin-bottom: 10px;
`;
const FilterBody = styled.div`
	background-color: #ffffff;
	
	margin-top: 2px;
	width: 100%;
	box-sizing: border-box;
`;
const ScoreBodyContainer = styled.div`
  padding: 10px;
  text-align: left;
  overflow-y: scroll;
  height: 555px;
`;
const RadioBlock = styled.div`
	margin-top: 10px;
`;
const SelectedRadio = styled.div`
	background-color: #37ADA7;
	border: 1px solid #1f615e;
	padding: 5px 10px;
	color: #ffffff;
	display: inline-block;
	cursor: pointer;
`;
const UnselectedRadio = styled.div`
	background-color: #F4F4F4;
	border: 1px solid #8c8c8c;
	padding: 5px 10px;
	color: #262626;
	display: inline-block;
	cursor: pointer;
`;

class AddEditApprovalModal extends React.Component {
    state = {    
        isApprovalGivenReceived: true,

        displayForLavelType: ["Level 1"],         
        displayForApproverType: ["Role","Specific User(s)"], 

        defaultApprovalValue: '',
        givenApprovalValue: '',
        receivedApprovalValue: '',
        defaultDisplayForLavel: "Level 1", 
        givenDisplayForLavel: "Level 1", 
        receivedDisplayForLavel: "Level 1", 
        defaultDisplayForApprover: "",
        givenDisplayForApprover: "",
        receivedDisplayForApprover: "",
        defaultApprover_options:[], 
        defaultSelected_multiple:[], 
        defaultApprover_tableHeader: "",
        givenApprover_options:[], 
        givenSelected_multiple:[], 
        givenApprover_tableHeader: "",
        receivedApprover_options:[], 
        receivedSelected_multiple:[], 
        receivedApprover_tableHeader: "",

        checkValueEqualDefault: false,
        checkValueEqualGiven: false,
        checkValueEqualReceived: false,

        ready: false,
        alert_param: null,
        approvalID: null,        
        approvalStatus: false,
        displayForLavelFiexd: ""
    };

    componentDidMount() {
        console.log("curid", this.props.curid)     
        if(this.props.curid !== 0) { 
            let approvalGiftTypesAll = this.props.approvalGiftTypesAll[0].elem
            let selectedRow = {}
            for(let d of approvalGiftTypesAll){
                if(parseInt(d.id) === parseInt(this.props.curid)){
                    selectedRow = d; 
                }
            } 
            console.log("selectedRow=========>", selectedRow) 
            let isApprovalGivenReceived = selectedRow.isApprovalGivenReceived;  
            let defaultApprovalValue = selectedRow.defaultApproval.value;
            let defaultDisplayForLavel = selectedRow.level;
            let defaultDisplayForApprover = selectedRow.defaultApproval.approver_type;            
            let defaultSelected_multiple = selectedRow.defaultApproval.approvers;        
            
            let receivedApprovalValue = selectedRow.receivedApproval.value;
            let receivedDisplayForLavel = selectedRow.level;
            let receivedDisplayForApprover = selectedRow.receivedApproval.approver_type;            
            let receivedSelected_multiple = selectedRow.receivedApproval.approvers;

            let givenApprovalValue = selectedRow.givenApproval.value;
            let givenDisplayForLavel = selectedRow.level;
            let givenDisplayForApprover = selectedRow.givenApproval.approver_type;            
            let givenSelected_multiple = selectedRow.givenApproval.approvers;    

            let dsObject = {}
            for(let d of defaultSelected_multiple){
                dsObject[d.id] = 1;
            }
            let rsObject = {}
            for(let d of receivedSelected_multiple){
                rsObject[d.id] = 1;
            }
            let gsObject = {}
            for(let d of givenSelected_multiple){
                gsObject[d.id] = 1;
            }

            let approver_options_default = [
                {id: 'gm', name: "Gift Manager", isCheck: false},
                {id: 'am', name: "Admin Manager", isCheck: false},
                {id: 'lm', name: "Line Manager", isCheck: false}
            ]            
            let approver_options_received = [
                {id: 'gm', name: "Gift Manager", isCheck: false},
                {id: 'am', name: "Admin Manager", isCheck: false},
                {id: 'lm', name: "Line Manager", isCheck: false}
            ]            
            let approver_options_given = [
                {id: 'gm', name: "Gift Manager", isCheck: false},
                {id: 'am', name: "Admin Manager", isCheck: false},
                {id: 'lm', name: "Line Manager", isCheck: false}
            ]            
            
            if(defaultDisplayForApprover === 'Role'){
                for(let d of approver_options_default){
                    if(d.id in dsObject){
                        d.isCheck = true
                    }
                }                
            }else{
                let contacts = Store.getStoreData('contacts');
                let approver_options_default_new = []
                for(let k in contacts){
                    let appObject = {}
                    appObject.id = k
                    appObject.name = contacts[k]
                    appObject.isCheck = false
                    if(k in dsObject){
                        appObject.isCheck = true
                    } 
                    approver_options_default_new.push(appObject);
                }
                approver_options_default = approver_options_default_new
            }                    
            if(receivedDisplayForApprover === 'Role'){
                for(let d of approver_options_received){
                    if(d.id in rsObject){
                        d.isCheck = true
                    }
                }
            }else{
                let contacts = Store.getStoreData('contacts');
                let approver_options_received_new = []
                for(let k in contacts){
                    let appObject = {}
                    appObject.id = k
                    appObject.name = contacts[k]
                    appObject.isCheck = false
                    if(k in rsObject){
                        appObject.isCheck = true
                    }
                    approver_options_received_new.push(appObject);
                }
                approver_options_received = approver_options_received_new
            }              
            if(givenDisplayForApprover === 'Role'){
                for(let d of approver_options_given){
                    if(d.id in gsObject){
                        d.isCheck = true
                    }
                }
            }else{
                let contacts = Store.getStoreData('contacts');
                let approver_options_given_new = [];
                for(let k in contacts){
                    let appObject = {}
                    appObject.id = k
                    appObject.name = contacts[k]
                    appObject.isCheck = false
                    if(k in gsObject){
                        appObject.isCheck = true
                    }
                    approver_options_given_new.push(appObject);
                }
                approver_options_given = approver_options_given_new;
            }    

            let displayForLavelFiexd = 'Level '+ approvalGiftTypesAll.length;  

            this.setState({                
                isApprovalGivenReceived,  
                defaultApprovalValue,
                defaultDisplayForLavel,
                defaultDisplayForApprover,
                defaultApprover_options: approver_options_default,
                defaultApprover_tableHeader: defaultDisplayForApprover,
                defaultSelected_multiple,

                receivedApprovalValue,
                receivedDisplayForLavel,
                receivedDisplayForApprover,
                receivedApprover_options: approver_options_received,
                receivedApprover_tableHeader: receivedDisplayForApprover,
                receivedSelected_multiple,

                givenApprovalValue,
                givenDisplayForLavel,
                givenDisplayForApprover,
                givenApprover_options: approver_options_given,
                givenApprover_tableHeader: givenDisplayForApprover,
                givenSelected_multiple,

                approvalID: selectedRow.id, 
                approvalStatus: selectedRow.status,                
                displayForLavelFiexd,
                ready: true
            }) 
        }else{   
            let approvalGiftTypesAll = this.props.approvalGiftTypesAll
            console.log("=========>", approvalGiftTypesAll[0].elem.length) 
            let update_appGiftAll = []
            for(let d of approvalGiftTypesAll[0].elem){
                if(d.status){
                    update_appGiftAll.push(d)
                }
            }       
            let displayForLavelType = []
            var length = update_appGiftAll.length + 1; // user defined length
            for(var i = 1; i <= length; i++) {
                let text = 'Level '+ i
                displayForLavelType.push(text);
            }
            let displayForLavel = 'Level '+ length
            
            let randNo = Math.floor(Math.random()*(99999-10000+1)+10000);

            this.setState({displayForLavelType, 
                defaultDisplayForLavel: displayForLavel,
                givenDisplayForLavel: displayForLavel, 
                receivedDisplayForLavel: displayForLavel, 
                displayForLavelFiexd: displayForLavel,
                approvalID: randNo, 
                approvalStatus: true, 
                ready: true}) 
        }       
    }

    closeModal = (event) => {
		event.preventDefault();
		this.props.closeModal();
    }

    handleChange = (event) => {
        event.preventDefault();
        this.setState({[event.target.name]: event.target.value});
    }
    handleChangeValue = (event) => {
        event.preventDefault();
        let approvalGiftTypesAll = this.props.approvalGiftTypesAll[0].elem
        console.log("=========>", approvalGiftTypesAll)          
        let defaultDisplayForLavel = this.state.displayForLavelFiexd;    
        let checkValueEqualDefault = false 
        let maxValueObject = {}    
        for(let d of approvalGiftTypesAll){ 
            if(this.props.curid !== 0) {                 
                if(parseInt(event.target.value) < parseInt(d.defaultApproval.value)){                
                    maxValueObject[d.defaultApproval.value] = d.level;            
                }    
                if(parseInt(d.id) !== parseInt(this.props.curid)){        
                    if(d.status && parseInt(event.target.value) === parseInt(d.defaultApproval.value)){
                        checkValueEqualDefault = true
                    }  
                }            
            }else{             
                if(parseInt(event.target.value) <= parseInt(d.defaultApproval.value)){                
                    maxValueObject[d.defaultApproval.value] = d.level;
                }
                if(d.status && parseInt(event.target.value) === parseInt(d.defaultApproval.value)){
                    checkValueEqualDefault = true
                }
            }
        } 
        if(this.props.curid !== 0) {  
            if(Object.keys(maxValueObject).length !== 0){ 
                let level_Value = maxValueObject[Object.keys(maxValueObject)[0]]
                var fields = level_Value.split(' ');
                let val = parseInt(fields[1]) - 1
                defaultDisplayForLavel = 'Level '+ val;
                if(defaultDisplayForLavel === 'Level 0'){
                    defaultDisplayForLavel = 'Level 1'
                }
            }
        }else{       
            if(Object.keys(maxValueObject).length !== 0){            
                defaultDisplayForLavel = maxValueObject[Object.keys(maxValueObject)[0]]
            }
        }
        console.log('displayForLavel', defaultDisplayForLavel)
        this.setState({[event.target.name]: event.target.value, defaultDisplayForLavel, checkValueEqualDefault});
    }

    handleChangeValueReceived = (event) => {
        event.preventDefault();
        let approvalGiftTypesAll = this.props.approvalGiftTypesAll[0].elem
        console.log("=========>", approvalGiftTypesAll)          
        let receivedDisplayForLavel = this.state.displayForLavelFiexd;    
        let checkValueEqualReceived = false 
        let maxValueObject = {}    
        for(let d of approvalGiftTypesAll){  
            if(this.props.curid !== 0) {                 
                if(parseInt(event.target.value) < parseInt(d.receivedApproval.value)){                
                    maxValueObject[d.receivedApproval.value] = d.level;                    
                }    
                if(parseInt(d.id) !== parseInt(this.props.curid)){        
                    if(d.status && parseInt(event.target.value) === parseInt(d.receivedApproval.value)){
                        checkValueEqualReceived = true
                    }  
                }            
            }else{          
                if(parseInt(event.target.value) <= parseInt(d.receivedApproval.value)){                
                    maxValueObject[d.receivedApproval.value] = d.level;
                }
                if(d.status && parseInt(event.target.value) === parseInt(d.receivedApproval.value)){
                    checkValueEqualReceived = true
                }
            }
        } 
        if(this.props.curid !== 0) {  
            if(Object.keys(maxValueObject).length !== 0){ 
                let level_Value = maxValueObject[Object.keys(maxValueObject)[0]]
                var fields = level_Value.split(' ');
                let val = parseInt(fields[1]) - 1
                receivedDisplayForLavel = 'Level '+ val;
                if(receivedDisplayForLavel === 'Level 0'){
                    receivedDisplayForLavel = 'Level 1'
                }               
            }
        }else{       
            if(Object.keys(maxValueObject).length !== 0){            
                receivedDisplayForLavel = maxValueObject[Object.keys(maxValueObject)[0]]
            }
        }
        console.log('displayForLavel', receivedDisplayForLavel)
        this.setState({[event.target.name]: event.target.value, receivedDisplayForLavel, checkValueEqualReceived});        
    }
    handleChangeValueGiven = (event) => {
        event.preventDefault();
        let approvalGiftTypesAll = this.props.approvalGiftTypesAll[0].elem
        console.log("=========>", approvalGiftTypesAll)          
        let givenDisplayForLavel = this.state.displayForLavelFiexd;    
        let checkValueEqualGiven = false 
        let maxValueObject = {}    
        for(let d of approvalGiftTypesAll){  
            if(this.props.curid !== 0) {                 
                if(parseInt(event.target.value) < parseInt(d.givenApproval.value)){                
                    maxValueObject[d.givenApproval.value] = d.level;                    
                }  
                if(parseInt(d.id) !== parseInt(this.props.curid)){        
                    if(d.status && parseInt(event.target.value) === parseInt(d.givenApproval.value)){
                        checkValueEqualGiven = true
                    }  
                }   
            }else{          
                if(parseInt(event.target.value) <= parseInt(d.givenApproval.value)){                
                    maxValueObject[d.givenApproval.value] = d.level;
                }
                if(d.status && parseInt(event.target.value) === parseInt(d.givenApproval.value)){
                    checkValueEqualGiven = true
                }
            }
        }  
        if(this.props.curid !== 0) {  
            console.log("maxValueObject==>", maxValueObject)
            if(Object.keys(maxValueObject).length !== 0){ 
                let level_Value = maxValueObject[Object.keys(maxValueObject)[0]]
                var fields = level_Value.split(' ');
                let val = parseInt(fields[1]) - 1
                givenDisplayForLavel = 'Level '+ val;
                if(givenDisplayForLavel === 'Level 0'){
                    givenDisplayForLavel = 'Level 1'
                }
            }
        }else{
            if(Object.keys(maxValueObject).length !== 0){            
                givenDisplayForLavel = maxValueObject[Object.keys(maxValueObject)[0]]
            }
        }      
        
        console.log('displayForLavel', givenDisplayForLavel)
        this.setState({[event.target.name]: event.target.value, givenDisplayForLavel, checkValueEqualGiven});        
    }
 
    changeFieldApprover = (selected_type) => {  
        let approver_options = [];   
        if(selected_type === 'Role'){
            approver_options = [
                {id: 'gm', name: "Gift Manager", isCheck: false},
                {id: 'am', name: "Admin Manager", isCheck: false},
                {id: 'lm', name: "Line Manager", isCheck: false}
            ]
        }else{
            let contacts = Store.getStoreData('contacts');
            for(let k in contacts){
                let appObject = {}
                appObject.id = k
                appObject.name = contacts[k]
                appObject.isCheck = false
                approver_options.push(appObject);
            }
        }
        this.setState({defaultDisplayForApprover: selected_type, defaultApprover_options: approver_options, defaultApprover_tableHeader: selected_type, defaultSelected_multiple: []})		
	}

    changeFieldApproverGiven = (selected_type) => {  
        let approver_options = [];   
        if(selected_type === 'Role'){
            approver_options = [
                {id: 'gm', name: "Gift Manager", isCheck: false},
                {id: 'am', name: "Admin Manager", isCheck: false},
                {id: 'lm', name: "Line Manager", isCheck: false}
            ]
        }else{
            let contacts = Store.getStoreData('contacts');
            for(let k in contacts){
                let appObject = {}
                appObject.id = k
                appObject.name = contacts[k]
                appObject.isCheck = false
                approver_options.push(appObject);
            }
        }
		this.setState({givenDisplayForApprover: selected_type, givenApprover_options: approver_options, givenApprover_tableHeader: selected_type, givenSelected_multiple: []})   
	}
    changeFieldApproverReceived = (selected_type) => {  
        let approver_options = [];   
        if(selected_type === 'Role'){
            approver_options = [
                {id: 'gm', name: "Gift Manager", isCheck: false},
                {id: 'am', name: "Admin Manager", isCheck: false},
                {id: 'lm', name: "Line Manager", isCheck: false}
            ]
        }else{
            let contacts = Store.getStoreData('contacts');
            for(let k in contacts){
                let appObject = {}
                appObject.id = k
                appObject.name = contacts[k]
                appObject.isCheck = false
                approver_options.push(appObject);
            }
        }
		this.setState({receivedDisplayForApprover: selected_type, receivedApprover_options: approver_options, receivedApprover_tableHeader: selected_type, receivedSelected_multiple: []})   
	}

    changeClear = () => {
		this.setState({isclear: false})
	}

    deleteSelected = (data, options)=>{        
        console.log("options==>", options)
        this.setState({defaultSelected_multiple: data, defaultApprover_options: options})
    }
    deleteSelectedGiven = (data, options)=>{        
        console.log("options==>", options)
        this.setState({givenSelected_multiple: data, givenApprover_options: options})
    }
    deleteSelectedReceived = (data, options)=>{        
        console.log("options==>", options)
        this.setState({receivedSelected_multiple: data, receivedApprover_options: options})
    }
    setSelectionMultiple = (data)=>{        
        this.setState({defaultSelected_multiple: data})
    }
    setSelectionMultipleGiven = (data)=>{        
        this.setState({givenSelected_multiple: data})
    }
    setSelectionMultipleReceived = (data)=>{        
        this.setState({receivedSelected_multiple: data})
    }   

    submitApproval = (event) => {
        event.preventDefault();
        if(this.state.isApprovalGivenReceived){
            if(this.state.checkValueEqualDefault){
                let alert_param = {title: 'ERROR', message: 'Please enter a valid Value.', ok_text: 'Ok', confirm: false, 
                               alertHandler: this.checkvalidation, stack: {}}
                this.setState({alert_param: alert_param}) 
                return
            }
            if(this.state.defaultApprovalValue === "" || this.state.defaultDisplayForApprover === "" || this.state.defaultSelected_multiple.length === 0){            
                let alert_param = {title: 'ERROR', message: 'Please complete all fields.', ok_text: 'Ok', confirm: false, 
                               alertHandler: this.checkvalidation, stack: {}}
                this.setState({alert_param: alert_param}) 
                return
            }

            console.log("selected_multiple==>", this.state.defaultSelected_multiple)
            let approvar = ''               
            if(this.state.defaultDisplayForApprover === 'Specific User(s)'){
                approvar = 'Specific User(s)'   
            }else if(this.state.defaultSelected_multiple.length === 1){
                approvar = this.state.defaultSelected_multiple[0].name 
            }else if(this.state.defaultSelected_multiple.length !== 0 || this.state.defaultSelected_multiple.length !== 1){
                approvar = 'Multiple'; 
            }

            let approvalObject = {} 
            approvalObject.level = this.state.defaultDisplayForLavel
            approvalObject.received = {value: this.state.defaultApprovalValue, approvar: approvar}
            approvalObject.given = {value: this.state.defaultApprovalValue, approvar: approvar}
            approvalObject.status = this.state.approvalStatus        
            approvalObject.id = this.state.approvalID
            approvalObject.isApprovalGivenReceived = this.state.isApprovalGivenReceived
            approvalObject.defaultApproval = {approver_type: this.state.defaultDisplayForApprover, approvers: this.state.defaultSelected_multiple, displayFor: 'both', value: this.state.defaultApprovalValue}
            approvalObject.givenApproval = {approver_type: this.state.defaultDisplayForApprover, approvers: this.state.defaultSelected_multiple, displayFor: 'both', value: this.state.defaultApprovalValue}
            approvalObject.receivedApproval = {approver_type: this.state.defaultDisplayForApprover, approvers: this.state.defaultSelected_multiple, displayFor: 'both', value: this.state.defaultApprovalValue}       
            
            console.log("approvalObject==>", approvalObject)
            if(this.props.curid !== 0) {
                this.props.updateApprovalValue(approvalObject);
            }else{                
                this.props.saveApprovalValue_receive_given(approvalObject);
            } 
        }else{
            if(this.state.checkValueEqualGiven || this.state.checkValueEqualReceived){
                let alert_param = {title: 'ERROR', message: 'Please enter a valid Value.', ok_text: 'Ok', confirm: false, 
                               alertHandler: this.checkvalidation, stack: {}}
                this.setState({alert_param: alert_param}) 
                return
            }   
            
            if(this.state.givenApprovalValue === "" && this.state.receivedApprovalValue !== ""){         
                console.log("Sourav 1")        
                if(this.state.receivedApprovalValue === "" || this.state.receivedDisplayForApprover === "" || this.state.receivedSelected_multiple.length === 0){            
                    let alert_param = {title: 'ERROR', message: 'Please complete all fields.', ok_text: 'Ok', confirm: false, 
                                alertHandler: this.checkvalidation, stack: {}}
                    this.setState({alert_param: alert_param}) 
                    return
                } 

                let alert_param = {title: 'Alert', message: "You only have completed one of the sections. If you submit, the other section will be entered as 'n/a' if the level does not already exist. Do you want to continue?", ok_text: 'Yes', cancel_text: 'No', confirm: true, 
                                alertHandler: this.afterClickOKButton_received, stack: {}}
                this.setState({alert_param: alert_param})

                
            }else if(this.state.receivedApprovalValue === "" && this.state.givenApprovalValue !== ""){
                console.log("Sourav 2")       
                if(this.state.givenApprovalValue === "" || this.state.givenDisplayForApprover === "" || this.state.givenSelected_multiple.length === 0){            
                    let alert_param = {title: 'ERROR', message: 'Please complete all fields.', ok_text: 'Ok', confirm: false, 
                                   alertHandler: this.checkvalidation, stack: {}}
                    this.setState({alert_param: alert_param}) 
                    return
                }

                let alert_param = {title: 'Alert', message: "You only have completed one of the sections. If you submit, the other section will be entered as 'n/a' if the level does not already exist. Do you want to continue?", ok_text: 'Yes', cancel_text: 'No', confirm: true, 
                                alertHandler: this.afterClickOKButton_given, stack: {}}
                this.setState({alert_param: alert_param})
                
            }else if(this.state.givenApprovalValue !== "" && this.state.receivedApprovalValue !== ""){
                console.log("Sourav 3")       
                if(this.state.givenApprovalValue === "" || this.state.receivedApprovalValue === "" || this.state.givenDisplayForApprover === "" || this.state.receivedDisplayForApprover === "" || this.state.givenSelected_multiple.length === 0 || this.state.receivedSelected_multiple.length === 0){            
                    let alert_param = {title: 'ERROR', message: 'Please complete all fields.', ok_text: 'Ok', confirm: false, 
                                   alertHandler: this.checkvalidation, stack: {}}
                    this.setState({alert_param: alert_param}) 
                    return
                } 

                let approvarGiven = ''               
                if(this.state.givenDisplayForApprover === 'Specific User(s)'){
                    approvarGiven = 'Specific User(s)'   
                }else if(this.state.givenSelected_multiple.length === 1){
                    approvarGiven = this.state.givenSelected_multiple[0].name 
                }else if(this.state.givenSelected_multiple.length !== 0 || this.state.givenSelected_multiple.length !== 1){
                    approvarGiven = 'Multiple'; 
                }
                let approvarReceived = ''               
                if(this.state.receivedDisplayForApprover === 'Specific User(s)'){
                    approvarReceived = 'Specific User(s)'   
                }else if(this.state.receivedSelected_multiple.length === 1){
                    approvarReceived = this.state.receivedSelected_multiple[0].name 
                }else if(this.state.receivedSelected_multiple.length !== 0 || this.state.receivedSelected_multiple.length !== 1){
                    approvarReceived = 'Multiple'; 
                }

                let approvalObject = {} 
                approvalObject.level = this.state.defaultDisplayForLavel
                approvalObject.received = {value: this.state.receivedApprovalValue, approvar: approvarReceived}
                approvalObject.given = {value: this.state.givenApprovalValue, approvar: approvarGiven}
                approvalObject.status = this.state.approvalStatus    
                approvalObject.id = this.state.approvalID
                approvalObject.isApprovalGivenReceived = this.state.isApprovalGivenReceived
                approvalObject.defaultApproval = {approver_type: this.state.defaultDisplayForApprover, approvers: this.state.defaultSelected_multiple, displayFor: 'both', value: this.state.defaultApprovalValue}
                approvalObject.givenApproval = {approver_type: this.state.givenDisplayForApprover, approvers: this.state.givenSelected_multiple, displayFor: 'both', value: this.state.givenApprovalValue}
                approvalObject.receivedApproval = {approver_type: this.state.receivedDisplayForApprover, approvers: this.state.receivedSelected_multiple, displayFor: 'both', value: this.state.receivedApprovalValue} 
                
                console.log("approvalObject==>", approvalObject)

                if(this.props.curid !== 0) {
                    this.props.updateApprovalValue(approvalObject);
                }else{                
                    this.props.saveApprovalValue_receive_given(approvalObject);
                }
            }else if(this.state.givenApprovalValue === "" && this.state.receivedApprovalValue === ""){
                console.log("Sourav 4")       
                let alert_param = {title: 'ERROR', message: 'Please enter atleast one section.', ok_text: 'Ok', confirm: false, 
                                   alertHandler: this.checkvalidation, stack: {}}
                this.setState({alert_param: alert_param})
            } 
        }        
    }

    afterClickOKButton_received = (result, stack) => {
        if(!result) {
            this.setState({alert_param: null})
            return
        }
        this.setState({alert_param: null})
        let approvarReceived = ''               
        if(this.state.receivedDisplayForApprover === 'Specific User(s)'){
            approvarReceived = 'Specific User(s)'   
        }else if(this.state.receivedSelected_multiple.length === 1){
            approvarReceived = this.state.receivedSelected_multiple[0].name 
        }else if(this.state.receivedSelected_multiple.length !== 0 || this.state.receivedSelected_multiple.length !== 1){
            approvarReceived = 'Multiple'; 
        }

        let approvalObject = {} 
        approvalObject.level = this.state.defaultDisplayForLavel
        approvalObject.received = {value: this.state.receivedApprovalValue, approvar: approvarReceived}
        approvalObject.given = {value: 'n/a', approvar: 'n/a'}
        approvalObject.status = this.state.approvalStatus      
        approvalObject.id = this.state.approvalID
        approvalObject.isApprovalGivenReceived = this.state.isApprovalGivenReceived
        approvalObject.defaultApproval = {approver_type: this.state.defaultDisplayForApprover, approvers: this.state.defaultSelected_multiple, displayFor: 'both', value: this.state.defaultApprovalValue}
        approvalObject.givenApproval = {approver_type: '', approvers: this.state.givenSelected_multiple, displayFor: 'both', value: this.state.givenApprovalValue}
        approvalObject.receivedApproval = {approver_type: this.state.receivedDisplayForApprover, approvers: this.state.receivedSelected_multiple, displayFor: 'both', value: this.state.receivedApprovalValue}   
        
        console.log("approvalObject==>", approvalObject)
        if(this.props.curid !== 0) {
            this.props.updateApprovalValue(approvalObject);
        }else{                
            this.props.saveApprovalValue_receive_given(approvalObject);
        }  
    }
    afterClickOKButton_given = (result, stack) => {
        if(!result) {
            this.setState({alert_param: null})
            return
        }
        this.setState({alert_param: null})

        let approvarGiven = ''       
        if(this.state.givenDisplayForApprover === 'Specific User(s)'){
            approvarGiven = 'Specific User(s)'   
        }else if(this.state.givenSelected_multiple.length === 1){
            approvarGiven = this.state.givenSelected_multiple[0].name 
        }else if(this.state.givenSelected_multiple.length !== 0 || this.state.givenSelected_multiple.length !== 1){
            approvarGiven = 'Multiple'; 
        }                

        let approvalObject = {} 
        approvalObject.level = this.state.defaultDisplayForLavel
        approvalObject.received = {value: 'n/a', approvar: 'n/a'}
        approvalObject.given = {value: this.state.givenApprovalValue, approvar: approvarGiven}
        approvalObject.status = this.state.approvalStatus    
        approvalObject.id = this.state.approvalID
        approvalObject.isApprovalGivenReceived = this.state.isApprovalGivenReceived
        approvalObject.defaultApproval = {approver_type: this.state.defaultDisplayForApprover, approvers: this.state.defaultSelected_multiple, displayFor: 'both', value: this.state.defaultApprovalValue}
        approvalObject.givenApproval = {approver_type: this.state.givenDisplayForApprover, approvers: this.state.givenSelected_multiple, displayFor: 'both', value: this.state.givenApprovalValue}
        approvalObject.receivedApproval = {approver_type: '', approvers: this.state.receivedSelected_multiple, displayFor: 'both', value: this.state.receivedApprovalValue} 
        
        console.log("approvalObject==>", approvalObject)

        if(this.props.curid !== 0) {
            this.props.updateApprovalValue(approvalObject);
        }else{                
            this.props.saveApprovalValue_receive_given(approvalObject);
        } 
    }

    checkvalidation = (result, stack) => {        
        this.setState({alert_param: null})              
    }

    switchRadio = (event) => {		
		event.preventDefault();		
		let isApprovalGivenReceived = this.state.isApprovalGivenReceived ? false : true;
		this.setState({isApprovalGivenReceived})
	}

    render() {
        if(!this.state.ready){
            return(<div>Loading...</div>)
        }
        let headerText = 'headerText' in this.props ? this.props.headerText : 'Add';
        return (<div>
			<EmailContainer>
                <MRModalHeader>
                    <MRModalHeaderText>{headerText} Approval Level</MRModalHeaderText>
                    <MRModalHeaderCloseBtn onClick={this.closeModal}><FaTimes /></MRModalHeaderCloseBtn>
                    <div style={{clear: "both"}}></div>   
                    <MRModalSubHeaderText>Fill out the below fields to submit your approval level.</MRModalSubHeaderText>
                    <div style={{clear: "both"}}></div>             
                </MRModalHeader>  
                <ScoreBodyContainer>                 
                <MRModalBody>  
                    <MRModalLabel style={{display: "inline-block"}}>This approval applies to given and received gifts</MRModalLabel>
                    {
										
                        (() => {
                            if (this.state.isApprovalGivenReceived) {
                                return (
                                    <RadioBlock style={{display: "inline-block", marginLeft: "10px"}}>										
                                        <SelectedRadio>Yes</SelectedRadio>		
                                        <UnselectedRadio onClick={this.switchRadio}>No</UnselectedRadio>								
                                    </RadioBlock>
                                );
                            } else {
                                return (
                                    <RadioBlock style={{display: "inline-block", marginLeft: "10px"}}>
                                        <UnselectedRadio onClick={this.switchRadio}>Yes</UnselectedRadio>
                                        <SelectedRadio>No</SelectedRadio>																				
                                    </RadioBlock>
                                );
                            }
                        })()
                    }
                    {
                        (() => {
                            if(this.state.isApprovalGivenReceived){
                                return(<div>
                                    <MRModalLabel style={{marginTop: "30px"}}>Value <span style={{marginLeft: "5px",color: "red"}}>*</span></MRModalLabel>
                                    <MRModalInput style={{width: "20%"}} type="number" name="defaultApprovalValue" value={this.state.defaultApprovalValue} min="0" onKeyDown={blockInvalidChar} onChange={this.handleChangeValue}/>
                                    {
                                        (() => {
                                            if(this.state.checkValueEqualDefault){
                                                return(<span title="You cannot enter a value equal to the value of another existing Level" style={{marginLeft: "10px",color: "red", fontSize: "18px"}}><FaExclamationTriangle /></span>)
                                            }
                                        })()
                                    }
                                    <MRModalLabel style={{marginTop: "30px"}}>Level</MRModalLabel>                                       
                                    <MRModalInput style={{width: "97%"}} type="text" name="defaultDisplayForLavel" value={this.state.defaultDisplayForLavel} readonly/> 
                                    <MRModalLabel style={{marginTop: "30px"}}>Approver Type</MRModalLabel>       
                                    <CslSimpleDropDown options={this.state.displayForApproverType} selected={this.state.defaultDisplayForApprover} changeFieldType={this.changeFieldApprover} changeWidth={"100%"}/>   
                                    <MRModalLabel style={{marginTop: "30px"}}>Approvers</MRModalLabel>       
                                    <FilterBody>
                                        <CslSimpleDropDownSearchable deleteSelected={this.deleteSelected} options={this.state.defaultApprover_options} selected={this.state.defaultSelected_multiple} setSelection={this.setSelectionMultiple} header={this.state.defaultApprover_tableHeader} />
                                    </FilterBody>
                                </div>)
                            }else{
                                console.log("this.state.receivedSelected_multiple===>", this.state.receivedSelected_multiple)
                                return(<div>
                                    <MRModalLabel style={{marginTop: "30px", fontSize: "initial"}}>For Gifts Received</MRModalLabel>

                                    <MRModalLabel style={{marginTop: "30px"}}>Value <span style={{marginLeft: "5px",color: "red"}}>*</span></MRModalLabel>
                                    <MRModalInput style={{width: "20%"}} type="number" name="receivedApprovalValue" value={this.state.receivedApprovalValue} min="0" onKeyDown={blockInvalidChar} onChange={this.handleChangeValueReceived}/>
                                    {
                                        (() => {
                                            if(this.state.checkValueEqualReceived){
                                                return(<span title="You cannot enter a value equal to the value of another existing Level" style={{marginLeft: "10px",color: "red", fontSize: "18px"}}><FaExclamationTriangle /></span>)
                                            }
                                        })()
                                    } 
                                                        
                                    <MRModalLabel style={{marginTop: "30px"}}>Level</MRModalLabel> 
                                    <MRModalInput style={{width: "97%"}} type="text" name="receivedDisplayForLavel" value={this.state.receivedDisplayForLavel} readonly/>
                                    <MRModalLabel style={{marginTop: "30px"}}>Approver Type</MRModalLabel>       
                                    <CslSimpleDropDown options={this.state.displayForApproverType} selected={this.state.receivedDisplayForApprover} changeFieldType={this.changeFieldApproverReceived} changeWidth={"100%"}/>   
                                    
                                    <MRModalLabel style={{marginTop: "30px"}}>Approvers</MRModalLabel>       
                                    <FilterBody>
                                        <CslSimpleDropDownSearchable deleteSelected={this.deleteSelectedReceived} options={this.state.receivedApprover_options} selected={this.state.receivedSelected_multiple} setSelection={this.setSelectionMultipleReceived} header={this.state.receivedApprover_tableHeader} />
                                    </FilterBody>

                                    <MRModalLabel style={{marginTop: "30px", fontSize: "initial"}}>For Gifts Given</MRModalLabel>

                                    <MRModalLabel style={{marginTop: "30px"}}>Value <span style={{marginLeft: "5px",color: "red"}}>*</span></MRModalLabel>
                                    <MRModalInput style={{width: "20%"}} type="number" name="givenApprovalValue" value={this.state.givenApprovalValue} min="0" onKeyDown={blockInvalidChar} onChange={this.handleChangeValueGiven}/>
                                    {
                                        (() => {
                                            if(this.state.checkValueEqualGiven){
                                                return(<span title="You cannot enter a value equal to the value of another existing Level" style={{marginLeft: "10px",color: "red", fontSize: "18px"}}><FaExclamationTriangle /></span>)
                                            }
                                        })()
                                    }  
                                                        
                                    <MRModalLabel style={{marginTop: "30px"}}>Level</MRModalLabel>                                       
                                    <MRModalInput style={{width: "97%"}} type="text" name="givenDisplayForLavel" value={this.state.givenDisplayForLavel} readonly/>
                                    <MRModalLabel style={{marginTop: "30px"}}>Approver Type</MRModalLabel>       
                                    <CslSimpleDropDown options={this.state.displayForApproverType} selected={this.state.givenDisplayForApprover} changeFieldType={this.changeFieldApproverGiven} changeWidth={"100%"}/>   
                                    
                                    <MRModalLabel style={{marginTop: "30px"}}>Approvers</MRModalLabel>       
                                    <FilterBody>
                                        <CslSimpleDropDownSearchable_given deleteSelected={this.deleteSelectedGiven} options={this.state.givenApprover_options} selected={this.state.givenSelected_multiple} setSelection={this.setSelectionMultipleGiven} header={this.state.givenApprover_tableHeader} />
                                    </FilterBody>
                                </div>)
                            }
                        })()
                    }
                    
                </MRModalBody> 
                </ScoreBodyContainer>               
                <div style={{clear: "both"}}></div> 
                <DocFooter style={{float: "right"}}>
                    <FooterButtonsContainer>   
                        <CancelBtn onClick={this.closeModal}>Cancel</CancelBtn>
                        <SaveBtn onClick={this.submitApproval}>Submit</SaveBtn>
                    </FooterButtonsContainer>   
                </DocFooter>
			</EmailContainer> 
            <AlertBox alertParam = {this.state.alert_param}/>           
            </div>);
    }
}
export default AddEditApprovalModal;



