import React from 'react';
import styled from 'styled-components';
import CSLTable from '../Common/CSLTable';
import EmailConfigModal from './EmailConfigModal';
import { IoIosCheckboxOutline, IoIosSquareOutline, IoIosWarning } from "react-icons/io";

const EmailContainer = styled.div`
	width: 100%;
`;
const EmailInnerContainer = styled.div`
	background-color: #F4F5F7;
	padding: 30px;
`;
const EmailBoldLabel = styled.div`
	font-weight: 600;
	color: #595959;
	margin-top: 5px;
`;
const EmailLabel = styled.div`
	color: #595959;
	margin-top: 5px;
`;
const ConfigBtn = styled.button`
    box-sizing: border-box;
    background-color: #37ada7;
    color: #ffffff;
    border: 1px solid #37ada7;
    font-size: 13px;
    border-radius: 4px;
    padding: 5px 20px;
    cursor: pointer;
`;

const SelectedRadio = styled.div`
	background-color: #37ADA7;
	border: 1px solid #1f615e;
	padding: 5px 10px;
	color: #ffffff;
	display: inline-block;
	cursor: pointer;
`;
const UnselectedRadio = styled.div`
	background-color: #F4F4F4;
	border: 1px solid #8c8c8c;
	padding: 5px 10px;
	color: #262626;
	display: inline-block;
	cursor: pointer;
`;
const RadioBlock = styled.div`
	margin-top: 10px;
`;
const EmailTopHeader = styled.div`
	font-weight: 600;
	color: #595959;
	margin-top: 5px;
	width:100%;
	height: 58px;
	padding: 20px;
	border-radius: 4px;
	border: 1px solid #e0e0e0;
	background-color: #ffffff;
	box-sizing: border-box;	
`;

class Email extends React.Component
{
	state = {email: null, variables: null, show_email_config_modal: null, content: null, general: true};

	componentDidMount()
	{
		this.setState({email: this.props.email, variables: this.props.variables, general: this.props.general});
	}

	componentDidUpdate(prevProps)
	{
		if (prevProps !== this.props) {
			this.setState({email: this.props.email, variables: this.props.variables, general: this.props.general});
		}
	}

	showEmailConfigModal = (email_id) => (event) => {
		event.preventDefault();
		console.log("email_id:", email_id);
		const content = this.state.email.find((item) => {
			return item.id === email_id;
		})
		this.setState({content, show_email_config_modal: true});
	}

	modifyEmails = (content) => {
		console.log("content:", content);
		let {email} = this.state;
		let newemail = [];
		email.forEach((item) => {
			if (item.id === content.id) {
				item = content;
				console.log("matched", item);
			}
			newemail.push(item);
		})
		console.log("emails:", newemail);
		this.props.updateEmail(newemail);
		this.setState({email: newemail, show_email_config_modal: null});
	}

	closeModal = () => {
		this.setState({show_email_config_modal: null, content: null});
	}

	tableData = () => {
        let ret = {data: [], columns: []};
        ret.columns =[
                        {Header: 'Email', accessor: 'email', minWidth: 120, headerStyle: {textAlign: 'left'}}, 
						{Header: 'Subject', accessor: 'subject', minWidth: 240, headerStyle: {textAlign: 'left'}},
						{'Header' : 'Enabled', Cell: row => (
							<div>
								   {
									   (()=> {
										   if (row.original.enabled) {
											   return(<div style={{cursor: "pointer", fontSize:"21px", float: "right"}} onClick={this.handlenaSectionIncluded(row.original.id)}><IoIosCheckboxOutline /></div>);
										   } else {
											   return(<div  style={{cursor: "pointer", fontSize:"21px", float: "right"}} onClick={this.handlenaSectionIncluded(row.original.id)}><IoIosSquareOutline /></div>);
										   }
									   })()
								   }
							</div>
						 ), headerStyle:{textAlign: 'center'}},
                        {'Header' : 'Action', Cell: row => (
                           <div>
                               <ConfigBtn onClick={this.showEmailConfigModal(row.original.id)}>Configure</ConfigBtn>
                           </div>
                        ), headerStyle:{textAlign: 'left'}}];
        this.state.email.forEach((item) => {
        	let elem = {id: item.id, email: item.name, subject: item.subject, enabled: item.enabled};
        	ret.data.push(elem);
        })
        return ret;
	}

	handlenaSectionIncluded = (section_id) => (event) => {
		console.log("content:", section_id);
		let {email} = this.state;
		let newemail = [];
		email.forEach((item) => {
			if (item.id === section_id) {
				item.enabled = item.enabled ? false : true; 
				console.log("matched", item);
			}
			newemail.push(item);
		})
		console.log("emails:", newemail);
		this.props.updateEmail(newemail);
	}

	switchRadio = (key) => (event) => {
		event.preventDefault();
		let {general} = this.state;
		general[key] = general[key] ? false : true;
		this.props.updateGeneralSave(general);
	}

	render()
	{
		if (this.state.email === null) {
			return (
				<div>Loading...</div>
			);
		}
		return (
			<div>
			{
				(() => {
					if (this.state.show_email_config_modal !== null) {
						return (<EmailConfigModal variables={this.state.variables} content={JSON.parse(JSON.stringify(this.state.content))} modifyEmails={this.modifyEmails} closeModal={this.closeModal
						} />);
					}
				})()
			}
			<EmailContainer>
				<EmailInnerContainer>
					<EmailBoldLabel style={{display: "inline-block"}}>Enable Email Reminder?</EmailBoldLabel>
					{
						(() => {
							if (this.state.general.email_remainder_enable) {
								return (
									<RadioBlock>
										<SelectedRadio>Yes</SelectedRadio>	
										<UnselectedRadio onClick={this.switchRadio('email_remainder_enable')}>No</UnselectedRadio>																			
									</RadioBlock>
								);
							} else {
								return (
									<RadioBlock>
										<UnselectedRadio onClick={this.switchRadio('email_remainder_enable')}>Yes</UnselectedRadio>
										<SelectedRadio>No</SelectedRadio>																				
									</RadioBlock>
								);
							}
						})()
					}
				</EmailInnerContainer>
			</EmailContainer>
			<div styled={{clear:"both"}}></div>
				{
					(() => {
						if (this.state.general.email_remainder_enable) {
							return(
									<EmailContainer>
										<EmailInnerContainer>											
											<EmailTopHeader style={{display: "inline-block" , fontWeight: 700}}>
												Emails												
											</EmailTopHeader>
											<CSLTable add={false} processData={this.tableData} headerText={''} tableRows="5" refreshCallback={this.refreshState} />
										</EmailInnerContainer>
									</EmailContainer>
								)
							
						}
					})()
				}			
			</div>
		);
	}
}

export default Email;