import React from 'react';
import styled from 'styled-components';
import {FaTimes, FaStarOfLife, FaCalendarAlt} from 'react-icons/fa';
import APICall from '../../Common/APICall';
import Store from '../../Common/Store.js';
import Dropzone from '../Common/Dropzone';
import * as Style from '../Common/StyledComponents';
import CSLLoader from '../Common/CSLLoader';
import DatePicker from 'react-date-picker';
import CSLTextInput from './../Common/CSLTextInput';
import moment from 'moment';
import AlertBox from '../Common/AlertBox'
import { blockInvalidChar } from "../Common/blockInvalidChar";

const MRModalContainer = styled.div`
    display: block;
    box-sizing: border-box;
    height: auto;
    width: 80%;
    position: absolute;
    top: 38px;
    z-index: 1001;
    background-color: rgb(243, 247, 251);
    box-shadow: rgb(167, 166, 166) 0px 0px 5px;
    margin-bottom: 10px;
    border-radius: 5px;
    margin-left: 100px;
`;
const MRModalHeader = styled.div`
    background-color: white;
    display: block;
    padding-top: 5px;
    font-size: 14px;
    font-weight: 700;
    height: 100px;    
    width: calc(100% - 10px);
    border-width: 1px 1px 1px 10px;    
    border-left: 9px solid #04ADA8; 
    border-bottom: 1px solid #DFDFDF;
`;
const MRModalHeaderText = styled.div`
    font-weight: 700;
    font-size: 22px;
    float: left;
    margin-top: 24px;
    margin-left: 35px;  
    color: #143151; 
`;
const MRModalHeaderCloseBtn = styled.div`
    position: absolute;
    top: 8px;
    right: 10px;
    box-sizing: border-box;
    margin-top: 24px;
    margin-right: 20px;
    font-size: 25px;
    cursor: pointer;
    font-weight: 200;
`;
const MRModalBody = styled.div`
    display: block;
    box-sizing: border-box;
    padding: 10px 20px 10px 20px;
`;
const MRModalLabel = styled.div`
    color: #030303;
    font-weight: 600;
    margin-top: 15px;
    font-size: 15px;
    margin-bottom: 15px;
`;
const MRModalFooter = styled.div`
    position: relative;
    background-color: white;
    height: 80px;
    border-style: solid;
    border-width: 1px;
    border-color: #f3f7fb;
    width: 100%;
    border-top: 1px solid #DFDFDF;
`;
const MRModalNextBtn = styled.button`
    display: inline-block;
    box-sizing: border-box;
    background-color: #11ABA6;
    color: #ffffff;
    width: 145px;
    height: 45px;
    border-radius: 4px;
    border: 1px solid #11ABA6;
    margin-left: 5px;
    font-size: 15px;
    margin-left: 10px;
    float: right;
    cursor: pointer;
`;
const MRModalSaveCloseBtn = styled.button`
    display: inline-block;
    box-sizing: border-box;
    background-color: #143151;
    color: #ffffff;
    width: 145px;
    height: 45px;
    border-radius: 4px;
    border: 1px solid #143151;
    margin-left: 5px;
    font-size: 15px;
    margin-left: 10px;
    float: right;
    cursor: pointer;
`;
const MRModalTextarea = styled.textarea`
    width: 99%;
    border: 1px solid #ffffff;
    border-radius: 3px;
    box-shadow: 0 0 4px #c4c4c4;
    padding: 5px;
    color: #000000;
    font-family: 'Montserrat', sans-serif;
`;
const MRModalCancelBtn = styled.button`
    display: inline-block;
    box-sizing: border-box;
    background-color: #ffffff;
    color: #545454;
    border: 2px solid #dadada;
    width: 145px;
    height: 45px;
    border-radius: 4px;
    font-size: 15px;
    float: right;
    cursor: pointer;
`;
const ModalNameLabelDivReference = styled.div`
    height: auto;
    width: 100%;    
    font-weight: 600;
    color: #282828;    
    display:inline;
`;
const MRModalSelect = styled.select`
    box-sizing: border-box;
    height: 40px;
    width: 100%;
    background-color: #fff;
    border: 1px solid #ffffff;
    border-radius: 3px;
    box-shadow: 0 0 4px #c4c4c4;
    font-family: Montserrat,sans-serif;
    color: #444444;    
    padding: 10px;
    font-weight: 600;
`;
const MRModalInput = styled.input`
    height: 25px;
    width: 99%;
    box-shadow: rgb(196, 196, 196) 0px 0px 4px;
    color: #545454;
    font-family: montserrat;
    font-size: 14px;
    font-weight: 600;
    border-width: 1px;
    border-style: solid;
    border-color: rgb(255, 255, 255);
    border-image: initial;
    border-radius: 3px;
    padding: 6px;
`;
const ModalNameInputDiv = styled.div`
    height: auto;
    width: 100%;    
    color: #282828;
    font-family: 'Montserrat', sans-serif;
`;
const MRModalSubHeaderText = styled.div`
    font-weight: 700;
    font-size: 13px;
    float: left;
    margin-left: 35px;
    margin-bottom: 20px;
    margin-top: 10px;
    color: #636363;
`;
const UserHr = styled.div`
border-top: 2px solid #E9EBED;
`;
const SpacerXL = styled.div`
    height: 20px;
`;
const SpacerL = styled.div`
    height: 10px;
`;
const SpacerS = styled.div`
    height: 5px;
`;
const CSLDateCover = styled.div`   
    margin-top: 8px;
    width: 40%;
    border-radius: 5px;
    border-style: solid;
    border-width: 1px;
    border-color: #282828;
    background-color: #ffffff;
    height: 25px;
    padding-top: 2px;
    padding-left: 2px;
    // float: left;
    position: relative;
`;
const ScoreBodyContainer = styled.div`
  padding: 10px;
  text-align: left;
  overflow-y: scroll;
  height: 555px;
`;
const MRTasksContainer = styled.div`
  display: block;
  box-sizing: border-box;
  height: auto;
  //width: 92%;
  margin: 0 auto;
  // top: 30px;
  margin-bottom: 10px;
  // border-radius: 5px;
  //padding: 5px 10px;
`;
const MRSuccessContainer = styled.div`
  box-sizing: border-box;
  margin-top: 30px;
  background-color: #e2f0d9;
  border: 1px solid #6c9450;
  border-radius: 5px;
  padding: 10px 20px;
`;
const MRDangerContainer = styled.div`
  box-sizing: border-box;
  margin-top: 30px;
  background-color: #eccaca;
	border: 1px solid #b75050;
  border-radius: 5px;
  padding: 10px 20px;
`;

const MRModalRemoveBtn = styled.button`
    display: inline-block;
    box-sizing: border-box;
    background-color: #E75C71;
    color: #ffffff;
    width: 145px;
    height: 45px;
    border-radius: 4px;
    border: 1px solid #E75C71;
    margin-left: 5px;
    font-size: 15px;
    margin-left: 10px;
    float: right;
    cursor: pointer;
`;

class TeamApprovalGiftsModal extends React.Component
{
    state = {
        giftStatus: "received",
        recipientlist: [],
        recipientName: "",
        recipientNameAll: "",
        isOtherRecipientName: false,
        donorName: "",
        giftTypeid: 0,
        giftDescription: "",
        giftTypeList: [],
        currency: "",
        minvalue: 0,

        Ref_ID: null,
        submit_form: null,
        curlane: null,
        taskid: null,
        gc_companies: null,
        gc_company: null,
        is_gc: false,
        cur_gc_id:null,
        ready: false,
        companyID: 0,
        due_date: '1970-01-01',
        isdesable: false,
        binFiles: [],
        cur_files_json: null,
        is_disabled: false,
        filterAlldatacheck: {},
        currentcompanyName: "",
        isLoadedIcon: false,
        giftedDate: new Date(),
        assign_data: null,
        alert_param: null,
        showApprovalReason: false,
        removalType: "",
        showDescription: false,
        reasonID: 0,
        reasonDescription: "",
        dicloserid: 0,
        app_levels: null,
        approval_state: null,
        approver_entitled: null,
        approval_data: [],
        manager_id: null,
        module_config: null,
        approval_level: "none",
        specificUserID: 0,
        giftGivenTo: '',
        app_currency: "",
        app_minvalue: 0,
        isShowAppValue: false,
        showRemovalReason: false,
        isUserlevelApproval: false
    };

    componentDidMount()
	{
        let index = Store.getStoreData('q_index') === null ? 0 : Store.getStoreData('q_index');
        console.log('index in componentDidMount', index);
        console.log("contact_id==>",Store.getStoreData('contacts'))
        let contracts = Store.getStoreData('contacts');
        let contactsArray = [];
        let role = Store.getStoreData('role');
        if(role === "team"){
            let otherObject = {id: "Other", name: "Other"}
            contactsArray.push(otherObject);
        }
        // let otherObject = {id: "Other", name: "Other"}
        // let contactsArray = [];



        let recipientlist = contactsArray.sort(function(a,b) {return (a.name > b.name) ? 1 : ((b.name > a.name) ? -1 : 0);} );
        console.log("contactsArray==>",recipientlist);
        Store.updateStore('recipientlistUpdate', recipientlist);
        let giftTypeList = [];
        if('gift_types' in Store.getStoreData('module_config')){
            let giftTypeListValue = Store.getStoreData('module_config').gift_types;
            for(let d of giftTypeListValue){
                if(d.isActive !== 0){
                    giftTypeList.push(d);
                }
            }
        }
        let minimumValue = 0;
        if('general' in Store.getStoreData('module_config') && 'companyCurrencyDetails' in Store.getStoreData('module_config').general){
            minimumValue = Store.getStoreData('module_config').general.companyCurrencyDetails.minvalue;
        }
        let currencyValue = "";
        if('general' in Store.getStoreData('module_config') && 'companyCurrencyDetails' in Store.getStoreData('module_config').general){
            currencyValue = Store.getStoreData('module_config').general.companyCurrencyDetails.currency;
        }

        //custom field start
        let module_config = Store.getStoreData('module_config');
        let additional_fields = [];
        if(Object.keys(module_config).length > 0) {
            if (module_config.custom_fields.additional_fields.length !== 0 && module_config.custom_fields.enabled) {
                let arrayAdditionalField = Object.values(module_config.custom_fields.additional_fields);
                arrayAdditionalField.forEach((item) => {
                    if(item.isActive === 1){
                        let copy_item = JSON.parse(JSON.stringify(item));
                        copy_item.value = item.type === "Calendar" ? new Date() : "";
                        //copy_item.value = item.type === "Dropdown" ? item.dropdown_options[0] : copy_item.value;
                        copy_item.value = item.type === "Dropdown" ? "" : copy_item.value;
                        additional_fields.push(copy_item);
                    }
                })
            }
        }

        console.log("additional_fields===>", additional_fields)
        //custom field end

        let gc_company = Store.getStoreData('gc_company');
        let gc_companies = Store.getStoreData('gc_companies');
        let cur_gc_id = 0;
        let is_gc = false;
        let form_data = {};
        let assign_data = {}
        if(index >= 0) {
            form_data = Store.getStoreData('cur_q_modal_data');
            // console.log('form_data', form_data);
            let task_json = JSON.parse(form_data.task_json);
            let dicloserid = form_data.contact_id;
            //let curgc_id = task_json.task_data.group_company_id;
            let curgc_id = form_data.company_id
            let sampleNumber = curgc_id;
            let lastDigitcurgc_id = sampleNumber % 100000;
            console.log("lastDigitcurgc_id===>",lastDigitcurgc_id)
            let gc_company = Store.getStoreData('gc_company');
            let is_gc = false;
            let companyUsers = {};
            let currentcompanyName = "";
            for(let gc of gc_company) {
                if(parseInt(gc.id) === parseInt(lastDigitcurgc_id)){
                    is_gc = gc.is_gc;
                    console.log("gc.id", gc.id);
                    currentcompanyName = gc.company_name
                    companyUsers = gc.users;
                }
            }

            //new
            let contactsArray = [];
            if(Object.keys(companyUsers).length !== 0){
                Object.keys(companyUsers).map((d, index) => {
                    if(d in contracts){
                        let contactsObject = {}
                        contactsObject.id = d;
                        contactsObject.name = contracts[d];
                        contactsArray.push(contactsObject);
                    }
                })
            }
            let recipientlistold = Store.getStoreData('recipientlistUpdate');
            for(let i=0;i<contactsArray.length;i++){
                recipientlistold.push(contactsArray[i])
            }
            //console.log("contactsArray==>==>", recipientlistold)
            let recipientlistnew = recipientlistold.sort(function(a,b) {return (a.name > b.name) ? 1 : ((b.name > a.name) ? -1 : 0);} );
            //new

            let cur_gc_id = parseInt(lastDigitcurgc_id)
            let companyID = is_gc ? parseInt(gc_company[0].id) * 100000 + cur_gc_id : cur_gc_id;

            let due_date = new Date(form_data.due_date);

            let isdesable = false;
            if(form_data.cur_lane !== "COMP_GIFTS_TEAMSAVE") {
                isdesable = true;
            }
            if (task_json.object_data.bin_files.length !== 0) {
                task_json.object_data.bin_files.forEach((item) => {
                    item.uid = "uid" in item ? item.uid : this.genKey(10);
                })
            }
            let isOtherRecipientName = false;
            if(task_json.object_data.headerinfo.check_recipient_value === 'Other'){
                isOtherRecipientName = true;
            }

            let giftedDate = 'giftedDate' in task_json.object_data.headerinfo ? new Date(task_json.object_data.headerinfo.giftedDate) : new Date();

            assign_data.additional_fields = "additional_fields" in task_json.object_data.headerinfo ? task_json.object_data.headerinfo.additional_fields : [];

            //approval section start
            let app_levels = null;
            let approval_state = null;
            let approver_entitled = null;
            let approval_data = [];
            let approval_level = "none";
            if('approval_level' in task_json.object_data.headerinfo){
                approval_level = task_json.object_data.headerinfo.approval_level;
                app_levels = task_json.object_data.headerinfo.approval_level.split("-");
                approval_data = "approval_data" in task_json.object_data.headerinfo ? task_json.object_data.headerinfo.approval_data : [];
                approval_state = approval_data.length;
                approver_entitled = app_levels[approval_state];
                if ("approval_data" in task_json.object_data.headerinfo === false) {
                    approval_data = [{
                        comments: "",
                        performed_by: Store.getStoreData("contact_id"),
                        action_time: moment().unix(),
                        status: "",
                        attachments: []
                    }];
                } else {
                    approval_data.push({
                        comments: "",
                        performed_by: Store.getStoreData("contact_id"),
                        action_time: moment().unix(),
                        status: "",
                        attachments: []
                    })
                }
            }
            //approval section end
            console.log("approval_data===>", approval_data)

            let manager_id = Store.getStoreData('manager_id');
            console.log("manager_id===>", manager_id)

            let specificUserID = 'specificUserID' in task_json.object_data.headerinfo ? task_json.object_data.headerinfo.specificUserID : 0

            let contracts_upd = Store.getStoreData('contacts');
            let namecon = {};
            for(let k in contracts_upd){
                namecon[contracts_upd[k]] = k
            }

            let check_recipient_value = task_json.object_data.headerinfo.check_recipient_value;
            if('check_recipient_id' in task_json.object_data.headerinfo && check_recipient_value !== 'Other'){
                check_recipient_value = task_json.object_data.headerinfo.check_recipient_id;
            }else if(check_recipient_value !== 'Other'){
                check_recipient_value = namecon[check_recipient_value]
            }

            let filterAlldatacheck = {};
            filterAlldatacheck.cur_gc_id = lastDigitcurgc_id;
            filterAlldatacheck.recipientName = check_recipient_value;
            filterAlldatacheck.donorName = task_json.object_data.headerinfo.donor_name;
            filterAlldatacheck.giftTypeid = task_json.object_data.headerinfo.gift_typeid;
            filterAlldatacheck.giftDescription = task_json.object_data.headerinfo.gift_description;

            let isUserlevelApproval = 'isUserlevelApproval' in task_json.object_data.headerinfo ? task_json.object_data.headerinfo.isUserlevelApproval : false

            this.setState({ Ref_ID: task_json.object_data.ref_id,
                recipientlist: recipientlistnew,
                giftTypeList: giftTypeList,
                currency: currencyValue !== "" ? currencyValue : Store.getStoreData('currencyDetails').currency,
                giftStatus: task_json.object_data.headerinfo.gift_status,
                recipientName: check_recipient_value,
                recipientNameAll: task_json.object_data.headerinfo.recipient_name,
                isOtherRecipientName: isOtherRecipientName,
                donorName: task_json.object_data.headerinfo.donor_name,
                giftTypeid: task_json.object_data.headerinfo.gift_typeid,
                minvalue: task_json.object_data.headerinfo.minvalue,
                giftDescription: task_json.object_data.headerinfo.gift_description,
                curlane: form_data.cur_lane,
                taskid: form_data.id, gc_companies: gc_companies, gc_company: Store.getStoreData('gc_company'), ready: true, cur_gc_id: lastDigitcurgc_id, is_gc: is_gc,
                companyID: companyID, due_date: due_date,isdesable: isdesable,cur_files_json: task_json.object_data.bin_files, filterAlldatacheck: filterAlldatacheck, currentcompanyName: currentcompanyName,
                giftedDate,
                assign_data: assign_data, dicloserid: dicloserid,
                app_levels,
                approval_state,
                approver_entitled,
                approval_data,
                manager_id,
                module_config: module_config,
                approval_level,
                specificUserID,
                giftGivenTo: 'giftGivenTo' in task_json.object_data.headerinfo ? task_json.object_data.headerinfo.giftGivenTo : '',
                app_currency: 'app_currency' in task_json.object_data.headerinfo ? task_json.object_data.headerinfo.app_currency : Store.getStoreData('currencyDetails').currency,
                app_minvalue: 'app_minvalue' in task_json.object_data.headerinfo ? task_json.object_data.headerinfo.app_minvalue : 0,
                isShowAppValue : 'isShowAppValue' in task_json.object_data.headerinfo ? task_json.object_data.headerinfo.isShowAppValue : false,
                isUserlevelApproval
            });
        }else{
            // if(gc_companies.length === 0){
            //     cur_gc_id = parseInt(Store.getStoreData('curCompanyID'));
            // }
            //console.log("Sourav=====>",cur_gc_id);
            let due_date = new Date();
            let gc_company = Store.getStoreData('gc_company');
            let update_gc_company = [];
            if('general' in Store.getStoreData('module_config') && 'show_parent_company_gifts' in Store.getStoreData('module_config').general && Store.getStoreData('module_config').general.show_parent_company_gifts === false){
                for(let gc of gc_company) {
                    if(parseInt(gc.id) !== parseInt(Store.getStoreData('curCompanyID'))){
                        update_gc_company.push(gc)
                    }
                }
            }else{
                update_gc_company = gc_company;
            }

            let companyUsers = {};
            if(!Store.getStoreData('has_gc')){
                companyUsers = update_gc_company[0].users;
                cur_gc_id = update_gc_company[0].id;
            }
            let contactsArray = [];
            if(Object.keys(companyUsers).length !== 0){
                Object.keys(companyUsers).map((d, index) => {
                    if(d in contracts){
                        let contactsObject = {}
                        contactsObject.id = d;
                        contactsObject.name = contracts[d];
                        contactsArray.push(contactsObject);
                    }
                })
            }
            let recipientlistold = Store.getStoreData('recipientlistUpdate');
            for(let i=0;i<contactsArray.length;i++){
                recipientlistold.push(contactsArray[i])
            }
            //console.log("contactsArray==>==>", recipientlistold)
            let recipientlistnew = recipientlistold.sort(function(a,b) {return (a.name > b.name) ? 1 : ((b.name > a.name) ? -1 : 0);} );

            assign_data.additional_fields = additional_fields;

            this.setState({ Ref_ID: "Not Yet Generated",
                            gc_company: update_gc_company,
                            gc_companies: gc_companies, ready: true, companyID: cur_gc_id,
                            cur_gc_id: cur_gc_id, is_gc: is_gc, due_date: due_date,recipientlist: recipientlistnew,giftTypeList: giftTypeList,
                            currency: currencyValue !== "" ? currencyValue : Store.getStoreData('currencyDetails').currency,
                            app_currency: currencyValue !== "" ? currencyValue : Store.getStoreData('currencyDetails').currency,
                            minvalue: minimumValue,
                            assign_data: assign_data});
            //console.log("this.generateRefID=",this.generateRefID())
        }
    }

    handleChange = (evt) => {
        this.setState({[evt.target.name]: evt.target.value});
    }

    handleChangeReceiveGiven = (evt) => {
        //custom field start
        let module_config = Store.getStoreData('module_config');
        let additional_fields = [];
        if(Object.keys(module_config).length > 0) {
            if (module_config.custom_fields.additional_fields.length !== 0 && module_config.custom_fields.enabled) {
                let arrayAdditionalField = Object.values(module_config.custom_fields.additional_fields);
                arrayAdditionalField.forEach((item) => {
                    if(item.isActive === 1){
                        let copy_item = JSON.parse(JSON.stringify(item));
                        copy_item.value = item.type === "Calendar" ? new Date() : "";
                        copy_item.value = item.type === "Dropdown" ? "" : copy_item.value;
                        additional_fields.push(copy_item);
                    }
                })
            }
        }
        //console.log("additional_fields===>", additional_fields)
        //custom field end
        let assign_data = {};
        assign_data.additional_fields = additional_fields;

        this.setState({[evt.target.name]: evt.target.value, assign_data});
    }

    onChangeGiftedDate = (date) => {
        this.setState({giftedDate: date});
    }

    handleChangeRecipient = (evt) => {
        if(evt.target.value != 'Other'){
           this.setState({recipientName: evt.target.value,recipientNameAll: evt.target.value,isOtherRecipientName: false})
        }else{
            this.setState({isOtherRecipientName: true, recipientName: evt.target.value, recipientNameAll: ""})
        }
    }

    handleChangeCurGc = (evt) => {
        let gc_companies = Store.getStoreData('gc_companies');
        let gc_company = Store.getStoreData('gc_company');
        let is_gc = true;
        //console.log("gc_company", gc_company);
        let companyUsers = {};
        for(let gc of gc_company) {
            //gc.id = (gc.id * 100000) + gc_company.id;
            if(parseInt(gc.id) === parseInt(evt.target.value)){
                is_gc = gc.is_gc;
                companyUsers = gc.users;
            }
        }
        let contracts = Store.getStoreData('contacts');
        let contactsArray = [];
        if(Object.keys(companyUsers).length !== 0){
            Object.keys(companyUsers).map((d, index) => {
                if(d in contracts){
                    let contactsObject = {}
                    contactsObject.id = d;
                    contactsObject.name = contracts[d];
                    contactsArray.push(contactsObject);
                }
            })
        }
        let recipientlist = Store.getStoreData('recipientlistUpdate');
        for(let i=0;i<contactsArray.length;i++){
            recipientlist.push(contactsArray[i])
        }
        //console.log("contactsArray==>==>", recipientlist)
        let recipientlistnew = recipientlist.sort(function(a,b) {return (a.name > b.name) ? 1 : ((b.name > a.name) ? -1 : 0);} );
        let cur_gc_id = parseInt(evt.target.value)
        let companyID = is_gc ? parseInt(gc_company[0].id) * 100000 + cur_gc_id : cur_gc_id;
        this.setState({ cur_gc_id: evt.target.value, is_gc: is_gc, companyID: companyID, recipientlist: recipientlistnew});
        //console.log("CURGCID", this.state.cur_gc_id);
    }

    filesLoaded = (files) => {
        let contact_id = Store.getStoreData('contact_id');
        for(let i=0; i < files.length; i++) {
            files[i].name = files[i].name.replace('&', '');
            //console.log('File_name', files[i].name);
            if(!('user_id' in files[i])) {
                files[i].user_id = contact_id;
                files[i].upload_date = Date.now();
            }

        }
        //console.log('files in Modal', files);
        this.setState({binFiles: files});
    }

    closeView = () => {
        if (document.referrer.endsWith("/tmview")) {
            window.location =  process.env.REACT_APP_TMVIEW_URL;
        } else {
            if(Store.getStoreData('isreferenceUrl') === true){
                if(Store.getStoreData('isReferenceEmail') === true){
                    window.location = "/";
                }
            } else {
                this.props.closeModal();
            }
        }
        //this.props.closeModal();
    }

    generateField = (data, data_container, isreadOnly) => {
    	return (
    		<div key={data.name}>
    			<MRModalLabel>
    			{
    				(() => {
    					if (data.required) {
    						return (<div>{data.nickname}<span style={{color: "red", paddingLeft: "5px", fontSize: "8px"}}><FaStarOfLife  title={data.nickname+" required"}/></span></div>);
    					} else {
    						return data.nickname;
    					}
    				})()
    			}
    			</MRModalLabel>
    			{
    				(() => {
    					let fieldhtml = <div></div>;
    					switch(data.type) {
    						case "Alpha":
    							fieldhtml = this.createAlphaField(data, data_container, isreadOnly);
    							break;
    						case "Numeric":
    							fieldhtml = this.createNumericField(data, data_container, isreadOnly);
    							break;
                            case "Dropdown":
                                fieldhtml = this.createDropdownField(data, data_container, isreadOnly);
                                break;
                            case "Date":
                                fieldhtml = this.createDateField(data, data_container);
                                break;
    						default:
    							fieldhtml = fieldhtml;
    					}
                        if (data_container === "additional_fields")
    					return fieldhtml;
    				})()
    			}
    		</div>
    	);
    }

    onChangeCustomDate = (name) => (date) => {
        //event.preventDefault();
        let assign_data = JSON.parse(JSON.stringify(this.state.assign_data));
        console.log("assign_data===>", assign_data)
        console.log("name===>", name)
        assign_data.additional_fields.forEach((item) => {
            if (item.name === name) {
                item.value = date;
            }
        })
        console.log("ModifyAssigndata+++==>", assign_data)
        this.setState({assign_data: assign_data});
    }

    createDateField = (data, data_container) => {
        console.log("Data==>", data)
        let curValue = data.value !== '' ? new Date(data.value) : new Date();
		return (<CSLDateCover id={"date-"+data.name} style={{display: "inline-block"}}>
                    <DatePicker
                            onChange={this.onChangeCustomDate(data.name)}
                            clearIcon={null}
                            locale={"en-GB"}
                            calendarIcon={<FaCalendarAlt style={{color: '#243E57'}} />}
                            value={curValue}
                    />
                </CSLDateCover>);
    }

    createAlphaField = (data, data_container, isreadOnly) => {
		return (<CSLTextInput id={"text-"+data.name} handleTextChange={this.handleTextChange} name={data.name} value={data.value} entity="additional_fields" field="value" disabled={isreadOnly} />);
    }

    handleTextChange = (obj) => {
		console.log("obj:", obj);
		let assign_data = JSON.parse(JSON.stringify(this.state.assign_data));
		if (obj.entity === null) {
			assign_data[obj.name] = obj.value;
		} else {
			assign_data[obj.entity].forEach(item => {
				if (item.name === obj.name) item[obj.field] = obj.value;
			})
		}
        this.setState({assign_data: assign_data});
	}

    createNumericField = (data, data_container, isreadOnly) => {
    	return (<MRModalInput id={"number-"+data.name} type="text" min={0} name={data.name} value={data.value} onChange={this.handleAddlField(data.type, data_container)} readOnly={isreadOnly}/>);
    }

    handleAddlField = (field_type, data_container) => (event) => {
        event.preventDefault();
        let assign_data = JSON.parse(JSON.stringify(this.state.assign_data));
        console.log("event.target.name===>", event.target.name)
        console.log("assign_data===>", assign_data)
        console.log("data_container===>", data_container)
        if (event.target.name === "smcr_role") {
            assign_data[event.target.name] = event.target.value;
            assign_data[data_container].forEach((item) => {
                if (item.name === event.target.name) {
                    item.value = event.target.value;
                }
            })
        } else {
            assign_data[data_container].forEach((item) => {
                if (item.name === event.target.name) {
                    item.value = event.target.value;
                }
            })
        }
        console.log("ModifyAssigndata+++==>", assign_data)
        this.setState({assign_data: assign_data});
    }

    createDropdownField = (data, data_container, isreadOnly) => {
    	return (
            <div>
        		<MRModalSelect id={"select-"+data.name} onChange={this.handleAddlField(data.type, data_container)} name={data.name} value={data.value} disabled={isreadOnly}>
                    <option key="sel" value="">Select</option>
        		    {
        		        data.dropdown_options.map((opt, index) => {
        		            return(
    	    		            <option key={index} value={opt}>{opt}</option>
        		            );
        		        })
        		    }
                    {
                        (() => {
                            if (data_container === "additional_fields") {
                                if ("other" in data) {
                                    if (data.other === true) {
                                        return (<option value="other">Other</option>);
                                    }
                                }
                            }
                        })()
                    }
        		</MRModalSelect>
                {
                    (() => {
                        if (data_container === "additional_fields") {
                            if ("other" in data) {
                                if (data.other === true && data.value === "other") {
                                    return (
                                        <div style={{marginTop: "15px"}}>
                                            <div style={{float: "left", width: "20%", boxSizing: "border-box", paddingTop: "12px"}}>
                                                <MRModalLabel style={{margin: "0px"}}>Please give details</MRModalLabel>
                                            </div>
                                            <div style={{float: "left", width: "80%", boxSizing: "border-box"}}>
                                                <CSLTextInput id={"text-"+data.name} entity="additional_fields" name={data.name} value={data.other_value} handleTextChange={this.handleTextChange} field="other_value"/>
                                            </div>
                                            <div style={{clear: "both"}}></div>
                                        </div>
                                    );
                                }
                            }
                        }
                    })()
                }
            </div>
    	);
    }

    openApproveGift = (event) => {
        event.preventDefault();
        if(this.state.approval_data.length !== 0){
            // if (this.state.approval_data[this.state.approval_state].comments === "") {
            //     let alert_param = {title: 'Warning', message: 'Comments field is mandatory and cannot be left blank.', ok_text: 'OK', confirm: false, alertHandler: this.samealertremoveHandler, stack: {id: 0}}
            //     this.setState({alert_param: alert_param})
            //     return false;
            // }
            if (this.state.approval_data.length === 2) {
                if (this.state.approval_data[0].performed_by === this.state.approval_data[1].performed_by) {
                    let alert_param = {title: 'Warning', message: 'Same person cannot provide first and second approval.', ok_text: 'OK', confirm: false, alertHandler: this.samealertremoveHandler, stack: {id: 0}}
                    this.setState({alert_param: alert_param})
                    return false;
                }
            }
        }
        //new part
        if (this.state.app_levels !== null) {
            if(this.state.app_levels.length === 1){
                this.setState({showApprovalReason: true, removalType: ""});
            }else{
                if (this.state.approval_data.length === 2) {
                    this.setState({showApprovalReason: true, removalType: ""});
                }else{
                    console.log("Sourav 12")
                    this.submitApproveGiftDirect(1);
                }
            }
        }else{
            this.setState({showApprovalReason: true, removalType: ""});
        }
        //new part
        // this.setState({showApprovalReason: true, removalType: ""});
    }

    handleChangeRemoval = (evt) => {
        console.log("key",evt.target.options.selectedIndex)
        let resultTypeValue = Store.getStoreData('module_config').result_types;
        let resultType = []
        for(let d of resultTypeValue){
            if(d.isActive !== 0){
                resultType.push(d);
            }
        }

        let curIndex = evt.target.options.selectedIndex;
        let showDescription = false;
        let reasonID = 0
        if(curIndex !== 0){
            if(resultType[curIndex - 1].isComments === 1){
                showDescription = true;
            }
            reasonID = resultType[curIndex - 1].resultTypeID
        }
        console.log("Sourav111",showDescription)
        this.setState({[evt.target.name]: evt.target.value, showDescription, reasonID});
    }

    submitApproveGiftDirect = (submit_form) => {

        let repNameAll = this.state.recipientNameAll
        let contracts = Store.getStoreData('contacts');
        let reciname = this.state.recipientName;
        let check_recipient_id = 0
        if(this.state.recipientName !== 'Other'){
            repNameAll = contracts[this.state.recipientName]
            reciname = contracts[this.state.recipientName]
            check_recipient_id = this.state.recipientName;
        }

        let headerinfo = {};
        headerinfo.gift_status = this.state.giftStatus;
        // headerinfo.recipient_name = this.state.recipientNameAll;
        headerinfo.donor_name = this.state.donorName;
        headerinfo.gift_typeid = parseInt(this.state.giftTypeid);
        headerinfo.currency = this.state.currency;
        headerinfo.minvalue = this.state.minvalue;
        headerinfo.gift_description = this.state.giftDescription;
        // headerinfo.check_recipient_value = this.state.recipientName;
        headerinfo.recipient_name = repNameAll;
        headerinfo.check_recipient_value = reciname;
        headerinfo.check_recipient_id = check_recipient_id;
        headerinfo.giftedDate = this.state.giftedDate;
        headerinfo.additional_fields = this.state.assign_data.additional_fields;
        headerinfo.approval_level = this.state.approval_level;
        headerinfo.specificUserID = this.state.specificUserID;
        headerinfo.giftGivenTo = this.state.giftGivenTo;
        headerinfo.app_currency = this.state.app_currency;
        headerinfo.app_minvalue = this.state.app_minvalue;
        headerinfo.isShowAppValue = this.state.isShowAppValue;

        let final_object_data = {};
        final_object_data.headerinfo = headerinfo;
        final_object_data.ref_id = this.state.Ref_ID;
        let bin_files = this.state.cur_files_json === null ? this.state.binFiles : [...this.state.binFiles, ...this.state.cur_files_json];

        // if(this.state.removalType === ""){
        //     let alert_param = {title: 'Warning', message: 'You must select a Result Type first.', ok_text: 'OK', confirm: false, alertHandler: this.samealertremoveHandler, stack: {id: 0}}
        //     this.setState({alert_param: alert_param})
        //     return;
        // }
        // if(this.state.showDescription && this.state.reasonDescription === ""){
        //     let alert_param = {title: 'Warning', message: 'Please provide a reason for approval.', ok_text: 'OK', confirm: false, alertHandler: this.samealertremoveHandler, stack: {id: 0}}
        //     this.setState({alert_param: alert_param})
        //     return;
        // }
        if (this.state.app_levels !== null) {
            if(this.state.app_levels.length === 1){
                let alert_param = {title: 'Confirm', message: 'The gift will be marked as approved and the discloser updated. Do you want to continue?', ok_text: 'Yes', cancel_text: 'No', confirm: true,
                alertHandler: this.acceptGiftAlert, stack: {final_object_data: final_object_data, bin_files: bin_files, submit_form: submit_form}}
                this.setState({alert_param: alert_param})
            }else {
                if (this.state.approval_data.length === 2) {
                    let alert_param = {title: 'Confirm', message: 'The gift will be marked as approved and the discloser updated. Do you want to continue?', ok_text: 'Yes', cancel_text: 'No', confirm: true, alertHandler: this.acceptGiftAlert, stack: {final_object_data: final_object_data, bin_files: bin_files, submit_form: submit_form}}
                    this.setState({alert_param: alert_param})
                } else {
                    let stack = {final_object_data: final_object_data, bin_files: bin_files, submit_form: submit_form}
                    this.acceptGiftAlert(true, stack)
                }
            }
        }else{
            let alert_param = {title: 'Confirm', message: 'The gift will be marked as approved and the discloser updated. Do you want to continue?', ok_text: 'Yes', cancel_text: 'No', confirm: true, alertHandler: this.acceptGiftAlert, stack: {final_object_data: final_object_data, bin_files: bin_files, submit_form: submit_form}}
            this.setState({alert_param: alert_param})
        }
        // let alert_param = {title: 'Confirm', message: 'The gift will be marked as approved and the discloser updated. Do you want to continue?', ok_text: 'Yes', cancel_text: 'No', confirm: true, alertHandler: this.acceptGiftAlert, stack: {final_object_data: final_object_data, bin_files: bin_files, submit_form: submit_form}}
        // this.setState({alert_param: alert_param})
    }

    submitApproveGift = (submit_form) => (event) => {
        event.preventDefault();

        let repNameAll = this.state.recipientNameAll
        let contracts = Store.getStoreData('contacts');
        let reciname = this.state.recipientName;
        let check_recipient_id = 0
        if(this.state.recipientName !== 'Other'){
            repNameAll = contracts[this.state.recipientName]
            reciname = contracts[this.state.recipientName]
            check_recipient_id = this.state.recipientName;
        }

        let headerinfo = {};
        headerinfo.gift_status = this.state.giftStatus;
        // headerinfo.recipient_name = this.state.recipientNameAll;
        headerinfo.donor_name = this.state.donorName;
        headerinfo.gift_typeid = parseInt(this.state.giftTypeid);
        headerinfo.currency = this.state.currency;
        headerinfo.minvalue = this.state.minvalue;
        headerinfo.gift_description = this.state.giftDescription;
        // headerinfo.check_recipient_value = this.state.recipientName;
        headerinfo.recipient_name = repNameAll;
        headerinfo.check_recipient_value = reciname;
        headerinfo.check_recipient_id = check_recipient_id;
        headerinfo.giftedDate = this.state.giftedDate;
        headerinfo.additional_fields = this.state.assign_data.additional_fields;
        headerinfo.approval_level = this.state.approval_level;
        headerinfo.specificUserID = this.state.specificUserID;
        headerinfo.giftGivenTo = this.state.giftGivenTo;
        headerinfo.app_currency = this.state.app_currency;
        headerinfo.app_minvalue = this.state.app_minvalue;
        headerinfo.isShowAppValue = this.state.isShowAppValue;

        let final_object_data = {};
        final_object_data.headerinfo = headerinfo;
        final_object_data.ref_id = this.state.Ref_ID;
        let bin_files = this.state.cur_files_json === null ? this.state.binFiles : [...this.state.binFiles, ...this.state.cur_files_json];

        if(this.state.removalType === ""){
            let alert_param = {title: 'Warning', message: 'You must select a Result Type first.', ok_text: 'OK', confirm: false, alertHandler: this.samealertremoveHandler, stack: {id: 0}}
            this.setState({alert_param: alert_param})
            return;
        }
        if(this.state.showDescription && this.state.reasonDescription === ""){
            let alert_param = {title: 'Warning', message: 'Please provide a reason for approval.', ok_text: 'OK', confirm: false, alertHandler: this.samealertremoveHandler, stack: {id: 0}}
            this.setState({alert_param: alert_param})
            return;
        }
        if (this.state.app_levels !== null) {
            if(this.state.app_levels.length === 1){
                let alert_param = {title: 'Confirm', message: 'The gift will be marked as approved and the discloser updated. Do you want to continue?', ok_text: 'Yes', cancel_text: 'No', confirm: true,
                alertHandler: this.acceptGiftAlert, stack: {final_object_data: final_object_data, bin_files: bin_files, submit_form: submit_form}}
                this.setState({alert_param: alert_param})
            }else {
                if (this.state.approval_data.length === 2) {
                    let alert_param = {title: 'Confirm', message: 'The gift will be marked as approved and the discloser updated. Do you want to continue?', ok_text: 'Yes', cancel_text: 'No', confirm: true, alertHandler: this.acceptGiftAlert, stack: {final_object_data: final_object_data, bin_files: bin_files, submit_form: submit_form}}
                    this.setState({alert_param: alert_param})
                } else {
                    let stack = {final_object_data: final_object_data, bin_files: bin_files, submit_form: submit_form}
                    this.acceptGiftAlert(true, stack)
                }
            }
        }else{
            let alert_param = {title: 'Confirm', message: 'The gift will be marked as approved and the discloser updated. Do you want to continue?', ok_text: 'Yes', cancel_text: 'No', confirm: true, alertHandler: this.acceptGiftAlert, stack: {final_object_data: final_object_data, bin_files: bin_files, submit_form: submit_form}}
            this.setState({alert_param: alert_param})
        }
        // let alert_param = {title: 'Confirm', message: 'The gift will be marked as approved and the discloser updated. Do you want to continue?', ok_text: 'Yes', cancel_text: 'No', confirm: true, alertHandler: this.acceptGiftAlert, stack: {final_object_data: final_object_data, bin_files: bin_files, submit_form: submit_form}}
        // this.setState({alert_param: alert_param})
    }

    samealertremoveHandler = (result,stack) => {
        this.setState({alert_param: null})
    }

    acceptGiftAlert = (result,stack) => {
        if(!result) {
            this.setState({alert_param: null})
            return
        }
        stack.final_object_data.headerinfo.approval_type = this.state.removalType;
        stack.final_object_data.headerinfo.approval_description = this.state.reasonDescription;
        let apov_lvl = 'none';
        let task_json = {}
        if (this.state.app_levels !== null) {
            if(this.state.app_levels.length === 1){
                stack.final_object_data.headerinfo.approval_data = this.state.approval_data;
                task_json = {
                    "task_data": { "module_id": "gifts", "last_action": "COMP_GIFTS_COMPLETE", "actiondate": Date.now(),
                    'system_id' : '1001', 'cur_lane' : 'COMP_GIFTS_COMPLETE',
                    "cur_assigned_to" : 0, 'task_type' : 'COMP_GIFTS', 'parent_task_id' : 0 },
                    "object_data": stack.final_object_data,
                    "action_data": { "task_id": this.state.taskid,"action": "COMP_GIFTS_COMPLETE", "actiondate": Date.now(), "assigned_to": 0, "cur_lane":"COMP_GIFTS_COMPLETE","approval_type":  this.state.removalType,
                    "approval_description": this.state.reasonDescription}
                }
            }else {
                if (this.state.approval_data.length === 2) {
                    stack.final_object_data.headerinfo.approval_data = this.state.approval_data;
                    task_json = {
                        "task_data": { "module_id": "gifts", "last_action": "COMP_GIFTS_COMPLETE", "actiondate": Date.now(),
                        'system_id' : '1001', 'cur_lane' : 'COMP_GIFTS_COMPLETE',
                        "cur_assigned_to" : 0, 'task_type' : 'COMP_GIFTS', 'parent_task_id' : 0 },
                        "object_data": stack.final_object_data,
                        "action_data": { "task_id": this.state.taskid,"action": "COMP_GIFTS_COMPLETE", "actiondate": Date.now(), "assigned_to": 0, "cur_lane":"COMP_GIFTS_COMPLETE","approval_type":  this.state.removalType,
                        "approval_description": this.state.reasonDescription}
                    }
                } else {
                    stack.final_object_data.headerinfo.approval_data = this.state.approval_data;
                    task_json = {
                        "task_data": { "module_id": "gifts", "last_action": "COMP_GIFTS_REGISTER", "actiondate": Date.now(),
                        'system_id' : '1001', 'cur_lane' : 'COMP_GIFTS_REGISTER',
                        "cur_assigned_to" : 0, 'task_type' : 'COMP_GIFTS', 'parent_task_id' : 0 },
                        "object_data": stack.final_object_data,
                        "action_data": { "task_id": this.state.taskid,"action": "COMP_GIFTS_REGISTER", "actiondate": Date.now(), "assigned_to": 0, "cur_lane":"COMP_GIFTS_REGISTER","approval_type":  this.state.removalType,
                        "approval_description": this.state.reasonDescription}
                    }

                    //new email to lm-ar
                    if(stack.final_object_data.headerinfo.approval_level === 'lm-ar'){
                        apov_lvl = 'ar'
                    }
                    //new email to lm-ar
                }
            }
        }else{
            task_json = {
                "task_data": { "module_id": "gifts", "last_action": "COMP_GIFTS_COMPLETE", "actiondate": Date.now(),
                'system_id' : '1001', 'cur_lane' : 'COMP_GIFTS_COMPLETE',
                "cur_assigned_to" : 0, 'task_type' : 'COMP_GIFTS', 'parent_task_id' : 0 },
                "object_data": stack.final_object_data,
                "action_data": { "task_id": this.state.taskid,"action": "COMP_GIFTS_COMPLETE", "actiondate": Date.now(), "assigned_to": 0, "cur_lane":"COMP_GIFTS_COMPLETE","approval_type":  this.state.removalType,
                "approval_description": this.state.reasonDescription}
            }
        }
        //  console.log("task jason", JSON.stringify(task_json));

        let curmytaskdata = Store.getStoreData('cur_q_modal_data');
        let curtask_jsonValue = JSON.parse(curmytaskdata.task_json);
        let from_api = 0
        if('from_api' in curtask_jsonValue.object_data){
            from_api = curtask_jsonValue.object_data.from_api;
        }
        let postData = {command: "update_gifts_task", task_json: task_json, bin_files: stack.bin_files, submit: stack.submit_form, taskid: this.state.taskid, dicloser_id: this.state.dicloserid, from_api: from_api,
                        apov_lvl: apov_lvl, gc_id: curmytaskdata.company_id };
        console.log("postData",postData)
        this.setState({submit_form: stack.submit_form, isLoadedIcon: true, loading: true})
        let api = new APICall();
        api.command(postData, this.processUpdateApproveGiftsData);
        this.setState({alert_param: null})
    }

    processUpdateApproveGiftsData = (result) => {
        this.setState({loading: false})
        console.log("result", result)
        let msg = '';
        let title = 'Alert';
        msg = 'Gifts & Hospitality Confirmed Successfully.'
        // alert('Gifts & Hospitality Confirmed Successfully');
        let alert_param = {title: title, message: msg, ok_text: 'OK', confirm: false, alertHandler: this.alertupdateHandler, stack: {reasonID: this.state.reasonID}}
        this.setState({alert_param: alert_param, isLoadedIcon: false})
    }

    alertupdateHandler = (result,stack) => {
        this.setState({alert_param: null})
        console.log("reasonID",stack.reasonID);
        let resultType = Store.getStoreData('module_config').result_types;
        let removalTypes = JSON.parse(JSON.stringify(resultType));
        let changemodule = 0
        removalTypes.forEach((item) => {
            if (item.resultTypeID === stack.reasonID && item.isDelete !== 1) {
                item.isDelete = 1;
                changemodule = 1
            }
        });

        console.log("modified submitedShareUserList", removalTypes);
        //console.log("modified submitedShareUserList", removalTypes);
        if(changemodule === 1){
            let {module_config} = this.state;
            module_config.result_types = removalTypes;
            const postData = {command: "save_module_config", module_config: module_config};
            const api = new APICall();
            api.command(postData, this.afterUpdatemoduleconfig);
        }else{
            if(Store.getStoreData('isreferenceUrl') === true){
                if(Store.getStoreData('isReferenceEmail') === true){
                    window.location = "/";
                }
            } else {
                window.location.reload();
            }
        }
    }

    afterUpdatemoduleconfig = (result) => {
        console.log(result);
        if(Store.getStoreData('isreferenceUrl') === true){
            if(Store.getStoreData('isReferenceEmail') === true){
                window.location = "/";
            }
        } else {
            window.location.reload();
        }
        // window.location.reload();
   }

    changeApprovalComments = (event) => {
        event.preventDefault();
        let approval_data = JSON.parse(JSON.stringify(this.state.approval_data));
        approval_data[this.state.approval_state].comments = event.target.value;
        this.setState({approval_data});
    }
    closeApproval = (event) => {
        event.preventDefault();
        this.setState({showApprovalReason: false});
    }

    openRemoval = (event) => {
        event.preventDefault();
        if(this.state.approval_data.length !== 0){
            if (this.state.approval_data[this.state.approval_state].comments === "") {
                let alert_param = {title: 'Warning', message: 'Comments field is mandatory and cannot be left blank.', ok_text: 'OK', confirm: false, alertHandler: this.samealertremoveHandler, stack: {id: 0}}
                this.setState({alert_param: alert_param})
                return false;
            }
        }
        this.setState({showRemovalReason: true, removalType: ""});
    }

    closeRemoval = (event) => {
        event.preventDefault();
        this.setState({showRemovalReason: false});
    }

    submitReasonRemoval = (event) => {
        event.preventDefault();
        let msg = '';
        let title = 'Warning';
        if(this.state.removalType === ""){
            msg = 'You must select a Result Type first.';
            let alert_param = {title: title, message: msg, ok_text: 'OK', confirm: false, alertHandler: this.samealertremoveHandler, stack: {id: 0}}
            this.setState({alert_param: alert_param})
            return;
        }
        if(this.state.showDescription && this.state.reasonDescription === ""){
            msg = 'Please provide a reason for approval.';
            let alert_param = {title: title, message: msg, ok_text: 'OK', confirm: false, alertHandler: this.samealertremoveHandler, stack: {id: 0}}
            this.setState({alert_param: alert_param})
            return;
        }
        let alert_param = {title: 'Confirm', message: 'The gift will be removed from the register and the discloser updated. Do you want to continue?', ok_text: 'Yes', cancel_text: 'No', confirm: true, alertHandler: this.rejectGiftAlert, stack: {id: 0}}
        this.setState({alert_param: alert_param})
    }

    rejectGiftAlert = (result,stack) => {
        if(!result) {
           this.setState({alert_param: null})
           return
       }

       let form_data = Store.getStoreData('cur_q_modal_data');
       let task_json = JSON.parse(form_data.task_json);
       let headerinfo = JSON.parse(form_data.task_json).object_data.headerinfo;

       let repNameAll = this.state.recipientNameAll
       let contracts = Store.getStoreData('contacts');
       let reciname = this.state.recipientName;
       let check_recipient_id = 0
       if(this.state.recipientName !== 'Other'){
           repNameAll = contracts[this.state.recipientName]
           reciname = contracts[this.state.recipientName]
           check_recipient_id = this.state.recipientName;
       }

       headerinfo['gift_status'] = this.state.giftStatus;
       headerinfo['donor_name'] = this.state.donorName;
       headerinfo['gift_typeid'] = parseInt(this.state.giftTypeid);
       headerinfo['currency'] = this.state.currency;
       headerinfo['minvalue'] = this.state.minvalue;
       headerinfo['gift_description'] = this.state.giftDescription;
       headerinfo['recipient_name'] = repNameAll;
       headerinfo['check_recipient_value'] = reciname;
       headerinfo['check_recipient_id'] = check_recipient_id;
       headerinfo['giftedDate'] = this.state.giftedDate;
       headerinfo['additional_fields'] = this.state.assign_data.additional_fields;
       headerinfo['specificUserID'] = this.state.specificUserID;
       headerinfo['giftGivenTo'] = this.state.giftGivenTo;
       headerinfo['app_currency'] = this.state.app_currency;
       headerinfo['app_minvalue'] = this.state.app_minvalue;
       headerinfo['isShowAppValue'] = this.state.isShowAppValue;

       let action_data = {
           task_id: this.state.taskid,
           cur_lane: "COMP_GIFTS_REMOVE",
           assigned_to: 0,
           action: "COMP_GIFTS_REMOVE",
           performed_by: Store.getStoreData('contact_id'),
           removal_type:  this.state.removalType,
           removal_description: this.state.reasonDescription
       };

       task_json['object_data']['headerinfo'] = headerinfo
       task_json['object_data']['removal_type'] = this.state.removalType;
       task_json['object_data']['removal_description'] = this.state.reasonDescription;
       task_json['action_data'] = action_data

       let postData = { "command": "removalTask", "SystemID": 1001, task_json: task_json, task_id: this.state.taskid, dicloser_id: this.state.dicloserid };
       console.log("postData",postData);
       let api = new APICall();
       this.setState({isLoadedIcon: true, alert_param: null, loading: true})
       api.command(postData, this.processUpdateRemoveGiftsData);
   }

    processUpdateRemoveGiftsData = (result) => {
        this.setState({isLoadedIcon: false})
        console.log("result", result)
        let alert_param = {title: 'Alert', message: 'The gift has been removed.', ok_text: 'OK', confirm: false, alertHandler: this.alertupdateHandler, stack: {reasonID: this.state.reasonID}}
        this.setState({alert_param: alert_param})
    }

    render()
    {
        if(!this.state.ready) {
            return(<div>Loading...</div>);
        }
        const gifteddate_obj = new Date(this.state.giftedDate);
        const gifteddate_day = gifteddate_obj.getDate() < 10 ? "0"+gifteddate_obj.getDate().toString() : gifteddate_obj.getDate();
        const gifteddate_month = gifteddate_obj.getMonth()+1 < 10 ? `0${(gifteddate_obj.getMonth() + 1).toString()}` : gifteddate_obj.getMonth()+1;
        const gifteddate_year = gifteddate_obj.getFullYear();
        const gifteddate_date = `${gifteddate_day}/${gifteddate_month}/${gifteddate_year}`;

        let module_config = Store.getStoreData('module_config');

        let resultType = []
        let resultTypeValue = Store.getStoreData('module_config').result_types;
        for(let d of resultTypeValue){
            if(d.isActive !== 0){
                resultType.push(d);
            }
        }
        let contacts = Store.getStoreData('contacts')

        let curmytaskdata = Store.getStoreData('cur_q_modal_data');
        let status = "";
        if(curmytaskdata.cur_lane === "COMP_GIFTS_REGISTER") {
            status =  "Registered";
        }else if(curmytaskdata.cur_lane === "COMP_GIFTS_COMPLETE") {
            status =  "Confirmed";
        }else if(curmytaskdata.cur_lane === "COMP_GIFTS_REMOVE") {
            status = "Removed"
        }

        return (
            <div>
                {
                    (() => {
                        if(this.state.isLoadedIcon){
                            return <div><CSLLoader style={{position: 'absolute'}}/></div>
                        }
                    })()
                }
                <MRModalContainer>
                    <MRModalHeader>
                        <MRModalHeaderText>Add Gifts & Hospitality Entry</MRModalHeaderText>
                        <MRModalHeaderCloseBtn onClick={this.closeView}><FaTimes /></MRModalHeaderCloseBtn>
                        <div style={{clear: "both"}}></div>
                        <MRModalSubHeaderText>Fill out the below fields to fully submit your Gifts & Hospitality disclosure.</MRModalSubHeaderText>
                        <div style={{clear: "both"}}></div>
                    </MRModalHeader>
                    <ScoreBodyContainer>
                    <MRModalBody>
                        <div style={{clear: "both"}}></div>
                        <div style={{position: "relative",padding: "35px 35px"}}>
                        {/* <div style={{paddingTop: '20px',marginBottom: "10px"}}>
                            <ModalNameLabelDivReference>GIFTS ID</ModalNameLabelDivReference>
                            <ModalNameLabelDivReferenceID>{this.state.Ref_ID}</ModalNameLabelDivReferenceID>
                        </div> */}
                        {
                            (() => {
                                if(Store.getStoreData('q_index') >= 0) {
                                    return(
                                        <div>
                                            <ModalNameLabelDivReference>Company - </ModalNameLabelDivReference>
                                            <MRModalLabel style={{display: "inline-block",marginLeft: "5px"}}>{this.state.currentcompanyName}</MRModalLabel>
                                        </div>
                                    )
                                }else{
                                    if(Store.getStoreData('gc_companies').length === 0){
                                        return(
                                            <div>
                                                <ModalNameLabelDivReference>Company - </ModalNameLabelDivReference>
                                                {
                                                    (() => {
                                                        if(this.state.gc_company.length !== 0){
                                                            return(<MRModalLabel style={{display: "inline-block",marginLeft: "5px"}}>{this.state.gc_company[0].company_name}</MRModalLabel>)
                                                        }
                                                    })()
                                                }
                                            </div>
                                        )
                                    }else{
                                        return(
                                            <div>
                                                <MRModalLabel>Select Company </MRModalLabel>
                                                <MRModalSelect id="select_company" name="cur_gc_id" value={this.state.cur_gc_id} onChange={this.handleChangeCurGc}>
                                                <option key={0} value={0}>Select</option>
                                                    {
                                                            this.state.gc_company.map((gc) => {
                                                            return(
                                                            <option key={gc.id} value={gc.id}>{gc.company_name}</option>
                                                            );
                                                        })
                                                    }
                                                </MRModalSelect>
                                            </div>
                                        )
                                    }
                                }
                            })()
                        }
                            <SpacerS/>
                            <MRModalLabel>Type</MRModalLabel>
                            <MRModalSelect id='type' name="giftTypeid" onChange={this.handleChange} value={this.state.giftTypeid} disabled={this.state.isdesable}>
                                <option value={0}>Select</option>
                                {
                                    this.state.giftTypeList.map((gf,index) => {
                                        return(
                                            <option key={index} value={gf.giftTypeID}>{gf.giftTypeName}</option>
                                        );
                                    })
                                }
                            </MRModalSelect>
                            <SpacerS/>
                            <MRModalLabel>Was the gift received or given?</MRModalLabel>
                            <MRModalSelect id='received_given' name="giftStatus" onChange={this.handleChangeReceiveGiven} value={this.state.giftStatus} disabled={this.state.isdesable}>
                                <option value="received">Received</option>
                                <option value="given">Given</option>
                            </MRModalSelect>
                            <SpacerS/>
                            <MRModalLabel>Gifted Date</MRModalLabel>
                            {
                                (() => {
                                    if(this.state.isdesable){
                                        return( <MRModalInput style={{height: "22px", width: "35%"}} type="text" defaultValue={gifteddate_date} readOnly/>   )
                                    }else{
                                        return(
                                            <CSLDateCover id="gifted_date" style={{display: "inline-block"}}>
                                                <DatePicker
                                                        onChange={this.onChangeGiftedDate}
                                                        clearIcon={null}
                                                        calendarIcon={<FaCalendarAlt style={{color: '#243E57'}} />}
                                                        locale={"en-GB"}
                                                        value={this.state.giftedDate}
                                                />
                                            </CSLDateCover>
                                        )
                                    }
                                })()
                            }
                            <SpacerS/>
                            {
                                (() => {
                                    if(this.state.giftStatus === "received"){
                                        return(<MRModalLabel>Recipient</MRModalLabel>)
                                    }else{
                                        return(<MRModalLabel>Given By</MRModalLabel>)
                                    }
                                })()
                            }
                            <MRModalSelect id='recipient' name="recipientName" onChange={this.handleChangeRecipient} value={this.state.recipientName} disabled={this.state.isdesable}>
                                <option value="">Select</option>
                                {
                                    this.state.recipientlist.map((rc,index) => {
                                        return(
                                        <option key={index} value={rc.id}>{rc.name}</option>
                                        );
                                    })
                                }
                            </MRModalSelect>
                            {
                                (() => {
                                    if(this.state.isOtherRecipientName){
                                        return(<div>
                                            <SpacerS/>
                                            <MRModalLabel>Please provide details</MRModalLabel>
                                            <MRModalInput id='recipient_name' type="text" name="recipientNameAll" onChange={this.handleChange} value={this.state.recipientNameAll} readOnly={this.state.isdesable}/>
                                        </div>)
                                    }
                                })()
                            }
                            {
                                (() => {
                                    if(this.state.giftStatus === "given"){
                                        return(<div>
                                            <MRModalLabel style={{marginTop: "30px"}}>Given To</MRModalLabel>
                                            <MRModalTextarea id='giftGivenTo' rows="6" name="giftGivenTo" value={this.state.giftGivenTo} onChange={this.handleChange} readOnly={this.state.isdesable}/>
                                        </div>)
                                    }
                                })()
                            }
                            {
                                (() => {
                                    if(this.state.giftStatus === "received"){
                                        return(<div>
                                            <SpacerS/>
                                            <MRModalLabel>Donor</MRModalLabel>
                                            <MRModalInput id='donor' type="text" placeholder="Enter donor name..." name="donorName" onChange={this.handleChange} value={this.state.donorName} readOnly={this.state.isdesable}/>
                                        </div>)
                                    }
                                })()
                            }
                            <SpacerS/>
                            <div style={{display: "inline-block", width: "50%"}}>
                                <MRModalLabel>Value</MRModalLabel>
                                <MRModalInput style={{display: "inline-block",width: "10%"}} value={this.state.currency} readOnly/>
                                <MRModalInput style={{width: "10%",marginLeft: "30px"}} id='value' type="number" step="any" name="minvalue" min="0" onKeyDown={blockInvalidChar} onChange={this.handleChange} value={this.state.minvalue} readOnly={this.state.isdesable}/>
                            </div>
                            {
                                (() => {
                                    if(this.state.isShowAppValue){
                                        return(
                                        <div style={{display: "inline-block", width: "50%"}}>
                                            <MRModalLabel>Approximate {this.state.app_currency} value</MRModalLabel>
                                            <MRModalInput style={{display: "inline-block",width: "25%"}} value={this.state.app_currency} readOnly/>
                                            <MRModalInput style={{width: "25%",marginLeft: "30px"}} id='app_value' type="number" step="any" name="app_minvalue" min="0" onKeyDown={blockInvalidChar} onChange={this.handleChange} value={this.state.app_minvalue} readOnly={this.state.isdesable}/>
                                        </div>
                                        )
                                    }
                                })()
                            }
                            {/* <div style={{display: "inline-block", width: "50%"}}>
                                <MRModalLabel>Approximate {this.state.app_currency} value</MRModalLabel>
                                <MRModalInput style={{display: "inline-block",width: "10%"}} value={this.state.app_currency} readOnly/>
                                <MRModalInput style={{width: "10%",marginLeft: "30px"}} id='app_value' type="number" step="any" name="app_minvalue" onChange={this.handleChange} value={this.state.app_minvalue} readOnly={this.state.isdesable}/>
                            </div> */}
                            <SpacerS/>
                            {/* custom field start */}
                            {
                                (() => {
                                    if('custom_fields' in module_config && module_config.custom_fields.enabled){
                                        if (this.state.assign_data.additional_fields.length !== 0) {
                                            let custom_html = [];
                                            this.state.assign_data.additional_fields.forEach((item) => {
                                                if(item.displayFor === 'both' || item.displayFor === this.state.giftStatus){
                                                    const field = this.generateField(item, "additional_fields", this.state.isdesable);
                                                    custom_html.push(field);
                                                }
                                            })
                                            return custom_html;
                                        }
                                    }
                                })()
                            }
                            {/* custom field end */}
                            <SpacerS/>
                            <MRModalLabel style={{marginTop: "30px"}}>Description</MRModalLabel>
                            <MRModalLabel style={{fontWeight: "100",fontSize: "13px",color: "#919292"}}>Please provide a description of the gift/hospitality</MRModalLabel>
                            <MRModalTextarea id='description' rows="6" name="giftDescription" value={this.state.giftDescription} onChange={this.handleChange} readOnly={this.state.isdesable}/>
                            <SpacerS/>
                            {
                                (() => {
                                    if(this.state.isdesable){
                                        if(this.state.cur_files_json.length !== 0){
                                            return(<MRModalLabel>Attachments</MRModalLabel> )
                                        }
                                    }else{
                                        return(<MRModalLabel>Attachments</MRModalLabel> )
                                    }
                                })()
                            }
                            <ModalNameInputDiv>
                                <Dropzone onFilesAdded={this.filesLoaded} initFiles={this.state.cur_files_json} addnew={!this.state.isdesable}/>
                            </ModalNameInputDiv>
                            <MRTasksContainer>
                                {this.state.approval_state !== null && this.state.approval_state >= 1 && this.state.curlane !== 'COMP_GIFTS_COMPLETE' && (this.state.approval_level === "ar-lm" || this.state.approval_level === "lm-ar") &&
                                <MRSuccessContainer>
                                    <div style={{ color: "#6c9451", fontWeight: "600" }}>Two Level Approval</div>
                                    <div style={{ color: "#6c9451", marginTop: "5px" }}>This Gift Register has received first level approval.</div>
                                    <div style={{ color: "#6c9451", marginTop: "20px" }}>
                                    <div style={{float: "left", width: "15%", fontWeight: "600"}}>Reviewer:</div>
                                    <div style={{float: "left", width: "85%"}}>
                                        {contacts[this.state.approval_data[this.state.approval_state - 1].performed_by]}
                                        </div>
                                    <div style={{clear: "both"}}></div>
                                    </div>
                                    <div style={{ color: "#6c9451", marginTop: "5px" }}>
                                    <div style={{float: "left", width: "15%", fontWeight: "600"}}>Approved:</div>
                                    <div style={{float: "left", width: "85%"}}>
                                        {moment.unix(this.state.approval_data[this.state.approval_state - 1].action_time).format('DD/MM/YYYY hh:mm a')}
                                    </div>
                                    <div style={{clear: "both"}}></div>
                                    </div>
                                    <div style={{ color: "#6c9451", marginTop: "5px" }}>
                                    <div style={{float: "left", width: "15%", fontWeight: "600"}}>Comments:</div>
                                    <div style={{float: "left", width: "85%"}}>
                                        {this.state.approval_data[this.state.approval_state - 1].comments}
                                    </div>
                                    <div style={{clear: "both"}}></div>
                                    </div>
                                </MRSuccessContainer>
                                }
                                {
                                    (() => {
                                        if (this.state.approval_level === "lm") {
                                            if (this.state.manager_id === null) {
                                                return (
                                                <MRDangerContainer>
                                                    <div style={{ color: "#a91414", fontWeight: "600" }}>First Level Approval</div>
                                                    <div style={{ color: "#b75050", marginTop: "5px" }}>This Gift Register requires Line Manager approval but the employee does not have a Line Manager set up</div>
                                                    <div style={{ color: "#b75050", marginTop: "15px" }}>Please add a Line Manager to the employee or provide the approval.</div>
                                                </MRDangerContainer>
                                                )
                                            }
                                        }
                                    })()
                                }
                                {
                                    (() => {
                                        if (this.state.approval_level === "ar-lm") {
                                            if (this.state.approval_state !== null && this.state.approval_state >= 1 && this.state.manager_id === null) {
                                                return (
                                                <MRDangerContainer>
                                                    <div style={{ color: "#a91414", fontWeight: "600" }}>Two Level Approval</div>
                                                    <div style={{ color: "#b75050", marginTop: "5px" }}>This Gift Register requires Line Manager approval but the employee does not have a Line Manager set up</div>
                                                    <div style={{ color: "#b75050", marginTop: "15px" }}>Please add a Line Manager to the employee or provide the second level approval. Note the same person cannot provide first and second approval.</div>
                                                </MRDangerContainer>
                                                )
                                            }
                                        }
                                    })()
                                }
                                {
                                    (() => {
                                        if (this.state.approval_level === "lm-ar") {
                                            if (this.state.approval_state !== null && this.state.approval_state === 0 && this.state.manager_id === null) {
                                                return (
                                                <MRDangerContainer>
                                                    <div style={{ color: "#a91414", fontWeight: "600" }}>Two Level Approval</div>
                                                    <div style={{ color: "#b75050", marginTop: "5px" }}>This Gift Register requires Line Manager approval but the employee does not have a Line Manager set up</div>
                                                    <div style={{ color: "#b75050", marginTop: "15px" }}>Please add a Line Manager to the employee or provide the first level approval. Note the same person cannot provide first and second approval.</div>
                                                </MRDangerContainer>
                                                )
                                            }
                                        }
                                    })()
                                }
                                {
                                    (() => {
                                        if(this.state.curlane !== 'COMP_GIFTS_COMPLETE' && this.state.approval_data.length !== 0){
                                            return(<div>
                                                <MRModalLabel style={{ marginTop: "50px" }}>Comments</MRModalLabel>
                                                <MRModalTextarea rows="5" name="gifts_comments" readOnly={this.state.readOnly} value={this.state.approval_data[this.state.approval_state].comments} onChange={this.changeApprovalComments} />
                                            </div>)
                                        }
                                    })()
                                }
                            </MRTasksContainer>
                        </div>
                    </MRModalBody>
                    </ScoreBodyContainer>
                    <MRModalFooter>
                    {
                        (() => {
                            if(Store.getStoreData('q_index') >= 0) {
                                if(this.state.curlane === "COMP_GIFTS_COMPLETE") {
                                    return(
                                        <div style={{position: "relative",padding: "17px"}}>
                                            <MRModalCancelBtn onClick={this.closeView}>Cancel</MRModalCancelBtn>
                                        </div>
                                    )
                                }else{
                                    if (this.state.approval_level.includes("lm")) {
                                        if (this.state.approval_level === "ar-lm") {
                                            if (this.state.approval_state !== null && this.state.approval_state >= 1) {
                                                return(
                                                    <div style={{position: "relative",padding: "17px"}}>
                                                        {
                                                            (() => {
                                                                if(!this.state.showRemovalReason && !this.state.showApprovalReason){
                                                                    return(<div>
                                                                        <MRModalNextBtn onClick={this.openApproveGift}>Approve</MRModalNextBtn>
                                                                        {
                                                                            (() => {
                                                                                    if(status !== "Removed"){
                                                                                        return(<MRModalRemoveBtn onClick={this.openRemoval}>Decline</MRModalRemoveBtn>)
                                                                                    }
                                                                            })()
                                                                        }
                                                                        <MRModalCancelBtn onClick={this.closeView}>Cancel</MRModalCancelBtn>
                                                                        </div>)
                                                                }else{
                                                                    return(<div>
                                                                        <MRModalNextBtn style={{backgroundColor: "#D4D4D4", color: "#ACACAC", border: "1px solid #D4D4D4",cursor: "default"}}>Approve</MRModalNextBtn>
                                                                        <MRModalRemoveBtn style={{backgroundColor: "#D4D4D4", color: "#ACACAC", border: "1px solid #D4D4D4",cursor: "default"}}>Decline</MRModalRemoveBtn>
                                                                        <MRModalCancelBtn style={{backgroundColor: "#D4D4D4", color: "#ACACAC", border: "1px solid #D4D4D4",cursor: "default"}}>Cancel</MRModalCancelBtn>
                                                                    </div>)
                                                                }
                                                            })()
                                                        }
                                                    </div>
                                                )
                                            }else{
                                                return(
                                                    <div style={{position: "relative",padding: "17px"}}>
                                                        <MRModalCancelBtn onClick={this.closeView}>Cancel</MRModalCancelBtn>
                                                    </div>
                                                )
                                            }
                                        }else if(this.state.approval_level === "lm-ar"){
                                            if (this.state.approval_state !== null && this.state.approval_state === 0) {
                                                return(
                                                    <div style={{position: "relative",padding: "17px"}}>
                                                        {
                                                            (() => {
                                                                if(!this.state.showRemovalReason && !this.state.showApprovalReason){
                                                                    return(<div>
                                                                        <MRModalNextBtn onClick={this.openApproveGift}>Approve</MRModalNextBtn>
                                                                        {
                                                                            (() => {
                                                                                    if(status !== "Removed"){
                                                                                        return(<MRModalRemoveBtn onClick={this.openRemoval}>Decline</MRModalRemoveBtn>)
                                                                                    }
                                                                            })()
                                                                        }
                                                                        <MRModalCancelBtn onClick={this.closeView}>Cancel</MRModalCancelBtn>
                                                                        </div>)
                                                                }else{
                                                                    return(<div>
                                                                        <MRModalNextBtn style={{backgroundColor: "#D4D4D4", color: "#ACACAC", border: "1px solid #D4D4D4",cursor: "default"}}>Approve</MRModalNextBtn>
                                                                        <MRModalRemoveBtn style={{backgroundColor: "#D4D4D4", color: "#ACACAC", border: "1px solid #D4D4D4",cursor: "default"}}>Decline</MRModalRemoveBtn>
                                                                        <MRModalCancelBtn style={{backgroundColor: "#D4D4D4", color: "#ACACAC", border: "1px solid #D4D4D4",cursor: "default"}}>Cancel</MRModalCancelBtn>
                                                                    </div>)
                                                                }
                                                            })()
                                                        }
                                                    </div>
                                                )
                                            }else{
                                                return(
                                                    <div style={{position: "relative",padding: "17px"}}>
                                                        <MRModalCancelBtn onClick={this.closeView}>Cancel</MRModalCancelBtn>
                                                    </div>
                                                )
                                            }
                                        }else{
                                            return(
                                                <div style={{position: "relative",padding: "17px"}}>
                                                    {
                                                        (() => {
                                                            if(!this.state.showRemovalReason && !this.state.showApprovalReason){
                                                                return(<div>
                                                                    <MRModalNextBtn onClick={this.openApproveGift}>Approve</MRModalNextBtn>
                                                                    {
                                                                        (() => {
                                                                                if(status !== "Removed"){
                                                                                    return(<MRModalRemoveBtn onClick={this.openRemoval}>Decline</MRModalRemoveBtn>)
                                                                                }
                                                                        })()
                                                                    }
                                                                    <MRModalCancelBtn onClick={this.closeView}>Cancel</MRModalCancelBtn>
                                                                    </div>)
                                                            }else{
                                                                return(<div>
                                                                    <MRModalNextBtn style={{backgroundColor: "#D4D4D4", color: "#ACACAC", border: "1px solid #D4D4D4",cursor: "default"}}>Approve</MRModalNextBtn>
                                                                    <MRModalRemoveBtn style={{backgroundColor: "#D4D4D4", color: "#ACACAC", border: "1px solid #D4D4D4",cursor: "default"}}>Decline</MRModalRemoveBtn>
                                                                    <MRModalCancelBtn style={{backgroundColor: "#D4D4D4", color: "#ACACAC", border: "1px solid #D4D4D4",cursor: "default"}}>Cancel</MRModalCancelBtn>
                                                                </div>)
                                                            }
                                                        })()
                                                    }
                                                </div>
                                            )
                                        }
                                    }else if(this.state.approval_level === "su"){
                                        return(
                                            <div style={{position: "relative",padding: "17px"}}>
                                                {
                                                    (() => {
                                                        if(!this.state.showApprovalReason){
                                                            if(this.state.isUserlevelApproval){
                                                                return(<div>
                                                                    <MRModalNextBtn onClick={this.openApproveGift}>Approve</MRModalNextBtn>
                                                                    {
                                                                        (() => {
                                                                                if(status !== "Removed"){
                                                                                    return(<MRModalRemoveBtn onClick={this.openRemoval}>Decline</MRModalRemoveBtn>)
                                                                                }
                                                                        })()
                                                                    }
                                                                    <MRModalCancelBtn onClick={this.closeView}>Cancel</MRModalCancelBtn>
                                                                    </div>)
                                                            }else{
                                                                return(<div>
                                                                    <MRModalNextBtn onClick={this.openApproveGift}>Approve</MRModalNextBtn>
                                                                    <MRModalCancelBtn onClick={this.closeView}>Cancel</MRModalCancelBtn>
                                                                    </div>)
                                                            }
                                                        }else{
                                                            if(this.state.isUserlevelApproval){
                                                                return(<div>
                                                                    <MRModalNextBtn style={{backgroundColor: "#D4D4D4", color: "#ACACAC", border: "1px solid #D4D4D4",cursor: "default"}}>Approve</MRModalNextBtn>
                                                                    <MRModalRemoveBtn style={{backgroundColor: "#D4D4D4", color: "#ACACAC", border: "1px solid #D4D4D4",cursor: "default"}}>Decline</MRModalRemoveBtn>
                                                                    <MRModalCancelBtn style={{backgroundColor: "#D4D4D4", color: "#ACACAC", border: "1px solid #D4D4D4",cursor: "default"}}>Cancel</MRModalCancelBtn>
                                                                </div>)
                                                            }else{
                                                                return(<div>
                                                                    <MRModalNextBtn style={{backgroundColor: "#D4D4D4", color: "#ACACAC", border: "1px solid #D4D4D4",cursor: "default"}}>Approve</MRModalNextBtn>
                                                                    <MRModalCancelBtn style={{backgroundColor: "#D4D4D4", color: "#ACACAC", border: "1px solid #D4D4D4",cursor: "default"}}>Cancel</MRModalCancelBtn>
                                                                </div>)
                                                            }
                                                        }
                                                    })()
                                                }
                                            </div>
                                        )
                                    }else{
                                        return(
                                            <div style={{position: "relative",padding: "17px"}}>
                                                <MRModalCancelBtn onClick={this.closeView}>Cancel</MRModalCancelBtn>
                                            </div>
                                        )
                                    }
                                }
                            }
                        })()
                    }
                    </MRModalFooter>
                    {
                        (() => {
                            if(this.state.showRemovalReason){
                                return(<div>
                                    <MRModalBody>
                                        <hr style={{marginTop: "10px", color: "#E3E4E4"}}/>
                                        <div style={{clear: "both"}}></div>
                                        <div style={{position: "relative",padding: "35px 35px"}}>
                                            <MRModalLabel style={{marginTop: "25px",display: "inline-block"}}>Result</MRModalLabel><span style={{marginLeft: "5px",color: "#38B8B5"}}>*</span>
                                            <MRModalSelect style={{width: "33%",minWidth: "200px", marginLeft: "10px"}} name="removalType" onChange={this.handleChangeRemoval} value={this.state.removalType} >
                                            <option value="">Select</option>
                                            {
                                                resultType.map((r, index) => {
                                                    return (<option key={index} value={r.resultTypeName}>{r.resultTypeName}</option>);
                                                })
                                            }
                                            </MRModalSelect>
                                            {
                                                (() => {
                                                    if(this.state.showDescription){
                                                        return(
                                                            <div>
                                                                <MRModalLabel style={{marginTop: "25px",display: "inline-block"}}>Reason for Rejection</MRModalLabel><span style={{marginLeft: "5px",color: "#38B8B5"}}>*</span>
                                                                <MRModalTextarea rows="5" name="reasonDescription" value={this.state.reasonDescription} onChange={this.handleChange} />
                                                            </div>
                                                        )
                                                    }
                                                })()
                                            }
                                        </div>
                                    </MRModalBody>
                                    <MRModalFooter>
                                        <div style={{position: "relative",padding: "17px"}}>
                                            <MRModalNextBtn onClick={this.submitReasonRemoval}>Confirm</MRModalNextBtn>
                                            <MRModalCancelBtn onClick={this.closeRemoval}>Cancel</MRModalCancelBtn>
                                        </div>
                                        <div style={{clear: "both"}}></div>
                                    </MRModalFooter>
                                </div>)
                            }
                        })()
                    }
                    {
                        (() => {
                            if(this.state.showApprovalReason){
                                return(<div>
                                    <MRModalBody>
                                        <hr style={{marginTop: "10px", color: "#E3E4E4"}}/>
                                        <div style={{clear: "both"}}></div>
                                        <div style={{position: "relative",padding: "35px 35px"}}>
                                            <MRModalLabel style={{marginTop: "25px",display: "inline-block"}}>Result</MRModalLabel><span style={{marginLeft: "5px",color: "#38B8B5"}}>*</span>
                                            <MRModalSelect style={{width: "33%",minWidth: "200px", marginLeft: "10px"}} name="removalType" onChange={this.handleChangeRemoval} value={this.state.removalType} >
                                            <option value="">Select</option>
                                            {
                                                resultType.map((r, index) => {
                                                    return (<option key={index} value={r.resultTypeName}>{r.resultTypeName}</option>);
                                                })
                                            }
                                            </MRModalSelect>
                                            {
                                                (() => {
                                                    if(this.state.showDescription){
                                                        return(
                                                            <div>
                                                                <MRModalLabel style={{marginTop: "25px",display: "inline-block"}}>Reason for Approval</MRModalLabel><span style={{marginLeft: "5px",color: "#38B8B5"}}>*</span>
                                                                <MRModalTextarea rows="5" name="reasonDescription" value={this.state.reasonDescription} onChange={this.handleChange} />
                                                            </div>
                                                        )
                                                    }
                                                })()
                                            }
                                        </div>
                                    </MRModalBody>
                                    <MRModalFooter>
                                        <div style={{position: "relative",padding: "17px"}}>
                                            <MRModalNextBtn onClick={this.submitApproveGift(1)}>Confirm</MRModalNextBtn>
                                            <MRModalCancelBtn onClick={this.closeApproval}>Cancel</MRModalCancelBtn>
                                        </div>
                                        <div style={{clear: "both"}}></div>
                                    </MRModalFooter>
                                </div>)
                            }
                        })()
                    }
                </MRModalContainer>
                <AlertBox alertParam = {this.state.alert_param}/>
            </div>
        );
    }
}

export default TeamApprovalGiftsModal;
